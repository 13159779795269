import { PrimaryText } from "components/Common/PrimaryText";
import React from "react";
import { StyleSheet, Text, TextInput, View } from "react-native";
// import { AlertCircle } from 'react-native-feather';
import { Colors } from "~/constants/Colors";

export const CustomInput = (props) => {
  const {
    title,
    placeholder,
    onChange,
    errorMessage,
    value,
    secure,
    style,
    multiline,
  } = props;
  return (
    <>
      {!!title && (
        <PrimaryText fontSize={14} fontWeight={700} style={styles.inputTitle}>
          {title}
        </PrimaryText>
      )}
        <TextInput 
            style={[styles.input, style]}
            placeholder={placeholder}
            placeholderTextColor={Colors.primary.placeholder}
            value={value ? value : ''}
            onChangeText={(e) => onChange(e.replaceAll("'", '‘').replaceAll('"', '“') )}
            // onChangeText={(e) => onChange(e)}
            secureTextEntry={secure}
            multiline={multiline}
        />
      {errorMessage && (
        <View style={styles.errorContainer}>
          {/* <AlertCircle width={16} height={16} fill={Colors.primary.error} stroke={Colors.primary.white} /> */}
          <Text style={styles.errorMessage}>{errorMessage}</Text>
        </View>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  inputTitle: {
    paddingHorizontal: 16,
    paddingVertical: 4,
    paddingTop: 12,
    color: Colors.primary.darkGray,
  },

  input: {
    borderRadius: 16,
    height: 40,
    borderColor: "#999999",
    borderWidth: 0.5,
    paddingHorizontal: 16,
    color: Colors.primary.black,
  },
  errorContainer: {
    backgroundColor: "#FEEFEF",
    height: 32,
    justifyContent: "flex-start",
    alignItems: "center",
    paddingHorizontal: 16,
    paddingVertical: 6,
    borderRadius: 8,
    flexDirection: "row",
  },
  errorMessage: {
    color: Colors.primary.error,
    paddingLeft: 8,
  },
});
