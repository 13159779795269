import { auth, firestore } from "utils/firebase";
import firebase from "firebase/app";
import {
  markDToolsItemDelivered,
  markDToolsItemPicked,
  markDToolsItemReturned,
} from "./dtools-service";

export const undoDeliveryOfItem = (company, item) => {
  const data = {
    deliveredBy: null,
    deliveredDate: null,
    deliveredQuantity: 0,
    deliveryComplete: false,
  };
  firestore
    .collection("companies")
    .doc(company.id)
    .collection("purchaseOrders")
    .doc(item.id)
    .update(data);
};

export const markItemDelivered = (company, item, setLoading) => {
  const userId = auth.currentUser.uid;
  const data = {
    deliveredBy: userId,
    deliveredDate: new Date(),
    deliveredQuantity: item.quantity,
    deliveryComplete: true,
    manualDelivery: true,
  };
  console.log('mark item delivered', item, data)
  firestore
    .collection("companies")
    .doc(company.id)
    .collection("purchaseOrders")
    .doc(item.id)
    .update(data)
    .then(() => {
      setLoading(false);
      if (company.dToolsKey) {
        const { customerId, itemListId, quantity } = item;
        const selectedItem = {
          customerId,
          itemListId,
          quantityToDeliver: quantity,
        };
        markDToolsItemDelivered(company, selectedItem);
      }
    });
};

export const submitTransaction = (
  company,
  currentUser,
  locationParts,
  from,
  to,
  selectedItems,
  addToStagedItemList,
  isDTools,
  projectAdditionSelected,
  clearAll,
  setLoading,
  setSubmitDisabled,
  users,
  alert
) => {
  setSubmitDisabled(true);
  setLoading(true);
  // console.log("submit transaction", selectedItems?.length);

  const customerRef = [];
  from.isCustomer && customerRef.push(from.id);
  to.isCustomer && customerRef.push(to.id);

  const batch = firestore.batch();
  //PROMISE?
  selectedItems.forEach((item) => {
    const history = {
      manufacturer: item.manufacturer,
      model: item.model,
      from: from.name,
      fromLocationId: from.isCustomer ? "" : from.id,
      fromClientId: from.isCustomer ? from.id : "",
      to: to.name,
      toLocationId: to.isCustomer ? "" : to.id,
      toClientId: to.isCustomer ? to.id : "",
      date: new Date(),
      qty: item.qty,
      user: currentUser.id,
      received: false,
      approved: false,
      partID: item.isCustom ? "" : item.id,
      custom: item.isCustom ? true : false,
      customerRef,
      rejected: false,
    };
    if (addToStagedItemList) history.staged = true;

    /** START CHECKING IF D-TOOLS NEEDS UPDATED */
    /**1. if it's a project addition, do nothing because it doesn't exist in D-Tools yet.
     * We need to handle this in the approvals process.
     */
    if (isDTools && projectAdditionSelected) {
      history.projectAddition = true;
    } else if (isDTools && to.isCustomer) {
      /** 2. if it's not a project addition, it exists already in D-Tools,
       * so we update picked and potentially delivered status based on addToStagedItemList
       */
      let data = {
        customerId: to.id,
        itemListId: item.id,
        qty: item.qty,
      };
      const user = users.find((x) => x.id === currentUser.id);
      const deliveredBy = user.firstName + " " + user.lastName;
      markDToolsItemPicked(company, data, deliveredBy, addToStagedItemList);
    } else if (isDTools && from.isCustomer) {
      const selectedItem = {
        customerId: from.id,
        itemListId: item.id,
        quantityToDeliver: item.qty,
      };
      markDToolsItemReturned(
        company,
        selectedItem,
        currentUser?.firstName + " " + currentUser?.lastName
      );
    } else {
      // console.log('D-Tools doesnt need updated')
    }
    /** END CHECKING IF D-TOOLS NEEDS UPDATED */

    //addHistory() ?
    const historyDocRef = firestore
      .collection("companies")
      .doc(company.id)
      .collection("history")
      .doc();
    batch.set(historyDocRef, history);

    //then if need to adjust qty
    //if either aren't blank AND if both aren't customers

    if (!history.custom) {
      if (
        (history.fromClientId || history.toClientId) &&
        !(history.fromClientId && history.toClientId)
      ) {
        updateQuantity(company, history);
      }

      if (history.fromLocationId) {

        updateLocationQuantity(company, locationParts, item, history, true);
      }

      if (history.toLocationId) {
        updateLocationQuantity(company, locationParts, item, history, false);
      }
    }

    if (addToStagedItemList && to.isCustomer) {
      const purchaseOrder = {
        TxnID: "",
        customerFullName: to.name,
        customerId: to.id,
        deliveryComplete: false,
        itemListId: item.isCustom ? "" : item.id,
        quantity: item.qty,
        receivedInFull: true,
        receivedQuantity: item.qty,
        timeCreated: new Date(),
        timeModified: new Date(),
        fromStock: true,
        historyDocRef: historyDocRef.id,
      };

      if (item.isCustom) {
        purchaseOrder.name = `${item.manufacturer}: ${item.model}`;
        purchaseOrder.isCustom = true;
      }

      const poDocRef = firestore
        .collection("companies")
        .doc(company.id)
        .collection("purchaseOrders")
        .doc();
      batch.set(poDocRef, purchaseOrder);
    }
  });

  // console.log('before batch commit')

  batch
    .commit()
    .then(() => {
      alert.show("Transaction Successful");
      clearAll();
    })
    .catch((e) => {
      setLoading(false);
      console.log("error committing history batch", e);
    });
};

export const rejectTransaction = (
  company,
  parts,
  history,
  locationParts,
  onSuccess
) => {
  const batch = firestore.batch();

  const undo = history.rejected;
  if (!undo) history.qty = -history.qty;

  //addHistory() ?
  const historyDocRef = firestore
    .collection("companies")
    .doc(company.id)
    .collection("history")
    .doc(history.id);

  batch.update(historyDocRef, { rejected: undo ? false : true });

  //then if need to adjust qty
  //if either aren't blank AND if both aren't customers

  if (!history.custom) {
    const part = parts.find((x) => x.id === history.partID);

    if (
      ((history.fromClientId || history.toClientId) &&
        !(history.fromClientId && history.toClientId)) ||
      history.fromClientId === "received"
    ) {
      updateQuantity(company, history);
    }

    if (history.fromLocationId) {
      updateLocationQuantity(company, locationParts, part, history, true);
    }

    if (history.toLocationId) {
      updateLocationQuantity(company, locationParts, part, history, false);
    }
  }

  batch
    .commit()
    .then(() => {
      // console.log('successfully committing rejectTransaction batch', history.id)
      // if (history.staged) { // add back in after mobile build goes out
      const query = firestore
        .collection("companies")
        .doc(company.id)
        .collection("purchaseOrders")
        .where("historyDocRef", "==", history.id);

      query.get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          doc.ref.update({ rejected: undo ? false : true }).catch((e) => {
            console.log("fudge", e);
          });
        });
      });
    })
    .catch((e) => {
      console.log("error committing rejectTransaction batch", e);
    });
};

export const returnAndMoveStockTransaction = (
  company,
  currentUser,
  parts,
  history,
  quantityToReturn,
  locationParts,
  newLocation,
  onSuccess
) => {
  const batch = firestore.batch();

  const newHistory = { ...history };
  newHistory.fromLocationId = history.toLocationId;
  newHistory.toLocationId = newLocation.id;
  newHistory.fromClientId = history.toClientId;
  newHistory.toClientId = "";
  newHistory.from = history.to;
  newHistory.to = newLocation.name;
  newHistory.qty = quantityToReturn;
  newHistory.date = new Date()
  newHistory.user = currentUser.id;
  newHistory.returnedQuantity = quantityToReturn;
  newHistory.historyDocRef = history.id;
  delete newHistory.id;
  delete newHistory.deliveredBy;
  delete newHistory.deliveredDate;
  delete newHistory.deliveredQuantity;
  delete newHistory.staged;

  console.log("test", newHistory);
  // return

  firestore
    .collection("companies")
    .doc(company.id)
    .collection("history")
    .add(newHistory);

  // firestore
  // .collection("companies")
  // .doc(company.id)
  // .collection("history")
  // .doc(history.id)
  // .update({
  //   qty: firebase.firestore.FieldValue.increment(-history.qty),
  // })

  //then if need to adjust qty
  //if either aren't blank AND if both aren't customers

  if (!newHistory.custom) {
    const part = parts.find((x) => x.id === newHistory.partID);

    if (
      ((newHistory.fromClientId || newHistory.toClientId) &&
        !(newHistory.fromClientId && newHistory.toClientId)) ||
      newHistory.fromClientId === "received"
    ) {
      updateQuantity(company, newHistory);
    }

    if (newHistory.fromLocationId) {
      updateLocationQuantity(company, locationParts, part, newHistory, true);
    }

    if (newHistory.toLocationId) {
      updateLocationQuantity(company, locationParts, part, newHistory, false);
    }
  }

  batch
    .commit()
    .then(() => {
      // console.log('successfully committing rejectTransaction batch', history.id)
      // if (history.staged) { // add back in after mobile build goes out
      const query = firestore
        .collection("companies")
        .doc(company.id)
        .collection("purchaseOrders")
        .where("historyDocRef", "==", history.id);

      query.get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const deliveredQuantity = firebase.firestore.FieldValue.increment(
            newHistory.qty
          );
          doc.ref.update({
            deliveredQuantity,
            deliveredBy: currentUser.id,
            deliveredDate: new Date(),
            returnedQuantity: firebase.firestore.FieldValue.increment(quantityToReturn),
          });
        });
      });
      if (company.dToolsKey) {
        const selectedItem = {
          customerId: newHistory.fromClientId,
          itemListId: newHistory.partID,
          quantityToDeliver: quantityToReturn,
        };
        markDToolsItemReturned(
          company,
          selectedItem,
          currentUser?.firstName + " " + currentUser?.lastName
        );
      }
      // }
      onSuccess && onSuccess();
    })
    .catch((e) => {
      console.log("error committing rejectTransaction batch", e);
    });
};

export function updateQuantity(company, item) {
  const partRef = firestore
    .collection("companies")
    .doc(company.id)
    .collection("parts")
    .doc(item.partID);
  firestore
    .runTransaction(async (transaction) => {
      const snapshot = await transaction.get(partRef);
      const partData = snapshot.data();
      const { qty, cost } = partData;
      const oldQty = qty || 0;
      const newQty = item.toClientId ? oldQty - item.qty : oldQty + item.qty;
      const totalValue = cost * newQty;
      transaction.update(partRef, {
        qty: newQty,
        totalValue,
        lastModified: new Date(),
      });
    })
    .catch((e) => {
      console.log("error updateQuantity transaction", e, item.partID);
    });
}

export function updateLocationQuantity(company, locationParts, part, history, from) {
  const locationID = from ? history.fromLocationId : history.toLocationId;
  const locationName = from ? history.from : history.to;
  const locationPartId = `${locationID}-${part.id}`;
  const exists = locationParts.find((x) => x.locationPartId === locationPartId);
  // console.log(!!exists, locationPartId)
  const locationPartRef = firestore
    .collection("companies")
    .doc(company.id)
    .collection("locationParts")
    .doc(locationPartId);

  if (exists) {
    locationPartRef.update({
      qty: firebase.firestore.FieldValue.increment(
        from ? -history.qty : history.qty
      ),
    });
    // console.log("updating locationPart", locationPartId);
  } else {
    const locationPart = {
      locationID,
      locationName,
      lowercaseMfg: part.lowercaseMfg,
      lowercaseModel: part.lowercaseModel,
      minStockQty: 0,
      partID: part.id,
      qty: from ? -history.qty : history.qty,
    };
    locationPartRef.set(locationPart);
    
  }
}

/** STAGING TRANSACTION */
export const submitStagingTransaction = (
  company,
  currentUser,
  selectedItems,
  stagedParts,
  returning,
  selectedCustomer,
  navigate,
  setLoading,
  setSubmitDisabled
) => {
  const batch = firestore.batch();
  selectedItems.forEach((item) => {
    const findSelectedItem = stagedParts.find((x) => x.id === item.id);

    const data = {
      deliveredQuantity: firebase.firestore.FieldValue.increment(
        returning
          ? -findSelectedItem.quantityToDeliver
          : findSelectedItem.quantityToDeliver
      ),
      deliveredBy: currentUser.id,
      deliveredDate: new Date(),
      deliveryComplete: returning
        ? false
        : findSelectedItem.quantityToDeliver ===
          findSelectedItem.availableQuantity,
    };

    if (item.stock) {
      const newPoRef = firestore
        .collection("companies")
        .doc(company.id)
        .collection("purchaseOrders")
        .doc();

      batch.set(newPoRef, {
        TxnID: "",
        customerFullName: item?.to,
        customerId: item?.toClientId,
        deliveryComplete: false,
        itemListId: item.partID,
        quantity: item.qty,
        receivedInFull: true,
        receivedQuantity: item.qty,
        timeCreated: new Date(),
        timeModified: new Date(),
        fromStock: true,
        historyDocRef: item.id,
      });

      const historyRef = firestore
        .collection("companies")
        .doc(company.id)
        .collection("history")
        .doc(item.id);

      batch.update(historyRef, { staged: true });
    }

    if (findSelectedItem.quantityToDeliver > 0) {
      if (!item.stock) {
        const poDocRef = firestore
          .collection("companies")
          .doc(company.id)
          .collection("purchaseOrders")
          .doc(item.id);
        batch.update(poDocRef, data);
      }
      const history = {
        manufacturer: "",
        model: "",
        from: returning ? selectedCustomer.name : "Staging",
        fromLocationId: returning ? "" : "staging",
        fromClientId: returning ? selectedCustomer.id : "",
        to: returning ? "Staging" : selectedCustomer.name,
        toLocationId: "staging",
        toClientId: returning ? "" : selectedCustomer.id,
        date: new Date(),
        qty: findSelectedItem.quantityToDeliver,
        user: currentUser.id,
        received: false,
        approved: false,
        partID: item.itemListId ? item.itemListId : item.partID ? item.partID : "",
        custom: findSelectedItem.isCustom ? true : false,
        customerRef: [selectedCustomer.id],
        rejected: false,
        purchaseOrderId: item?.stock ? null : item.id,
        staging: true,
      };
      // console.log('creating history', history, item)
      const historyDocRef = firestore
        .collection("companies")
        .doc(company.id)
        .collection("history")
        .doc();
      batch.set(historyDocRef, history);

      if (company.dToolsKey) {
        // console.log('markDToolsItemDelivered')
        if (returning && !item.stock) {
          markDToolsItemReturned(
            company,
            item,
            currentUser?.firstName + " " + currentUser?.lastName
          );
        } else {
          markDToolsItemDelivered(
            company,
            item,
            currentUser?.firstName + " " + currentUser?.lastName
          );
        }
      }
    }
  });

  batch
    .commit()
    .then(() => {
      // navigation.goBack()
      navigate(-1);
    })
    .catch((e) => {
      setLoading(false);
      setSubmitDisabled(false);
      console.log("error updating items", e);
    });
};
