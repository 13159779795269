import firebase from "firebase/app";
import 'firebase/firestore';
import 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyCOuf1vJtanHEsVrVKLNdtDbOF7COwygCE",
  authDomain: "integrator-dashboard.firebaseapp.com",
  databaseURL: "https://integrator-dashboard.firebaseio.com",
  projectId: "integrator-dashboard",
  storageBucket: "integrator-dashboard.appspot.com",
  messagingSenderId: "689951189928",
  appId: "1:689951189928:web:fafb6a04c88f95d9f90b9d"
}

const app = firebase.initializeApp(firebaseConfig);

const firestore = app.firestore()
const auth = app.auth()


export { firestore, auth };
