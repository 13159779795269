import React, { useEffect, useState } from "react";
import { StyleSheet, View, ActivityIndicator, FlatList } from "react-native";
import { Colors } from "~/constants/Colors";
import { Header } from "~/components/Header";
import { CustomInput } from "~/components/Inputs/CustomInput";
import { HistoryCard } from "~/components/Cards/HistoryCard";
import { useCompany } from "context/CompanyContext";
import { getUserHistory } from "services/user-service";
import { useParts } from "context/PartsContext";
import { useCustomers } from "context/CustomersContext";
import { formatApprovalsReport } from "utils/exports-helper";

export function HistoryByUser() {

  const {company, selectedUser, locations, users} = useCompany()
  const {parts, manufacturers} = useParts()
  const {customers} = useCustomers()

  const [history, setHistory] = useState(null)
  const [filtered, setFiltered] = useState(null)
  const [search, setSearch] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)

    const subscriber = getUserHistory(company, selectedUser)
      .get().then(querySnapshot => {
          const temp = []
          // console.log('querySnapshot', querySnapshot.size)
          querySnapshot && querySnapshot.forEach(doc => {
            const data = doc.data()
              
              const item = {
                id: doc.id,
                ...data
              }

              const part = parts.find(x => x.id===item.partID)
              if (part) {
                item.manufacturer = part.manufacturer
                item.model = part.model
                if (part.parentRefID && !part.manufacturer) {
                  const parent = manufacturers.find(x => x.id ===part.parentRefID)
                  item.manufacturer = parent ? parent.model : ''
                  item.lowercaseMfg = parent ? parent.model.toLowerCase() : ''
                  part.manufacturer = parent ? parent.model : ''
                  part.lowercaseMfg = parent ? parent.model.toLowerCase() : ''
                }
              }

              if (item.fromClientId) {
                const from = customers.find(x => x.id===item.fromClientId)
                if (from) {
                  item.from = from.fullName
                }
              } else if (item.fromLocationId) {
                const from = locations.find(x => x.id===item.fromLocationId)
                if (from) {
                  item.from = from?.name
                }
              }

              if (item.toClientId) {
                const from = customers.find(x => x.id===temp?.toClientId)
                if (from) {
                  item.to = from.fullName
                }
              } else if (item.toLocationId) {
                const from = locations.find(x => x.id===temp?.toLocationId)
                if (from) {
                  item.to = from?.name
                }
              }

              temp.push(item)
          })
          setHistory(temp)
      })
      // return () => subscriber()

  }, [selectedUser])

  useEffect(() => {
    if (loading && history) {
      setFiltered(history)
      setLoading(false)
    }
  }, [history])

  useEffect(() => {
    if (search) {
      const temp = history
      .filter(x => 
        x.to.toLowerCase().includes(search.toLowerCase()) 
      || x.from.toLowerCase().includes(search.toLowerCase())
      || x.model.toLowerCase().includes(search.toLowerCase())
      || x.manufacturer.toLowerCase().includes(search.toLowerCase())
      )

      setFiltered(temp)
    } else {
      setFiltered(history)
    }
  }, [search])


  return (
    <View style={styles.container}>
        <Header 
        title={'History'} 
        exportData={formatApprovalsReport(filtered, users)}
        />
        {loading ?
            <ActivityIndicator animating size={'large'} style={styles.activity} color={Colors.primary.lightBlue} />
        :
            <>
                <CustomInput 
                placeholder={'Search History'}
                style={{marginHorizontal: 12}}
                onChange={setSearch}
                value={search}
                />
                <FlatList
                style={{flex: 1}}
                contentContainerStyle={{backgroundColor: Colors.primary.white}}
                data={filtered}
                keyExtractor={item => item.id}
                renderItem={({ item, index }) => {
                    return (
                        <HistoryCard
                        item={item}
                        showItem
                        />
                    )
                }}
                />
            </>
        }
        
    </View>
  );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        width: '100%',
        flexDirection: "column",
        backgroundColor: Colors.primary.white,
    },
    activity: {
      flex: 1,
      backgroundColor: Colors.primary.white
    }

});
