// const baseURL = 'http://localhost:8080'
const baseURL = 'https://api.tracknicity.com'

export const createClientAndPaymentMethod = (company, email, cardNonce, userCount, startToday, startDate, isDTools, yearly, func) => {
  const url = baseURL+'/createClientAndPaymentMethod'

  const companyName = company.name


  const body =  {
      companyDocId: company.id,
      companyName,
      email,
      userCount,
      cardNonce,
      startToday, 
      startDate,
      isDTools,
      yearly
    }

  const data = new FormData()
  for ( var key in body ) {
    data.append(key, body[key]);
  }

  const requestOptions = {
    method: 'POST',
    body: data
  };



  fetch(url, requestOptions)
  .then(response => response.json())
  .then(data => {
    // console.log('createClientAndPaymentMethod', data)
    if (data.success) {
      func(data.customer)
      //update subscription info
      // send opt in email
    } else {
      console.log('no go', data)
    }
  })
  .catch(e => {
    console.log('error createClientAndPaymentMethod', e)
  })
}

export const getUpcomingInvoice = (company, func) => {
  const url = baseURL+'/getUpcomingInvoice'

  const body =  {
      companyDocId: company.id,
      paymentCustomerId: company.paymentCustomerId,
      paymentSubscriptionId: company.paymentSubscriptionId
    }

  const data = new FormData()
  for ( var key in body ) {
    data.append(key, body[key]);
  }

  const requestOptions = {
    method: 'POST',
    body: data
  };



  fetch(url, requestOptions)
      .then(response => response.json())
      .then(data => {
        // console.log('got upcoming invoice', data)
        if (data.success) {
          func(data.customer)
        }
      })
      .catch(e => {
        // console.log('error getting payment info', e)
      })
}

export const getPaymentCardInfo = (company, func) => {
  const url = baseURL+'/getCustomer'

  const body =  {
    companyDocId: company.id,
    paymentCustomerId: company.paymentCustomerId,
    paymentSubscriptionId: company.paymentSubscriptionId
  }

  const data = new FormData()
  for ( var key in body ) {
    data.append(key, body[key]);
  }

  const requestOptions = {
    method: 'POST',
    body: data
  };

  fetch(url, requestOptions)
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          func(data.customer)
        }
        // console.log('got payment info', data)
        
      })
      .catch(e => {
        console.log('error getting payment info', e)
      })
}

export const updateSubscription = (company, userCount, func) => {
  const url = baseURL+'/updateSubscription'

  const body =  {
    companyDocId: company.id,
    paymentSubscriptionId: company.paymentSubscriptionId,
    userCount
  }

  const data = new FormData()
  for ( var key in body ) {
    data.append(key, body[key]);
  }

  const requestOptions = {
    method: 'POST',
    body: data
  };

  fetch(url, requestOptions)
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          func()
        }
        // console.log('update subscription', data)
        
      })
      .catch(e => {
        // console.log('error updating subscription', e)
      })
}

export const cancelSubscription = (company, func) => {
  const url = baseURL+'/cancelSubscription'

  const body =  {
    companyDocId: company.id,
    paymentSubscriptionId: company.paymentSubscriptionId
  }

  const data = new FormData()
  for ( var key in body ) {
    data.append(key, body[key]);
  }

  const requestOptions = {
    method: 'POST',
    body: data
  };

  fetch(url, requestOptions)
      .then(response => response.json())
      .then(data => {
        if (data.success || data.message==='Subscription has already been canceled.') {
          func()
          // console.log('success')
        }
        console.log('cancel subscription', data)
        
      })
      .catch(e => {
        console.log('error cancelling subscription', e)
      })
}

export const updatePaymentMethod = (company, cardNonce, func) => {
  const url = baseURL+'/updatePaymentMethod'

  const body =  {
      companyDocId: company.id,
      cardNonce,
      paymentCustomerId: company.paymentCustomerId
    }

  const data = new FormData()
  for ( var key in body ) {
    data.append(key, body[key]);
  }

  const requestOptions = {
    method: 'POST',
    body: data
  };



  fetch(url, requestOptions)
  .then(response => response.json())
  .then(data => {
    // console.log('updatePaymentMethod', data)
    if (data.success) {
      func()
      //update payment method
    }
  })
  .catch(e => {
    // console.log('error createClientAndPaymentMethod', e)
  })
}