import React, { useState } from "react";
import { StyleSheet, View } from "react-native";
import {Colors} from "~/constants/Colors";
import { useCompany } from "~/context/CompanyContext";
import { InventoryByLocationItems, Items } from "./InventoryByLocationItems";
import { LocationsSelector } from "components/Locations/LocationsSelector";

export function InventoryByLocation() {

    const {selectedLocation} = useCompany()

    const [selectedSubLocation, setSelectedSubLocation] = useState(null)

    function onSubLocationChanged(subLocation) {
        setSelectedSubLocation(subLocation)
    }

    return (
        <View style={styles.container}>
            <View style={styles.body}>
                <LocationsSelector 
                all={true} 
                subLocations={true} 
                onSubLocationChanged={onSubLocationChanged}
                selectedSubLocation={selectedSubLocation}
                setSelectedSubLocation={setSelectedSubLocation}
                />
                {!!selectedLocation &&
                    <InventoryByLocationItems subLocation={selectedSubLocation} />
                }
            </View>
            
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        width: '100%',
        backgroundColor: Colors.primary.white,
    },
    body: {
        flex: 1,
        width: '100%',
        flexDirection: "row",
        backgroundColor: Colors.primary.white,
    }

});
