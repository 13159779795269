import { firestore } from "utils/firebase";

export const getLocations = (company, setLocations) => {
  // console.log(companyId, userId)
  return firestore
    .collection('companies')
    .doc(company.id)
    .collection('locations')
    .orderBy('lowercaseName')
    .onSnapshot(querySnapshot => {
      const temp = []
      querySnapshot.forEach(doc => {
        const location = {
          id: doc.id,
          ...doc.data()
        }
        if (location.id === company.defaultLocationId) {
          temp.unshift(location)
        } else {
          temp.push(location)
        }
      })
      setLocations(temp)
    })
}

export const addNewLocation = (company, name, onSuccess) => {
  const data = {
    active: true,
    lowercaseName: name.toLowerCase(),
    name
  }
  firestore
    .collection('companies')
    .doc(company.id)
    .collection('locations')
    .add(data)
    .then(() => {
      onSuccess()
    })
    .catch(e => {
      console.log('error adding new location', e)
    })
}

export const updateLocation = (company, location, data, onSuccess) => {
  firestore
    .collection('companies')
    .doc(company.id)
    .collection('locations')
    .doc(location.id)
    .update(data)
    .then(() => {
      if (data.active === false) {
        console.log('here1')
        //delete all locationParts for this location
        firestore
          .collection('companies')
          .doc(company.id)
          .collection('locationParts')
          .where('locationID', '==', location.id)
          .get()
          .then(querySnapshot => {
            querySnapshot.forEach(doc => {
              const { partID, qty: locationQty } = doc.data();
              const partRef = firestore
                .collection("companies")
                .doc(company.id)
                .collection("parts")
                .doc(partID);
              firestore
                .runTransaction(async (transaction) => {
                  const snapshot = await transaction.get(partRef);
                  if (!snapshot.exists) {
                    console.log("part does not exist", partID);
                    return;
                  }
                  const partData = snapshot.data();

                  const { qty, cost } = partData;
                  const oldQty = qty || 0;
                  const newQty = oldQty - locationQty;
                  const totalValue = cost * newQty;
                  console.log('should be updated qty to', newQty)
                  transaction.update(partRef, {
                    qty: newQty,
                    totalValue,
                    lastModified: new Date(),
                  });
                })
                .then(() => {
                  console.log("transaction complete", partID);
                  doc.ref.delete();
                })
                .catch((e) => {
                  console.log("error updateQuantity transaction", e, partID);
                });
            })
            onSuccess();
          })
      } else {
        onSuccess()

      }
    })
    .catch(e => {
      console.log('error updating location', e)
    })
}

export const updateDefaultLocation = (company, location, onSuccess) => {
  const data = {
    defaultLocationId: location.id,
    defaultLocationName: location.name
  }
  firestore
    .collection('companies')
    .doc(company.id)
    .update(data)
    .then(() => {
      onSuccess()
    })
    .catch(e => {
      console.log('error updating new default location', e)
    })
}