import React, { useEffect, useState } from "react";
import { StyleSheet, View, Dimensions, FlatList } from "react-native";
import {Colors} from "~/constants/Colors";
import { ManufacturerCard } from "~/components/Cards/ManufacturerCard";
import { CustomInput } from "~/components/Inputs/CustomInput";
import { Header } from "components/Header";
import { useCompany } from "context/CompanyContext";
import { UserCard } from "components/Cards/UserCard";
import { useNavigate } from "react-router-dom";
import { cloneDeep } from "lodash";

export function HistoryUsers() {

  const navigate = useNavigate()

  const { activeUsers, setSelectedUser, selectedUser } = useCompany()

  const [users, setUsers] = useState(activeUsers)
  const [search, setSearch] = useState(null)

  useEffect(() => {
    const tempActive = cloneDeep(activeUsers)
    tempActive.unshift({firstName: 'All', id: 'all'})
    setUsers(tempActive)
  }, [activeUsers])

  
  useEffect(() => {

    if (search) {
      const temp = activeUsers.filter(x => x.lowercaseModel.includes(search.toLowerCase()))
      setUsers(temp)
    } else {
      const tempActive = cloneDeep(activeUsers)
      tempActive.unshift({firstName: 'All', id: 'all'})
      setUsers(tempActive)
    }

  }, [search])

  return (
    <View style={styles.listContainer}>
        <Header 
        title={'Users'}
        onBack={() => navigate(-1)}
        />
        <CustomInput 
        placeholder={'Search Users'}
        style={{marginHorizontal: 12}}
        onChange={setSearch}
        value={search}
        />
        <FlatList
            style={{flex: 1}}
            contentContainerStyle={{backgroundColor: Colors.primary.white}}
            data={users}
            keyExtractor={item => item.id}
            renderItem={({ item, index }) => {
                return (
                <UserCard
                  onPress={() => setSelectedUser(item)}
                  item={item}
                  highlighted={item.id===selectedUser?.id}
                />
                )
            }}
            />
    </View>
  );
}

const styles = StyleSheet.create({
    listContainer: {
        // flex: 1,
        width: '40%',
        flexDirection: "column",
        backgroundColor: Colors.primary.white,
    }
});
