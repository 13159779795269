import React from "react";
import { StyleSheet, View } from "react-native";
import {Colors} from "~/constants/Colors";
import { useCompany } from "~/context/CompanyContext";
import { ValuationChart } from "./ValuationChart";
// import { InventoryByLocationItems, Items } from "./InventoryByLocationItems";
// import { InventoryByLocationsLocations } from "./InventoryByLocations-Locations";

export function Valuation() {

    const {selectedLocation} = useCompany()

    return (
        <View style={styles.container}>
            <View style={styles.body}>
                <ValuationChart />
                {/* <InventoryByLocationsLocations/> */}
                {/* {!!selectedLocation &&
                    <InventoryByLocationItems />
                } */}
            </View>
            
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        width: '100%',
        backgroundColor: Colors.primary.white,
    },
    body: {
        flex: 1,
        width: '100%',
        flexDirection: "row",
        backgroundColor: Colors.primary.white,
    }

});
