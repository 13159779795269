import React, { useEffect, useState } from 'react';
import { StyleSheet, View, Dimensions, TextInput, TouchableOpacity } from 'react-native';
import { Colors } from '~/constants/Colors';
import { PrimaryText } from '~/components/Common/PrimaryText';
import * as FaIcons from "react-icons/fa";
import { firestore } from 'utils/firebase';
import { useCompany } from 'context/CompanyContext';
// import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';


const { height, width } = Dimensions.get('window')


export const ItemCheckoutStagingCard = ({
  item,
  onQuantityChanged,
  selectedStagedItem,
  selectedItems,
  returning = false
}) => {

  const { company } = useCompany();

  let returnedQuantity = returning ? item?.returnedQuantity || 0 : 0
  const [qty, setQty] = useState(item.quantityToDeliver - returnedQuantity)

  const [name, setName] = useState(null)

  useEffect(() => {
    if (item.isCustom) {
      getData()
    }
  }, [item])

  async function getData() {
    if (item.isCustom) {

      const history = await firestore.collection("companies").doc(company.id).collection("history").doc(item.historyDocRef).get()
      const historyData = history.data()
      // console.log('is custom', historyData)
      setName(historyData?.manufacturer ? `${historyData?.manufacturer}: ${historyData?.model}` : historyData?.model)
    }
  }

  useEffect(() => {
    onQuantityChanged(qty)
  }, [qty])

  function subtract() {
    if (qty > 0) {
      setQty(qty - 1)
    }
  }

  function add() {
    if (qty < item.availableQuantity - returnedQuantity)
      setQty(qty + 1)
  }

  function quantityChanged(text) {
    const trimmed = text.replace(/\D/g, '')
    const num = Number(trimmed)
    if (num <= item.availableQuantity - returnedQuantity) {
      setQty(num)
    }
  }

  let style = styles(false)

  const isSelected = selectedItems.find(x => x.id === item.id)


  return (
    <TouchableOpacity
      onPress={() => {
        console.log('selected', item)
        selectedStagedItem(item)
      }}
      style={[style.card, isSelected && { backgroundColor: Colors.primary.extremelyLightGray }]}
    >
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        <FaIcons.FaSquare name="square-medium" color={(item?.receivedQuantity || returning) ? Colors.primary.blue : Colors.primary.red} size={8} style={{ marginRight: 4 }} />
        <PrimaryText fontSize={16} fontWeight={400}>{name || `${item.itemFullName} (${item.availableQuantity - returnedQuantity})`}</PrimaryText>
      </View>
      <View style={style.quantityContainer}>
        <TouchableOpacity onPress={subtract}>
          <FaIcons.FaMinus name="minus" color={qty === 0 ? Colors.primary.lightGray : Colors.primary.red} size={24} />
        </TouchableOpacity>
        <TextInput
          value={qty.toString()}
          onChangeText={(text) => quantityChanged(text)}
          style={{ height: 23, width: width * 0.12, textAlign: 'center' }}
          keyboardType={'number-pad'}
        />
        <TouchableOpacity onPress={add}>
          <FaIcons.FaPlus name="plus" color={qty === (item.availableQuantity-returnedQuantity) ? isSelected ? Colors.primary.green : Colors.primary.lightGray : Colors.primary.red} size={24} />
        </TouchableOpacity>
      </View>
    </TouchableOpacity>
  )
}


export const styles = darkMode => StyleSheet.create({
  card: {
    paddingVertical: 8,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderColor: Colors.primary.lightGray,
    borderBottomWidth: 1,
    backgroundColor: Colors.primary.white
  },
  quantityContainer: {
    flexDirection: 'row',
    alignItems: 'center'
  }

});
