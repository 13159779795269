import React, { useCallback, useEffect, useState } from "react";
import {
  StyleSheet,
  View,
  FlatList,
  ActivityIndicator,
  TouchableOpacity,
} from "react-native";
import { ItemQtyByLocationCard } from "components/Cards/ItemQtyByLocationCard";
import { PrimaryText } from "components/Common/PrimaryText";
import { FullModal } from "components/Modals/FullModal";
import * as AiIcons from "react-icons/ai";

import { Colors } from "~/constants/Colors";
import { useParts } from "~/context/PartsContext";
import { ItemDetail } from "./ItemDetail";
import { Items } from "./Items";
import { EditItem } from "./EditItem";
import { ConfirmModal } from "components/Modals/Confirmation";
import { isParentItem } from "utils/helpers";
import { useCompany } from "context/CompanyContext";
import { updateLocationPart, updatePart } from "services/parts-service";
import { Waveform } from "@uiball/loaders";
import { TextInput } from "react-native-web";
import { Scanner } from "components/Scanner/Scanner";

export function Inventory() {
  const {
    selectedItem,
    setSelectedItem,
    itemByLocation,
    parts,
    locationParts,
    itemHistory
  } = useParts();
  const { company } = useCompany();

  const [showLocations, setShowLocations] = useState(false);
  const [showEditItem, setShowEditItem] = useState(false);

  const [selectedArchiveItem, setSelectedArchiveItem] = useState(null);
  const [showArchiveConfirmation, setShowArchiveConfirmation] = useState(false);
  const [confirmationInfo, setConfirmationInfo] = useState(null);

  const [showScanner, setShowScanner] = useState(false);
  const [scannedItem, setScannedItem] = useState("");

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // setShowEditItem(false);
  }, [selectedItem]);

  useEffect(() => {
    !showArchiveConfirmation && setSelectedArchiveItem(null);
  }, [showArchiveConfirmation]);

  function onArchivePress(item) {
    setSelectedArchiveItem(item);
    const archived = item.archived;
    if (isParentItem(parts, item)) {
      const info = {
        title: `${archived ? "Unarchive" : "Archive"} ${
          item.model
        } And All Children`,
        message: `${
          archived
            ? "Are you sure?"
            : "Are you sure? This will adjust all location quantities and values to zero."
        }`,
      };
      setConfirmationInfo(info);
      setShowArchiveConfirmation(true);
    } else {
      const info = {
        title: `${archived ? "Unarchive" : "Archive"} ${item.model}`,
        message: `${
          archived
            ? "Are you sure?"
            : "Are you sure? This will adjust all location quantities and values to zero."
        }`,
      };
      setConfirmationInfo(info);
      setShowArchiveConfirmation(true);
    }
  }

  function archiveConfirmed() {
    const archived = !selectedArchiveItem?.archived;
    if (isParentItem(parts, selectedArchiveItem)) {
      const temp = parts.filter(
        (x) => x.parentRefID === selectedArchiveItem.id
      );
      temp.forEach((part) => {
        archiveItem(part, archived);
      });
      archiveItem(selectedArchiveItem, archived);
    } else {
      archiveItem(selectedArchiveItem, archived);
    }
  }

  function archiveItem(part, archived) {
    setLoading(true);
    if (archived) {
      // we're archiving so we need to adjust quantities to zero
      const temp = locationParts.filter((x) => x.partID === part.id);
      temp.forEach((part) => {
        updateLocationPart(company, part.locationPartId, { qty: 0 });
      });
    } else {
      //we're unarchiving so let's make sure the parent is unarchived also
      const parent = parts.find((x) => x.id === part.parentRefID);
      parent &&
        updatePart(company, parent, {
          archived: archived,
          qty: 0,
          totalValue: 0,
          lastModified: new Date(),
        });
    }

    updatePart(
      company,
      part,
      { archived: archived, qty: 0, totalValue: 0, lastModified: new Date() },
      onSuccess
    );
  }

  function onSuccess() {
    setShowArchiveConfirmation(false);
    setLoading(false);
  }

  return (
    <View style={styles.container}>
      <View style={styles.body}>
        <Items
          onArchivePress={onArchivePress}
          setShowScanner={() => setShowScanner(true)}
          showScanner={showScanner}
          showEditItem={() => setShowEditItem(false)}
        />
        {!!selectedItem && (
          <>
            {!showEditItem ? (
              <ItemDetail
                toggleShowLocations={() => setShowLocations(!showLocations)}
                showEditItem={() => setShowEditItem(true)}
              />
            ) : (
              <EditItem goBack={() => setShowEditItem(false)} />
            )}
          </>
        )}
      </View>
      {showLocations && (
        <FullModal>
          <View style={styles.modal}>
            <View style={styles.modalHeader}>
              <PrimaryText
                fontSize={20}
                fontWeight={400}
                style={{ width: 40 }}
              ></PrimaryText>
              <PrimaryText fontSize={20} fontWeight={400}>
                Item By Location & Staging
              </PrimaryText>
              <TouchableOpacity onPress={() => setShowLocations(false)}>
                <AiIcons.AiOutlineClose
                  name="barcode"
                  color={Colors.primary.darkGray}
                  size={24}
                  style={{ width: 40 }}
                />
              </TouchableOpacity>
            </View>
            <FlatList
              data={
                itemByLocation?.length > 0
                  ? itemByLocation.filter((x) => x.locationQty !== 0)
                  : []
              }
              keyExtractor={(item) => item.locationID}
              renderItem={({ item, index }) => {
                if (!item.active || item.locationQty === 0) return;
                if (item.fromStock) {
                  const stockItem = itemHistory.find(
                    (x) =>
                      (x.date.toDate().setSeconds(0, 0) ===
                      item.timeCreated.toDate().setSeconds(0, 0)) ||
                      item?.historyDocRef === x.id
                  );
                  if (stockItem && stockItem.rejected) {
                    return;
                  }
                }
                return <ItemQtyByLocationCard item={item} />;
              }}
            />
          </View>
        </FullModal>
      )}

      {/** ARCHIVE ITEM */}
      {showArchiveConfirmation && (
        <ConfirmModal
          title={confirmationInfo?.title}
          message={confirmationInfo?.message}
          onConfirm={() => archiveConfirmed()}
          onCancel={() => setShowArchiveConfirmation(false)}
        />
      )}

      <Scanner
        showScanner={showScanner}
        setShowScanner={setShowScanner}
        scannedItem={scannedItem}
        setScannedItem={setScannedItem}
        title={"Scan To Search For Item"}
        setSelectedItem={setSelectedItem}
      />

      {loading && (
        <ActivityIndicator
          animating={loading}
          size={"large"}
          style={StyleSheet.absoluteFill}
          color={Colors.primary.blue}
        />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: "100%",
    backgroundColor: Colors.primary.white,
  },
  body: {
    flex: 1,
    width: "100%",
    flexDirection: "row",
    backgroundColor: Colors.primary.white,
  },
  modal: {
    minWidth: 400,
    minHeight: 200,
    maxHeight: 600,
  },
  modalHeader: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 20,
    borderColor: Colors.primary.gray,
    borderBottomWidth: 0.5,
    paddingBottom: 12,
  },
});
