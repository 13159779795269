import React, { useEffect, useState } from "react";
import {
  StyleSheet,
  View,
  Dimensions,
  ActivityIndicator,
  FlatList,
} from "react-native";
import { Colors } from "constants/Colors";
import { Header } from "components/Header";
import { CustomInput } from "components/Inputs/CustomInput";
import { PrimaryText } from "components/Common/PrimaryText";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import { useParts } from "context/PartsContext";
import { BStockItemCard } from "components/Cards/BStockItemCard";
import { deletePO, getOpenPurchaseOrderItems } from "services/purchaseOrders-service";
import { useCompany } from "context/CompanyContext";
import { getItemName } from "utils/helpers";
import { TouchableOpacity } from "react-native-web";
import { AiFillCaretDown } from "react-icons/ai";
import { ListContainer } from "components/ListContainer";
import { formatOpenPurchaseOrderReport } from "utils/exports-helper";
import { OpenPurchaseOrderCard } from "components/Cards/OpenPurchaseOrderCard";
import { useCustomers } from "context/CustomersContext";
import { useAuth } from "context/AuthContext";
import { cloneDeep } from "lodash";
import { ConfirmModal } from "components/Modals/Confirmation";
import { VariableSizeList } from "react-window";

const { width, height } = Dimensions.get("window");

export function OpenPurchaseOrders() {
  const navigate = useNavigate();

  const { currentUser } = useAuth();
  const { parts, manufacturers } = useParts();
  const { company, isDTools } = useCompany();
  const { customers } = useCustomers()

  const [purchaseOrderItems, setPurchaseOrderItems] = useState(null);
  const [search, setSearch] = useState(null);

  const [filtered, setFiltered] = useState(null);

  const [sortBy, setSortBy] = useState("itemFullName");
  const [loading, setLoading] = useState(true);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  useEffect(() => {
    getOpenPurchaseOrderItems(
      company.id,
      setPurchaseOrderItems,
      parts,
      manufacturers
    );
  }, []);

  useEffect(() => {
    if (!purchaseOrderItems) return;
    purchaseOrderItems.forEach((item) => {
      const customer = customers.find(x => x.id === item.customerId)
      if (customer) item.customerFullName = customer.fullName
      else if (!item.customerFullName) item.customerFullName = ''
    })
    // console.log("test", purchaseOrderItems?.length, sortBy);
    setFiltered(
      purchaseOrderItems.sort((a, b) => (a[sortBy] > b[sortBy] ? 1 : -1))
    );
    // temp.forEach((item) => {
    //   if (item.parentRefID && !item.manufacturer) {
    //     const parent = manufacturers.find((x) => x.id === item.parentRefID);
    //     item.manufacturer = parent ? parent.model : "";
    //     item.lowercaseMfg = parent ? parent.model.toLowerCase() : "";
    //   }
    // });
    setLoading(false);
  }, [purchaseOrderItems, sortBy, customers]);

  //on search change
  useEffect(() => {
    if (!purchaseOrderItems) return;
    if (search) {
      const temp = purchaseOrderItems.filter((x) =>
        x.itemFullName.toLowerCase().includes(search.toLowerCase())
      );
      setFiltered(temp);
    } else {
      const temp = purchaseOrderItems.sort((a, b) =>
        a[sortBy] > b[sortBy] ? 1 : -1
      );
      setFiltered(temp);
    }
  }, [search, purchaseOrderItems]);

  function deleteItem(item) {
    deletePO(company, item);
    const cloned = cloneDeep(purchaseOrderItems);
    const filtered = cloned.filter((x) => x.id !== item.id);
    setPurchaseOrderItems(filtered);
    setShowConfirmModal(false);
  }

  function RenderItem({ index, style }) {
    const item = filtered[index]
    return (
      <View style={style}>
        <OpenPurchaseOrderCard
          item={item}
          open
          admin={currentUser.admin}
          key={item.id}
          onDelete={() => setShowConfirmModal(item)}
        />
      </View>
    )
  }

  const getItemSize = index => {
    // const item = sorted[index]
    return 48
    // if (item?.stagingArea) {
    //   return 65
    // } else {
    //   return 65
    // }
  }

  return (
    <View style={styles.container}>
      <Header
        title={"Open Purchase Order Items"}
        onBack={() => navigate(-1)}
        exportData={formatOpenPurchaseOrderReport(filtered)}
      />
      <View
        style={{
          zIndex: 999,
          flexDirection: "row",
          width: "100%",
          alignItems: "center",
        }}
      >
        <CustomInput
          placeholder={"Filter By Item"}
          style={{ flex: 1, marginHorizontal: 12, height: 40 }}
          onChange={setSearch}
          value={search}
        />
      </View>

      {filtered && filtered?.length > 0 ? (
        <View style={styles.body}>
          <View style={styles.detailLabelContainer}>
            <PrimaryText fontSize={14} fontWeight={400} style={{ flex: 1, color: Colors.primary.white }} >Item/Customer</PrimaryText>
            <View style={{ flexDirection: 'row' }}>
              <PrimaryText fontSize={14} fontWeight={400} style={[styles.detailLabel, { width: width * 0.06 }]} >Ordered</PrimaryText>
              <PrimaryText fontSize={14} fontWeight={400} style={[styles.detailLabel, { width: width * 0.06 }]} >Received</PrimaryText>
              <PrimaryText fontSize={14} fontWeight={400} style={[styles.detailLabel, { width: width * 0.06 }]} >Delivered</PrimaryText>
              <PrimaryText fontSize={14} fontWeight={400} style={[styles.detailLabel, { width: width * 0.05 }]} >PO#</PrimaryText>
              {currentUser?.admin && (
                <View style={{ width: width * 0.04 }}>
                  <PrimaryText fontSize={14} fontWeight={400} style={[styles.detailLabel, { width: width * 0.3 }]} ></PrimaryText>
                </View>
              )}
              {/* <PrimaryText fontSize={14} fontWeight={400} style={styles.detailLabel} >From Stock</PrimaryText>
                      <PrimaryText fontSize={14} fontWeight={400} style={styles.detailLabel} >From Staging</PrimaryText> */}
            </View>
          </View>

          <View style={{ flex: 1, width: width - 300 }}>
            <VariableSizeList
              height={height}
              width={width - 300}
              itemSize={getItemSize}
              itemCount={filtered ? filtered.length : 0}
            >
              {RenderItem}
            </VariableSizeList>
          </View>

          {/* <FlatList
            style={{ flex: 1, width: '100%', borderTopWidth: 1, borderColor: Colors.primary.lightGray }}
            data={filtered}
            keyExtractor={(item, index) => { item.id || index.toString() }}
            renderItem={({ item, index }) => {

              return (
                <OpenPurchaseOrderCard
                  item={item}
                  open
                  admin={currentUser.admin}
                  key={item.id}
                  onDelete={() => setShowConfirmModal(item)}
                />
              )
            }}
          /> */}

        </View>
      ) : (
        <View style={styles.noCustomerContainer}>
          <PrimaryText fontSize={16} fontWeight={400}>
            {`No open purchase order items to report`}
          </PrimaryText>
        </View>
      )}

      {!!showConfirmModal && (
        <ConfirmModal
          title={"Delete PO Line Item"}
          message={`Are you sure? This item can be recovered by making a change to the PO in ${isDTools ? "D-Tools" : "QuickBooks"
            }.`}
          onConfirm={() => deleteItem(showConfirmModal)}
          onCancel={() => setShowConfirmModal(false)}
        />
      )}

      {loading && (
        <ActivityIndicator
          animating={loading}
          size={"large"}
          style={StyleSheet.absoluteFill}
          color={Colors.primary.blue}
        />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: "100%",
    flexDirection: "column",
    backgroundColor: Colors.primary.white,
  },
  button: {
    backgroundColor: Colors.primary.green,
    padding: 12,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 4,
  },
  noCustomerContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  body: {
    flex: 1,
    width: "100%",
    backgroundColor: Colors.primary.white,
    marginTop: 12,
    alignItems: "center",
  },
  optionContainer: {
    paddingHorizontal: 12,
    // width: 300,
    flexDirection: "row",
    alignItems: "center",
    // justifyContent:'space-between',
    marginVertical: 12,
  },
  legend: {
    flexDirection: "row",
    justifyContent: "center",
    marginVertical: 8,
  },

  //details
  detailLabelContainer: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: 12,
    marginTop: 12,
    backgroundColor: Colors.primary.gray,
  },
  detailLabel: {
    // marginHorizontal: 6,
    width: width * 0.04,
    textAlign: "right",
    color: Colors.primary.white,
    alignItems: 'center'
  },
  text: {
    color: Colors.primary.white,
    textAlign: 'center',
  },

  //modal
  modal: {
    // justifyContent: 'flex-end',
    margin: 0,
  },
  modalContent: {
    // height: height*0.6,
    width: width,
    backgroundColor: Colors.primary.white,
    borderRadius: 12,
    padding: 12,
    // alignItems: 'center'
  },
  modalSwipeButton: {
    width: 32,
    height: 3,
    backgroundColor: Colors.primary.darkGray,
    borderRadius: 3,
    marginBottom: 12,
    alignSelf: "center",
  },
  description: {
    marginHorizontal: 12,
    marginVertical: 4,
  },
  lineBreak: {
    height: 1,
    backgroundColor: Colors.primary.lightGray,
    margin: 12,
  },
});
