import React, { memo } from 'react';
import {StyleSheet, View, Dimensions, TouchableOpacity} from 'react-native';
import {Colors} from 'constants/Colors';
import { PrimaryText } from 'components/Common/PrimaryText';
import { dollarFormatter } from 'context/CompanyContext';



const {height, width} = Dimensions.get('window')


const Card = ({
  item,
  onPress
}) => {

  let style = styles(false)

  const name = item?.manufacturer ? item.manufacturer+': '+item.model : item.model

  return (
    <TouchableOpacity 
    onPress={onPress}
    style={style.card}
    >
      <View style={{flexDirection: 'row', alignItems:'center'}}>
        <View style={style.leftContainer}>
          <PrimaryText fontSize={13} fontWeight={500} style={{flex: 1}} >{name}</PrimaryText>
        </View>
      </View>
      <View style={{flexDirection: 'row'}}>
        <PrimaryText fontSize={13} fontWeight={400} style={[style.text, {width: width*0.12}]}>{dollarFormatter.format(item?.totalValue)}</PrimaryText>
        <PrimaryText fontSize={13} fontWeight={400} style={style.text}>{item?.qty}</PrimaryText>
      </View>
    </TouchableOpacity>
  )
}

export const MostValuableItemsCard = memo(Card)


const styles = darkMode => StyleSheet.create({
  card: {
    paddingHorizontal: 12,
    paddingVertical: 8,
    flexDirection: 'row',
    alignItems:'center',
    justifyContent:'space-between',
    borderColor: Colors.primary.lightGray,
    borderBottomWidth: 1
  },
  leftContainer: {
    flex: 1,
  },
  text: {
    width: width*0.06,
    textAlign:'center'
  },
  deliverButtonContainer: {
    width: width*0.06, 
    alignItems:'center',
    justifyContent:'center'
  },
  deliverButton: {
    backgroundColor: Colors.primary.lightBlue, 
    padding: 6,
    borderRadius: 4,
    alignItems: 'center',
    justifyContent:'center'
  }

});
