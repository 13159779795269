import React from "react";
import { StyleSheet, View } from "react-native";
import { Colors } from "constants/Colors";
import { MinMaxReport } from "./MinMaxReport";
import { MyHistory } from "./RecentHistory";
import { QuickActions } from "./QuickActions";
import { useParts } from "context/PartsContext";
import { ActivityIndicator } from "react-native-web";
import { PrimaryText } from "components/Common/PrimaryText";

export function Dashboard() {

    const { isGettingParts } = useParts();

    if (isGettingParts) {
        return <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
            {/* <Text>Loading...</Text> */}
            <ActivityIndicator size="large" color={Colors.primary.blue} />
            <PrimaryText>Fetching Data---Please Wait.</PrimaryText>
        </View>
    }

    return (
        <View style={styles.container}>
            <View style={[styles.body, { borderColor: 'gray', borderBottomWidth: 1 }]}>
                <View style={{ flex: 1, borderRightWidth: 1, borderColor: Colors.primary.gray }}>
                    <QuickActions />
                </View>
                <MyHistory />
            </View>
            <View style={styles.body}>
                {/* <MyHistory /> */}
                <MinMaxReport />
            </View>

        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        width: '100%',
        backgroundColor: Colors.primary.white,
    },
    body: {
        flex: 1,
        flexDirection: "row",
        backgroundColor: Colors.primary.white,
        paddingHorizontal: 4
        // marginVertical: 2
    }

});
