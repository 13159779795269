import React, { useEffect, useState } from 'react';
import {StyleSheet, TouchableOpacity, FlatList} from 'react-native';
import { Colors } from '~/constants/Colors';
import { PrimaryText } from '~/components/Common/PrimaryText';
import * as IoIcons from 'react-icons/io';
import * as VscIcons from 'react-icons/vsc';


export const LocationExpandableCard = ({
  item,
  onPress,
  highlighted,
  onSubLocationChanged,
  selectedSubLocation,
  subLocations,
  small
}) => {

  const [expanded, setExpanded] = useState(false)

  useEffect(() => {
    !highlighted && setExpanded(false)
  }, [highlighted])

  return (
    <>
    <TouchableOpacity 
    style={[styles.card, highlighted && {backgroundColor: Colors.primary.lightGray}]} 
    onPress={onPress}
    >
      <TouchableOpacity 
      style={{height: '100%', justifyContent:'center', alignItems:'center', paddingHorizontal: 4, marginRight: 4}}
      onPress={() => {
        setExpanded(!expanded)
        onPress()
      }}
      >
        {subLocations && (
        expanded && item.id!=='all' ?
          <IoIcons.IoMdArrowDropdown />
          :
          item.id!=='all' ?
          <IoIcons.IoMdArrowDropright />
          :
          <VscIcons.VscDash />
        )}
      </TouchableOpacity>
      <PrimaryText fontSize={small ? 12 : 16} fontWeight={400} style={styles.text}>{item.name}</PrimaryText>
    </TouchableOpacity>
    {expanded && highlighted && item.id!=='all' && 
      <>
        {item?.subLocations && item?.subLocations?.length > 0 ?
          <FlatList
          // contentContainerStyle={{flexGrow: 1, backgroundColor: Colors.primary.white, width: '100', paddingBottom: 12}}
          data={(item?.subLocations && item.subLocations?.length>0) ? item.subLocations.filter(x => x.active) : item.subLocations}
          keyExtractor={item => item.id}
          renderItem={(sub) => {
            const subItem = sub.item
            // if (!subItem.active) return
            return (
              <TouchableOpacity 
              style={[styles.subCard, subItem?.id===selectedSubLocation?.id && {backgroundColor: Colors.primary.lightGray}]} 
              onPress={() => onSubLocationChanged(subItem)} 
              >
                <PrimaryText fontSize={16} fontWeight={400} >{subItem?.name}</PrimaryText>
              </TouchableOpacity>
            )
          }}
          />
          :
          <PrimaryText fontSize={16} fontWeight={400} style={{textAlign:'center', marginBottom: 4}} >No sub-locations created</PrimaryText>
        }
      </>
      }
      </>
  )
}


const styles = StyleSheet.create({
  card: {
    marginHorizontal: 12,
    padding: 8,
    flexDirection: 'row',
    alignItems:'center',
    borderColor: Colors.primary.lightGray,
    borderBottomWidth: 1,
  },
  subCard: {
    width: '90%',
    alignSelf: 'flex-end',
    marginRight: 12,
    flexDirection: 'row',
    padding: 4,
    alignItems: 'center',
    borderColor: Colors.primary.lightGray,
    borderBottomWidth: 1,
  }

});
