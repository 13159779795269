import React from "react";
import { View, StyleSheet } from "react-native";
import {Colors} from "constants/Colors";
import { Dimensions } from "react-native-web";

const {height} = Dimensions.get('window');

export function FullModal({ children }) {



  return (
    <View style={styles.container}>
        <View style={styles.body}>
            {children}
        </View>
    </View>
  );
}

const styles = StyleSheet.create({
    container: {
        position:'absolute',
        zIndex: 999,
        flex: 1,
        width: '100%',
        height: height-100,
        backgroundColor: 'rgba(255,255,255,0.7)',
        alignItems:'center',
        justifyContent: 'center',
    },
    body: {
        // flex: 1,
        backgroundColor: Colors.primary.white,
        padding: 24,

        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
    }
});