export const currencies = [
  {
    default_locale: 'es-AR',
    iso_4217_3: 'ARS',
    name: 'Argentine Peso',
    number_decimals: 2,
    symbols: {narrow: '$', primary: 'ARS'},
  },
  {
    default_locale: 'en-AU',
    iso_4217_3: 'AUD',
    name: 'Australian Dollar',
    number_decimals: 2,
    symbols: {narrow: '$', primary: 'A$'},
  },
  {
    default_locale: 'bg',
    iso_4217_3: 'BGN',
    name: 'New Bulgarian Lev',
    number_decimals: 2,
    symbols: {primary: 'BGN'},
  },
  {
    default_locale: 'pt-BR',
    iso_4217_3: 'BRL',
    name: 'Brazilian Real',
    number_decimals: 2,
    symbols: {narrow: 'R$', primary: 'R$'},
  },
  {
    default_locale: 'en-CA',
    iso_4217_3: 'CAD',
    name: 'Canadian Dollar',
    number_decimals: 2,
    symbols: {narrow: '$', primary: 'CA$'},
  },
  {
    default_locale: 'fr-CH',
    iso_4217_3: 'CHF',
    name: 'Swiss Franc',
    number_decimals: 2,
    symbols: {primary: 'CHF'},
  },
  {
    default_locale: 'es-CL',
    iso_4217_3: 'CLP',
    name: 'Chilean Peso',
    number_decimals: 0,
    symbols: {narrow: '$', primary: 'CLP'},
  },
  {
    default_locale: 'zh-CN',
    iso_4217_3: 'CNY',
    name: 'Yuan Renminbi',
    number_decimals: 2,
    symbols: {narrow: '¥', primary: 'CN¥'},
  },
  {
    default_locale: 'es-CO',
    iso_4217_3: 'COP',
    name: 'Colombian Peso',
    number_decimals: 2,
    symbols: {narrow: '$', primary: 'COP'},
  },
  {
    default_locale: 'cs-CZ',
    iso_4217_3: 'CZK',
    name: 'Czech Koruna',
    number_decimals: 2,
    symbols: {narrow: 'Kč', primary: 'CZK'},
  },
  {
    default_locale: 'da-DK',
    iso_4217_3: 'DKK',
    name: 'Danish Krone',
    number_decimals: 2,
    symbols: {narrow: 'kr', primary: 'DKK'},
  },
  {
    default_locale: 'de',
    iso_4217_3: 'EUR',
    name: 'Euro',
    number_decimals: 2,
    symbols: {narrow: '€', primary: '€'},
  },
  {
    default_locale: 'en-GB',
    iso_4217_3: 'GBP',
    name: 'Pound Sterling',
    number_decimals: 2,
    symbols: {narrow: '£', primary: '£'},
  },
  {
    default_locale: 'zh-HK',
    iso_4217_3: 'HKD',
    name: 'Hong Kong Dollar',
    number_decimals: 2,
    symbols: {narrow: '$', primary: 'HK$'},
  },
  {
    default_locale: 'hr',
    iso_4217_3: 'HRK',
    name: 'Croatia Kuna',
    number_decimals: 2,
    symbols: {narrow: 'kn', primary: 'HRK'},
  },
  {
    default_locale: 'hu',
    iso_4217_3: 'HUF',
    name: 'Hungarian Forint',
    number_decimals: 0,
    symbols: {narrow: 'Ft', primary: 'HUF'},
  },
  {
    default_locale: 'id',
    iso_4217_3: 'IDR',
    name: 'Indonesian Rupiah',
    number_decimals: 0,
    symbols: {narrow: 'Rp', primary: 'IDR'},
  },
  {
    default_locale: 'ar-IL',
    iso_4217_3: 'ILS',
    name: 'New Israeli Scheqel',
    number_decimals: 2,
    symbols: {narrow: '₪', primary: '₪'},
  },
  {
    default_locale: 'bn-IN',
    iso_4217_3: 'INR',
    name: 'Indian Rupee',
    number_decimals: 2,
    symbols: {narrow: '₹', primary: '₹'},
  },
  {
    default_locale: 'is',
    iso_4217_3: 'ISK',
    name: 'Iceland Krona',
    number_decimals: 2,
    symbols: {narrow: 'kr', primary: 'ISK'},
  },
  {
    default_locale: 'ja-JP',
    iso_4217_3: 'JPY',
    name: 'Japanese Yen',
    number_decimals: 0,
    symbols: {narrow: '¥', primary: '¥'},
  },
  {
    default_locale: 'ko-KR',
    iso_4217_3: 'KRW',
    name: 'South-Korean Won',
    number_decimals: 0,
    symbols: {narrow: '₩', primary: '₩'},
  },
  {
    default_locale: 'ar-MA',
    iso_4217_3: 'MAD',
    name: 'Moroccan Dirham',
    number_decimals: 2,
    symbols: {primary: 'MAD'},
  },
  {
    default_locale: 'es-MX',
    iso_4217_3: 'MXN',
    name: 'Mexican Peso',
    number_decimals: 2,
    symbols: {narrow: '$', primary: 'MX$'},
  },
  {
    default_locale: 'en-MY',
    iso_4217_3: 'MYR',
    name: 'Malaysian Ringgit',
    number_decimals: 2,
    symbols: {narrow: 'RM', primary: 'MYR'},
  },
  {
    default_locale: 'nb-NO',
    iso_4217_3: 'NOK',
    name: 'Norwegian Krone',
    number_decimals: 2,
    symbols: {narrow: 'kr', primary: 'NOK'},
  },
  {
    default_locale: 'en-CK',
    iso_4217_3: 'NZD',
    name: 'New Zealand Dollar',
    number_decimals: 2,
    symbols: {narrow: '$', primary: 'NZ$'},
  },
  {
    default_locale: 'en-PH',
    iso_4217_3: 'PHP',
    name: 'Philippine Peso',
    number_decimals: 2,
    symbols: {narrow: '₱', primary: 'PHP'},
  },
  {
    default_locale: 'pl',
    iso_4217_3: 'PLN',
    name: 'New Polish Zloty',
    number_decimals: 2,
    symbols: {narrow: 'zł', primary: 'PLN'},
  },
  {
    default_locale: 'ro',
    iso_4217_3: 'RON',
    name: 'New Romanian Lei',
    number_decimals: 2,
    symbols: {narrow: 'lei', primary: 'RON'},
  },
  {
    default_locale: 'ru',
    iso_4217_3: 'RUB',
    name: 'Russian Ruble',
    number_decimals: 0,
    symbols: {narrow: '₽', primary: 'RUB'},
  },
  {
    default_locale: 'ar-SA',
    iso_4217_3: 'SAR',
    name: 'Saudi Riyal',
    number_decimals: 2,
    symbols: {primary: 'SAR'},
  },
  {
    default_locale: 'sv-SE',
    iso_4217_3: 'SEK',
    name: 'Swedish Krone',
    number_decimals: 2,
    symbols: {narrow: 'kr', primary: 'SEK'},
  },
  {
    default_locale: 'zh-SG',
    iso_4217_3: 'SGD',
    name: 'Singapore Dollar',
    number_decimals: 2,
    symbols: {narrow: '$', primary: 'SGD'},
  },
  {
    default_locale: 'th',
    iso_4217_3: 'THB',
    name: 'Thai Baht',
    number_decimals: 2,
    symbols: {narrow: '฿', primary: 'THB'},
  },
  {
    default_locale: 'tr',
    iso_4217_3: 'TRY',
    name: 'New Turkish Lira',
    number_decimals: 2,
    symbols: {narrow: '₺', primary: 'TRY'},
  },
  {
    default_locale: 'zh-TW',
    iso_4217_3: 'TWD',
    name: 'New Taiwan Dollar',
    number_decimals: 0,
    symbols: {narrow: '$', primary: 'NT$'},
  },
  {
    default_locale: 'en-US',
    iso_4217_3: 'USD',
    name: 'US Dollars',
    number_decimals: 2,
    symbols: {narrow: '$', primary: 'US$'},
  },
  {
    default_locale: 'vi-VN',
    iso_4217_3: 'VND',
    name: 'Vietnamese New Dong',
    number_decimals: 0,
    symbols: {narrow: '₫', primary: '₫'},
  },
  {
    default_locale: 'en-LS',
    iso_4217_3: 'ZAR',
    name: 'South African Rand',
    number_decimals: 2,
    symbols: {narrow: 'R', primary: 'ZAR'},
  },
];
