import React from 'react';
import {StyleSheet, TouchableOpacity, Dimensions} from 'react-native';
import {Colors} from '~/constants/Colors';
import { PrimaryText } from '~/components/Common/PrimaryText';


const {height, width} = Dimensions.get('window')


export const CustomerCard = ({
  item,
  onPress,
  small
}) => {

  let style = styles(false)

  return (
    <TouchableOpacity style={style.card} onPress={onPress}>
      <PrimaryText fontSize={small ? 12 : 16} fontWeight={400} style={style.text}>{item.fullName}</PrimaryText>
    </TouchableOpacity>
  )
}


export const styles = darkMode => StyleSheet.create({
  card: {
    marginHorizontal: 12,
    paddingVertical: 8,
    flexDirection: 'row',
    alignItems:'center',
    justifyContent:'space-between',
    borderColor: Colors.primary.lightGray,
    borderBottomWidth: 1
  }

});
