import React, { useEffect, useState } from 'react';
import { View } from 'react-native-web';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';

const SidebarLink = styled(Link)`
  display: flex;
  color: #e1e9fc;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  list-style: none;
  height: 20px;
  text-decoration: none;
  background: ${({ active }) => (active ? '#252831' : 'transparent')};
  border-left: ${({ active }) => (active ? '4px solid #1DA1F2' : '')};

  &:hover {
    background: #252831;
    border-left: 4px solid #1DA1F2;
    cursor: pointer;
  }
`;

const SidebarLabel = styled.span`
  margin-left: 16px;
  font-size: small;
`;

const DropdownLink = styled(Link)`
  height: 35px;
  padding-left: 2.3rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #f5f5f5;
  font-size: 18px;
  background: ${({ active }) => (active ? '#1DA1F2' : '#414757')};

  &:hover {
    background: #1DA1F2;
    cursor: pointer;
  }
`;

export const SubMenu = ({ item, currentUser, showSidebar, setShowSidebar, company, subnav, setSubnav }) => {

  const location = useLocation()
  
  const showSubnav = () => setSubnav(subnav===item.title ? null : item.title);

  useEffect(() => {
    !!subnav && setShowSidebar(true)
  }, [subnav])

  useEffect(() => {
    !showSidebar && setSubnav(null)
  }, [showSidebar])

  return (
    <>
      <SidebarLink to={item.path ? item.path : '#'} onClick={item.subNav && showSubnav} active={item?.path===location.pathname ? 1 : 0}>
        <View style={{flexDirection: 'row', alignItems: 'center'}}>
          {item.icon}
          {showSidebar &&
            <SidebarLabel>{item.title}</SidebarLabel>
          }
          
        </View>
        <View>
          {item.subNav && subnav===item.title
            ? item.iconOpened
            : item.subNav
            ? item.iconClosed
            : null}
        </View>
      </SidebarLink>
      {subnav===item.title &&
        item.subNav.map((item, index) => {
          if (!currentUser.admin && (item.title==='Audits' || item.title==='Total Valuation')) return
          if ((company.qbo===undefined) && item.title==='QuickBooks Integration') return
          if ((company.qbo!==undefined) && item.title==='D-Tools Integration') return
          return (
            <DropdownLink to={item.path} key={index} active={item?.path===location.pathname ? 1 : 0}>
              {item.icon}
              <SidebarLabel>{item.title}</SidebarLabel>
            </DropdownLink>
          );
        })}
    </>
  );
};