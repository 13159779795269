import React from 'react'
import RootNavigator from './navigators/RootNavigator';
import { AuthProvider } from 'context/AuthContext';
import { CompanyProvider } from 'context/CompanyContext';
import { DisplayProvider } from 'context/DisplayContext';
import './App.css';
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import AlertTemplate from 'react-alert-template-basic'

function App() {
  return (
    <RootNavigator />
  );
}

// optional configuration
const options = {
  // you can also just use 'bottom center'
  position: positions.MIDDLE,
  timeout: 5000,
  // offset: '30px',
  type: 'success',
  // you can also just use 'scale'
  transition: transitions.SCALE
}

export default () => {
  return (
    <AlertProvider template={AlertTemplate} {...options}>
    <DisplayProvider>
      <AuthProvider>
        <CompanyProvider>
          <App />
        </CompanyProvider>
      </AuthProvider>
    </DisplayProvider>
    </AlertProvider>
  );
};