import React from 'react';
import {StyleSheet, TouchableOpacity} from 'react-native';
import {Colors} from '~/constants/Colors';
import { PrimaryText } from '~/components/Common/PrimaryText';


export const UserCard = ({
  item,
  onPress,
  highlighted
}) => {

  let style = styles(false)
  
  return (
    <TouchableOpacity style={[style.card, highlighted && {backgroundColor: Colors.primary.lightGray}]} onPress={onPress}>
      <PrimaryText fontSize={16} fontWeight={400} style={style.text}>{item?.firstName} {item?.lastName}</PrimaryText>
    </TouchableOpacity>
  )
}


export const styles = darkMode => StyleSheet.create({
  card: {
    marginHorizontal: 12,
    paddingVertical: 8,
    flexDirection: 'row',
    alignItems:'center',
    borderColor: Colors.primary.lightGray,
    borderBottomWidth: 1
  },
  text: {
    marginLeft: 12
  }

});
