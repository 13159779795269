import { firestore } from "utils/firebase";

export const getAccounts = (company, setAccounts) => {
    // console.log(companyId, userId)
    return firestore
    .collection('companies')
    .doc(company.id)
    .collection('accounts')
    .orderBy('fullName')
    .onSnapshot(querySnapshot => {
        const temp = []
        querySnapshot.forEach(doc => {
            const account = {
                id: doc.id,
                ...doc.data()
            }
            account.label = account.name
            account.value = account.id
            temp.push(account)
        })
        setAccounts(temp)
    })
}