import React, { useEffect, useState } from "react";
import {
  StyleSheet,
  View,
  Dimensions,
  Alert,
  ActivityIndicator,
  TouchableOpacity,
  FlatList,
} from "react-native";
import * as MaterialCommunityIcons from "react-icons/md";
import * as VSIcons from "react-icons/vsc";
import * as AiIcons from "react-icons/ai";
import * as FaIcons from "react-icons/fa";
import cloneDeep from "lodash/cloneDeep";

import { Colors } from "constants/Colors";
import { Header } from "components/Header";
import { PrimaryText } from "components/Common/PrimaryText";
import { CustomerCard } from "components/Cards/CustomerCard";
import { LocationExpandableCard } from "components/Cards/LocationExpandableCard";
import { ManufacturerCard } from "components/Cards/ManufacturerCard";
import { ModelCard } from "components/Cards/ModelCard";
import { CustomInput } from "components/Inputs/CustomInput";
import { ItemCheckoutCard } from "components/Cards/ItemCheckoutCard";
import { ConfirmModal } from "components/Modals/Confirmation";

import { useCompany } from "context/CompanyContext";
import { useParts } from "context/PartsContext";
import { useCustomers } from "context/CustomersContext";
import { useAuth } from "context/AuthContext";

import {
  isParentItem,
  searchCustomers,
  searchParts,
  selectCustomerLocation,
  selectLocation,
  selectPart,
} from "utils/helpers";
import { Links } from "constants/Links";
import { FullModal } from "components/Modals/FullModal";
import { useNavigate } from "react-router-dom";
import { submitTransaction } from "services/transactions-service";
import { ListContainer } from "components/ListContainer";
import { Scanner } from "components/Scanner/Scanner";
import { BiBarcode, BiQrScan } from "react-icons/bi";
import { useAlert } from "react-alert";
import { Pressable } from "react-native-web";

const { width, height } = Dimensions.get("window");

export function Transactions() {
  const navigate = useNavigate();
  const { company, selectableLocations, isDTools, users } = useCompany();
  const { currentUser } = useAuth();
  const { manufacturers, parts, locationParts } = useParts();
  const { customers, setSelectedCustomer } = useCustomers();
  const alert = useAlert()

  //items
  const [items, setItems] = useState(manufacturers.filter((x) => x.active && !x.archived));
  const [selectedItems, setSelectedItems] = useState([]);
  const [customPart, setCustomPart] = useState({ manufacturer: "", model: "" });
  const [showCustomModal, setShowCustomModal] = useState(false);
  const [selectedParent, setSelectedParent] = useState(null);
  const [search, setSearch] = useState(null);
  const [showBrowseModal, setShowBrowseModal] = useState(false);
  const [addToStagedItemList, setAddToStagedItemList] = useState(false);
  const [isReturning, setIsReturning] = useState(false)

  //customer and locations
  const [from, setFrom] = useState({
    id: company?.defaultLocationId,
    name: company?.defaultLocationName,
    isCustomer: false,
  });
  const [to, setTo] = useState(null);
  const [filteredCustomers, setFilteredCustomers] = useState(customers);
  const [customerSearch, setCustomerSearch] = useState(null);
  const [showCustomerModal, setShowCustomerModal] = useState(false);
  const [showLocationModal, setShowLocationModal] = useState(false);
  const [stagingSelected, setStagingSelected] = useState(false);
  const [projectAdditionSelected, setProjectAdditionSelected] = useState(false);

  const [showConfirmModal, setShowConfirmModal] = useState(false);

  //other
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [loading, setLoading] = useState(false);

  const [showScanner, setShowScanner] = useState(false);
  const [scannedItem, setScannedItem] = useState("");

  useEffect(() => {
    return () => {
      clearAll();
    };
  }, []);

  useEffect(() => {
    checkSubmitDisabled();
  }, [to, selectedItems]);

  function checkSubmitDisabled() {
    if (to !== null && selectedItems.length > 0 && to.id !== from.id) {
      const zeroQuantities = !!selectedItems.find((x) => x.qty === 0);
      setSubmitDisabled(zeroQuantities ? true : false);
    } else {
      setSubmitDisabled(true);
    }
  }

  /** START ITEMS */

  //reset items on close browse modal
  useEffect(() => {
    !showBrowseModal && setItems(manufacturers.filter((x) => x.active && !x.archived));
  }, [showBrowseModal]);

  //search or browse parts
  useEffect(() => {
    searchParts(
      search,
      selectedParent,
      items.filter((x) => x.active && !x.archived),
      setItems,
      parts.filter((x) => x.active && !x.archived),
      manufacturers.filter((x) => x.active && !x.archived),
      showBrowseModal,
      true
    );
  }, [search]);

  useEffect(() => {
    if (!selectedParent) {
      setItems(manufacturers.filter((x) => x.active && !x.archived));
      setSearch(null);

    }
  }, [selectedParent]);

  function itemSelected(item) {
    const findIndex = selectedItems.findIndex((x) => x.id === item.id);
    if (findIndex > -1) {
      const temp = cloneDeep(selectedItems);
      temp[findIndex].qty += 1;
      setSelectedItems(temp);
    } else {
      selectPart(
        item,
        parts,
        setSelectedParent,
        setItems,
        setSelectedItems,
        setSearch,
        setShowBrowseModal,
        true,
        selectedItems
      );
    }
  }

  function itemQtyChanged(item, index) {
    const temp = cloneDeep(selectedItems);
    temp[index] = item;
    setSelectedItems(temp);
  }

  function customItemChanged(key, text) {
    const temp = cloneDeep(customPart);
    temp[key] = text;
    setCustomPart(temp);
  }

  function saveCustomPart() {
    customPart.qty = 1;
    customPart.timeStamp = new Date().getTime().toString();
    customPart.isCustom = true;
    const temp = cloneDeep(selectedItems);
    temp.push(customPart);
    setSelectedItems(temp);
    setCustomPart({ manufacturer: "", model: "" });
    setShowCustomModal(false);
  }

  function removeItem(index) {
    const temp = cloneDeep(selectedItems);
    temp.splice(index, 1);
    setSelectedItems(temp);
  }
  /** END ITEMS */

  /** START LOCATIONS AND CUSTOMERS */

  //on customer search change
  useEffect(() => {
    searchCustomers(customerSearch, customers, setFilteredCustomers);
  }, [customerSearch]);

  function customerSelected(customer) {
    selectCustomerLocation(
      customers,
      customer,
      showLocationModal,
      setShowLocationModal,
      setFrom,
      setTo,
      setShowCustomerModal,
      setCustomerSearch,
      setFilteredCustomers,
      stagingSelected,
      setStagingSelected
    );
  }

  function locationSelected(location) {
    selectLocation(
      location,
      showLocationModal,
      setFrom,
      setTo,
      setShowLocationModal,
      setShowCustomerModal
    );
  }

  useEffect(() => {
    // console.log('from changed', from)
    if (from?.id === "staging" && !stagingSelected) {
      setStagingSelected(true);
      setTimeout(() => {
        setShowLocationModal("from");
        setShowCustomerModal(true);
      }, 250);
    }

    if (stagingSelected && from?.isCustomer) {
      setStagingSelected(false);
      // console.log('staging and customer')
      setSelectedCustomer(from);
      navigate(Links.StagingAreaTransaction);
    }

    if (from?.id && from.id === to?.id) {
      setTo(null);
    }
  }, [from]);

  useEffect(() => {
    // console.log('to changed', to)
    if (to?.id === "staging" && !stagingSelected) {
      setStagingSelected(true);
      setTimeout(() => {
        setShowLocationModal("to");
        setShowCustomerModal(true);
      }, 250);
    }

    if (stagingSelected && to?.isCustomer) {
      setStagingSelected(false);
      // console.log('staging and customer')
      setSelectedCustomer(to);
      navigate(Links.StagingAreaTransaction, { state: { to: true } });
    }

    if (to?.id && to.id === from?.id) {
      setFrom({
        id: company?.defaultLocationId,
        name: company?.defaultLocationName,
        isCustomer: false,
      });
    }
  }, [to]);

  useEffect(() => {
    if (
      !showLocationModal &&
      (from?.id === "staging" || to?.id === "staging") &&
      stagingSelected
    ) {
      setIsReturning(false);
      setStagingSelected(false);
      setShowCustomerModal(false);
      if (from?.id === "staging") {
        setFrom({
          id: company?.defaultLocationId,
          name: company?.defaultLocationName,
          isCustomer: false,
        });
      } else {
        setTo(null);
      }
    }
  }, [showLocationModal]);

  function purchaseOrderItemFound(item) {
    const customer = customers.find((x) => x.id === item.customerId);
    setSelectedCustomer(customer);
    console.log('test', stagingSelected, to)
    navigate(Links.StagingAreaTransaction, { state: { item, to: isReturning } });
  }

  /** END LOCATIONS AND CUSTOMERS */

  function clearAll() {
    setSelectedItems([]);
    setIsReturning(false);
    setAddToStagedItemList(false);
    setProjectAdditionSelected(false);
    setFrom({
      id: company?.defaultLocationId,
      name: company?.defaultLocationName,
      isCustomer: false,
    });
    setTo(null);
    setLoading(false);
  }

  function RenderItem({ index, style }) {
    const item = items[index];
    if (!item.active || item.archived) item.model = "Archived";
    if (isParentItem(parts, item)) {
      return (
        <View style={style}>
          <ManufacturerCard onPress={() => itemSelected(item)} item={item} />
        </View>
      );
    } else {
      return (
        <View>
          <ModelCard onPress={() => itemSelected(item)} item={item} />
        </View>
      );
    }
  }

  return (
    <>
      <View style={styles.container}>
        <Header
          title="Transaction"
          onRight={() =>
            submitTransaction(
              company,
              currentUser,
              locationParts,
              from,
              to,
              selectedItems,
              addToStagedItemList,
              isDTools,
              projectAdditionSelected,
              clearAll,
              setLoading,
              setSubmitDisabled,
              users,
              alert
            )
          }
          rightText={"Submit"}
          rightDisabled={submitDisabled}
        />

        <View style={styles.topContainer}>
          <TouchableOpacity
            onPress={() => {
              setStagingSelected(true);
              setIsReturning(true);
              setTimeout(() => {
                setShowLocationModal("to");
                setShowCustomerModal(true);
              }, 250);
            }}
            style={[
              styles.itemButtons,
              {
                alignSelf: "center",
                width: "50%",
                backgroundColor: Colors.primary.lightBlue,
              },
            ]}
          >
            <PrimaryText
              fontSize={14}
              fontWeight={400}
              style={styles.itemButtonText}
            >
              {"Return Delivered Items To Staging"}
            </PrimaryText>
            <MaterialCommunityIcons.MdKeyboardReturn
              color={Colors.primary.white}
              size={20}
            />
          </TouchableOpacity>

          {/**FROM */}
          <View style={styles.locationContainer}>
            <PrimaryText fontSize={16} fontWeight={300}>{`From: `}</PrimaryText>
            <TouchableOpacity
              onPress={() => setShowLocationModal("from")}
              style={styles.locationButton}
            >
              <PrimaryText fontSize={18} fontWeight={400}>
                {from.name}
              </PrimaryText>
              <MaterialCommunityIcons.MdChevronRight
                size={24}
                color={Colors.primary.gray}
              />
            </TouchableOpacity>
          </View>

          {/** TO */}
          <View style={styles.locationContainer}>
            <PrimaryText fontSize={16} fontWeight={300}>{`To: `}</PrimaryText>
            <TouchableOpacity
              onPress={() => setShowLocationModal("to")}
              style={styles.locationButton}
            >
              <PrimaryText fontSize={18} fontWeight={400}>
                {to ? to.name : "Select Location"}
              </PrimaryText>
              <MaterialCommunityIcons.MdChevronRight
                color={Colors.primary.gray}
                size={24}
              />
            </TouchableOpacity>
          </View>

          {/** Buttons */}
          <View style={styles.buttonContainer}>
            {/* <TouchableOpacity onPress={() => setShowBrowseModal(!showBrowseModal)} style={styles.itemButtons}>
              <PrimaryText fontSize={14} fontWeight={400} style={styles.itemButtonText}>{'Browse'}</PrimaryText>
              <VSIcons.VscPackage name="package-variant" color={Colors.primary.white} size={20} />
            </TouchableOpacity> */}

            <TouchableOpacity
              onPress={() => setShowBrowseModal("search")}
              style={styles.itemButtons}
            >
              <PrimaryText
                fontSize={14}
                fontWeight={400}
                style={styles.itemButtonText}
              >
                {"Search Items"}
              </PrimaryText>
              <VSIcons.VscSearch
                name="magnify"
                color={Colors.primary.white}
                size={20}
              />
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => setShowScanner(true)}
              style={styles.itemButtons}
            >
              <PrimaryText
                fontSize={14}
                fontWeight={400}
                style={styles.itemButtonText}
              >
                {"Scan Items"}
              </PrimaryText>
              <BiBarcode
                name="magnify"
                color={Colors.primary.white}
                size={20}
              />
            </TouchableOpacity>
          </View>

          {/** CUSTOM AND STAGED ITEM BUTTONS */}
          <TouchableOpacity
            onPress={() => setShowCustomModal(true)}
            style={styles.extraButtons}
          >
            <PrimaryText fontSize={14} fontWeight={400}>
              {"Add Custom Item"}
            </PrimaryText>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => setAddToStagedItemList(!addToStagedItemList)}
            style={styles.extraButtons}
          >
            {addToStagedItemList ? (
              <MaterialCommunityIcons.MdOutlineCheckBox
                color={Colors.primary.gray}
                size={20}
                style={{ marginRight: 4 }}
              />
            ) : (
              <MaterialCommunityIcons.MdOutlineCheckBoxOutlineBlank
                color={Colors.primary.gray}
                size={20}
                style={{ marginRight: 4 }}
              />
            )}

            <PrimaryText fontSize={14} fontWeight={400}>
              {"Add To Staging Area"}
            </PrimaryText>
          </TouchableOpacity>

          {isDTools && (
            <TouchableOpacity
              onPress={() =>
                setProjectAdditionSelected(!projectAdditionSelected)
              }
              style={styles.extraButtons}
            >
              {projectAdditionSelected ? (
                <MaterialCommunityIcons.MdOutlineCheckBox
                  color={Colors.primary.gray}
                  size={20}
                  style={{ marginRight: 4 }}
                />
              ) : (
                <MaterialCommunityIcons.MdOutlineCheckBoxOutlineBlank
                  color={Colors.primary.gray}
                  size={20}
                  style={{ marginRight: 4 }}
                />
              )}
              <PrimaryText fontSize={14} fontWeight={400}>
                {"D-Tools Project Additions/Returns"}
              </PrimaryText>
              <TouchableOpacity onPress={() => setShowConfirmModal(true)}>
                <MaterialCommunityIcons.MdInfoOutline
                  name={"information-outline"}
                  color={Colors.primary.gray}
                  size={20}
                  style={{ marginLeft: 4 }}
                />
              </TouchableOpacity>
            </TouchableOpacity>
          )}
        </View>

        {selectedItems && selectedItems.length > 0 ? (
          <FlatList
            // contentContainerStyle={{flexGrow: 1, backgroundColor: Colors.primary.white}}
            data={selectedItems}
            keyExtractor={(item, index) => item.id + index}
            renderItem={({ item, index }) => {
              return (
                <ItemCheckoutCard
                  onQuantityChanged={(a, b) => itemQtyChanged(a, b)}
                  item={item}
                  removeItem={() => removeItem(index)}
                />
              );
            }}
          />
        ) : (
          <View style={styles.emptyContainer}>
            <PrimaryText fontSize={16} fontWeight={400}>
              No Items Selected
            </PrimaryText>
          </View>
        )}
      </View>

      {/** LOCATION MODAL */}
      {!!showLocationModal && (
        <FullModal>
          <View style={styles.modalContent}>
            <View style={[styles.modalHeader, { marginBottom: 6 }]}>
              <PrimaryText
                fontSize={20}
                fontWeight={400}
                style={{ width: 40 }}
              ></PrimaryText>
              <PrimaryText fontSize={18} fontWeight={400}>
                {showCustomerModal
                  ? "Select Customer / Job"
                  : "Select Location"}
              </PrimaryText>
              <TouchableOpacity
                onPress={() => {
                  setShowLocationModal(false);
                  setShowCustomerModal(false);
                }}
              >
                <AiIcons.AiOutlineClose
                  name="barcode"
                  color={Colors.primary.darkGray}
                  size={24}
                  style={{ width: 40 }}
                />
              </TouchableOpacity>
            </View>
            {showCustomerModal && (
              <>
                {/* {showLocationModal === "from" && ( */}
                <TouchableOpacity
                  onPress={() => setShowScanner(true)}
                  style={[styles.itemButtons, { marginBottom: 6 }]}
                >
                  <PrimaryText
                    fontSize={14}
                    fontWeight={400}
                    style={styles.itemButtonText}
                  >
                    {"Scan Items"}
                  </PrimaryText>

                  <BiQrScan
                    name="magnify"
                    color={Colors.primary.white}
                    size={20}
                  />
                </TouchableOpacity>
                {/* )} */}
                <CustomInput
                  placeholder={"Search Customers"}
                  style={{ marginHorizontal: 12, height: 40, width: "100%" }}
                  onChange={setCustomerSearch}
                  value={customerSearch}
                />
              </>
            )}
            <FlatList
              style={{ flex: 1 }}
              contentContainerStyle={{
                backgroundColor: Colors.primary.white,
                paddingBottom: 12,
                width: 400,
              }}
              data={showCustomerModal ? filteredCustomers : selectableLocations}
              // data={showCustomerModal ? filteredCustomers : showLocationModal==='to' ? selectableLocations.filter(x => x.id!=='staging') : selectableLocations}
              keyExtractor={(item) => item.id}
              keyboardDismissMode={"interactive"}
              renderItem={({ item, index }) => {
                if (showCustomerModal) {
                  return (
                    <CustomerCard
                      onPress={() => customerSelected(item)}
                      item={item}
                    />
                  );
                } else {
                  if (showLocationModal === "to" && item.id === "staging")
                    return;
                  return (
                    <LocationExpandableCard
                      onPress={() => locationSelected(item)}
                      item={item}
                    />
                  );
                }
              }}
            />
          </View>
        </FullModal>
      )}

      {/** BROWSE MODEL */}
      {!!showBrowseModal && (
        <FullModal>
          <View style={styles.modalContent}>
            <View style={styles.modalSwipeButton} />
            <View style={styles.modalHeader}>
              {selectedParent ? (
                <Pressable
                  underlayColor={"transparent"}
                  // style={{ width: 40 }}
                  onPress={() => setSelectedParent(null)}
                >
                  <FaIcons.FaChevronLeft
                    color={Colors.primary.darkGray}
                    size={24}
                  />
                </Pressable>
              ) : (
                <PrimaryText
                  fontSize={20}
                  fontWeight={400}
                  style={{ width: 40 }}
                ></PrimaryText>
              )}

              <PrimaryText fontSize={18} fontWeight={400}>
                {selectedParent
                  ? parts.find((x) => x.id === selectedParent.id).model
                  : "Items"}
              </PrimaryText>

              <TouchableOpacity onPress={() => setShowBrowseModal(false)}>
                <AiIcons.AiOutlineClose
                  color={Colors.primary.darkGray}
                  size={24}
                  style={{ width: 40 }}
                />
              </TouchableOpacity>
            </View>

            <CustomInput
              placeholder={"Search Items"}
              style={{ width: "90%", marginVertical: 4 }}
              onChange={setSearch}
              value={search}
            />
            <ListContainer
              items={items}
              size={40}
              renderItem={RenderItem}
              listWidth={400}
              filter={true}
            />
          </View>
        </FullModal>
      )}

      {/** CUSTOM ITEM MODAL */}
      {showCustomModal && (
        <FullModal>
          <View style={styles.customModalContent}>
            <View style={styles.modalSwipeButton} />

            <PrimaryText
              fontSize={16}
              fontWeight={400}
              style={{ textAlign: "center" }}
            >
              Custom Item
            </PrimaryText>

            <View style={{ marginVertical: 12 }}>
              <CustomInput
                value={customPart.manufacturer}
                onChange={(text) => customItemChanged("manufacturer", text)}
                title={"Manufacturer"}
              />
              <CustomInput
                value={customPart.model}
                onChange={(text) => customItemChanged("model", text)}
                title={"Model"}
              />

              <View style={{ flexDirection: "row", justifyContent: "center" }}>
                <TouchableOpacity
                  style={[styles.customButton]}
                  onPress={() => {
                    setCustomPart({ manufacturer: "", model: "" });
                    setShowCustomModal(false);
                  }}
                >
                  <PrimaryText
                    fontSize={12}
                    fontWeight={400}
                    style={{ textAlign: "center" }}
                  >
                    Cancel
                  </PrimaryText>
                </TouchableOpacity>
                <TouchableOpacity
                  disabled={!customPart.manufacturer || !customPart.model}
                  style={styles.customButton}
                  onPress={() => saveCustomPart()}
                >
                  <PrimaryText
                    fontSize={12}
                    fontWeight={400}
                    style={{ textAlign: "center" }}
                  >
                    Add
                  </PrimaryText>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </FullModal>
      )}

      {/** INFO MODAL */}
      {showConfirmModal && (
        <ConfirmModal
          title="D-Tools Project Additions"
          message={
            "Mark these items as product additions to the D-Tools Proposal for this project. \nOnce approved, a change-order will be initiated."
          }
          onConfirmText={"Okay"}
          onConfirm={() => setShowConfirmModal(false)}
        />
      )}

      <Scanner
        // editing={true}
        showScanner={showScanner}
        setShowScanner={setShowScanner}
        keepScannerOpen={true}
        scannedItem={scannedItem}
        setScannedItem={setScannedItem}
        title={"Scan To Search"}
        setSelectedItem={itemSelected}
        setPurchaseOrder={showCustomerModal ? purchaseOrderItemFound : null}
      // setUpc={setUpc}
      />

      {loading && (
        <ActivityIndicator
          size={"large"}
          animating
          color={Colors.primary.blue}
          style={[
            StyleSheet.absoluteFill,
            { backgroundColor: "rgba(0,0,0,0.3)" },
          ]}
        />
      )}
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: "100%",
    flexDirection: "column",
    backgroundColor: Colors.primary.white,
  },
  emptyContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  topContainer: {
    marginBottom: 12,
  },
  locationContainer: {
    flexDirection: "row",
    paddingVertical: 12,
    justifyContent: "flex-end",
    alignItems: "center",
  },
  locationButton: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "70%",
    marginRight: "10%",
    borderBottomWidth: 1,
    borderBottomColor: Colors.primary.lightGray,
    paddingVertical: 4,
  },
  buttonContainer: {
    flexDirection: "row",
    paddingVertical: 12,
    justifyContent: "center",
    alignItems: "center",
  },
  itemButtons: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: Colors.primary.red,
    borderRadius: 4,
    paddingVertical: 6,
    marginHorizontal: 4,
    width: "30%",
  },
  itemButtonText: {
    color: Colors.primary.white,
    marginRight: 6,
  },
  extraButtons: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 4,
    borderColor: Colors.primary.darkGray,
    borderWidth: 1,
    padding: 6,
    marginHorizontal: "20%",
    marginVertical: 12,
  },

  hiddenIconsContainer: {
    width: width * 0.175,
    marginRight: 12,
    flexDirection: "row",
    position: "absolute",
    right: 0,
    alignItems: "center",
    justifyContent: "space-around",
    height: "100%",
  },
  hiddenButton: {
    width: width * 0.15,
    height: "100%",
    borderRadius: height * 0.01,
    alignItems: "center",
    justifyContent: "center",
  },
  hiddenIcons: {
    width: "50%",
    height: "50%",
    resizeMode: "contain",
  },

  bottomModal: {
    justifyContent: "flex-end",
    margin: 0,
  },
  modalContent: {
    height: height * 0.7,
    backgroundColor: Colors.primary.white,
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
    padding: 12,
    alignItems: "center",
  },
  modal: {
    // justifyContent: 'flex-end',
    margin: 0,
    marginBottom: height * 0.3,
  },
  modalHeader: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 20,
  },
  customModalContent: {
    // height: height*0.6,
    width: 400,
    backgroundColor: Colors.primary.white,
    borderRadius: 12,
    padding: 12,
    // alignItems: 'center'
  },
  customButton: {
    marginTop: 36,
    marginHorizontal: 12,
    height: 30,
    width: 200,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 15,

    backgroundColor: Colors.primary.white,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,

    elevation: 5,
  },
});
