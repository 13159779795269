import React from "react"
import {
  StyleSheet,
  View,
  Dimensions,
} from "react-native"
import { Colors } from "constants/Colors"
import { Header } from "components/Header"
import { PrimaryText } from "components/Common/PrimaryText"
import "react-datepicker/dist/react-datepicker.css"
import { useNavigate } from "react-router-dom"

const { width } = Dimensions.get("window")

export function DToolsInventoryLocations() {
  const navigate = useNavigate()


  return (
    <View style={styles.container}>
      <Header
        title={"Inventory Locations"}
        onBack={() => navigate(-1)}
      // exportData={formatAgingItemsReport(filtered)}
      />

      <View style={styles.body}>
        <View style={{ width: "100%", paddingHorizontal: 12 }}>
          <PrimaryText
            fontSize={16}
            fontWeight={300}
            style={{ marginBottom: 12 }}
          >
            Inventory Locations are anywhere you keep inventory. This can be a warehouse, a vehicle, or a more just a group of items that you currently have on eBay that you want to keep track of.  Inventory items are items that are available for sale and not allocated to a project.
          </PrimaryText>

          <ol>
            <li style={{marginBottom: 12}}>
              <PrimaryText
                fontSize={16}
                fontWeight={500}
                style={{ marginBottom: 12 }}
              >
                Default Location
              </PrimaryText>
              <ol type="a">
                <li>
                  <PrimaryText
                    fontSize={14}
                    fontWeight={400}
                    style={{ marginBottom: 12 }}
                  >
                    When your account was created, a default location was made. This default location will populate at the top of most lists and will also be the default "From" location for checking out parts. You can rename this to whatever you would like.
                  </PrimaryText>
                </li>
              </ol>
            </li>

            <li style={{marginBottom: 12}}>
              <PrimaryText
                fontSize={16}
                fontWeight={500}
                style={{ marginBottom: 12 }}
              >
                Adding Locations
              </PrimaryText>
              <ol type="a">
                <li>
                  <PrimaryText
                    fontSize={14}
                    fontWeight={400}
                    style={{ marginBottom: 12 }}
                  >
                    In the Web Dashboard under Company Settings you will find the list of Inventory Locations.
                  </PrimaryText>
                </li>
                <li>
                  <PrimaryText
                    fontSize={14}
                    fontWeight={400}
                    style={{ marginBottom: 12 }}
                  >
                    From there, just click Add Location and name the location whatever you would like and you're good to go.
                  </PrimaryText>
                </li>
                <li>
                  <PrimaryText
                    fontSize={14}
                    fontWeight={400}
                    style={{ marginBottom: 12 }}
                  >
                    You can also create sub-locations under each primary inventory location.  This allows you to assign items to certain shelfs, racks, bins, etc…
                  </PrimaryText>
                </li>
                <li>
                  <PrimaryText
                    fontSize={14}
                    fontWeight={400}
                    style={{ marginBottom: 12 }}
                  >
                    Click <a href="https://komododecks.com/recordings/RA5o7YVu4nWdH3pFlFPE">here</a> for a full video tutorial on setting up users and locations in Tracknicity
                  </PrimaryText>
                </li>
              </ol>
            </li>

            <li style={{marginBottom: 12}}>
              <PrimaryText
                fontSize={16}
                fontWeight={500}
                style={{ marginBottom: 12 }}
              >
                Managing Locations
              </PrimaryText>
              <ol type="a">
                <li>
                  <PrimaryText
                    fontSize={14}
                    fontWeight={400}
                    style={{ marginBottom: 12 }}
                  >
                    In the Web Dashboard under Company Settings you will find the list of Inventory Locations.
                  </PrimaryText>
                </li>

                <li>
                  <PrimaryText
                    fontSize={14}
                    fontWeight={400}
                    style={{ marginBottom: 12 }}
                  >
                    Editing a Location let's you rename it. All items under this location will remain.
                  </PrimaryText>
                </li>

                <li>
                  <PrimaryText
                    fontSize={14}
                    fontWeight={400}
                    style={{ marginBottom: 12 }}
                  >
                    If you no longer use a location, you can archive it.
                  </PrimaryText>
                  <ol type="i">
                    <li>
                      <PrimaryText
                        fontSize={14}
                        fontWeight={400}
                        style={{ marginBottom: 12 }}
                      >This does not affect any items in this location so it is best practice to zero all of these items out of inventory first.
                      </PrimaryText>

                    </li>
                  </ol>
                  <li>
                    <PrimaryText
                      fontSize={14}
                      fontWeight={400}
                      style={{ marginBottom: 12 }}
                    >
                      Click <a href="https://komododecks.com/recordings/RA5o7YVu4nWdH3pFlFPE">here</a> for a full video tutorial on setting up users and locations in Tracknicity
                    </PrimaryText>
                  </li>
                </li>
              </ol>
            </li>


          </ol>
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: "100%",
    flexDirection: "column",
    backgroundColor: Colors.primary.white,
  },
  body: {
    flex: 1,
    width: "100%",
    backgroundColor: Colors.primary.white,
    marginTop: 12,
    alignItems: "center",
  },
})
