import React, { useEffect, useState } from 'react';
import {StyleSheet, View, TextInput, TouchableOpacity} from 'react-native';
import {Colors} from '~/constants/Colors';
import { PrimaryText } from '~/components/Common/PrimaryText';
import * as FaIcons from 'react-icons/fa';
import { useCompany } from '~/context/CompanyContext';


export const EditItemQtyByLocationCard = ({
  item,
  useQuantity,
  onQuantityChanged,
  maxQuantity
}) => {

  const {locations} = useCompany()

  const [qty, setQty] = useState(useQuantity)

  useEffect(() => {
    onQuantityChanged(qty)
  }, [qty])

  function subtract() {
    if (qty>0) {
      setQty(qty-1)
    }
  }

  function add() {
    if (maxQuantity && qty>=maxQuantity) return
    setQty(qty+1)
  }

  function quantityChanged(text) {
    const trimmed = text.replace(/\D/g,'')
    setQty(Number(trimmed))
  }

  let style = styles(false)
  const name = locations.find(x => x.id===item.locationID)

  return (
    <View style={style.card}>
      <PrimaryText fontSize={14} fontWeight={400} style={{flex: 1}}>{name?.name}</PrimaryText>
      <View style={style.quantityContainer}>
        <TouchableOpacity onPress={subtract}>
          <FaIcons.FaMinus name="minus" color={Colors.primary.red} size={24} />
        </TouchableOpacity>
        <TextInput 
            value={qty.toString()}
            onChangeText={(text) => quantityChanged(text)}
            style={{height: 23, width: 75, textAlign: 'center'}}
            keyboardType={'number-pad'}
        />
        <TouchableOpacity onPress={add}>
        <FaIcons.FaPlus name="plus" color={Colors.primary.red} size={24} />
        </TouchableOpacity>
      </View>
    </View>
  )
}


export const styles = darkMode => StyleSheet.create({
  card: {
    marginHorizontal: 12,
    paddingVertical: 8,
    flexDirection: 'row',
    alignItems:'center',
    justifyContent:'space-between',
    borderColor: Colors.primary.lightGray,
    borderBottomWidth: 1,
    backgroundColor: Colors.primary.white
  },
  quantityContainer: {
    flexDirection: 'row',
    alignItems:'center'
  }

});
