import React, { useEffect, useState } from "react";
import {
  StyleSheet,
  View,
  Dimensions,
  Alert,
  ActivityIndicator,
  TouchableOpacity,
  FlatList,
  TextInput,
} from "react-native";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as BiIcons from "react-icons/bi";

import { Colors } from "~/constants/Colors";
import { Header } from "~/components/Header";
import { PrimaryText } from "~/components/Common/PrimaryText";

import { useCompany } from "~/context/CompanyContext";
import { useParts } from "~/context/PartsContext";
import { useAuth } from "~/context/AuthContext";

import { firestore } from "utils/firebase";
import { EditItemQtyByLocationCard } from "~/components/Cards/EditItemQtyByLocationCard";
import { FullModal } from "components/Modals/FullModal";
import { cloneDeep } from "lodash";
import { ConfirmModal } from "components/Modals/Confirmation";
import { updatePart } from "services/parts-service";
import { SubLocationExpandableCard } from "components/Cards/SubLocationExpandableCard";
import { Waveform } from "@uiball/loaders";
import { Scanner } from "components/Scanner/Scanner";
import { useAlert } from "react-alert";

const { width, height } = Dimensions.get("window");

let tempScannedItem = "";
let timer;

export function EditItem({ goBack }) {
  const { company, locations } = useCompany();
  const { currentUser } = useAuth();
  const { selectedItem, locationParts, itemByLocation, parts } = useParts();
  const alert = useAlert();

  const [allLocationQuantities, setAllLocationQuantities] = useState(null); //all locations including qty===0 for item
  const [editedItemByLocation, setEditedItemByLocation] =
    useState(itemByLocation);
  const [showLocationModal, setShowLocationModal] = useState(false);
  const [showSubLocationModal, setShowSubLocationModal] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);

  //upc
  const [upc, setUpc] = useState(selectedItem.upc);
  const [showScanner, setShowScanner] = useState(false);
  const [scannedItem, setScannedItem] = useState("");

  const [bStockQty, setBStockQty] = useState(selectedItem?.bStockQty);
  const [bStockValuePercentage, setBStockValuePercentage] = useState(
    selectedItem?.bStockValuePercentage
  );

  //other
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showDeleteUpcModal, setShowDeleteUpcModal] = useState(false);
  const [loading, setLoading] = useState(false);

  function handleKeyDown(e) {
    if (e.key === "Escape") {
      setShowScanner(false);
    }
    if (showScanner && e.key?.length === 1) {
      tempScannedItem = tempScannedItem + e.key;
      timer = setTimeout(() => {
        setScannedItem(tempScannedItem);
      }, 500);
    }
  }

  useEffect(() => {
    if (!itemByLocation) return;
    const temp = [];
    const tempArchived = [];
    // console.log(itemByLocation);
    locations
      // .filter((x) => x.active)
      .forEach((location) => {
        const filtered = itemByLocation.filter((x) => !x.customerId);
        let { subLocations = [] } = location;
        if (!subLocations) {
          subLocations = [];
        }
        subLocations.sort((a, b) =>
          a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1
        );

        const findQty = filtered.find((x) => x.locationID === location.id);
        // console.log('wtf', findQty, location.id)
        const tempItem = {
          qty: findQty ? findQty.locationQty : 0,
          minStockQty: findQty ? findQty.minStockQty : 0,
          maxStockQty: findQty ? findQty.maxStockQty : 0,
          subLocation: findQty ? findQty.subLocation : undefined,
          locationID: location.id,
          location,
        };
        if (location.active || tempItem.qty > 0) {
          temp.push(tempItem);
        }
      });

    setAllLocationQuantities(temp);
  }, [itemByLocation]);

  useEffect(() => {
    checkSubmitDisabled();
  }, [allLocationQuantities, bStockQty, bStockValuePercentage, upc]);

  useEffect(() => {
    // console.log('editedItemByLocation', editedItemByLocation)
  }, [editedItemByLocation]);

  function checkSubmitDisabled() {
    // console.log('checkSubmit', bStockQty, selectedItem.bStockQty)
    let edited = false;
    if (selectedItem.upc !== upc) edited = true;
    if (selectedItem.bStockQty !== bStockQty) edited = true;
    if (selectedItem.bStockValuePercentage !== bStockValuePercentage)
      edited = true;

    const temp = [];
    allLocationQuantities &&
      allLocationQuantities.forEach((locationQty) => {
        const findQty = itemByLocation.find(
          (x) => x.locationID === locationQty.locationID
        );
        if (findQty) {
          if (
            findQty.locationQty !== locationQty.qty ||
            findQty.minStockQty !== locationQty.minStockQty ||
            findQty.maxStockQty !== locationQty.maxStockQty ||
            findQty.subLocation !== locationQty.subLocation
          ) {
            edited = true;
            if (findQty.locationQty === locationQty.qty)
              locationQty.stockLevelAdjustmentOnly = true; //dont create history for stock level adjustments
            temp.push(locationQty);
          }
        } else if (
          locationQty.qty !== 0 ||
          locationQty.minStockQty !== 0 ||
          locationQty.maxStockQty !== 0 ||
          locationQty.subLocation !== undefined
        ) {
          edited = true;
          if (locationQty.qty === 0)
            locationQty.stockLevelAdjustmentOnly = true; //dont create history for stock level adjustments
          temp.push(locationQty);
        }
      });

    setEditedItemByLocation(temp);
    setSubmitDisabled(!edited);
  }

  function itemQtyChanged(index, qty) {
    const clone = cloneDeep(allLocationQuantities);
    if (showLocationModal === "qty") clone[index].qty = qty;
    if (showLocationModal === "min") clone[index].minStockQty = qty;
    if (showLocationModal === "max") clone[index].maxStockQty = qty;
    setAllLocationQuantities(clone);
  }

  function sum(items) {
    const sum = items
      ? items
          .map((x) => x.qty)
          .reduce((a, b) => {
            return a + b;
          })
      : 0;
    return sum;
  }

  function bStockQtyChanged(text) {
    const trimmed = text.replace(/\D/g, "");
    setBStockQty(Number(trimmed));
  }

  function bStockPercentageChanged(text) {
    let trimmed = text.replace(/\D/g, "").slice(0, 3);
    if (Number(trimmed) > 100) {
      trimmed = 100;
    }
    setBStockValuePercentage(Number(trimmed));
  }

  function onSubLocationChanged(index, subLocation) {
    const clone = cloneDeep(allLocationQuantities);
    clone[index].subLocation = subLocation ? subLocation.id : null;
    setAllLocationQuantities(clone);
  }

  function save() {
    setSubmitDisabled(true);
    setLoading(true);

    const batch = firestore.batch();
    const partRef = firestore
      .collection("companies")
      .doc(company.id)
      .collection("parts")
      .doc(selectedItem.id);
    const partDataToUpdate = {};
    partDataToUpdate.lastModified = new Date();
    if (selectedItem.qty !== totalQty) {
      partDataToUpdate.qty = totalQty;
      partDataToUpdate.totalValue = totalQty * selectedItem.cost;
    }
    if (selectedItem.upc !== upc) partDataToUpdate.upc = upc;
    if (selectedItem.bStockQty !== bStockQty)
      partDataToUpdate.bStockQty = bStockQty;
    if (selectedItem.bStockValuePercentage !== bStockValuePercentage)
      partDataToUpdate.bStockValuePercentage = bStockValuePercentage;

    partRef.update(partDataToUpdate);
    // batch.update(partRef, partDataToUpdate);

    editedItemByLocation.forEach((item) => {
      const location = item.location;
      const locationPartId = `${location.id}-${selectedItem.id}`;
      const exists = locationParts.find(
        (x) => x.locationPartId === locationPartId
      );
      const locationPartRef = firestore
        .collection("companies")
        .doc(company.id)
        .collection("locationParts")
        .doc(locationPartId);

      if (exists) {
        const data = {
          qty: item.qty,
          minStockQty: item.minStockQty,
          maxStockQty: item.maxStockQty,
        };
        data.subLocation = item?.subLocation || null;
        batch.update(locationPartRef, data);
      } else {
        const locationPart = {
          locationID: location.id,
          locationName: location.name,
          lowercaseMfg: selectedItem.lowercaseMfg,
          lowercaseModel: selectedItem.lowercaseModel,
          minStockQty: item.minStockQty,
          maxStockQty: item.maxStockQty,
          partID: selectedItem.id,
          qty: item.qty,
        };
        if (item.subLocation) locationPart.subLocation = item.subLocation;
        batch.set(locationPartRef, locationPart);
      }

      const history = {
        manufacturer: selectedItem?.manufacturer,
        model: selectedItem?.model,
        from:
          exists && exists.locationQty > item.qty
            ? location.name
            : "Manual Adjustment",
        fromLocationId:
          exists && exists.locationQty > item.qty ? location.id : "manual",
        fromClientId: "",
        to:
          exists && exists.locationQty > item.qty
            ? "Manual Adjustment"
            : location.name,
        toLocationId:
          exists && exists.locationQty > item.qty ? "manual" : location.id,
        toClientId: "",
        date: new Date(),
        qty: exists ? Math.abs(exists.locationQty - item.qty) : item.qty,
        user: currentUser.id,
        received: false,
        approved: true,
        partID: selectedItem.id,
        custom: false,
        rejected: false,
        manualAdjustment: true,
      };
      const historyRef = firestore
        .collection("companies")
        .doc(company.id)
        .collection("history")
        .doc();
      !item.stockLevelAdjustmentOnly && batch.set(historyRef, history); //dont create history for stock level adjustments
    });
    batch
      .commit()
      .then(() => {
        goBack();
      })
      .catch((e) => {
        console.log("error saving edited item", e);
        setSubmitDisabled(false);
        setLoading(false);
        alert.show(
          "Error updating item. Check internet connection and try again"
        );
      });
  }

  function backPressed() {
    if (submitDisabled) {
      goBack();
    } else {
      setShowConfirmModal(true);
    }
  }

  const name = selectedItem?.manufacturer
    ? `${selectedItem.manufacturer}: ${selectedItem.model}`
    : selectedItem.model;
  const totalQty = sum(allLocationQuantities);
  return (
    <>
      <View style={styles.container}>
        <Header
          title="Edit Item"
          onBack={backPressed}
          onRight={save}
          rightText={"Save"}
          rightDisabled={submitDisabled}
        />
        <PrimaryText
          fontSize={16}
          fontWeight={600}
          style={styles.name}
          numberOfLines={1}
        >
          {name}
        </PrimaryText>

        <View style={styles.topContainer}>
          {/**SUB-LOCATION ASSIGNMENT */}
          <TouchableOpacity
            onPress={() => setShowSubLocationModal(true)}
            style={styles.buttonContainer}
          >
            <PrimaryText
              fontSize={14}
              fontWeight={300}
              style={{ flex: 1 }}
            >{`Sub-location Assignments`}</PrimaryText>
            <View style={styles.button}>
              <FaIcons.FaChevronRight
                name="chevron-right"
                color={Colors.primary.gray}
                size={24}
              />
            </View>
          </TouchableOpacity>

          {/**QTY */}
          <TouchableOpacity
            onPress={() => setShowLocationModal("qty")}
            style={styles.buttonContainer}
          >
            <PrimaryText
              fontSize={14}
              fontWeight={300}
              style={{ flex: 1 }}
            >{`Qty By Location`}</PrimaryText>
            <View style={styles.button}>
              <PrimaryText
                fontSize={16}
                fontWeight={400}
              >{`Total: ${totalQty}`}</PrimaryText>
              <FaIcons.FaChevronRight
                name="chevron-right"
                color={Colors.primary.gray}
                size={24}
                style={{ marginLeft: 12 }}
              />
            </View>
          </TouchableOpacity>

          {/** MIN STOCK */}
          <TouchableOpacity
            onPress={() => setShowLocationModal("min")}
            style={styles.buttonContainer}
          >
            <PrimaryText
              fontSize={14}
              fontWeight={300}
              style={{ flex: 1 }}
            >{`Minimum Stock Qty`}</PrimaryText>
            <View style={styles.button}>
              <FaIcons.FaChevronRight color={Colors.primary.gray} size={24} />
            </View>
          </TouchableOpacity>

          {/** MAX STOCK */}
          <TouchableOpacity
            onPress={() => setShowLocationModal("max")}
            style={styles.buttonContainer}
          >
            <PrimaryText
              fontSize={14}
              fontWeight={300}
              style={{ flex: 1 }}
            >{`Maximum Stock Qty`}</PrimaryText>
            <View style={styles.button}>
              <FaIcons.FaChevronRight color={Colors.primary.gray} size={24} />
            </View>
          </TouchableOpacity>

          {/** UPC */}
          <TouchableOpacity
            onPress={() => setShowScanner(true)}
            style={styles.buttonContainer}
          >
            <PrimaryText
              fontSize={14}
              fontWeight={300}
              style={{ flex: 1 }}
            >{`UPC`}</PrimaryText>
            <View style={styles.button}>
              <PrimaryText fontSize={16} fontWeight={400}>
                {upc ? upc : "No UPC Assigned"}
              </PrimaryText>
              {!!upc ? (
                <TouchableOpacity onPress={() => setShowDeleteUpcModal(true)}>
                  <FaIcons.FaTrash
                    color={Colors.primary.red}
                    size={20}
                    style={{ marginLeft: 12 }}
                  />
                </TouchableOpacity>
              ) : (
                <BiIcons.BiBarcodeReader
                  color={Colors.primary.gray}
                  size={24}
                  style={{ marginLeft: 12 }}
                />
              )}
            </View>
          </TouchableOpacity>

          {/** B-STOCK */}
          <View style={styles.buttonContainer}>
            <PrimaryText
              fontSize={14}
              fontWeight={300}
              style={{ flex: 1 }}
            >{`B-Stock Qty`}</PrimaryText>
            <View style={styles.button}>
              <TextInput
                value={bStockQty ? bStockQty.toString() : "0"}
                onChangeText={(text) => bStockQtyChanged(text)}
                style={styles.textInput}
                keyboardType={"number-pad"}
              />
              {/* <PrimaryText fontSize={16} fontWeight={400} >{editedItem?.bStockQty ? editedItem.bStockQty : 0}</PrimaryText> */}
            </View>
          </View>

          <View style={styles.buttonContainer}>
            <PrimaryText
              fontSize={14}
              fontWeight={300}
              style={{ flex: 1 }}
            >{`B-Stock Percentage of Cost`}</PrimaryText>
            <View style={styles.button}>
              <TextInput
                value={
                  bStockValuePercentage
                    ? bStockValuePercentage.toString()
                    : company.bStockValuePercentage.toString()
                }
                onChangeText={(text) => bStockPercentageChanged(text)}
                style={styles.textInput}
                keyboardType={"number-pad"}
              />
            </View>
          </View>
        </View>
      </View>

      {/** LOCATION MODAL */}
      {!!showLocationModal && (
        <FullModal>
          <View style={styles.modal}>
            <View style={styles.modalHeader}>
              <PrimaryText
                fontSize={20}
                fontWeight={400}
                style={{ width: 40 }}
              ></PrimaryText>
              <PrimaryText fontSize={18} fontWeight={400}>
                {showLocationModal === "qty"
                  ? "Quantity By Location"
                  : showLocationModal === "min"
                  ? "Minimum Stock"
                  : "Maximum Stock"}
              </PrimaryText>
              <TouchableOpacity onPress={() => setShowLocationModal(false)}>
                <AiIcons.AiOutlineClose
                  name="barcode"
                  color={Colors.primary.darkGray}
                  size={24}
                  style={{ width: 40 }}
                />
              </TouchableOpacity>
            </View>

            <FlatList
              // contentContainerStyle={{flexGrow: 1, backgroundColor: Colors.primary.white, width: '100', paddingBottom: 12}}
              data={allLocationQuantities}
              keyExtractor={(item) => item.locationID}
              renderItem={({ item, index }) => {
                return (
                  <EditItemQtyByLocationCard
                    useQuantity={
                      showLocationModal === "qty"
                        ? item.qty
                        : showLocationModal === "min"
                        ? item.minStockQty
                        : item.maxStockQty
                    }
                    onQuantityChanged={(qty) => itemQtyChanged(index, qty)}
                    item={item}
                  />
                );
              }}
            />
          </View>
        </FullModal>
      )}

      {/** SUB-LOCATION MODAL */}
      {showSubLocationModal && (
        <FullModal>
          <View style={styles.modal}>
            <View style={styles.modalHeader}>
              <PrimaryText
                fontSize={20}
                fontWeight={400}
                style={{ width: 40 }}
              ></PrimaryText>
              <PrimaryText fontSize={18} fontWeight={400}>
                Sub-locations
              </PrimaryText>
              <TouchableOpacity onPress={() => setShowSubLocationModal(false)}>
                <AiIcons.AiOutlineClose
                  color={Colors.primary.darkGray}
                  size={24}
                  style={{ width: 40 }}
                />
              </TouchableOpacity>
            </View>

            <FlatList
              // contentContainerStyle={{flexGrow: 1, backgroundColor: Colors.primary.white, width: '100', paddingBottom: 12}}
              data={allLocationQuantities}
              keyExtractor={(item) => item.locationID}
              renderItem={({ item, index }) => {
                const selected =
                  item.locationID === selectedLocation?.locationID;
                return (
                  <SubLocationExpandableCard
                    item={item}
                    locations={locations}
                    onPress={() => setSelectedLocation(selected ? null : item)}
                    onSubLocationChanged={(subLocation) =>
                      onSubLocationChanged(index, subLocation)
                    }
                    selected={selected}
                  />
                );
              }}
            />
          </View>
        </FullModal>
      )}

      {showConfirmModal && (
        <ConfirmModal
          title={"Exit without saving?"}
          message={"Are you sure?"}
          onConfirm={goBack}
          onCancel={() => setShowConfirmModal(false)}
        />
      )}

      {showDeleteUpcModal && (
        <ConfirmModal
          title={"Delete UPC"}
          message={"Are you sure?"}
          onConfirm={() =>
            updatePart(
              company,
              selectedItem,
              { upc: "", lastModified: new Date() },
              () => setShowDeleteUpcModal(false)
            )
          }
          onCancel={() => setShowDeleteUpcModal(false)}
        />
      )}

      <Scanner
        editing={true}
        showScanner={showScanner}
        setShowScanner={setShowScanner}
        scannedItem={scannedItem}
        setScannedItem={setScannedItem}
        title={"Scan To Learn UPC"}
        setUpc={setUpc}
      />

      {loading && (
        <ActivityIndicator
          size={"large"}
          animating
          color={Colors.primary.blue}
          style={{
            position: "absolute",
            left: "40%",
            right: 0,
            top: 0,
            bottom: 0,
          }}
        />
      )}
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
    backgroundColor: Colors.primary.white,
  },
  name: {
    textAlign: "center",
    marginVertical: 12,
  },
  topContainer: {
    flex: 1,
    marginBottom: 12,
  },
  buttonContainer: {
    flexDirection: "row",
    paddingVertical: 8,
    paddingHorizontal: 12,
    alignItems: "center",
    borderBottomWidth: 1,
    borderBottomColor: Colors.primary.lightGray,
  },
  button: {
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    paddingVertical: 4,
  },
  textInput: {
    height: 23,
    width: width * 0.12,
    textAlign: "center",
    borderColor: Colors.primary.darkGray,
    borderWidth: 1,
    borderRadius: 4,
  },
  modalContent: {
    height: height * 0.9,
    backgroundColor: Colors.primary.white,
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
    padding: 12,
    alignItems: "center",
  },
  modal: {
    width: 400,
    minHeight: 200,
    maxHeight: 600,
  },
  modalHeader: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 20,
    borderColor: Colors.primary.gray,
    borderBottomWidth: 0.5,
    paddingBottom: 12,
  },
});
