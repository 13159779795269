import React, { useState } from "react"
import { StyleSheet, View, TouchableOpacity } from "react-native"
import { Colors } from "constants/Colors"
import { useCompany } from "context/CompanyContext"
import { PrimaryText } from "components/Common/PrimaryText"
import * as MdIcons from "react-icons/md"
import { ScrollView } from "react-native-web"
import BraintreeDropIn from "./components/BraintreeDropIn"
import { createClientAndPaymentMethod } from "services/subscription-service"
import { useAuth } from "context/AuthContext"
import { updateCompany } from "services/company-service"
import { sendCustomerOptIn } from "services/email-service"

export function Expired() {
  const { company, activeUsers, isDTools } = useCompany()
  const { currentAuth } = useAuth()

  const [yearly, setYearly] = useState(false)

  const onSubmitPayment = (cardNonce) => {
    createClientAndPaymentMethod(
      company,
      currentAuth.email,
      cardNonce,
      activeUsers.length,
      true,
      new Date(),
      isDTools,
      yearly,
      onSuccess
    )
  }

  const onSuccess = (data) => {
    const { paymentCustomerId, paymentSubscriptionId } = data
    updateCompany(company, {
      active: true,
      paymentCustomerId,
      paymentSubscriptionId,
      yearly
    })
    sendCustomerOptIn(
      company,
      currentAuth.email,
      new Date().toDateString(),
      activeUsers.length,
      isDTools,
      yearly,
    )
  }

  return (
    <ScrollView style={styles.container}>
      <View style={styles.body}>
        <PrimaryText fontSize={16} fontWeight={400}>
          {
            "Trial Expired. For Continued Access, please opt into a subscription plan below."
          }
        </PrimaryText>

        {!isDTools ? (
          <View style={[styles.tabContainer, {width: '50%'}]}>
            <TouchableOpacity
              style={[styles.tabButton, styles.activeTab, {width: '100%'}]}
            >
              <PrimaryText fontSize={20} fontWeight={400}>
                QuickBooks
              </PrimaryText>
              <View style={styles.priceContainer}>
                <PrimaryText
                  fontSize={30}
                  fontWeight={400}
                  style={styles.userCount}
                >
                  1-2 Users
                </PrimaryText>
                <View style={styles.priceRow}>
                  <PrimaryText
                    fontSize={18}
                    fontWeight={400}
                    style={styles.price}
                  >
                    $
                  </PrimaryText>
                  <PrimaryText
                    fontSize={24}
                    fontWeight={400}
                    style={styles.price}
                  >
                    50/
                  </PrimaryText>
                  <View style={styles.monthContainer}>
                    <PrimaryText
                      fontSize={15}
                      fontWeight={400}
                      style={styles.price}
                    >
                      mo
                    </PrimaryText>
                  </View>
                </View>
              </View>

              <View style={styles.priceContainer}>
                <PrimaryText
                  fontSize={30}
                  fontWeight={400}
                  style={styles.userCount}
                >
                  3-10 Users
                </PrimaryText>
                <View style={styles.priceRow}>
                  <PrimaryText
                    fontSize={18}
                    fontWeight={400}
                    style={styles.price}
                  >
                    $
                  </PrimaryText>
                  <PrimaryText
                    fontSize={24}
                    fontWeight={400}
                    style={styles.price}
                  >
                    125/
                  </PrimaryText>
                  <View style={styles.monthContainer}>
                    <PrimaryText
                      fontSize={15}
                      fontWeight={400}
                      style={styles.price}
                    >
                      mo
                    </PrimaryText>
                  </View>
                </View>
              </View>

              <View style={styles.priceContainer}>
                <PrimaryText
                  fontSize={30}
                  fontWeight={400}
                  style={styles.userCount}
                >
                  11-20 Users
                </PrimaryText>
                <View style={styles.priceRow}>
                  <PrimaryText
                    fontSize={18}
                    fontWeight={400}
                    style={styles.price}
                  >
                    $
                  </PrimaryText>
                  <PrimaryText
                    fontSize={24}
                    fontWeight={400}
                    style={styles.price}
                  >
                    200/
                  </PrimaryText>
                  <View style={styles.monthContainer}>
                    <PrimaryText
                      fontSize={15}
                      fontWeight={400}
                      style={styles.price}
                    >
                      mo
                    </PrimaryText>
                  </View>
                </View>
              </View>

              <View style={styles.priceContainer}>
                <PrimaryText
                  fontSize={30}
                  fontWeight={400}
                  style={styles.userCount}
                >
                  21+ Users
                </PrimaryText>
                <View style={styles.priceRow}>
                  <PrimaryText
                    fontSize={18}
                    fontWeight={400}
                    style={styles.price}
                  >
                    $
                  </PrimaryText>
                  <PrimaryText
                    fontSize={24}
                    fontWeight={400}
                    style={styles.price}
                  >
                    7/
                  </PrimaryText>
                  <View style={styles.monthContainer}>
                    <PrimaryText
                      fontSize={15}
                      fontWeight={400}
                      style={styles.price}
                    >
                      mo
                    </PrimaryText>
                  </View>
                </View>
                <PrimaryText
                  fontSize={12}
                  fontWeight={400}
                  style={styles.userCount}
                >
                  Per Active User
                </PrimaryText>
                <PrimaryText
                  fontSize={12}
                  fontWeight={400}
                  style={styles.userCount}
                >
                  + $60/mo Base Fee Per Month
                </PrimaryText>
              </View>

              <View style={styles.listContainer}>
                <View style={[styles.priceRow, { alignItems: "center" }]}>
                  <MdIcons.MdOutlineCheck
                    color={Colors.primary.green}
                    size={24}
                  />
                  <PrimaryText
                    fontSize={18}
                    fontWeight={400}
                    style={styles.price}
                  >
                    Unlimited Inventory Items
                  </PrimaryText>
                </View>
                <View style={[styles.priceRow, { alignItems: "center" }]}>
                  <MdIcons.MdOutlineCheck
                    color={Colors.primary.green}
                    size={24}
                  />
                  <PrimaryText
                    fontSize={18}
                    fontWeight={400}
                    style={styles.price}
                  >
                    Unlimited Projects
                  </PrimaryText>
                </View>
                <View style={[styles.priceRow, { alignItems: "center" }]}>
                  <MdIcons.MdOutlineCheck
                    color={Colors.primary.green}
                    size={24}
                  />
                  <PrimaryText
                    fontSize={18}
                    fontWeight={400}
                    style={styles.price}
                  >
                    Unlimited Cloud Storage
                  </PrimaryText>
                </View>
              </View>

              {/* <View 
                    style={styles.button}
                    >
                        <PrimaryText fontSize={16} fontWeight={400} style={{color: Colors.primary.white}}>Save</PrimaryText>
                    </View> */}
            </TouchableOpacity>
          </View>
        ) : (
            <View style={styles.tabContainer}>
            <TouchableOpacity
              onPress={() => setYearly(false)}
              style={[styles.tabButton, !yearly && styles.activeTab]}
            >
              <PrimaryText fontSize={20} fontWeight={400}>
                D-Tools Monthly
              </PrimaryText>

              <View style={styles.priceContainer}>
                <PrimaryText
                  fontSize={30}
                  fontWeight={400}
                  style={styles.userCount}
                >
                  Unlimited Users
                </PrimaryText>
                <View style={styles.priceRow}>
                  <PrimaryText
                    fontSize={18}
                    fontWeight={400}
                    style={styles.price}
                  >
                    $
                  </PrimaryText>
                  <PrimaryText
                    fontSize={24}
                    fontWeight={400}
                    style={styles.price}
                  >
                    199/
                  </PrimaryText>
                  <View style={styles.monthContainer}>
                    <PrimaryText
                      fontSize={15}
                      fontWeight={400}
                      style={styles.price}
                    >
                      mo
                    </PrimaryText>
                  </View>
                </View>
              </View>

              <View style={styles.listContainer}>
                <View style={[styles.priceRow, { alignItems: "center" }]}>
                  <MdIcons.MdOutlineCheck
                    color={Colors.primary.green}
                    size={24}
                  />
                  <PrimaryText
                    fontSize={18}
                    fontWeight={400}
                    style={styles.price}
                  >
                    Unlimited Inventory Items
                  </PrimaryText>
                </View>
                <View style={[styles.priceRow, { alignItems: "center" }]}>
                  <MdIcons.MdOutlineCheck
                    color={Colors.primary.green}
                    size={24}
                  />
                  <PrimaryText
                    fontSize={18}
                    fontWeight={400}
                    style={styles.price}
                  >
                    Unlimited Projects
                  </PrimaryText>
                </View>
                <View style={[styles.priceRow, { alignItems: "center" }]}>
                  <MdIcons.MdOutlineCheck
                    color={Colors.primary.green}
                    size={24}
                  />
                  <PrimaryText
                    fontSize={18}
                    fontWeight={400}
                    style={styles.price}
                  >
                    Unlimited Cloud Storage
                  </PrimaryText>
                </View>
              </View>

            </TouchableOpacity>


            <TouchableOpacity
              onPress={() => setYearly(true)}
              style={[styles.tabButton, yearly && styles.activeTab]}
            >
              <PrimaryText fontSize={20} fontWeight={400}>
                D-Tools Yearly
              </PrimaryText>

              <View style={styles.priceContainer}>
                <PrimaryText
                  fontSize={30}
                  fontWeight={400}
                  style={styles.userCount}
                >
                  Unlimited Users
                </PrimaryText>
                <View style={styles.priceRow}>
                  <PrimaryText
                    fontSize={18}
                    fontWeight={400}
                    style={styles.price}
                  >
                    $
                  </PrimaryText>
                  <PrimaryText
                    fontSize={24}
                    fontWeight={400}
                    style={styles.price}
                  >
                    2189/
                  </PrimaryText>
                  <View style={styles.monthContainer}>
                    <PrimaryText
                      fontSize={15}
                      fontWeight={400}
                      style={styles.price}
                    >
                      yr
                    </PrimaryText>
                  </View>
                </View>
              </View>

              <View style={styles.listContainer}>
                <View style={[styles.priceRow, { alignItems: "center" }]}>
                  <MdIcons.MdOutlineCheck
                    color={Colors.primary.green}
                    size={24}
                  />
                  <PrimaryText
                    fontSize={18}
                    fontWeight={400}
                    style={styles.price}
                  >
                    Unlimited Inventory Items
                  </PrimaryText>
                </View>
                <View style={[styles.priceRow, { alignItems: "center" }]}>
                  <MdIcons.MdOutlineCheck
                    color={Colors.primary.green}
                    size={24}
                  />
                  <PrimaryText
                    fontSize={18}
                    fontWeight={400}
                    style={styles.price}
                  >
                    Unlimited Projects
                  </PrimaryText>
                </View>
                <View style={[styles.priceRow, { alignItems: "center" }]}>
                  <MdIcons.MdOutlineCheck
                    color={Colors.primary.green}
                    size={24}
                  />
                  <PrimaryText
                    fontSize={18}
                    fontWeight={400}
                    style={styles.price}
                  >
                    Unlimited Cloud Storage
                  </PrimaryText>
                </View>
              </View>

              {/* <View 
                    style={styles.button}
                    >
                        <PrimaryText fontSize={16} fontWeight={400} style={{color: Colors.primary.white}}>Save</PrimaryText>
                    </View> */}
            </TouchableOpacity>
          </View>
        )}

        {/** TABS */}
      </View>
      <BraintreeDropIn
        show={true}
        onSubmit={(cardNonce) => onSubmitPayment(cardNonce)}
      />
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    height: "100%",
    width: "100%",
    backgroundColor: Colors.primary.white,
    paddingTop: 12,
    marginLeft: 4,
    marginTop: 4,
    paddingBottom: 100,
  },
  body: {
    flex: 1,
    backgroundColor: Colors.primary.white,
    alignItems: "center",
  },
  tabContainer: {
    flexDirection: "row",
    justifyContent: "space-around",
    backgroundColor: Colors.primary.lightGray,
    borderRadius: 4,
    padding: 4,
    marginTop: 12,
    width: "80%",
  },
  tabButton: {
    alignItems: "center",
    justifyContent: "flex-start",
    width: "50%",
    borderRadius: 4,
    marginHorizontal: 0,
    padding: 4,
    // width: width*0.15-12,
    backgroundColor: Colors.primary.lightGray,
  },
  activeTab: {
    backgroundColor: Colors.primary.white,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,

    elevation: 5,
  },
  button: {
    backgroundColor: Colors.primary.green,
    // width: 100,
    justifyContent: "center",
    alignItems: "center",
    padding: 8,
    marginHorizontal: 4,
    borderRadius: 6,
  },

  priceContainer: {
    marginVertical: 24,
    alignItems: "center",
  },
  priceRow: {
    flexDirection: "row",
  },
  userCount: {
    textAlign: "center",
  },
  price: {
    textAlign: "center",
    color: Colors.primary.gray,
  },
  monthContainer: {
    flex: 1,
    justifyContent: "flex-end",
    height: "100%",
  },
  listContainer: {
    marginVertical: 24,
  },
})
