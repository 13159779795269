import React, { memo } from 'react';
import {StyleSheet, TouchableOpacity} from 'react-native';
import {Colors} from '~/constants/Colors';
import { PrimaryText } from '~/components/Common/PrimaryText';
import * as MaterialCommunityIcons from "react-icons/md";
import { View } from 'react-native-web';


const Card = ({
  item,
  onPress,
  onSelect,
  selected
}) => {
  
  return (
    <TouchableOpacity style={styles.card} onPress={onPress}>
      <View style={{flexDirection:'row', alignItems: 'center', paddingVertical: 8,}}>
        <MaterialCommunityIcons.MdFolderOpen color={Colors.primary.gray} size={24} />
        <PrimaryText fontSize={16} fontWeight={400} style={styles.text}>{item.model}</PrimaryText>
      </View>

      <TouchableOpacity style={styles.archiveButton} onPress={onSelect}>
        {selected ?
        <MaterialCommunityIcons.MdOutlineCheckBox color={Colors.primary.gray} size={24} />
        :
        <MaterialCommunityIcons.MdCheckBoxOutlineBlank color={Colors.primary.gray} size={24} />
        }
        
      </TouchableOpacity>
      
      
    </TouchableOpacity>
  )
}

export const SelectManufacturerCard = memo(Card)


const styles = StyleSheet.create({
  card: {
    marginHorizontal: 12,
    
    flexDirection: 'row',
    alignItems:'center',
    justifyContent:'space-between',
    borderColor: Colors.primary.lightGray,
    borderBottomWidth: 1
  },
  text: {
    marginLeft: 12
  },
  archiveButton: {
    padding: 8,
  }

});
