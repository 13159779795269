import React, {useEffect, useState} from 'react'
// import './index.css';
import DropIn from "braintree-web-drop-in"
import { Button } from 'react-native-web';
import { useCompany } from 'context/CompanyContext';
import { getBraintreeConfig } from 'utils/helpers';

export default function BraintreeDropIn(props) {
    const { show, onSubmit, buttonLabel } = props;

    const {company} = useCompany()

    const [braintreeInstance, setBraintreeInstance] = useState(undefined)

    useEffect(() => {
        const authorization = getBraintreeConfig(company)
        if (show) {
            const initializeBraintree = () => DropIn.create({
                // insert your tokenization key or client token here
                authorization: authorization, 
                container: '#braintree-drop-in-div',
                card: {
                    cardholderName: true
                }
            }, function (error, instance) {
                if (error)
                    console.error(error)
                else
                    setBraintreeInstance(instance);
            });

            if (braintreeInstance) {
                braintreeInstance
                    .teardown()
                    .then(() => {
                        initializeBraintree();
                    });
            } else {
                initializeBraintree();
            }
        }
    }, [show])

    return (
        <div
            style={{display: `${show ? "block" : "none"}`}}
        >
            <div
                id={"braintree-drop-in-div"}
            />

            <Button
                className={"braintreePayButton"}
                type="primary"
                title={buttonLabel ? buttonLabel : 'Sign Up'}
                disabled={!braintreeInstance}
                
                onPress={() => {
                    if (braintreeInstance) {
                        braintreeInstance.requestPaymentMethod(
                            (error, payload) => {
                                if (error) {
                                    console.error(error);
                                } else {
                                    const paymentMethodNonce = payload.nonce;
                                    onSubmit(paymentMethodNonce);
                                }
                            });
                    }
                }}
            />
        </div>
    )
}