import React, { useEffect, useState } from "react";
import { StyleSheet, View, FlatList } from "react-native";
import { Colors } from "~/constants/Colors";
import { Header } from "~/components/Header";
import { useParts } from "~/context/PartsContext";
import { CustomInput } from "~/components/Inputs/CustomInput";
import { ItemByLocationCard } from "~/components/Cards/ItemByLocationCard";
import { useCompany } from "context/CompanyContext";
import { Links } from "constants/Links";
import { useNavigate } from "react-router-dom";
import { formatInventoryByLocationReport } from "utils/exports-helper";
import { firestore } from "utils/firebase";
import { getItemName, getItemNameFromManufacturer } from "utils/helpers";

export function InventoryByLocationItems({ subLocation }) {
  const navigate = useNavigate();

  const { models, setSelectedItem, locationParts, parts, manufacturers } =
    useParts();
  const { company, selectedLocation } = useCompany();

  const [location, setLocation] = useState("");
  const [allParts, setAllParts] = useState(null);
  const [filtered, setFiltered] = useState(models);
  const [search, setSearch] = useState(null);

  useEffect(() => {
    if (!parts || !locationParts) return;

    // const batch = firestore.batch();
    // parts.forEach((part) => {
    //   const items = locationParts.filter((x) => x.partID === part.id);
    //   const sum = items?.length
    //     ? items
    //         .map((x) => x.locationQty)
    //         .reduce((a, b) => {
    //           return a + b;
    //         })
    //     : 0;
    //   if (sum !== part.qty) {
    //     const docRef = firestore
    //       .collection("companies")
    //       .doc(company.id)
    //       .collection("parts")
    //       .doc(part.id);
    //     batch.update(docRef, { qty: sum });
    //     // console.log('update sum? ', sum, part.qty, part.id, company.id)
    //   }
    // });
    // batch.commit();
  }, []);

  useEffect(() => {
    setLocation(selectedLocation);
    if (selectedLocation?.id !== "all") {
      
      let temp = locationParts
        .filter(
          (x) => x.locationID === selectedLocation.id && x.locationQty !== 0
        )
        // .sort((a, b) =>
        //   (a.manufacturer
        //     ? getItemName(a).toLowerCase()
        //     : getItemNameFromManufacturer(a, manufacturers).toLowerCase()) >
        //   (b.manufacturer
        //     ? getItemName(b).toLowerCase()
        //     : getItemNameFromManufacturer(b, manufacturers).toLowerCase())
        //     ? 1
        //     : -1
        // );
      if (subLocation) {
        temp = temp.filter((x) => x.subLocation === subLocation.id);
      }
      setAllParts(temp);
    } else {
      
      const temp = parts
        .filter((x) => x.qty !== 0 && x.qty !== undefined)
        .sort((a, b) =>
          (a.manufacturer
            ? getItemName(a).toLowerCase()
            : getItemNameFromManufacturer(a, manufacturers).toLowerCase()) >
          (b.manufacturer
            ? getItemName(b).toLowerCase()
            : getItemNameFromManufacturer(b, manufacturers).toLowerCase())
            ? 1
            : -1
        );
      setAllParts(temp);
    }
  }, [selectedLocation, subLocation]);

  useEffect(() => {
    if (search && allParts) {
      const temp = allParts.filter(
        (x) =>
          x.lowercaseModel.includes(search.toLowerCase()) ||
          x.lowercaseMfg.includes(search.toLowerCase())
      );
      setFiltered(temp);
    } else {
      const temp = allParts
        ? allParts
            .filter((x) => selectedLocation ? x.locationQty!==0 : (x.qty !== 0 && x.qty !== undefined))
            .sort((a, b) => (a.lowercaseMfg > b.lowercaseMfg ? 1 : -1))
        : [];
      setFiltered(temp);
    }
  }, [search, allParts]);

  const itemSelected = (item) => {
    setSelectedItem(item);
    navigate(Links.Inventory);
  };

  let sum = 0;
  filtered &&
    filtered.forEach((item) => {
      const cost = item.cost;
      const qty = location?.name === "All" ? item.qty : item.locationQty;
      sum += cost * qty;
    });

  return (
    <View style={styles.container}>
      <Header
        title={location?.name}
        exportData={formatInventoryByLocationReport(location, filtered)}
        // onBack={() => navigation.goBack()}
      />
      <CustomInput
        placeholder={"Search Items"}
        style={{ marginHorizontal: 12 }}
        onChange={setSearch}
        value={search}
      />
      <FlatList
        style={{ flex: 1 }}
        // contentContainerStyle={{flexGrow: 1, backgroundColor: Colors.primary.white}}
        data={filtered}
        keyExtractor={(item) => item.id}
        renderItem={({ item, index }) => {
          return (
            <ItemByLocationCard
              onPress={() => itemSelected(item)}
              item={item}
              qty={location?.name === "All" ? item.qty : item.locationQty}
              name={
                item.manufacturer
                  ? getItemName(item)
                  : getItemNameFromManufacturer(item, manufacturers)
              }
            />
          );
        }}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
    backgroundColor: Colors.primary.white,
  },
});
