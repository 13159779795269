import { cloneDeep } from "lodash";
import React, { useState, createContext, useEffect, useContext } from "react";
import { getLocations } from "services/locations-service";
import {
  getPaymentCardInfo,
  getUpcomingInvoice,
} from "services/subscription-service";
import { firestore } from "utils/firebase";
import {
  getCompany,
  getUsers,
  updateCompany,
} from "~/services/company-service";
import { fetchQboSyncData } from "services/quickbooks-service";
import { logout } from "services/auth-service";
import { useAuth } from "./AuthContext";
import { getAccounts } from "services/accounts-service";
import { usePersistState } from "./usePersistState";
import { currencies } from "constants/Currencies";
import { defaultDToolsCustomFieldMapping } from "constants/Constants";

export const CompanyContext = createContext();

let currency = {
  name: "US Dollars",
  iso_4217_3: "EUR",
  number_decimals: 2,
  symbols: {
    primary: "US$",
    narrow: "$",
  },
  default_locale: "en-US",
};

export const CompanyProvider = ({ children }) => {
  const { currentAuth, currentUser, setCurrentUser, setAssignedLocation } =
    useAuth();

  const [company, setCompany] = usePersistState("COMPANY_company", null);
  const [isDTools, setIsDTools] = usePersistState("COMPANY_isDTools", false);
  const [users, setUsers] = usePersistState("COMPANY_users", null);
  const [accounts, setAccounts] = useState(null);
  const [activeUsers, setActiveUsers] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [locations, setLocations] = usePersistState("COMPANY_locations", null);
  const [selectableLocations, setSelectableLocations] = useState(null);
  const [stagingAreaSubLocations, setStagingAreaSubLocations] = usePersistState(
    "COMPANY_stagingAreaSubLocations",
    []
  );
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedAudit, setSelectedAudit] = useState(null);
  const [qboSyncData, setQboSyncData] = useState(null);
  const [getQboSyncData, setGetQboSyncData] = useState(false);

  //subscription
  const [upcomingInvoice, setUpcomingInvoice] = useState(null);
  const [paymentInfo, setPaymentInfo] = useState(null);

  const [loading, setLoading] = useState(false);

  function clearState() {
    setCompany(null);
    setIsDTools(false);
    setUsers(null);
    setLocations(null);
    setSelectableLocations(null);
  }

  useEffect(() => {
    if (currentAuth) {
      const subscriber = getCompany(currentAuth).onSnapshot((querySnapshot) => {
        querySnapshot.forEach((companyDoc) => {
          const company = {
            ...companyDoc.data(),
            id: companyDoc.id,
            ref: companyDoc.ref,
          };
          if (!company.bStockValuePercentage)
            company.bStockValuePercentage = 100;

          const inTrial = company.trialEndDate
            ? company.trialEndDate.toDate().getTime() > new Date().getTime()
            : false;

          if (company.active && !company.paymentSubscriptionId && !inTrial) {
            updateCompany(company, { active: false }, null);
          } else if (!company.active && inTrial) {
            updateCompany(company, { active: true }, null);
          }

          if (company.type === "dtoolsSI") {
            const deliveredDate =
              company?.dToolsCustomFields?.deliveredDate ||
              defaultDToolsCustomFieldMapping.deliveredDate;
            const deliveredBy =
              company?.dToolsCustomFields?.deliveredBy ||
              defaultDToolsCustomFieldMapping.deliveredBy;
            const pickedBy =
              company?.dToolsCustomFields?.pickedBy ||
              defaultDToolsCustomFieldMapping.pickedBy;
            const returnInfo =
              company?.dToolsCustomFields?.returnInfo ||
              defaultDToolsCustomFieldMapping.returnInfo;

            company.dToolsCustomFields = {
              deliveredDate,
              deliveredBy,
              pickedBy,
              returnInfo,
            };
          }

          setCompany(company);
        });
      });

      return () => subscriber();
    }
  }, [currentAuth]);

  useEffect(() => {
    // console.log('auth context company changed', company)
    if (company && currentAuth) {
      setIsDTools(company.type === "dtoolsSI");

      if (company?.currency) {
        currency = currencies.find((x) => x.iso_4217_3 === company.currency);
      } else {
        currency = currencies.find((x) => x.iso_4217_3 === "USD");
      }

      dollarFormatter = new Intl.NumberFormat(currency?.default_locale, {
        style: "currency",
        currency: currency?.iso_4217_3,
      });

      if (
        company?.stagingAreaSubLocations &&
        company?.stagingAreaSubLocations?.length > 0
      ) {
        let filtered = company?.stagingAreaSubLocations?.filter(
          (x) => x.active
        );
        let sorted = filtered.sort((a, b) =>
          a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
        );
        setStagingAreaSubLocations(sorted);
      }

      const subscriber = firestore
        .collection("companies")
        .doc(company.id)
        .collection("users")
        .doc(currentAuth.uid)
        .onSnapshot(
          (userDoc) => {
            if (userDoc?.exists) {
              const user = {
                ...userDoc.data(),
                id: userDoc.id,
              };
              if (!user.active) {
                window.confirm(
                  "This user has been deactivated by an administrator"
                );
                logout(setCurrentUser, clearState);
                window.location.reload();
              } else {
                setCurrentUser(user);
              }
            } else {
              setCurrentUser(null);
            }
          },
          (e) => {
            // console.log('test2', e)
          }
        );
      // getUser(company.id, currentAuth.uid, setCurrentUser)
      const userSubscriber = getUsers(company.id, setUsers, setActiveUsers);
      const accountSubscriber = getAccounts(company, setAccounts);

      const locationSubscriber = getLocations(company, setLocations);

      if (company.paymentCustomerId && company.active) {
        getUpcomingInvoice(company, setUpcomingInvoice);
        getPaymentCardInfo(company, setPaymentInfo);
      }

      return () => {
        subscriber();
        userSubscriber();
        accountSubscriber();
        locationSubscriber();
      };
    }
  }, [company]);

  useEffect(() => {
    if (locations && currentUser) {
      if (currentUser.vehicle) {
        const vehicle = locations.find((x) => x.id === currentUser.vehicle);
        setAssignedLocation(vehicle?.name);
      }

      //move default location to top and add client as selectable option
      const clonedLocations = cloneDeep(locations);
      const sorted = clonedLocations.sort((a, b) =>
        a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1
      );
      let temp = [];
      sorted.forEach((location) => {
        if (location.id === company.defaultLocationId) {
          temp.unshift(location);
        } else {
          temp.push(location);
        }
      });
      temp = temp.filter((x) => x.active);

      temp.unshift({
        active: true,
        lowercaseName: "staging area",
        name: "Staging Area",
        id: "staging",
      });
      temp.unshift({
        active: true,
        lowercaseName: "client",
        name: "Client",
        id: "client",
      });

      setSelectableLocations(temp);
    }
  }, [locations, currentUser, company]);

  useEffect(() => {
    if (getQboSyncData && company) {
      const subscriber = fetchQboSyncData(company, setQboSyncData);
      return () => {
        subscriber && subscriber();
      };
    }
  }, [getQboSyncData, company]);

  return (
    <CompanyContext.Provider
      value={{
        loading,
        setLoading,
        clearState,
        company,
        setCompany,
        isDTools,
        setIsDTools,
        users,
        setUsers,
        activeUsers,
        setActiveUsers,
        selectedUser,
        setSelectedUser,
        accounts,
        setAccounts,
        locations,
        setLocations,
        selectableLocations,
        setSelectableLocations,
        stagingAreaSubLocations,
        setStagingAreaSubLocations,
        selectedLocation,
        setSelectedLocation,
        selectedAudit,
        setSelectedAudit,
        upcomingInvoice,
        setUpcomingInvoice,
        paymentInfo,
        setPaymentInfo,
        qboSyncData,
        setQboSyncData,
        getQboSyncData,
        setGetQboSyncData,
        dollarFormatter,
      }}
    >
      {children}
    </CompanyContext.Provider>
  );
};

export const useCompany = () => useContext(CompanyContext);

export let dollarFormatter = new Intl.NumberFormat(currency?.default_locale, {
  style: "currency",
  currency: currency?.iso_4217_3,
});
