import React from 'react';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import * as IoIcons from 'react-icons/io';
import * as RiIcons from 'react-icons/ri';
import * as GiIcons from 'react-icons/gi';
import { Links } from 'constants/Links';

export const SidebarData = [
  {
    title: 'Dashboard',
    path: Links.Dashboard,
    icon: <AiIcons.AiFillHome />
  },
  {
    title: 'Inventory Items',
    path: Links.Inventory,
    icon: <FaIcons.FaBoxOpen />
  },
  {
    title: 'New Transaction',
    path: Links.Transactions,
    icon: <FaIcons.FaPlusSquare />
  },
  {
    title: 'Receive Items To Stock',
    path: Links.Receive,
    icon: <GiIcons.GiBoxUnpacking />
  },
  {
    title: 'Approvals',
    path: Links.Approvals,
    icon: <FaIcons.FaCheckDouble />
  },
  {
    title: 'Serial # / Item Details Lookup',
    path: Links.Serial,
    icon: <AiIcons.AiOutlineBarcode />
  },
  {
    title: 'Reports',
    icon: <IoIcons.IoIosPaper />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: 'Projects',
        path: Links.Projects,
        icon: <IoIcons.IoMdArrowDropright />,
        cName: 'sub-nav'
      },
      {
        title: 'Inventory By Location',
        path: Links.InventoryByLocation,
        icon: <IoIcons.IoMdArrowDropright />,
        cName: 'sub-nav'
      },
      {
        title: 'Staging Area',
        path: Links.StagingArea,
        icon: <IoIcons.IoMdArrowDropright />
      },
      // {
      //   title: 'History',
      //   path: Links.History,
      //   icon: <IoIcons.IoMdArrowDropright />
      // },

      {
        title: 'Quick Reports',
        path: Links.Quick,
        icon: <IoIcons.IoMdArrowDropright />
      },
      {
        title: 'Min/Max Stock',
        path: Links.MinMax,
        icon: <IoIcons.IoMdArrowDropright />
      },
      {
        title: 'Audits',
        path: Links.Audits,
        icon: <IoIcons.IoMdArrowDropright />
      },
      {
        title: 'Total Valuation',
        path: Links.TotalValuation,
        icon: <IoIcons.IoMdArrowDropright />
      },
      // {
      //   title: 'Delivered Items',
      //   path: Links.Delivered,
      //   icon: <IoIcons.IoMdArrowDropright />
      // }
    ]
  },
  {
    title: 'Company',
    icon: <IoIcons.IoMdSettings />,

    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: 'Settings',
        path: Links.Settings,
        icon: <IoIcons.IoMdArrowDropright />
      },
      {
        title: 'Inventory Locations',
        path: Links.InventoryLocations,
        icon: <IoIcons.IoMdArrowDropright />
      },
      {
        title: 'Users',
        path: Links.Users,
        icon: <IoIcons.IoMdArrowDropright />
      },
      {
        title: 'QuickBooks Integration',
        path: Links.QuickBooks,
        icon: <IoIcons.IoMdArrowDropright />
      },
      {
        title: 'D-Tools Integration',
        path: Links.DTools,
        icon: <IoIcons.IoMdArrowDropright />
      }
    ]
  },
  {
    title: 'Support',
    path: Links.Support,
    icon: <IoIcons.IoMdHelpCircle />
  }
];