import React from "react"
import { StyleSheet, View, TouchableOpacity, Dimensions } from "react-native"
import Select from "react-select"
import { Colors } from "~/constants/Colors"
import { PrimaryText } from "~/components/Common/PrimaryText"
import { CustomInput } from "components/Inputs/CustomInput"
import { Switch } from "react-native-web"
import { updateCompany } from "services/company-service"
import { Fonts } from "constants/Fonts"
import { timezoneOptions } from "constants/Constants"
import { currencies } from "constants/Currencies"

const { height } = Dimensions.get("window")

export function Company({ name, setName, company, isDTools }) {
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: "1px solid lightgray",
      color: state.isSelected ? Colors.primary.white : Colors.primary.darkGray,
      paddingHorizontal: 12,
      fontFamily: Fonts.primary.regular,
      fontSize: 14,
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      width: 240,
    }),
    singleValue: (styles) => ({
      ...styles,
      color: Colors.primary.darkGray,
      fontFamily: Fonts.primary.regular,
      fontSize: 14,
    }),
  }

  return (
    <View style={styles.container}>
      <View style={styles.settingContainer}>
        {/* <PrimaryText fontSize={20} fontWeight={400} style={styles.title} >General Settings</PrimaryText> */}

        <View style={styles.settingsRow}>
          <PrimaryText fontSize={20} fontWeight={400} style={styles.setting}>
            Company Name:
          </PrimaryText>
          <CustomInput
            value={name}
            onChange={(text) => setName(text)}
            style={{ minWidth: 300, margin: 0 }}
          />
          {!!(company.name !== name) && (
            <TouchableOpacity
              style={styles.button}
              onPress={() => updateCompany(company, { name })}
            >
              <PrimaryText
                fontSize={16}
                fontWeight={400}
                style={{ color: Colors.primary.white }}
              >
                Save
              </PrimaryText>
            </TouchableOpacity>
          )}
        </View>

        {/** CURRENCY */}

        <View style={[styles.settingsRow, { marginTop: "5%", zIndex: 999 }]}>
          <PrimaryText fontSize={14} fontWeight={400} style={styles.setting}>
            Currency:
          </PrimaryText>
          <Select
            styles={customStyles}
            value={
              company?.currency
                ? currencies.find((x) => x.iso_4217_3 === company.currency)
                : currencies.find((x) => x.iso_4217_3 === 'USD')
            }
            onChange={(value) =>
              updateCompany(company, { currency: value.iso_4217_3 })
            }
            options={currencies}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.iso_4217_3}
          />
        </View>

        {/** TIME ZONE */}
        {!company?.qbo && !isDTools && (
          <>
            <View
              style={[styles.settingsRow, { marginTop: "5%", zIndex: 999 }]}
            >
              <PrimaryText
                fontSize={14}
                fontWeight={400}
                style={styles.setting}
              >
                Timezone*:
              </PrimaryText>
              <Select
                styles={customStyles}
                value={
                  company?.timezone
                    ? timezoneOptions.find((x) => x.value === company.timezone)
                    : timezoneOptions[0]
                }
                onChange={(value) =>
                  updateCompany(company, { timezone: value.value })
                }
                options={timezoneOptions}
              />
            </View>
            <PrimaryText fontSize={12} fontWeight={300} style={styles.info}>
              * Location of QuickBooks Company File, not the location of the
              business branch.
            </PrimaryText>
          </>
        )}

        {/** HIDE FEATURES */}
        <View style={styles.infoContainer}>
          <PrimaryText fontSize={16} fontWeight={300} style={styles.info}>
            Hide features you don't use
          </PrimaryText>
          <View style={styles.settingsRow}>
            <PrimaryText fontSize={18} fontWeight={400} style={styles.setting}>
              Show Time Tracking:
            </PrimaryText>
            <Switch
              value={company?.showTimeTracking}
              onValueChange={(value) =>
                updateCompany(company, { showTimeTracking: value })
              }
            />
          </View>
          <View style={styles.settingsRow}>
            <PrimaryText fontSize={18} fontWeight={400} style={styles.setting}>
              Show Purchase Orders:
            </PrimaryText>
            <Switch
              value={company?.showPurchaseOrders}
              onValueChange={(value) =>
                updateCompany(company, { showPurchaseOrders: value })
              }
            />
          </View>
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    width: "100%",
    padding: 24,
    // backgroundColor:'red'
  },
  infoContainer: {
    marginTop: "5%",
  },
  info: {
    marginLeft: 12,
    marginBottom: 12,
  },
  settingContainer: {
    marginBottom: "5%",
  },
  settingsRow: {
    flexDirection: "row",
    margin: 12,
    alignItems: "center",
  },
  title: {
    color: Colors.primary.gray,
    marginVertical: 12,
  },
  setting: {
    marginRight: 30,
    // width: 204,
    fontSize: 18,
  },
  button: {
    backgroundColor: Colors.primary.green,
    // width: 100,
    justifyContent: "center",
    alignItems: "center",
    padding: 8,
    marginHorizontal: 4,
    borderRadius: 6,
  },
  button2: {
    flexDirection: "row",
    backgroundColor: Colors.primary.blue,
    // width: 100,
    justifyContent: "center",
    alignItems: "center",
    padding: 8,
    margin: 12,
    borderRadius: 6,
  },
})
