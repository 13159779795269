import React, { useEffect, useState } from "react";
import { StyleSheet, View, FlatList } from "react-native";
import { Colors } from "~/constants/Colors";
import { Header } from "components/Header";
import { useCompany } from "context/CompanyContext";
import { LocationExpandableCard } from "components/Cards/LocationExpandableCard";
import { cloneDeep } from "lodash";

export function LocationsSelector({
  all,
  subLocations,
  onSubLocationChanged,
  selectedSubLocation,
  setSelectedSubLocation,
}) {
  const { locations, setSelectedLocation, selectedLocation } = useCompany();

  const [locationsWithAll, setLocationsWithAll] = useState(null);

  useEffect(() => {
    if (locations) {
      const temp = cloneDeep(locations);
      const sorted = temp.sort((a, b) =>
        a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1
      );
      sorted.forEach((location) => {
        const { subLocations = [] } = location;
        if (!subLocations) return;
        subLocations.sort((a, b) =>
          a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1
        );
      });

      all && sorted.unshift({ active: true, name: "All", id: "all" });
      setLocationsWithAll(sorted);
    }
  }, [locations]);

  function locationSelected(location) {
    setSelectedSubLocation && setSelectedSubLocation(null);

    setSelectedLocation(location);
  }

  return (
    <View style={styles.listContainer}>
      <Header title={"Locations"} />
      <FlatList
        style={{ flex: 1 }}
        contentContainerStyle={{ backgroundColor: Colors.primary.white }}
        data={
          locationsWithAll && locationsWithAll?.length > 0
            ? locationsWithAll.filter((x) => x.active)
            : locationsWithAll
        }
        keyExtractor={(item) => item.id}
        renderItem={({ item, index }) => {
          // if (!item.active) return
          return (
            <LocationExpandableCard
              onPress={() => locationSelected(item)}
              item={item}
              highlighted={selectedLocation?.id === item.id}
              subLocations={subLocations}
              onSubLocationChanged={onSubLocationChanged}
              selectedSubLocation={selectedSubLocation}
            />
          );
        }}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  listContainer: {
    // flex: 1,
    width: "40%",
    flexDirection: "column",
    backgroundColor: Colors.primary.white,
  },
});
