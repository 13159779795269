import React, { useEffect, useState } from "react";
import { StyleSheet, View, Image, Text, Dimensions, ActivityIndicator, TouchableHighlight } from "react-native";
import { Colors } from "constants/Colors";
import { CustomInput } from "components/Inputs/CustomInput";
import { Header } from "components/Header";
import { useCompany } from "context/CompanyContext";
import { useAuth } from "context/AuthContext";
import { sendSupportEmail } from "services/email-service";
import { ConfirmModal } from "components/Modals/Confirmation";
import { PrimaryText } from "components/Common/PrimaryText";
import { QuickReportCard } from "components/Cards/QuickReportCard";
import { Links } from "constants/Links";
import { useNavigate } from "react-router-dom";

const { width, height } = Dimensions.get('window');

export function Support() {

    const navigate = useNavigate();

    const { company, isDTools } = useCompany()
    const { currentUser, currentAuth } = useAuth()

    const [info, setInfo] = useState(null)
    const [showConfirmModal, setShowConfirmModal] = useState(false)
    const [submitDisabled, setSubmitDisabled] = useState(true)
    const [loading, setLoading] = useState(false)

    const [highlighted, setHighlighted] = useState(null)

    const whiteLogo = require('assets/whiteLogo.png')

    useEffect(() => {
        setSubmitDisabled(!info)
    }, [info])

    const submitPressed = () => {
        setLoading(true)
        sendSupportEmail(company, currentUser, currentAuth?.email, info, onSuccess)
    }

    function onSuccess() {
        setLoading(false)
        setInfo(null)
        setShowConfirmModal(true)
    }

    function navigateToLink(link) {
        window.open(link);
    }

    function toggleHighlighted(item) {
        if (highlighted === item) {
            setHighlighted(null)
        } else {
            setHighlighted(item)
        }
    }



    return (
        <View style={styles.container}>
            <Header
                title={'Support'}
            />
            <View style={styles.row}>
                <View style={styles.list}>
                    <PrimaryText fontSize={20} fontWeight={400}>Help Links</PrimaryText>
                    <QuickReportCard
                        title={'YouTube'}
                        onPress={() => navigateToLink('https://www.youtube.com/@tracknicity', '_blank')}
                    />


                    {!isDTools ? (
                        <>
                            <QuickReportCard
                                title={'Getting Started'}
                                onPress={() => navigateToLink(isDTools ? 'https://komododecks.com/recordings/DL3KXzneoqM77k5S19ha' : 'https://tracknicity.com/admin/help/getting-started.html')}
                            />
                            <QuickReportCard
                                title={'Overview'}
                                onPress={() => isDTools ? navigate(Links.DToolsOverview) : navigateToLink('https://tracknicity.com/admin/help/overview.html')}
                            />


                            <QuickReportCard
                                title={'Inventory Locations'}
                                onPress={() => isDTools ? navigate(Links.DToolsInventoryLocations) : navigateToLink('https://tracknicity.com/admin/help/inventory-locations.html')}
                            />
                            <QuickReportCard
                                title={'Users'}
                                onPress={() => navigateToLink('https://tracknicity.com/admin/help/users.html')}
                            />
                            <PrimaryText fontSize={20} fontWeight={400} style={{marginTop: 12}} >QuickBooks</PrimaryText>
                            <QuickReportCard
                                title={'Setup'}
                                onPress={() => navigateToLink('https://tracknicity.com/admin/help/quickbooks-setup.html')}
                            />
                            <QuickReportCard
                                title={'Approvals'}
                                onPress={() => navigateToLink('https://tracknicity.com/admin/help/quickbooks-approvals.html')}
                            />
                            <QuickReportCard
                                title={'Time Tracking'}
                                onPress={() => navigateToLink('https://tracknicity.com/admin/help/quickbooks-timetracking.html')}
                            />
                            <QuickReportCard
                                title={'Purchase Orders'}
                                onPress={() => navigateToLink('https://tracknicity.com/admin/help/quickbooks-purchaseorders.html')}
                            />
                            <QuickReportCard
                                title={'Processes - Non Inventory'}
                                onPress={() => navigateToLink('https://tracknicity.com/admin/help/processes-noninventory.html')}
                            />
                            <QuickReportCard
                                title={'Processes - Inventory'}
                                onPress={() => navigateToLink('https://tracknicity.com/admin/help/processes-inventory.html')}
                            />
                        </>
                    ) : (
                        <>
                            {/* <PrimaryText fontSize={20} fontWeight={400} style={{ marginTop: 24 }} >D-Tools</PrimaryText> */}
                            <QuickReportCard
                                title={'Video Tutorials'}
                                onPress={() => toggleHighlighted('Video Tutorials')}
                                expandable
                                subItems={[
                                    {
                                        title: 'Tracknicity V3.0 Overview',
                                        onPress: () => navigateToLink('https://komododecks.com/recordings/DL3KXzneoqM77k5S19ha')
                                    },
                                    {
                                        title: 'Setting up D-Tools and Tracknicity',
                                        onPress: () => navigateToLink('https://komododecks.com/recordings/QRRXvGDmwVNuEhj321gG')
                                    },
                                    {
                                        title: 'Setting up Custom Fields in D-Tools',
                                        onPress: () => navigateToLink('https://komododecks.com/recordings/ToWrl7CeOCJLQ4StGWDp')
                                    },
                                    {
                                        title: 'Inventory Locations and Users',
                                        onPress: () => navigateToLink('https://komododecks.com/recordings/RA5o7YVu4nWdH3pFlFPE')
                                    },
                                    {
                                        title: 'Managing an item in Tracknicity',
                                        onPress: () => navigateToLink('https://komododecks.com/recordings/1bIenSvtBGvLBF5EyehR')
                                    },
                                    {
                                        title: 'Checking Inventory in D-Tools and Staging an Item',
                                        onPress: () => navigateToLink('https://komododecks.com/recordings/ekWCEcud9NNhcDYx2i02')
                                    },
                                    {
                                        title: 'Staging Area vs. Inventory Explanation',
                                        onPress: () => navigateToLink('https://komododecks.com/recordings/297bwshWXLdF4I0kElm7')
                                    },
                                    {
                                        title: 'Delivering Items From Staging Area',
                                        onPress: () => navigateToLink('https://komododecks.com/recordings/4UHuwKYOLqaICEVoAPqR')
                                    },
                                    {
                                        title: 'Labels and QR Codes',
                                        onPress: () => navigateToLink('https://komododecks.com/recordings/bVqMXc0gwNRrD9FZImb5')
                                    },
                                    {
                                        title: 'How to Return an Item',
                                        onPress: () => navigateToLink('https://komododecks.com/recordings/S8xn3AUAelO5hPdAvbC1')
                                    },
                                    {
                                        title: 'D-Tools Change Order Processing via Tracknicity',
                                        onPress: () => navigateToLink('https://komododecks.com/recordings/ekWCEcud9NNhcDYx2i02')
                                    },


                                ]}
                                highlighted={highlighted === 'Video Tutorials'}
                            />
                        </>
                    )}

                    {isDTools && (
                        <>
                            <QuickReportCard
                                title={'Getting Started'}
                                onPress={() => navigateToLink(isDTools ? 'https://komododecks.com/recordings/DL3KXzneoqM77k5S19ha' : 'https://tracknicity.com/admin/help/getting-started.html')}
                            />
                            <QuickReportCard
                                title={'Overview'}
                                onPress={() => isDTools ? navigate(Links.DToolsOverview) : navigateToLink('https://tracknicity.com/admin/help/overview.html')}
                            />


                            <QuickReportCard
                                title={'Inventory Locations'}
                                onPress={() => isDTools ? navigate(Links.DToolsInventoryLocations) : navigateToLink('https://tracknicity.com/admin/help/inventory-locations.html')}
                            />
                            <QuickReportCard
                                title={'Users'}
                                onPress={() => navigateToLink('https://tracknicity.com/admin/help/users.html')}
                            />
                        </>
                    )}








                </View>
                <View style={styles.body}>
                    <View style={styles.imageContainer}>
                        <Image source={whiteLogo} style={{ width: height * 0.2, height: height * 0.2 }} />
                    </View>
                    <View style={styles.bodyContainer}>
                        <View>
                            <View style={styles.inputContainer}>
                                <CustomInput
                                    title={'Contact Us'}
                                    placeholder={'Let us know how we can help'}
                                    value={info}
                                    onChange={e => setInfo(e)}
                                    multiline
                                    style={{ height: height * 0.25 }}
                                />
                            </View>

                            <TouchableHighlight
                                style={[styles.loginButton, submitDisabled ? styles.inactive : styles.active]}
                                underlayColor={'#DDD'}
                                onPress={() => submitPressed()}
                                disabled={submitDisabled}
                            >
                                <Text style={styles.loginButtonText}>Submit</Text>
                            </TouchableHighlight>
                        </View>

                    </View>
                </View>
            </View>
            {
                showConfirmModal &&
                <ConfirmModal
                    title={'Ticket Submitted'}
                    message={'Our support staff will contact you shortly. Thank you.'}
                    onConfirm={() => setShowConfirmModal(false)}
                    onConfirmText={'Okay'}
                    onCancel={null}
                />
            }
            {
                loading &&
                <ActivityIndicator
                    animating={loading}
                    size={'large'}
                    style={StyleSheet.absoluteFill} color={Colors.primary.blue}
                />
            }

        </View >
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        width: '100%',
        backgroundColor: Colors.primary.white,
    },
    row: {
        flexDirection: 'row'
    },
    list: {
        width: '40%',
    },
    body: {
        width: '60%'
    },
    imageContainer: {
        // flex: 1,
        alignItems: 'center',
        marginTop: 20
    },
    bodyContainer: {
        flex: 1,
        marginTop: 0,
        paddingHorizontal: 24,
        alignContent: 'center',
        justifyContent: 'space-between',
    },
    inputContainer: {
        flexDirection: 'column',
        paddingTop: 12,
        paddingBottom: 8
    },
    loginButton: {
        marginTop: 40,
        marginBottom: 44,
        height: 48,
        borderRadius: 15,
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: Colors.primary.lightBlue,
    },
    active: {
        opacity: 1
    },
    inactive: {
        opacity: 0.5
    },
    loginButtonText: {
        fontSize: 20,
        fontWeight: '600',
        color: Colors.primary.white
    },

});
