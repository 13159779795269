

export const Fonts = {
    primary: {
        thin: 'GalanoGrotesque-Thin',
        extraLight: 'GalanoGrotesque-ExtraLight',
        light: 'GalanoGrotesque-Light',
        regular: 'GalanoGrotesque-Regular',
        medium: 'GalanoGrotesque-Medium',
        semiBold: 'GalanoGrotesque-SemiBold',
        bold: 'GalanoGrotesque-Bold',
        extraBold: 'GalanoGrotesque-ExtraBold',
        black: 'GalanoGrotesque-Black'
    },
}