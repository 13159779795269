import React, { useEffect, useState } from "react";
import {
  StyleSheet,
  View,
  Dimensions,
  ActivityIndicator,
  FlatList,
  TouchableOpacity,
} from "react-native-web";
import { Colors } from "constants/Colors";
import { Header } from "components/Header";
import { useCompany } from "context/CompanyContext";
import { PrimaryText } from "components/Common/PrimaryText";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import {
  approveAuditItems,
  completeAudit,
  getAuditItems,
} from "services/audits-service";
import { AuditDetailCard } from "components/Cards/AuditDetailCard";
import { useParts } from "context/PartsContext";
import { cloneDeep } from "lodash";
import { ConfirmModal } from "components/Modals/Confirmation";
import { useAuth } from "context/AuthContext";
import { getItemName, getItemNameFromManufacturer } from "utils/helpers";
import { formatAuditReport } from "utils/exports-helper";

const { width, height } = Dimensions.get("window");

export function AuditReport() {
  const navigate = useNavigate();

  const { currentUser } = useAuth();
  const { company, selectedAudit, selectedLocation } = useCompany();
  const { parts, locationParts, manufacturers } = useParts();

  const [allParts, setAllParts] = useState(null);
  const [scannedItems, setScannedItems] = useState(null);
  const [filteredItems, setFilteredItems] = useState(null);
  const [sorted, setSorted] = useState(null);

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [approveDisabled, setApproveDisabled] = useState(
    selectedAudit?.complete
  );

  useEffect(() => {
    // console.log('test', selectedAudit)
    setAllParts(
      locationParts.filter(
        (x) => x.locationID === selectedLocation.id && x.locationQty !== 0
      )
    );
    const subscriber = getAuditItems(company, selectedAudit, setScannedItems);
    return () => subscriber();
  }, []);

  useEffect(() => {
    if (scannedItems) {
      const temp = [];
      scannedItems.forEach((item) => {
        const part = parts.find((x) => x.id === item.partID);
        if (part) {
          const clonedItem = cloneDeep(part);
          clonedItem.locationQty = item.originalQty;
          clonedItem.newQty = item.newQty;
          clonedItem.timeStamp = item.scanTime;
          clonedItem.approved = item.approved;
          // if (clonedItem.parentRefID) {

          //     const parent = manufacturers && manufacturers.find(x => x.id ===clonedItem.parentRefID)

          //     clonedItem.manufacturer = parent ? parent.model : ''
          //     clonedItem.lowercaseMfg = parent ? parent.model.toLowerCase() : ''
          // } else {
          //     console.log('jhere', clonedItem.model)
          // }
          temp.push(clonedItem);
        }
      });
      setFilteredItems(temp);
    }
  }, [scannedItems]);

  useEffect(() => {
    // console.log('all parts', allParts)
    if (filteredItems && allParts) {
      const temp = filteredItems.sort((a, b) =>
        sortObject(a.lowercaseMfg.toLowerCase(), b.lowercaseMfg.toLowerCase())
      );

      allParts
        .filter((x) => x.active && x.qty !== 0 && x.qty !== undefined)
        .sort((a, b) =>
          sortObject(a.lowercaseMfg.toLowerCase(), b.lowercaseMfg.toLowerCase())
        )
        .forEach((item) => {
          const find = temp.find((x) => x.id === item.partID);
          if (!find) {
            temp.push(item);
          } else {
            find.actualQty = item.locationQty;
          }
        });
      setSorted(temp);
    }
  }, [filteredItems, allParts]);

  useEffect(() => {
    if (sorted) {
      const items = sorted.filter(
        (x) => x?.newQty !== undefined && !x.approved
      );
      setApproveDisabled(
        selectedAudit?.complete || !items || items?.length === 0
      );
    }
  }, [sorted]);

  function sortObject(a, b) {
    if (a === b) return 0;
    return a < b ? -1 : 1;
  }

  function approveAll() {
    setLoading(true);
    const items = sorted.filter((x) => x?.newQty !== undefined && !x.approved);
    // console.log('items to approve', items)
    if (items && items.length > 0) {
      approveAuditItems(
        company,
        currentUser,
        selectedAudit,
        locationParts,
        selectedLocation,
        items,
        onSuccess
      );
    }
  }

  function onSuccess() {
    setShowConfirmModal('complete');
    setLoading(false);
  }

  return (
    <View style={styles.container}>
      <Header
        title={
          "Audit Report - " +
          selectedLocation.name +
          ": " +
          selectedAudit.createdAt.toDate().toDateString()
        }
        onBack={() => navigate(-1)}
        onRight={() => setShowConfirmModal("approve")}
        exportData={formatAuditReport(
          selectedLocation.name +
            ": " +
            selectedAudit.createdAt.toDate().toDateString(),
          sorted
        )}
        rightText={"Approve All"}
        rightDisabled={approveDisabled}
      />
      {/* <View style={{zIndex: 999, flexDirection: 'row', width: '100%', alignItems:'center'}}>
            <CustomInput 
            placeholder={'Filter By Customer or Item'}
            style={{flex: 1, marginHorizontal: 12, height: 40}}
            onChange={setSearch}
            value={search}
            />

            <View style={styles.optionContainer} >
                <PrimaryText 
                fontSize={14} 
                fontWeight={400} 
                style={{color: Colors.primary.darkGray, marginHorizontal: 4}} 
                numberOfLines={1} >
                Date Range:
                </PrimaryText>

            </View>




        </View> */}

      {filteredItems && filteredItems.length > 0 ? (
        <View style={styles.body}>
          <View style={styles.detailLabelContainer}>
            <PrimaryText
              fontSize={14}
              fontWeight={400}
              style={{ color: Colors.primary.white, width: width * 0.06 }}
            >
              Approved
            </PrimaryText>
            <View style={{ flexDirection: "row" }}>
              <PrimaryText
                fontSize={14}
                fontWeight={400}
                style={[styles.detailLabel, { width: width * 0.12 }]}
              >
                Original Qty
              </PrimaryText>
              <PrimaryText
                fontSize={14}
                fontWeight={400}
                style={styles.detailLabel}
              >
                New Qty
              </PrimaryText>
              <PrimaryText
                fontSize={14}
                fontWeight={400}
                style={styles.detailLabel}
              >
                Adjustment
              </PrimaryText>
              <PrimaryText
                fontSize={14}
                fontWeight={400}
                style={styles.detailLabel}
              >
                Counted
              </PrimaryText>
            </View>
          </View>

          <FlatList
            style={{
              flex: 1,
              width: "100%",
              borderTopWidth: 1,
              borderColor: Colors.primary.lightGray,
            }}
            data={sorted}
            keyExtractor={(item) => item.id}
            renderItem={({ item, index }) => {
              return (
                <AuditDetailCard
                  item={item}
                  name={
                    item.manufacturer
                      ? getItemName(item)
                      : getItemNameFromManufacturer(item, manufacturers)
                  }
                />
              );
            }}
          />
        </View>
      ) : (
        <View style={styles.noItemsContainer}>
          <PrimaryText fontSize={16} fontWeight={400}>
            {`No items for approval`}
          </PrimaryText>
        </View>
      )}

      {!!showConfirmModal && (
        <ConfirmModal
          title={
            showConfirmModal === "approve"
              ? "Approve All Audit Items"
              : "Mark Audit as Complete?"
          }
          message={"Are you sure?"}
          onConfirm={() =>
            showConfirmModal === "approve"
              ? approveAll()
              : completeAudit(company, selectedAudit, () =>
                  setShowConfirmModal(false)
                )
          }
          onCancel={() => setShowConfirmModal(false)}
        />
      )}

      {loading && (
        <ActivityIndicator
          animating={loading}
          size={"large"}
          style={StyleSheet.absoluteFill}
          color={Colors.primary.blue}
        />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: "100%",
    flexDirection: "column",
    backgroundColor: Colors.primary.white,
  },
  noItemsContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  body: {
    flex: 1,
    width: "100%",
    backgroundColor: Colors.primary.white,
    marginTop: 12,
    alignItems: "center",
  },
  optionContainer: {
    paddingHorizontal: 12,
    // width: 300,
    flexDirection: "row",
    alignItems: "center",
    // justifyContent:'space-between',
    marginVertical: 12,
  },

  //details
  detailLabelContainer: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: 12,
    marginTop: 12,
    backgroundColor: Colors.primary.gray,
  },
  detailLabel: {
    width: width * 0.09,
    textAlign: "center",
    color: Colors.primary.white,
  },

  //modal
  modal: {
    // justifyContent: 'flex-end',
    margin: 0,
  },
  modalContent: {
    // height: height*0.6,
    width: width,
    backgroundColor: Colors.primary.white,
    borderRadius: 12,
    padding: 12,
    // alignItems: 'center'
  },
  modalSwipeButton: {
    width: 32,
    height: 3,
    backgroundColor: Colors.primary.darkGray,
    borderRadius: 3,
    marginBottom: 12,
    alignSelf: "center",
  },
  description: {
    marginHorizontal: 12,
    marginVertical: 4,
  },
  lineBreak: {
    height: 1,
    backgroundColor: Colors.primary.lightGray,
    margin: 12,
  },
});
