import { firestore } from "utils/firebase";
import { Alert } from 'react-native';

export const getCompany = (auth) => {
  // console.log(auth)
  const email = auth.email
  return firestore.collection('companies').where('userEmails', 'array-contains', email)
}

export const updateCompany = (company, data, onSuccess) => {
  firestore
    .collection('companies')
    .doc(company.id)
    .update(data)
    .then(() => {
      onSuccess && onSuccess()
    })
}

export const createCompany = (data, onSuccess) => {
  const { company, email, firstName, lastName, userId } = data
  var now = new Date();
  now.setDate(now.getDate() + 30);

  firestore.collection("companies").add({
    active: true,
    logoURL: "",
    quickbooksSync: false,
    inventoryCreateInvoice: false,
    inventoryDoNothing: true,
    name: company,
    nonInventoryCreateCheck: false,
    nonInventoryCreateInvoice: false,
    nonInventoryDoNothing: true,
    paymentCustomerId: "",
    paymentSubscriptionId: "",
    showTimeTracking: true,
    showPurchaseOrders: true,
    signupDate: new Date(),
    trial: false,
    trialEndDate: new Date(),
    userEmails: [email]
  })
    .then(function (docRef) {

      // console.log("Document successfully written!");

      //CREATE DEFAULT LOCATION AND SAVE DOCID IN COMPANY SETTINGS
      firestore.collection("companies").doc(docRef.id).collection("locations")
        .add({
          lowercaseName: "warehouse",
          name: "Warehouse",
          active: true
        })
        .then(function (locationDocRef) {
          firestore.collection("companies").doc(docRef.id).update({
            "defaultLocationId": locationDocRef.id,
            "defaultLocationName": "Warehouse"
          })
          // console.log("Document written with ID: ", locationDocRef.id);
        });

      //CREATE USER
      firestore.collection("companies").doc(docRef.id).collection("users").doc(userId).set({
        active: true,
        admin: true,
        firstName: firstName,
        lastName: lastName,
        requests: true,
      })
        .then(function () {
          onSuccess && onSuccess()
        })
        .catch(function (error2) {
          // console.error("Error writing document: ", error2);
        });

      //CREATE LAST SYNC DOC
      firestore.collection("companies").doc(docRef.id).collection("lastSync").doc("lastSync").set({
        lastParentPartSync: new Date("1983-01-01")
      });

      const formdata = new FormData();
      formdata.append("companyId", docRef.id);
      formdata.append("companyName", company);
      formdata.append("email", email);
      formdata.append("plan", "v2");
      formdata.append("firstName", firstName);
      formdata.append("lastName", lastName);
      formdata.append("signupDate", new Date().toDateString());

      const requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow"
      };

      fetch("https://tracknicity.com/admin/php/email.php", requestOptions)
        // .then((response) => response.text())
        // .then((result) => console.log(result))
        // .catch((error) => console.error(error));

    })
    .catch(function (error) {
      console.error("Error writing document: ", error);
    });
}

export const getUsers = (companyId, setUsers, setActiveUsers) => {
  // console.log(companyId, userId)
  return firestore
    .collection('companies')
    .doc(companyId)
    .collection('users')
    .onSnapshot(querySnapshot => {
      const temp = []
      // const tempActive = []
      querySnapshot.forEach(doc => {
        const user = {
          id: doc.id,
          ...doc.data()
        }
        temp.push(user)
      })

      const sorted = temp.sort((a, b) => a.firstName.toLowerCase() > b.firstName.toLowerCase() ? 1 : -1)
      setUsers(sorted)

      const tempActive = sorted.filter(x => x?.active)
      // tempActive.unshift({firstName: 'All', id: 'all'})
      setActiveUsers(tempActive)

    })
}

export const getCompanyHistoryByDate = (company, startDate, endDate) => {
  return firestore
    .collection('companies')
    .doc(company.id)
    .collection('history')
    .where("date", ">=", startDate)
    .where("date", "<=", endDate)
    .orderBy("date", "desc")
}

export const getDeliveredItemsByDate = (company, startDate, endDate) => {
  return firestore
    .collection('companies')
    .doc(company.id)
    .collection('purchaseOrders')
    .where("deliveredDate", ">=", startDate)
    .where("deliveredDate", "<=", endDate)
    .orderBy("deliveredDate", "desc")
}

export const getDeliveredStockItemsByDate = (company, startDate, endDate) => {
  return firestore
    .collection('companies')
    .doc(company.id)
    .collection('history')
    .where("date", ">=", startDate)
    .where("date", "<=", endDate)
    .orderBy("date", "desc")
}

export const updateHistoryItem = (company, item, data) => {
  return firestore
    .collection('companies')
    .doc(company.id)
    .collection('history')
    .doc(item.id)
    .update(data)
}

export const getSerialNumbers = (company) => {
  return firestore
    .collection('companies')
    .doc(company.id)
    .collection('serialNumbers')
}