import React, { useState, createContext, useEffect, useContext } from "react";
import { auth } from "utils/firebase";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {

  const [initializing, setInitializing] = useState(true)
  const [currentAuth, setCurrentAuth] = useState(null)
  const [currentUser, setCurrentUser] = useState(null)
  const [assignedLocation, setAssignedLocation] = useState(null)

  // Handle user state changes
  function onAuthStateChanged(auth) {
    setCurrentAuth(auth);
    if (initializing) setInitializing(false);
  }

  useEffect(() => {
    const subscriber = auth.onAuthStateChanged(onAuthStateChanged);
    return subscriber; // unsubscribe on unmount
  }, []);


  if (initializing) return null;

  return (
    <AuthContext.Provider value={{ 
      initializing,
      currentAuth, setCurrentAuth,
      currentUser, setCurrentUser,
      assignedLocation, setAssignedLocation
      }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext)
