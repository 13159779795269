import React from "react"
import { StyleSheet, TouchableOpacity, View } from "react-native"
import { Colors } from "constants/Colors"
import { PrimaryText } from "components/Common/PrimaryText"
import { useCompany } from "context/CompanyContext"
import { useCustomers } from "context/CustomersContext"
import * as FaIcons from "react-icons/fa"

export const ItemQtyByLocationCard = ({ item, onPress }) => {
  const { locations, stagingAreaSubLocations } = useCompany()
  const { customers } = useCustomers()

  let style = styles(false)
  const name =
    locations && customers
      ? !item.customerId
        ? item.locationID === "bstock"
          ? "B-Stock"
          : locations.find((x) => x.id === item.locationID)?.name
        : customers.find((x) => x.id === item.customerId)?.fullName
      : ""
  const location = !item.customerId
    ? locations.find((x) => x.id === item.locationID)
    : null
  let subLocation = null
  if (item.subLocation && location.subLocations) {
    subLocation =
      " (" +
      location.subLocations.find((x) => x.id === item.subLocation).name +
      ") "
  }
  let stagingArea
  if (item.customerId) {
    let customer = customers.find((x) => x.id === item.customerId)
    if (customer?.stagingArea) {
      stagingArea =
        "Staging Area: " +
        stagingAreaSubLocations?.find((x) => x.id === customer?.stagingArea)
          ?.name
    } else {
      stagingArea = "Staging Area: Default"
    }
  }

  return (
    <TouchableOpacity style={style.card} onPress={() => console.log(item)}>
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          flex: 1,
          paddingRight: 12,
        }}
      >
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          {!!item.customerId && (
            <FaIcons.FaSquare
              color={Colors.primary.blue}
              size={6}
              style={{ marginRight: 4 }}
            />
          )}
          <PrimaryText
            fontSize={16}
            fontWeight={400}
            numberOfLines={1}
            style={style.text}
          >
            {name}
          </PrimaryText>
          {subLocation && (
            <PrimaryText fontSize={16} fontWeight={400} numberOfLines={1}>
              {subLocation}
            </PrimaryText>
          )}
        </View>
        <PrimaryText fontSize={16} fontWeight={400}>
          Qty: {item.locationQty}
        </PrimaryText>
      </View>

      {stagingArea && (
        <PrimaryText fontSize={16} fontWeight={400} numberOfLines={1}>
          {stagingArea}
        </PrimaryText>
      )}
    </TouchableOpacity>
  )
}

export const styles = (darkMode) =>
  StyleSheet.create({
    card: {
      marginHorizontal: 6,
      paddingVertical: 8,
      // flexDirection: "row",
      // alignItems: "center",
      // justifyContent: "space-between",
      borderColor: Colors.primary.lightGray,
      borderBottomWidth: 1,
    },
    text: {
      maxWidth: 400,
    },
  })
