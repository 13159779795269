import React from "react"
import { Dimensions } from "react-native"
import { FixedSizeList, VariableSizeList } from "react-window"

const { height, width } = Dimensions.get("window")

export function ListContainer({ items, size, renderItem, listWidth, filter = false }) {
  // console.log("listWidth", listWidth, width)
  function getItemSize(index) {
    // if (filter) {
    //   console.log('test', items[index])
    //   if (!items[index].active || items[index].archived) {
    //     console.log('test', items[index].model)
    //     return 0
    //   } else {
    //     return size
    //   }
    // } else {
      return size
    // }
  }
  return (
    <VariableSizeList
      height={height}
      width={listWidth ? listWidth : width - 300}
      itemSize={getItemSize}
      itemCount={items ? items.length : 0}
    >
      {renderItem}
    </VariableSizeList>
  )
}
