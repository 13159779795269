export const timezoneOptions = [
  { value: "America/New_York", label: "EST (UTC-5)" },
  { value: "America/Chicago", label: "CST (UTC-6)" },
  { value: "America/Denver", label: "MST (UTC-7)" },
  { value: "America/Phoenix", label: "MST no DST (UTC-7)" },
  { value: "America/Los_Angeles", label: "PST (UTC-8)" },
  { value: "America/Anchorage", label: "AKST (UTC-9)" },
  { value: "America/Adak", label: "HST (UTC-10)" },
  { value: "America/Honolulu", label: "HST no DST (UTC-10)" },
];

export const dToolsCustomFieldStringOptions = [
  { value: "CustomField1", label: "CustomField1" },
  { value: "CustomField2", label: "CustomField2" },
  { value: "CustomField3", label: "CustomField3" },
  { value: "CustomField4", label: "CustomField4" },
  { value: "CustomField5", label: "CustomField5" },
  { value: "CustomField12", label: "CustomField12" },
  { value: "CustomField13", label: "CustomField13" },
  { value: "CustomField14", label: "CustomField14" },
  { value: "CustomField15", label: "CustomField15" },
  { value: "CustomField16", label: "CustomField16" },
  { value: "CustomField17", label: "CustomField17" },
  { value: "CustomField18", label: "CustomField18" },
  // { value: "CustomField23", label: "CustomField23" },
  // { value: "CustomField24", label: "CustomField24" },
  // { value: "CustomField25", label: "CustomField25" },
  // { value: "CustomField26", label: "CustomField26" },
];

export const dToolsCustomFieldDateOptions = [
  { value: "CustomField9", label: "CustomField9" },
  { value: "CustomField10", label: "CustomField10" },
  { value: "CustomField11", label: "CustomField11" },
];

export const defaultDToolsCustomFieldMapping = {
  deliveredDate: "CustomField11", //delivered date
  deliveredBy: "CustomField12", //delivered by
  pickedBy: "CustomField13", //picked by
  returnInfo: "CustomField14" //returned date and by string
};
