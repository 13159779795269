import React, { useEffect, useState } from "react";
import { StyleSheet, View, TouchableOpacity } from "react-native";
import { PrimaryText } from "components/Common/PrimaryText";
import { FullModal } from "components/Modals/FullModal";
import * as AiIcons from "react-icons/ai";

import { Colors } from "~/constants/Colors";
import { useParts } from "~/context/PartsContext";
import { Waveform } from "@uiball/loaders";
import { TextInput } from "react-native-web";
import { firestore } from "utils/firebase";
import { useCompany } from "context/CompanyContext";
import { useAlert } from 'react-alert'

let tempScannedItem = "";
let timer;

export function Scanner(props) {
  const {
    showScanner,
    setShowScanner,
    scannedItem,
    setScannedItem,
    editing = false,
    title,
    setUpc,
    setSelectedItem,
    keepScannerOpen = false,
    setPurchaseOrder = null,
  } = props;
  const { parts } = useParts();
  const { company } = useCompany();
  const alert = useAlert()

  //setup keyboard listener
  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [showScanner]);

  function handleKeyDown(e) {
    if (e.key === "Escape") {
      !keepScannerOpen && setShowScanner(false);
    }
    if (showScanner && e.key?.length === 1) {
      tempScannedItem = tempScannedItem + e.key;
      timer = setTimeout(() => {
        setScannedItem(tempScannedItem);
      }, 250);
    }
  }

  useEffect(() => {
    if (!scannedItem) {
      return;
    }
    if (editing) {
      const temp = parts.filter((x) => x?.upc === scannedItem);
      if (temp.length > 0) {
        !keepScannerOpen && setShowScanner(false);

        tempScannedItem = "";
        alert.show(`Part found with that UPC: ${temp[0].model}`);
      } else {
        setUpc(scannedItem);
        !keepScannerOpen && setShowScanner(false);
        // alert.show("No part found with that UPC");
      }
    } else {
      if (setPurchaseOrder) {
        // console.log('here')
        const docId = scannedItem
        console.log("docId", docId);
        firestore
          .collection("companies")
          .doc(company.id)
          .collection("purchaseOrders")
          .doc(docId)
          .get()
          .then((doc) => {
            if (doc.exists) {
              // console.log("we found something!", doc.data());
              const purchaseOrder = {
                ...doc.data(),
                id: doc.id,
              };
              setPurchaseOrder && setPurchaseOrder(purchaseOrder);
              // setSelectedItem(doc.data());
              // !keepScannerOpen && setShowScanner(false);
              // tempScannedItem = "";
            }
          });
          return;
      }
      // console.log('here')
      const temp = parts.filter((x) => x?.upc === scannedItem);
      if (temp.length > 0) {
        setSelectedItem(temp[0]);
        !keepScannerOpen && setShowScanner(false);

        tempScannedItem = "";
      }
    }
  }, [scannedItem, parts]);

  useEffect(() => {
    if (!showScanner) {
      setScannedItem(null);
      tempScannedItem = "";
    }
  }, [showScanner]);

  return (
    showScanner && (
      <FullModal>
        <View style={[styles.modal, { minHeight: undefined }]}>
          <View style={styles.modalHeader}>
            <PrimaryText
              fontSize={20}
              fontWeight={400}
              style={{ width: 40 }}
            ></PrimaryText>
            <PrimaryText fontSize={20} fontWeight={400}>
              {title}
            </PrimaryText>
            <TouchableOpacity onPress={() => setShowScanner(false)}>
              <AiIcons.AiOutlineClose
                name="barcode"
                color={Colors.primary.darkGray}
                size={24}
                style={{ width: 40 }}
              />
            </TouchableOpacity>
          </View>
          {scannedItem ? (
            <>
              <PrimaryText fontSize={14} fontWeight={400}>
                No Item Found
              </PrimaryText>
              <View style={{ flexDirection: "row", alignItems: "center" }}>
                <TextInput
                  //   autoFocus={true}
                  disabled={true}
                  style={{
                    height: 40,
                    borderColor: "gray",
                    borderWidth: 1,
                    width: "100%",
                    // marginTop: 20,
                    padding: 10,
                    marginRight: 12,
                  }}
                  onChangeText={(text) => setScannedItem(text)}
                  value={scannedItem}
                />
                <TouchableOpacity
                  onPress={() => {
                    setScannedItem("");
                    tempScannedItem = "";
                  }}
                >
                  <AiIcons.AiOutlineCloseSquare
                    color={Colors.primary.darkGray}
                    size={24}
                  />
                </TouchableOpacity>
              </View>
            </>
          ) : (
            <View style={{ alignItems: "center" }}>
              <Waveform size={40} lineWeight={3.5} speed={2} color="black" />
            </View>
          )}
        </View>
      </FullModal>
    )
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: "100%",
    backgroundColor: Colors.primary.white,
  },
  body: {
    flex: 1,
    width: "100%",
    flexDirection: "row",
    backgroundColor: Colors.primary.white,
  },
  modal: {
    minWidth: 400,
    minHeight: 200,
    maxHeight: 600,
  },
  modalHeader: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 20,
    borderColor: Colors.primary.gray,
    borderBottomWidth: 0.5,
    paddingBottom: 12,
  },
});
