import React, { useState, useEffect } from "react";
import { StyleSheet, View, TouchableOpacity } from "react-native";
import {Colors} from "constants/Colors";
import { useCompany } from "context/CompanyContext";
import { PrimaryText } from "components/Common/PrimaryText";
import { Header } from "components/Header";
import { ConfirmModal } from "components/Modals/Confirmation";
import * as MdIcons from "react-icons/md";
import { ScrollView } from "react-native-web";
import { updateCompany } from "services/company-service";

export function Onboarding() {

    const {company} = useCompany()

    const [type, setType] = useState(null)
    const [showConfirmModal, setShowConfirmModal] = useState(false)

    useEffect(() => {
        if (type) {
            setShowConfirmModal(true)
        } else {
            setShowConfirmModal(false)
        }
    }, [type])

    function getName() {
        let name
        switch (type) {
            case 'quickbooksDesktop':
                name = 'QuickBooks Desktop'
                break
            case 'quickbooksOnline':
                name = 'QuickBooks Online'
                break
            case 'dtoolsSI':
                name = 'D-Tools SI'
                break
            case 'dtoolsCloud':
                name = 'D-Tools Cloud'
                break
            default:
                name = null
        }

        return name
    }

    function saveIntegrationPath() {
        const data = {
            qbo: type==='quickbooksOnline',
            type
        }
        type==='dtoolsSI' && delete(data.qbo)
        type==='dtoolsCloud' && delete(data.qbo)
        updateCompany(company, data, () => null)
    }

    

    return (
        <ScrollView style={styles.container}>

            <View style={styles.body}>
                <PrimaryText fontSize={16} fontWeight={400} >{'Choose your integration path'}</PrimaryText>
                {/** TABS */}
                <View style={styles.tabContainer}>
                    <PrimaryText fontSize={20} fontWeight={400} >QuickBooks</PrimaryText>
                    <View style={styles.row}>
                        <TouchableOpacity 
                        onPress={() => setType('quickbooksDesktop')}
                        style={styles.button}
                        >
                            <PrimaryText fontSize={16} fontWeight={300} style={{color: Colors.primary.white}}>QUICKBOOKS DESKTOP</PrimaryText>
                        </TouchableOpacity>

                        <TouchableOpacity 
                        onPress={() => setType('quickbooksOnline')}
                        style={styles.button}
                        >
                            <PrimaryText fontSize={16} fontWeight={300} style={{color: Colors.primary.white}}>QUICKBOOKS ONLINE</PrimaryText>
                        </TouchableOpacity>
                    </View>

                    <PrimaryText fontSize={14} fontWeight={300} style={{marginVertical: 24}} >{'-- OR --'}</PrimaryText>


                    <PrimaryText fontSize={20} fontWeight={400} >D-Tools</PrimaryText>
                    {/* <PrimaryText fontSize={14} fontWeight={400} >COMING SOON!</PrimaryText> */}
                    <View style={[styles.row]}>
                        <TouchableOpacity 
                        onPress={() => setType('dtoolsSI')}
                        style={styles.button}
                        // disabled={true}
                        >
                            <PrimaryText fontSize={16} fontWeight={300} style={{color: Colors.primary.white}}>D-TOOLS SI</PrimaryText>
                        </TouchableOpacity>

                        {/* <TouchableOpacity 
                        onPress={() => setType('dtoolsCloud')}
                        style={styles.button}
                        disabled={true}
                        >
                            <PrimaryText fontSize={16} fontWeight={300} style={{color: Colors.primary.white}}>D-TOOLS CLOUD</PrimaryText>
                        </TouchableOpacity> */}
                    </View>
                </View>
            </View>
            

            {showConfirmModal &&
                <ConfirmModal
                title={`Select ${getName()}`}
                message={'Are you sure?'}
                onConfirm={() => saveIntegrationPath()}
                onCancel={() => setType(null)}
                />
            }

        </ScrollView>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        height: '100%',
        width: '100%',
        backgroundColor: Colors.primary.white,
        paddingTop: 12,
        marginLeft: 4,
        marginTop: 4,
        paddingBottom: 100
    },
    body: {
        flex: 1,
        backgroundColor: Colors.primary.white,
        alignItems:'center'
    },
    tabContainer: {
        alignItems: 'center',
        justifyContent: 'space-around',
        backgroundColor: Colors.primary.lightGray,
        borderRadius: 4,
        padding: 12,
        marginTop: 12,
        // width: '80%'
    },
    row: {
        flexDirection: 'row',
        marginVertical: 12,
        width: 500,
    },
    button: {
        backgroundColor: Colors.primary.green,
        width: '100%',
        flex: 1,
        justifyContent:'center',
        alignItems:'center',
        padding: 8,
        marginHorizontal: 4,
        borderRadius: 6,
    },


});
