import React, { useEffect, useState } from "react";
import { StyleSheet, View, ActivityIndicator, FlatList } from "react-native";
import { Colors } from "constants/Colors";
import { Header } from "components/Header";
import { CustomInput } from "components/Inputs/CustomInput";
import { HistoryCard } from "components/Cards/HistoryCard";
import { useUser } from "context/UserContext";
import { PrimaryText } from "components/Common/PrimaryText";

export function MyHistory() {

  const { history } = useUser()

  const [filtered, setFiltered] = useState(null)
  const [search, setSearch] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {

  }, [])

  useEffect(() => {
    if (loading && history) {
      setFiltered(history)
      setLoading(false)
    }
  }, [history])

  useEffect(() => {
    if (search) {
      const temp = history
      .filter(x => 
        x.to.toLowerCase().includes(search.toLowerCase()) 
      || x.from.toLowerCase().includes(search.toLowerCase())
      || x.model.toLowerCase().includes(search.toLowerCase())
      || x.manufacturer.toLowerCase().includes(search.toLowerCase())
      )

      setFiltered(temp)
    } else {
      setFiltered(history)
    }
  }, [search])

  return (
    <View style={styles.container}>
        <Header 
        title={'Recent History'} 
        // onBack={() => navigation.goBack()}
        />
        {loading ?
            <ActivityIndicator animating size={'large'} style={styles.activity} color={Colors.primary.lightBlue} />
        :
            <>
                <CustomInput 
                placeholder={'Search History'}
                style={{marginHorizontal: 12}}
                onChange={setSearch}
                value={search}
                />
                {!loading && (!filtered || filtered.length===0) ?
                  <View style={{flex: 1, justifyContent:'center', alignItems:'center'}}>
                    <PrimaryText fontSize={20} fontWeight={400}>No history available</PrimaryText>
                  </View>
                  :
                  <FlatList
                  style={{flex: 1}}
                  contentContainerStyle={{backgroundColor: Colors.primary.white}}
                  data={filtered}
                  keyExtractor={item => item.id}
                  renderItem={({ item, index }) => {
                      return (
                          <HistoryCard
                          item={item}
                          showItem
                          />
                      )
                  }}
                  />
                }
            </>
        }
        
    </View>
  );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        width: '100%',
        flexDirection: "column",
        backgroundColor: Colors.primary.white,
    },
    activity: {
      flex: 1,
      backgroundColor: Colors.primary.white
    }

});
