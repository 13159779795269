import React, { useEffect } from 'react';
import {StyleSheet, View, TouchableOpacity} from 'react-native';
import {Colors} from 'constants/Colors';
import { PrimaryText } from 'components/Common/PrimaryText';
import { firestore } from 'utils/firebase';
import { useCompany } from 'context/CompanyContext';


export const MinMaxCard = ({
  item,
  name,
  location,
  onPress
}) => {

  const {company, isDTools} = useCompany();

  const [onOrderQty, setOnOrderQty] = React.useState(0);



  let style = styles(false)

  return (
    <TouchableOpacity 
    onPress={onPress}
    style={[style.card, item.fromStock && {backgroundColor: Colors.primary.lightGray, borderColor: Colors.primary.white}]}
    >
      <View style={style.leftContainer}>
        <PrimaryText fontSize={13} fontWeight={500} style={{flex: 1}} >{name}</PrimaryText>
        <PrimaryText fontSize={12} fontWeight={400} style={{flex: 1}} >{location}</PrimaryText>
      </View>
      <View style={{flexDirection: 'row'}}>
        
        <PrimaryText fontSize={13} fontWeight={400} style={style.text}>{item?.minStockQty ? item.minStockQty : 'n/a'}</PrimaryText>
        <PrimaryText fontSize={13} fontWeight={400} style={style.text}>{item.locationQty}</PrimaryText>
        <PrimaryText fontSize={13} fontWeight={400} style={style.text}>{item?.maxStockQty ? item.maxStockQty : 'n/a'}</PrimaryText>
        {!isDTools &&<PrimaryText fontSize={13} fontWeight={400} style={[style.text, {width: 90}]}>{item?.onOrderQty}</PrimaryText>}
      </View>
    </TouchableOpacity>
  )
}


const styles = darkMode => StyleSheet.create({
  card: {
    paddingHorizontal: 12,
    paddingVertical: 8,
    flexDirection: 'row',
    alignItems:'center',
    justifyContent:'space-between',
    borderColor: Colors.primary.lightGray,
    borderBottomWidth: 1
  },
  leftContainer: {
    flex: 1,
  },
  text: {
    // marginHorizontal: 6,
    width: 60,
    textAlign:'right'
  }

});
