import React from 'react';
import {StyleSheet, View, Dimensions} from 'react-native';
import {Colors} from '~/constants/Colors';
import { PrimaryText } from '~/components/Common/PrimaryText';


const {height, width} = Dimensions.get('window')


export const ServiceItemCard = ({
  item,
  isDTools
}) => {

  let style = styles(false)

  return (
    <View style={style.card}>
      <PrimaryText fontSize={13} fontWeight={400} style={{flex: 1}} >{item.name}</PrimaryText>
      <View style={{flexDirection: 'row', flex: 1}}>
        <PrimaryText fontSize={13} fontWeight={400} style={style.text}>{item.budget.toFixed(2)}</PrimaryText>
        {!isDTools && <PrimaryText fontSize={13} fontWeight={400} style={style.text}>{item.used.toFixed(2)}</PrimaryText>}
      </View>
    </View>
  )
}


export const styles = darkMode => StyleSheet.create({
  card: {
    marginHorizontal: 12,
    paddingVertical: 8,
    flexDirection: 'row',
    alignItems:'center',
    justifyContent:'space-between',
    borderColor: Colors.primary.lightGray,
    borderBottomWidth: 1
  },
  text: {
    width: width*0.12,
    textAlign:'right'
  }

});
