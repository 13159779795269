import React, { useState } from "react";
import { StyleSheet, View, Text, Dimensions, TouchableOpacity, ActivityIndicator } from "react-native";
import { Colors } from "constants/Colors";
import { Header } from "components/Header";
import { useCompany } from "context/CompanyContext";
import { Links } from "constants/Links";
import { useAuth } from "context/AuthContext";
import { useNavigate } from "react-router-dom";
import { PrimaryText } from "components/Common/PrimaryText";

const {width, height} = Dimensions.get('window');

export const QuickActions = () => {

    const navigate = useNavigate()

    const { currentUser } = useAuth()
    const { setSelectedUser } = useCompany()

    function myHistorySelected() {
        setSelectedUser(currentUser)
        navigate(Links.History)
    }


    return (
        <View style={styles.container}>
        <Header title="Shortcuts" />
            <View style={styles.body}>
                <TouchableOpacity 
                style={styles.checkOutButton} 
                onPress={() => navigate(Links.Transactions)}
                >
                    <PrimaryText fontSize={18} fontWeight={400} style={styles.checkOutText} >New Transaction</PrimaryText>
                </TouchableOpacity>
                <TouchableOpacity 
                style={styles.checkOutButton} 
                onPress={() => navigate(Links.Inventory)}
                >
                    <PrimaryText fontSize={18} fontWeight={400} style={styles.checkOutText} >View Inventory</PrimaryText>
                </TouchableOpacity>
            </View>
            <View style={styles.body}>
                <TouchableOpacity 
                style={styles.checkOutButton} 
                onPress={() => navigate(Links.Projects)}
                >
                    <PrimaryText fontSize={18} fontWeight={400} style={styles.checkOutText} >Project Reports</PrimaryText>
                </TouchableOpacity>
                <TouchableOpacity 
                style={styles.checkOutButton} 
                onPress={myHistorySelected}
                >
                    <PrimaryText fontSize={18} fontWeight={400} style={styles.checkOutText} >My History</PrimaryText>
                </TouchableOpacity>
            </View>

        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignContent: 'center',
        backgroundColor: Colors.primary.white,
    },
    body: {
        flex: 1,
        width: '100%',
        flexDirection: 'row',
        marginBottom: height*0.01,
        padding: 12,
        backgroundColor: Colors.primary.white
    },
    checkOutButton: {
        flex: 1,
        justifyContent:'center',
        alignItems:'center',
        backgroundColor: Colors.primary.lightGray,
        paddingVertical: 8,
        marginHorizontal: 12,
        borderRadius: 5,

        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
    },
    checkOutText: {
      color: Colors.primary.darkGray
    },
    activity: {
        flex: 1,
        backgroundColor: Colors.primary.white
      },

});
