import React, { useEffect, useState } from "react";
import { StyleSheet, View, Dimensions, FlatList, TouchableOpacity } from "react-native";
import { Colors } from "~/constants/Colors";
import { useParts } from "~/context/PartsContext";
import { useCompany } from "~/context/CompanyContext";
import { PrimaryText } from "~/components/Common/PrimaryText";
import { useCustomers } from "~/context/CustomersContext";
import { getBudgetData, getTimeData } from "~/services/customers-service";
import { ServiceItemCard } from "~/components/Cards/ServiceItemCard";
import { Cell, Label, Pie, PieChart, Tooltip } from "recharts";
import { Fonts } from "constants/Fonts";
import { addBudgetData, addTimeData, getOldBudgetData, getOldTimeData, updateCustomer } from "services/customers-service";

const {width, height} = Dimensions.get('window');

const COLORS = [Colors.primary.blue, Colors.primary.teal];

export function ProjectTime() {
    const {selectedCustomer} = useCustomers()
    const {company, isDTools} = useCompany()
    const {serviceItems} = useParts()

    const [activeTab, setActiveTab] = useState(0)
    
    const [timeData, setTimeData] = useState(null)
    const [budgetData, setBudgetData] = useState(null)
    const [oldTimeData, setOldTimeData] = useState(null)
    const [oldBudgetData, setOldBudgetData] = useState(null)
    const [chartData, setChartData] = useState([])
    const [detailedData, setDetailedData] = useState(null)

    const [totalUsed, setTotalUsed] = useState(0)
    const [totalBudget, setTotalBudget] = useState(0)
    const [percentageComplete, setPercentageComplete] = useState(0)

    const [loaded, setLoaded] = useState(false)
    const [loaded2, setLoaded2] = useState(false)



    //on selected customer change
    useEffect(() => {
        let subscriber
        if (selectedCustomer) {
            subscriber = getBudgetData(company.id, selectedCustomer.id, gotBudgetData)
        }

        return () => {
            subscriber && subscriber()
            setTimeData(null)
            setBudgetData(null)
            setOldTimeData(null)
            setOldBudgetData(null)
            setChartData([])
            setDetailedData(null)
            setTotalBudget(0)
            setTotalUsed(0)
            setPercentageComplete(0)
            setLoaded(false)
            setLoaded2(false)
        }
    }, [selectedCustomer])

    function gotBudgetData(data) {
        // console.log('test', data)
        setBudgetData(data ? data : true)
    }

    useEffect(() => {
        let subscriber
        if (budgetData) {
            if (!selectedCustomer.syncedNewTime) {
                getOldBudgetData(company.id, selectedCustomer.id, setOldBudgetData)
                updateCustomer(company, selectedCustomer, {syncedNewTime: true})
            }
            subscriber = getTimeData(company.id, selectedCustomer.id, setTimeData)
        }
        return () => {
            subscriber && subscriber()
        }
    }, [budgetData])

    //on time data or budget data change
    useEffect(() => {

        if (timeData && timeData.length>0) {
            const total = timeData.map(x => x.timeTrackingHours).reduce((prev, next) => prev+next)
            setTotalUsed(total)
        }
        if (budgetData && budgetData.length>0) {
            budgetData.forEach(item => {
                const findServiceItem = serviceItems.find(x => x.id===item.itemListId)
                if (!findServiceItem) {
                    item.quantity = 0
                }
            })
            
            const total = budgetData.map(x => x.quantity).reduce((prev, next) => prev+next)
            setTotalBudget(total)
        }

        //item detail
        if (timeData && budgetData) {
            getOldTimeData(company.id, selectedCustomer.id, setOldTimeData)
            const temp = []
            
            serviceItems.forEach(serviceItem => {
                const budgetForServiceItem = budgetData.filter(x => x.itemListId===serviceItem.id)
                // console.log('test', budgetForServiceItem)
                const totalHours = budgetForServiceItem && budgetForServiceItem.length>0 ? budgetForServiceItem.map(x => x.quantity).reduce((prev, next) => prev+next) : 0
                // console.log('test2', totalHours)
                const item = {id: serviceItem.id, name: serviceItem.fullName, budget: totalHours, used: 0}
                temp.push(item)
            })

            temp.forEach(item => {
                const usedForServiceItem = timeData.filter(x => x.itemServiceID===item.id)
                item.used = usedForServiceItem && usedForServiceItem.length>0 ? usedForServiceItem.map(x => x.timeTrackingHours).reduce((prev, next) => prev+next) : 0

            })

            const filtered = temp.filter(x => x.budget>0||x.used>0)

            var uniqueServiceItems = [];
            filtered.forEach(function(item){
                var i = uniqueServiceItems.findIndex(x => x.name == item.name);
                if(i <= -1){
                    uniqueServiceItems.push(item);
                }
            });
            
            const finalTemp = uniqueServiceItems.map(x => {
                const total = filtered.filter(item => x.name===item.name).map(x => x.budget).reduce((prev, next) => prev+next)
                x.budget = total
                return x
            })

            setDetailedData(finalTemp)
        }
        
    }, [timeData])

    //on total used or total budget changed
    useEffect(() => {
        const data = [
            {
                name: "Used",
                total: totalUsed
            },
            {
                name: "Remaining",
                total: totalBudget-totalUsed > 0 ? totalBudget-totalUsed : 0
            },
        ]
        setChartData(data)

        let percentageComplete = totalUsed&&totalBudget ? ((totalUsed/totalBudget)*100).toFixed(0) : 0
        setPercentageComplete(percentageComplete)
        
    }, [totalUsed, totalBudget])

    //check old data and copy to new location if needed
    useEffect(() => {
        if (oldBudgetData && budgetData && !loaded) {
            setLoaded(true)
            oldBudgetData.forEach(item => {
                const find = budgetData.find(x => x.id===item.id || x.id===(item.TxnID+'-'+item.id))
                if (!find) {
                    item.customerID = selectedCustomer.id
                    item.timeModified = new Date()
                    addBudgetData(company.id, item)
                }
            })
        }
    }, [oldBudgetData])

    useEffect(() => {
        if (oldTimeData && timeData && !loaded2) {
            setLoaded2(true)
            oldTimeData.forEach(item => {
                const find = timeData.find(x => x.id===item.id)
                if (!find) {
                    item.customerID = selectedCustomer.id
                    item.timeModified = new Date()
                    addTimeData(company.id, item)
                }
            })
        }
    }, [oldTimeData])


    let summaryText = totalBudget-totalUsed >= 0 ? `Summary: ${(totalBudget - totalUsed).toFixed(2)}hrs under budget` : `Summary: ${(totalBudget - totalUsed).toFixed(2)}hrs over budget`
    if (isDTools) summaryText = ''
    return (
        <View style={styles.container}>

                
            <PrimaryText fontSize={16} fontWeight={600} style={{textAlign: 'center'}} >{`Budget ${isDTools ? 'Hours' : 'vs Actual'}`}</PrimaryText>
            
            {timeData && (totalUsed>0 || totalBudget>0) ?
            <View style={styles.body}>

                {/** TABS */}
                <View style={styles.tabContainer}>
                    <TouchableOpacity 
                    onPress={() => setActiveTab(0)}
                    style={[styles.tabButton, activeTab===0 && styles.activeTab]}
                    >
                        <PrimaryText fontSize={14} fontWeight={400} >Summary</PrimaryText>
                    </TouchableOpacity>
                    <TouchableOpacity 
                    onPress={() => setActiveTab(1)}
                    style={[styles.tabButton, activeTab===1 && styles.activeTab]}
                    >
                        <PrimaryText fontSize={14} fontWeight={400} >Detailed</PrimaryText>
                    </TouchableOpacity>
                </View>

                {/** CHART */}
                {activeTab===0 ?
                
                <View style={styles.chartContainer}>
                    <View style={{width: '100%', alignSelf: 'flex-start', marginHorizontal: '10%', marginTop: '10%'}}>
                        <PrimaryText fontSize={16} fontWeight={400} >Budget: {totalBudget.toFixed(2)}hrs</PrimaryText>
                        {!isDTools && <PrimaryText fontSize={16} fontWeight={400} >Used: {totalUsed.toFixed(2)}hrs</PrimaryText>}
                        <PrimaryText fontSize={16} fontWeight={400} >{summaryText}</PrimaryText>
                    </View>
                    <PieChart
                        height={340}
                        width={340}
                    >
                        <Pie
                        data={chartData}
                        dataKey='total'
                        cx="50%"
                        cy="50%"
                        fill="#8884d8"
                        // label={renderCustomizedLabel}
                        labelLine={false}
                        innerRadius={70}
                        >
                            {chartData.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                            ))}
                            {percentageComplete!==0 &&
                                <Label value={`${percentageComplete}% Complete`} position="center" fontFamily={Fonts.primary.regular} />
                            }
                            
                        </Pie>
                        <Tooltip />
                    </PieChart>
                    
                </View>
                :
                    <View style={{width: '100%', paddingHorizontal: 12}}>
                        <View style={styles.detailLabelContainer}>
                            <PrimaryText fontSize={14} fontWeight={400} style={{flex: 1}} >Service Item</PrimaryText>
                            <View style={{flexDirection:'row', flex: 1}}>
                                <PrimaryText fontSize={14} fontWeight={400} style={styles.detailLabel} >Budget</PrimaryText>
                                {!isDTools &&<PrimaryText fontSize={14} fontWeight={400} style={styles.detailLabel} >Used</PrimaryText>}
                            </View>
                        </View>
                        <FlatList
                        // contentContainerStyle={{flexGrow: 1, backgroundColor: Colors.primary.white, width: width-12, alignSelf: 'center'}}
                        data={detailedData}
                        keyExtractor={item => item.id}
                        renderItem={({ item, index }) => {
                            return (
                                <ServiceItemCard
                                item={item}
                                isDTools={isDTools}
                                />
                            )
                        }}
                        />
                    </View>
                }

            </View>
            :
                <View style={styles.noCustomerContainer}>
                    <PrimaryText fontSize={16} fontWeight={400} >
                        {'No time data available'}
                    </PrimaryText>
                </View>
            }
            
        
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        // flex: 0.7,
        width: '35%',
        // height: '100%',
        flexDirection: "column",
        backgroundColor: Colors.primary.extremelyLightGray,
        borderRadius: 12,
        paddingTop: 12,
        marginTop: 4,
        borderColor: Colors.primary.lightGray,
        borderWidth: 1
    },
    
    noCustomerContainer:{
        flex: 1,
        justifyContent:'center',
        alignItems:'center'
    },
    body: {
        flex: 1,
        backgroundColor: Colors.primary.extremelyLightGray,
        marginTop: 12,
        alignItems:'center'
    },
    tabContainer: {
        flexDirection: 'row',
        alignSelf: 'center',
        justifyContent: 'space-around',
        backgroundColor: Colors.primary.lightGray,
        borderRadius: 4,
        padding: 4,
        marginTop: 4,
        width: '70%'
    },
    tabButton: {
        alignItems:'center', 
        justifyContent:'center',
        width: '49%',
        borderRadius: 4,
        marginHorizontal: 2,
        padding: 2,
        // width: width*0.15-12,
        backgroundColor: Colors.primary.lightGray,
    },
    activeTab: {
        backgroundColor: Colors.primary.white,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,

        elevation: 5,
    },

    //chart
    chartContainer: {
        flex: 1,
        alignItems:'center'
    },

    //details
    detailLabelContainer: {
        flexDirection:'row',
        justifyContent:'space-between',
        paddingHorizontal: 12,
        marginVertical: 12,
        backgroundColor: Colors.primary.lightGray
    },
    detailLabel: {
        width: width*0.12,
        textAlign:'right'
    }

});
