import React, { useEffect, useState } from "react";
import {
  StyleSheet,
  View,
  Image,
  Text,
  Dimensions,
  ActivityIndicator,
  TouchableHighlight,
  TouchableOpacity,
} from "react-native";
import { Colors } from "~/constants/Colors";
import { CustomInput } from "~/components/Inputs/CustomInput";
import { login } from "~/services/auth-service";
import { sendPasswordReset, signup } from "services/auth-service";
import { PrimaryText } from "components/Common/PrimaryText";
import { ScrollView } from "react-native-web";

const { height } = Dimensions.get("window");

export function Landing() {
  const [email, setEmail] = useState(""); //jim@tracknicity.com
  const [password, setPassword] = useState(""); //0522MAy!
  const [confirmPassword, setConfirmPassword] = useState(""); //0522MAy!
  const [firstName, setFirstName] = useState(""); //jim@tracknicity
  const [lastName, setLastName] = useState(""); //0522MAy!
  const [company, setCompany] = useState(""); //0522MAy!
  const [emailError, setEmailError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);
  const [loginDisabled, setLoginDisabled] = useState(true);
  const [signupDisabled, setSignupDisabled] = useState(true);
  const [loading, setLoading] = useState(false);

  const [isSignup, setIsSignup] = useState(false);
  const [eula, setEula] = useState(false);

  // console.log('eula', eula)

  const whiteLogo = require("~/assets/whiteLogo.png");

  useEffect(() => {
    setEmailError(null);
    setPasswordError(null);

    //check valid email here
    checkIfLoginActive();
  }, [email, password, confirmPassword, firstName, lastName, company, eula]);

  useEffect(() => {
    setLoading(false);
  }, [emailError, passwordError]);

  const checkIfLoginActive = () => {
    //check if email is valid with regex
    const isValidEmail = email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
    // console.log('test', eula)
    setLoginDisabled(email === "" || password === "" || !isValidEmail || password.length < 6);

    setSignupDisabled(
      !eula
      || company === ""
      || firstName === ""
      || lastName === ""
      || email === ""
      || password === ""
      || confirmPassword === ""
      || password !== confirmPassword
      || !isValidEmail || password.length < 6
    )
  };

  const loginPressed = () => {
    setLoading(true);
    login(email, password, setEmailError, setPasswordError);
  };

  const signupPressed = () => {
    setLoading(true);
    signup(email, password, firstName, lastName, company, () => {
      setLoading(false);
      console.log("signup success");
    });
    // login(email, password, setEmailError, setPasswordError);
  };

  function forgotPasswordPressed() {
    const email = window.prompt("Enter your email address");
    if (email) {
      sendPasswordReset(email);
    }
  }

  return (
    <ScrollView>
      <View style={styles.container}>
        <View style={styles.body}>
          <View style={styles.imageContainer}>
            <Image
              source={whiteLogo}
              style={{ width: height * 0.3, height: height * 0.3 }}
            />
          </View>
          {!isSignup ? (
            <View style={styles.bodyContainer}>
              <form>
                <View style={styles.inputContainer}>
                  <PrimaryText
                    fontSize={14}
                    fontWeight={700}
                    style={styles.inputTitle}
                  >
                    {"Email"}
                  </PrimaryText>
                  <View style={styles.input}>
                    <input
                      type="text"
                      id="username"
                      name="ig"
                      style={{
                        height: "100%",
                        color: Colors.primary.black,
                        borderWidth: 0,
                      }}
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                    />
                  </View>
                  {emailError && (
                    <View style={styles.errorContainer}>
                      {/* <AlertCircle width={16} height={16} fill={Colors.primary.error} stroke={Colors.primary.white} /> */}
                      <Text style={styles.errorMessage}>{emailError}</Text>
                    </View>
                  )}
                  <PrimaryText
                    fontSize={14}
                    fontWeight={700}
                    style={styles.inputTitle}
                  >
                    {"Password"}
                  </PrimaryText>
                  <View style={styles.input}>
                    <input
                      type="password"
                      name="password"
                      id="password"
                      style={{
                        height: "100%",
                        color: Colors.primary.black,
                        borderWidth: 0,
                      }}
                      onChange={(e) => setPassword(e.target.value)}
                      value={password}
                    />
                  </View>
                  {passwordError && (
                    <View style={styles.errorContainer}>
                      {/* <AlertCircle width={16} height={16} fill={Colors.primary.error} stroke={Colors.primary.white} /> */}
                      <Text style={styles.errorMessage}>{passwordError}</Text>
                    </View>
                  )}
                </View>
              </form>
              <TouchableOpacity onPress={() => forgotPasswordPressed()}>
                <Text style={styles.forgotPassword}>Forgot Password?</Text>
              </TouchableOpacity>

              <View style={{ flex: 1, alignItems: 'center' }}>
                <TouchableHighlight
                  style={[
                    styles.loginButton,
                    loginDisabled ? styles.inactive : styles.active,
                  ]}
                  underlayColor={"#DDD"}
                  onPress={() => loginPressed()}
                  disabled={loginDisabled}
                >
                  <Text style={styles.loginButtonText}>Log In</Text>
                </TouchableHighlight>
                <Text style={styles.termsText}>Don't have an account?</Text>
                <TouchableHighlight
                  style={[
                    styles.signupButton,
                    styles.secondButton,
                    // loginDisabled ? styles.inactive : styles.active,
                  ]}
                  underlayColor={"#DDD"}
                  onPress={() => setIsSignup(true)}
                // disabled={loginDisabled}
                >
                  <Text style={styles.loginButtonText}>Sign Up</Text>
                </TouchableHighlight>
              </View>
            </View>
          ) : (
            <View style={styles.bodyContainer}>
              <form>
                <View style={styles.inputContainer}>
                  <PrimaryText
                    fontSize={14}
                    fontWeight={700}
                    style={styles.inputTitle}
                  >
                    {"Company Name"}
                  </PrimaryText>
                  <View style={styles.input}>
                    <input
                      type="text"
                      id="username"
                      name="ig"
                      style={{
                        height: "100%",
                        color: Colors.primary.black,
                        borderWidth: 0,
                      }}
                      onChange={(e) => setCompany(e.target.value)}
                      value={company}
                    />
                  </View>
                  <PrimaryText
                    fontSize={14}
                    fontWeight={700}
                    style={styles.inputTitle}
                  >
                    {"First Name"}
                  </PrimaryText>
                  <View style={styles.input}>
                    <input
                      type="text"
                      id="firstName"
                      name="ig"
                      style={{
                        height: "100%",
                        color: Colors.primary.black,
                        borderWidth: 0,
                      }}
                      onChange={(e) => setFirstName(e.target.value)}
                      value={firstName}
                    />
                  </View>
                  <PrimaryText
                    fontSize={14}
                    fontWeight={700}
                    style={styles.inputTitle}
                  >
                    {"Last Name"}
                  </PrimaryText>
                  <View style={styles.input}>
                    <input
                      type="text"
                      id="lastName"
                      name="ig"
                      style={{
                        height: "100%",
                        color: Colors.primary.black,
                        borderWidth: 0,
                      }}
                      onChange={(e) => setLastName(e.target.value)}
                      value={lastName}
                    />
                  </View>
                  <PrimaryText
                    fontSize={14}
                    fontWeight={700}
                    style={styles.inputTitle}
                  >
                    {"Email"}
                  </PrimaryText>
                  <View style={styles.input}>
                    <input
                      type="text"
                      id="email"
                      name="ig"
                      style={{
                        height: "100%",
                        color: Colors.primary.black,
                        borderWidth: 0,
                      }}
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                    />
                  </View>
                  {emailError && (
                    <View style={styles.errorContainer}>
                      {/* <AlertCircle width={16} height={16} fill={Colors.primary.error} stroke={Colors.primary.white} /> */}
                      <Text style={styles.errorMessage}>{emailError}</Text>
                    </View>
                  )}
                  <PrimaryText
                    fontSize={14}
                    fontWeight={700}
                    style={styles.inputTitle}
                  >
                    {"Password"}
                  </PrimaryText>
                  <View style={styles.input}>
                    <input
                      type="password"
                      name="password"
                      id="password"
                      style={{
                        height: "100%",
                        color: Colors.primary.black,
                        borderWidth: 0,
                      }}
                      onChange={(e) => setPassword(e.target.value)}
                      value={password}
                    />
                  </View>
                  {passwordError && (
                    <View style={styles.errorContainer}>
                      {/* <AlertCircle width={16} height={16} fill={Colors.primary.error} stroke={Colors.primary.white} /> */}
                      <Text style={styles.errorMessage}>{passwordError}</Text>
                    </View>
                  )}
                  <PrimaryText
                    fontSize={14}
                    fontWeight={700}
                    style={styles.inputTitle}
                  >
                    {"Confirm Password"}
                  </PrimaryText>
                  <View style={styles.input}>
                    <input
                      type="password"
                      name="confirmPassword"
                      id="confirmPassword"
                      style={{
                        height: "100%",
                        color: Colors.primary.black,
                        borderWidth: 0,
                      }}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      value={confirmPassword}
                    />
                  </View>
                  {passwordError && (
                    <View style={styles.errorContainer}>
                      {/* <AlertCircle width={16} height={16} fill={Colors.primary.error} stroke={Colors.primary.white} /> */}
                      <Text style={styles.errorMessage}>{passwordError}</Text>
                    </View>
                  )}
                  {/** Checkbox for EULA */}
                  <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginTop: 16 }}>
                    <input 
                    type="checkbox" 
                    id="terms" 
                    name="terms" 
                    value={eula} 
                    // checked={eula}
                    onChange={(e) => {
                      setEula(e.target.checked)
                      console.log('eula1', e.target.checked)
                    }} 
                    />
                    <PrimaryText
                      fontSize={14}
                      fontWeight={700}
                    // style={styles.termsButtonText}
                    >By signing up, I agree to Tracknicity's
                      <a href="https://www.tracknicity.com/eula" style={{ color: Colors.primary.lightBlue }} target="_blank">{" EULA"}</a>
                    </PrimaryText>
                  </View>

                </View>
              </form>

              <View style={{ flex: 1, alignItems: 'center' }}>
                <TouchableHighlight
                  style={[
                    styles.signupButton,
                    signupDisabled ? styles.inactive : styles.active,
                  ]}
                  underlayColor={"#DDD"}
                  onPress={signupPressed}
                  disabled={signupDisabled}
                >
                  <Text style={styles.loginButtonText}>Sign Up</Text>
                </TouchableHighlight>

                <Text style={styles.termsText}>Already have an account?</Text>

                <TouchableHighlight
                  style={[
                    styles.loginButton,
                    styles.secondButton,
                    // loginDisabled ? styles.inactive : styles.active,
                  ]}
                  underlayColor={"#DDD"}
                  onPress={() => setIsSignup(false)}
                // disabled={loginDisabled}
                >
                  <Text style={styles.loginButtonText}>Log In</Text>
                </TouchableHighlight>

              </View>
            </View>
          )}
        </View>
        {loading && (
          <ActivityIndicator
            animating={loading}
            size={"large"}
            style={StyleSheet.absoluteFill}
            color={Colors.primary.blue}
          />
        )}
      </View>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
    backgroundColor: Colors.primary.white,
    justifyContent: "center",
    alignItems: "center",
  },
  body: {
    minWidth: 500,
  },
  imageContainer: {
    // flex: 1,
    alignItems: "center",
    marginTop: 20,
  },
  loginText: {
    fontSize: 20,
    fontWeight: "700",
    textAlign: "center",
    color: Colors.primary.lightBlue,
  },
  bodyContainer: {
    flex: 1,
    marginTop: 0,
    paddingHorizontal: 24,
    alignContent: "center",
    justifyContent: "space-between",
  },
  inputContainer: {
    flexDirection: "column",
    paddingTop: 12,
    paddingBottom: 8,
  },
  forgotPassword: {
    justifyContent: "flex-end",
    alignContent: "flex-end",
    textAlign: "right",
    color: Colors.primary.lightBlue,
    fontSize: 14,
    fontWeight: "700",
  },
  loginButton: {
    // marginTop: 40,
    // marginBottom: 44,
    marginVertical: 12,
    height: 48,
    borderRadius: 15,
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: Colors.primary.lightBlue,
    width: '100%',
  },
  signupButton: {
    // marginTop: 40,
    // marginBottom: 44,

    marginVertical: 12,
    // marginHorizontal: 50,
    height: 48,
    borderRadius: 15,
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: Colors.primary.red,
    width: '100%',
  },
  secondButton: {
    width: '50%',
  },
  active: {
    opacity: 1,
  },
  inactive: {
    opacity: 0.5,
  },
  loginButtonText: {
    fontSize: 20,
    fontWeight: "600",
    color: Colors.primary.white,
  },
  termsText: {
    textAlign: "center",
    color: Colors.primary.black,
    fontSize: 14,
    fontWeight: "400",
    flexWrap: "nowrap",
  },
  termsButtonText: {
    alignItems: "center",
    textAlign: "center",
    justifyContent: "center",
    color: Colors.primary.lightBlue,
    flexWrap: "nowrap",
  },
  inputTitle: {
    paddingHorizontal: 16,
    paddingVertical: 4,
    paddingTop: 12,
    color: Colors.primary.darkGray,
  },

  input: {
    borderRadius: 16,
    height: 40,
    borderColor: "#999999",
    borderWidth: 0.5,
    paddingHorizontal: 16,
    color: Colors.primary.black,
  },
  errorContainer: {
    backgroundColor: "#FEEFEF",
    height: 32,
    justifyContent: "flex-start",
    alignItems: "center",
    paddingHorizontal: 16,
    paddingVertical: 6,
    borderRadius: 8,
    flexDirection: "row",
  },
  errorMessage: {
    color: Colors.primary.error,
    paddingLeft: 8,
  },
});
