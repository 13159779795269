import React, { useEffect, useState } from "react";
import { StyleSheet, View, Dimensions, ActivityIndicator, FlatList, Alert, TouchableOpacity  } from "react-native";
import Select from 'react-select';
import { Colors } from "constants/Colors";
import { Header } from "components/Header";
import { useParts } from "context/PartsContext";
import { CustomInput } from "components/Inputs/CustomInput";
import { useCompany } from "context/CompanyContext";
import { PrimaryText } from "components/Common/PrimaryText";
import * as FaIcons from "react-icons/fa";
import { Fonts } from "constants/Fonts";
import { cloneDeep } from "lodash";
import { getSerialNumbers } from "services/company-service";
import { useCustomers } from "context/CustomersContext";
import { SerialCard } from "components/Cards/SerialCard";
import { ConfirmModal } from "components/Modals/Confirmation";
import { deleteSerial } from "services/serial-service";
import { useAuth } from "context/AuthContext";

const {width, height} = Dimensions.get('window');

const options = [
  { value: 'customer', label: 'Customer' },
  { value: 'item', label: 'Item' },
  // { value: 'createdAt', label: 'Created At' }
];

export function SerialNumbers() {

  const {currentUser} = useAuth()
  const {users, company} = useCompany()
  const {customers} = useCustomers()
  const {parts, manufacturers} = useParts()

  const [search, setSearch] = useState(null)
  const [selectedSort, setSelectedSort] = useState(options[0])
  const [ascending, setAscending] = useState(true)
  const [allItems, setAllItems] = useState(null)
  const [filtered, setFiltered] = useState(null)
  const [sorted, setSorted] = useState(null)

  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const subscriber = getSerialNumbers(company)
    .onSnapshot(querySnapshot => {
      const temp = []
      querySnapshot.forEach(doc => {
        const data = doc.data()
        const part = parts.find(x => x.id===data.partID)
        const customer = customers.find(x => x.id===data.customerId)
        if (part && customer) {
          const item = {
            id: doc.id,
            ...data,
            part,
            customer
          }
          if (item?.part?.parentRefID) {
            const parent = manufacturers && manufacturers.find(x => x.id ===item?.part?.parentRefID)
            item.part.manufacturer = parent ? parent.model : ''
            item.part.lowercaseMfg = parent ? parent.model.toLowerCase() : ''
          } else {
            console.log('here?', item)
          }
          temp.push(item)
        }
        
      })
      setAllItems(temp)
      setLoading(false)

    })

    return () => subscriber()
  }, [])

  //on search change
  useEffect(() => {
    if (search) {
      const temp = allItems
      .filter(x => 
        x.part.lowercaseModel.includes(search.toLowerCase())
        || x.part.lowercaseMfg.includes(search.toLowerCase())
        || x.customer.fullName.toLowerCase().includes(search.toLowerCase())
        || x.serialNumLowercase.includes(search.toLowerCase())
      )
      
      setFiltered(temp)
    } else {
      setFiltered(allItems)
    }
  }, [search, allItems])

  useEffect(() => {
    if (filtered) {

      let temp = cloneDeep(filtered)

      switch (selectedSort.value) {
        case 'customer':
          temp = temp.sort((a,b) => sortObject(a.customer.fullName.toLowerCase(), b.customer.fullName.toLowerCase()))
          break;
        case 'item':
          temp = temp.sort((a,b) => sortObject((a.part.lowercaseMfg+a.part.lowercaseModel), (b.part.lowercaseMfg+b.part.lowercaseModel)))
          break;
        case 'createdAt':
          temp = temp.sort((a,b) => sortObject(a.date, b.date))
          break;
        default:
          temp = temp.sort((a,b) => sortObject(a.customer.fullName.toLowerCase(), b.customer.fullName.toLowerCase()))
          break;
      }

      setSorted(temp)
    }
  }, [selectedSort, ascending, filtered])


  function sortObject(a, b) {

    if (a === b) return 0

    if (!ascending) {
      return a < b ? 1 : -1
    } else {
      return a < b ? -1 : 1
    }
  }

  function deleteItem(item) {
    deleteSerial(company, item)
    setShowConfirmModal(false)
  }

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: '1px solid lightgray',
      color: state.isSelected ? Colors.primary.white : Colors.primary.darkGray,
      paddingHorizontal: 12,
      fontFamily: Fonts.primary.regular,
      fontSize: 14
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      width: 115,
    }),
    singleValue: (styles) => ({ 
      ...styles, 
      color: Colors.primary.darkGray,
      fontFamily: Fonts.primary.regular,
      fontSize: 14
    }),
  }

  return (
    <View style={styles.container}>
      <Header 
      title={'Serial Numbers'} 
      // onBack={() => navigation.goBack()}
      />
      <View style={{zIndex: 999, flexDirection: 'row', width: '100%', alignItems:'center'}}>
          <CustomInput 
          placeholder={'Filter By Customer, Item, or Serial Number'}
          style={{flex: 1, marginHorizontal: 12, height: 40}}
          onChange={setSearch}
          value={search}
          />
          <View style={{flexDirection: 'row', paddingLeft: 40, alignItems:'center'}}>
          <PrimaryText fontSize={14} fontWeight={400} style={{color: Colors.primary.darkGray, marginHorizontal: 4}} >Sort By:</PrimaryText>
          <Select
          styles={customStyles}
          value={selectedSort}
          onChange={(value) => setSelectedSort(value)}
          options={options}
          name={'test'}
          />
          <TouchableOpacity onPress={() => setAscending(!ascending)} style={{marginLeft: 12}}>
            {ascending ?
            <FaIcons.FaChevronUp size={16} color={Colors.primary.darkGray}/>
            :
            <FaIcons.FaChevronDown size={16} color={Colors.primary.darkGray}/>
            }
          </TouchableOpacity>
          
          </View>
      </View>

        
      {sorted ?
      <View style={styles.body}>
        <View style={styles.detailLabelContainer}>
          {/* <PrimaryText fontSize={14} fontWeight={400} style={{width: 40}} ></PrimaryText> */}
          <PrimaryText fontSize={14} fontWeight={400} style={{flex: 0.5, color: Colors.primary.white, marginRight: 12}} >Serial</PrimaryText>
          <PrimaryText fontSize={14} fontWeight={400} style={{flex: 1, color: Colors.primary.white}} >Item/Customer</PrimaryText>
          <View style={{flexDirection:'row'}}>
              <PrimaryText fontSize={14} fontWeight={400} style={styles.detailLabel} >Location</PrimaryText>
              <PrimaryText fontSize={14} fontWeight={400} style={styles.detailLabel} >Mac Address</PrimaryText>
              <PrimaryText fontSize={14} fontWeight={400} style={styles.detailLabel} >Additional</PrimaryText>
              {currentUser.admin &&
                <PrimaryText fontSize={14} fontWeight={400} style={[styles.detailLabel, {width: width*0.06}]} ></PrimaryText>
              }
          </View>
        </View>

        <FlatList
        style={{flex: 1, width: '100%'}}
        // contentContainerStyle={{width: '100%'}}
        data={sorted}
        keyExtractor={item => item.id}
        renderItem={({ item, index }) => {
            return (
                <SerialCard
                item={item}
                // onPress={() => setSelectedItem(item)}
                onDelete={() => setShowConfirmModal(item)}
                admin={currentUser.admin}
                />
            )
        }}
        />

      </View>
      :
          <View style={styles.noCustomerContainer}>
              <PrimaryText fontSize={16} fontWeight={400} >
                  {`No items in staging area`}
              </PrimaryText>
          </View>
      }

      {!!showConfirmModal &&
        <ConfirmModal
        title={'Delete Serial Entry'}
        message={'Are you sure? This action cannot be undone.'}
        onConfirm={() => deleteItem(showConfirmModal)}
        onCancel={() => setShowConfirmModal(false)}
        />
      }
      

      {loading &&
            <ActivityIndicator 
            animating={loading}
            size={'large'} 
            style={StyleSheet.absoluteFill} color={Colors.primary.blue} 
            />
      }
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
      flex: 1,
      width: '100%',
      flexDirection: "column",
      backgroundColor: Colors.primary.white,
  },
  noCustomerContainer:{
      flex: 1,
      justifyContent:'center',
      alignItems:'center'
  },
  body: {
      flex: 1,
      width: '100%',
      backgroundColor: Colors.primary.white,
      marginTop: 12,
      alignItems:'center'
  },

  //details
  detailLabelContainer: {
    width: '100%',
    flexDirection:'row',
    justifyContent:'space-between',
    paddingHorizontal: 12,
    marginTop: 12,
    backgroundColor: Colors.primary.gray
  },
  detailLabel: {
      // marginHorizontal: 6,
      width: 200,
      textAlign:'right',
      color: Colors.primary.white
  },


  //modal
  modal: {
    // justifyContent: 'flex-end',
    margin: 0
  },
  modalContent: {
    // height: height*0.6,
    width: width,
    backgroundColor: Colors.primary.white,
    borderRadius: 12,
    padding: 12,
    // alignItems: 'center'
  },
  modalSwipeButton: {
    width: 32, 
    height: 3, 
    backgroundColor: Colors.primary.darkGray, 
    borderRadius: 3, 
    marginBottom: 12, 
    alignSelf:'center'
  },
  description: {
    marginHorizontal: 12,
    marginVertical: 4
  },
  lineBreak: {
    height: 1,
    backgroundColor: Colors.primary.lightGray,
    margin: 12,
  }


});
