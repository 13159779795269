import React, { forwardRef, useEffect, useState } from "react";
import { StyleSheet, View, TouchableOpacity } from "react-native";
import { Colors } from "~/constants/Colors";
import DatePicker from "react-datepicker";
import { useCompany } from "~/context/CompanyContext";
import { PrimaryText } from "~/components/Common/PrimaryText";
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { Fonts } from "constants/Fonts";
import { getStagedItemsValue, getTotalValuationHistory, getInventoryValue, saveNewValuation, formatShortNumber } from "services/valuation-service";
import "react-datepicker/dist/react-datepicker.css";
import { dollarFormatter } from 'context/CompanyContext';
import { useParts } from "context/PartsContext";
import * as FaIcons from "react-icons/fa";

export function ValuationChart() {

    const { company } = useCompany()
    const { setGetStagedItems, stagedParts, parts } = useParts()

    const [chartData, setChartData] = useState([])
    const [filtered, setFiltered] = useState([])
    const [startDate, setStartDate] = useState(new Date())
    const [endDate, setEndDate] = useState(new Date())
    const [xTicks, setXTicks] = useState([])

    const [inventoryTotal, setInventoryTotal] = useState(null)
    const [stagingAreaTotal, setStagingAreaTotal] = useState(null)
    const [total, setTotal] = useState(null)

    useEffect(() => {
        setGetStagedItems(true)
        let start = new Date()
        start.setDate(start.getDate() - 365)
        setStartDate(start)

        const subscriber = getTotalValuationHistory(company, setChartData, setInventoryTotal, setStagingAreaTotal, setTotal)


        return () => {
            subscriber()
        }
    }, [])

    // useEffect(() => {
    //     if (stagedParts) {
    //         const currentInventoryValue = getInventoryValue(parts, company)
    //         const stagedItemsValue = getStagedItemsValue(stagedParts)
    //         saveNewValuation(company, currentInventoryValue, stagedItemsValue)
    //     }
    // }, [stagedParts])

    function runValuationReportForToday() {
        const currentInventoryValue = getInventoryValue(parts, company)
        const stagedItemsValue = getStagedItemsValue(stagedParts)
        saveNewValuation(company, currentInventoryValue, stagedItemsValue)
    }

    useEffect(() => {

        startDate.setHours(0)
        startDate.setMinutes(0)
        startDate.setSeconds(0)
        endDate.setHours(23)
        endDate.setMinutes(59)
        endDate.setSeconds(59)


        //filter
        if (!chartData) return
        const temp = []
        const filtered = chartData.filter(x => x.time >= startDate.getTime() && x.time <= endDate.getTime())
        filtered.forEach((item, index) => {
            if (index === 0) temp.push(item)
            if (temp.length > 0 && temp[temp.length - 1].month !== item.month) {
                temp.push(item)
            }
        })

        setFiltered(filtered)
        setXTicks(temp.map(x => x.time))


    }, [startDate, endDate, chartData])

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <View style={styles.toolTipContainer}>
                    <PrimaryText fontSize={16} fontWeight={500} >{new Date(label).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: '2-digit' })}</PrimaryText>
                    <PrimaryText fontSize={14} fontWeight={400} >{`Inventory: ${dollarFormatter.format(payload[0].value)}`}</PrimaryText>
                    {!!company.includeStagedItemsInValue &&
                        <>
                            <PrimaryText fontSize={14} fontWeight={400} >{`Staged Items: ${dollarFormatter.format(payload[1].value)}`}</PrimaryText>
                            <PrimaryText fontSize={14} fontWeight={400} >{`Total: ${dollarFormatter.format(payload[0].value + payload[1].value)}`}</PrimaryText>
                        </>
                    }

                </View>
            );
        }

        return null;
    };

    const DateInput = forwardRef(({ value, onClick }, ref) => (
        <button
            className="example-custom-input"
            style={{
                backgroundColor: Colors.primary.white,
                borderRadius: 4,
                borderWidth: 0.5,
                borderColor: Colors.primary.lightGray,
                fontFamily: Fonts.primary.regular,
                fontSize: 14
            }}
            onClick={onClick} ref={ref}>
            {value}
        </button>
    ))

    function currencyFormatter(value) {
        return `$${formatShortNumber(value)}`
    }

    return (
        <View style={styles.container}>
            <View style={{ width: '100%', flexDirection: 'row', padding: 12, alignItems: 'flex-start', justifyContent: 'space-between', zIndex: 999 }}>
                <View style={{ flex: 1, justifyContent: 'space-between' }}>
                    <PrimaryText fontSize={16} fontWeight={400} >{`Current Inventory Total: ${dollarFormatter.format(inventoryTotal)}`}</PrimaryText>
                    {!!company.includeStagedItemsInValue &&
                        <>
                            <PrimaryText fontSize={16} fontWeight={400} >{`Current Staging Total: ${dollarFormatter.format(stagingAreaTotal)}`}</PrimaryText>
                            <PrimaryText fontSize={16} fontWeight={400} >{`Current Combined Total: ${dollarFormatter.format(total)}`}</PrimaryText>
                        </>
                    }
                </View>
                <View>
                    <PrimaryText fontSize={16} fontWeight={600} style={{ textAlign: 'center' }} >Inventory Valuations</PrimaryText>
                    <TouchableOpacity
                        // disabled={rightDisabled}
                        onPress={runValuationReportForToday}
                        style={[{ backgroundColor: Colors.primary.lightBlue, padding: 4, borderRadius: 4, marginTop: 4 }]}
                    >
                        {/* <View style={{ flexShrink: 1, flexDirection: 'row', alignItems: 'center', opacity: 1, padding: 2, backgroundColor: 'red' }}> */}
                            <PrimaryText fontSize={16} fontWeight={400} style={{ color: Colors.primary.white, textAlign: 'center' }}>{"Run Report"}</PrimaryText>

                            {/* <FaIcons.FaCheck name="pencil" color={Colors.primary.white} size={12} style={{ marginLeft: 8, marginBottom: 2 }} /> */}

                        {/* </View> */}
                    </TouchableOpacity>
                </View>

                <View style={styles.optionContainer} >
                    <PrimaryText
                        fontSize={14}
                        fontWeight={400}
                        style={{ color: Colors.primary.darkGray, marginHorizontal: 4 }}
                        numberOfLines={1} >
                        Date Range:
                    </PrimaryText>

                    <View >
                        <DatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            customInput={<DateInput />}
                        />
                    </View>

                    <PrimaryText fontSize={14} fontWeight={400} style={{ color: Colors.primary.darkGray, marginHorizontal: 4 }} >-</PrimaryText>

                    <View >
                        <DatePicker
                            selected={endDate}
                            onChange={(date) => setEndDate(date)}
                            customInput={<DateInput />}
                        />
                    </View>

                </View>
            </View>



            <View style={styles.body}>
                <ResponsiveContainer width="95%" height="95%">
                    <LineChart
                        margin={{ top: 20, right: 20, left: 20, bottom: 20 }}
                        data={filtered}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                            tickFormatter={unixTime => new Date(unixTime).toLocaleString('default', { month: 'short' })}
                            dataKey="time"
                            tickLine={false}
                            type={'number'}
                            domain={['auto', 'auto']}
                            ticks={xTicks}
                        />
                        <YAxis tickFormatter={currencyFormatter} />
                        <Tooltip
                            content={<CustomTooltip />}
                        />
                        <Line type="monotone" dataKey="totalValuation" stroke="#8884d8" activeDot={{ r: 8 }} />
                        {!!company.includeStagedItemsInValue &&
                            <Line type="monotone" dataKey="totalStagedItemsValue" stroke="#82ca9d" />
                        }

                    </LineChart>
                </ResponsiveContainer>

            </View>


        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flexDirection: 'column-reverse',
        width: '100%',
        height: '50%',
        flexDirection: "column",
        backgroundColor: Colors.primary.extremelyLightGray,
        borderRadius: 12,
        paddingTop: 12,
        marginTop: 4,
        borderColor: Colors.primary.lightGray,
        borderWidth: 1
    },
    optionContainer: {
        flex: 1,
        justifyContent: 'flex-end',
        flexDirection: 'row',
        alignItems: 'center',
        // zIndex: 999
    },
    body: {
        width: '100%',
        height: '100%',
        backgroundColor: Colors.primary.extremelyLightGray,
        marginTop: 12,
        alignItems: 'center'
    },
    toolTipContainer: {
        backgroundColor: 'rgba(92, 137, 211,0.5)',
        padding: 12
    }

});
