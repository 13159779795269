import React, { useState, useEffect } from "react";
import { StyleSheet, View, TouchableOpacity } from "react-native";
import { Colors } from "constants/Colors";
import { useCompany } from "context/CompanyContext";
import { PrimaryText } from "components/Common/PrimaryText";
import { ScrollView, Text } from "react-native-web";
import { updateCompany } from "services/company-service";
import { CustomInput } from "components/Inputs/CustomInput";
import Select from "react-select";
import { Fonts } from "constants/Fonts";
import {
  dToolsCustomFieldDateOptions,
  dToolsCustomFieldStringOptions,
  defaultDToolsCustomFieldMapping,
} from "constants/Constants";

export function DTools() {
  const { company } = useCompany();

  const [dToolsKey, setDToolsKey] = useState(company?.dToolsKey);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: "1px solid lightgray",
      color: state.isSelected ? Colors.primary.white : Colors.primary.darkGray,
      paddingHorizontal: 12,
      fontFamily: Fonts.primary.regular,
      fontSize: 14,
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      width: 240,
    }),
    singleValue: (styles) => ({
      ...styles,
      color: Colors.primary.darkGray,
      fontFamily: Fonts.primary.regular,
      fontSize: 14,
    }),
  };

  const checkValueIsNotAlreadyUsed = (value) => {
    const values = Object.values(company.dToolsCustomFields);
    const used = values.includes(value);
    if (used) {
        alert("This value is already mapped to another field. Please choose another.");
    }
    return used;
  };

  const { dToolsCustomFields } = company;
  const { deliveredDate, deliveredBy, pickedBy, returnInfo } =
    dToolsCustomFields;

  return (
    <ScrollView style={styles.container}>
      <View style={styles.body}>
        <View style={styles.tabContainer}>
          <PrimaryText fontSize={20} fontWeight={400}>
            D-Tools SI Integration
          </PrimaryText>
          <View style={{ padding: 12, margin: 12, borderRadius: 4 }}>
            {/* <PrimaryText fontSize={16} fontWeight={400} style={styles.number} >Tracknicity Key For D-Tools: <b>{company?.id}</b></PrimaryText> */}
          </View>

          <View style={styles.descriptionRow}>
            <PrimaryText fontSize={16} fontWeight={400} style={styles.number}>
              1.
            </PrimaryText>
            <PrimaryText
              fontSize={16}
              fontWeight={300}
              style={styles.description}
            >
              Enter the following key into D-Tools:
              <View
                style={{
                  backgroundColor: Colors.primary.white,
                  marginHorizontal: 12,
                  borderRadius: 4,
                }}
              >
                <PrimaryText
                  fontSize={16}
                  fontWeight={400}
                  style={{ marginHorizontal: 12 }}
                >
                  <b>{company?.id}</b>
                </PrimaryText>
              </View>
            </PrimaryText>
          </View>

          <View style={styles.descriptionRow}>
            <PrimaryText fontSize={16} fontWeight={400} style={styles.number}>
              2.
            </PrimaryText>
            <PrimaryText
              fontSize={16}
              fontWeight={300}
              style={styles.description}
            >
              Copy/Paste your key from D-Tools here:{" "}
            </PrimaryText>
            <CustomInput
              value={dToolsKey}
              onChange={(text) => setDToolsKey(text)}
              style={{ flex: 1, margin: 0 }}
            />
            {!!(company.dToolsKey !== dToolsKey) && (
              <TouchableOpacity
                style={styles.button}
                onPress={() => updateCompany(company, { dToolsKey })}
              >
                <PrimaryText
                  fontSize={16}
                  fontWeight={400}
                  style={{ color: Colors.primary.white }}
                >
                  Save
                </PrimaryText>
              </TouchableOpacity>
            )}
          </View>
          <View style={{ justifyContent: "center", marginTop: 24 }}>
            <PrimaryText fontSize={16} fontWeight={400} style={styles.number}>
              *Please reach out to your D-Tools representative to enable
              Tracknicity
            </PrimaryText>
          </View>
        </View>

        <View style={styles.settingContainer}>
          <PrimaryText
            fontSize={20}
            fontWeight={400}
            style={{ alignSelf: "center", marginBottom: 12 }}
          >
            Custom Field Settings
          </PrimaryText>
          <View style={[styles.settingsRow, { marginTop: "1%", zIndex: 999 }]}>
            <PrimaryText fontSize={14} fontWeight={400} style={styles.setting}>
              Delivered Date:
            </PrimaryText>
            <Select
              styles={customStyles}
              value={dToolsCustomFieldDateOptions.find(
                (x) => x.value === deliveredDate
              )}
              onChange={(value) => {
                updateCompany(company, {
                  dToolsCustomFields: {
                    ...dToolsCustomFields,
                    deliveredDate: value.value,
                  },
                });
              }}
              options={dToolsCustomFieldDateOptions}
            />
          </View>

          <View style={[styles.settingsRow, { marginTop: "1%", zIndex: 998 }]}>
            <PrimaryText fontSize={14} fontWeight={400} style={styles.setting}>
              Delivered By:
            </PrimaryText>
            <Select
              styles={customStyles}
              value={dToolsCustomFieldStringOptions.find(
                (x) => x.value === deliveredBy
              )}
              onChange={(value) => {
                if (checkValueIsNotAlreadyUsed(value.value)) return;
                updateCompany(company, {
                  dToolsCustomFields: {
                    ...dToolsCustomFields,
                    deliveredBy: value.value,
                  },
                });
              }}
              options={dToolsCustomFieldStringOptions}
            />
          </View>

          <View style={[styles.settingsRow, { marginTop: "1%", zIndex: 997 }]}>
            <PrimaryText fontSize={14} fontWeight={400} style={styles.setting}>
              Picked By:
            </PrimaryText>
            <Select
              styles={customStyles}
              value={dToolsCustomFieldStringOptions.find(
                (x) => x.value === pickedBy
              )}
              onChange={(value) => {
                updateCompany(company, {
                  dToolsCustomFields: {
                    ...dToolsCustomFields,
                    pickedBy: value.value,
                  },
                });
              }}
              options={dToolsCustomFieldStringOptions}
            />
          </View>

          <View style={[styles.settingsRow, { marginTop: "1%", zIndex: 996 }]}>
            <PrimaryText fontSize={14} fontWeight={400} style={styles.setting}>
              Return Info:
            </PrimaryText>
            <Select
              styles={customStyles}
              value={dToolsCustomFieldStringOptions.find(
                (x) => x.value === returnInfo
              )}
              onChange={(value) => {
                updateCompany(company, {
                  dToolsCustomFields: {
                    ...dToolsCustomFields,
                    returnInfo: value.value,
                  },
                });
              }}
              options={dToolsCustomFieldStringOptions}
            />
          </View>
        </View>
      </View>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    height: "100%",
    width: "100%",
    backgroundColor: Colors.primary.white,
    paddingTop: 12,
    marginLeft: 4,
    marginTop: 4,
    paddingBottom: 100,
  },
  body: {
    flex: 1,
    backgroundColor: Colors.primary.white,
    alignItems: "center",
  },
  tabContainer: {
    alignItems: "center",
    justifyContent: "space-around",
    backgroundColor: Colors.primary.lightGray,
    borderRadius: 4,
    padding: 12,
    marginTop: 12,
    width: "80%",
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
    marginVertical: 12,
    width: "100%",
  },
  row2: {
    alignSelf: "flex-start",
    flexDirection: "row",
    alignItems: "center",
    marginVertical: 12,
    marginLeft: 48,
  },
  button: {
    flexDirection: "row",
    backgroundColor: Colors.primary.green,
    // width: 250,
    // flex: 1,
    justifyContent: "space-between",
    alignItems: "center",
    padding: 8,
    marginHorizontal: 12,
    borderRadius: 6,
  },
  buttonText: {
    color: Colors.primary.white,
    marginRight: 12,
  },

  //qb desktop
  descriptionRow: {
    flexDirection: "row",
    alignItems: "center",
    marginVertical: 12,
    width: "100%",
  },
  number: {
    fontWeight: 300,
    marginRight: 12,
  },
  description: {
    // flex: 1
  },
  bold: {
    fontWeight: 500,
  },
  settingsRow: {
    flexDirection: "row",
    margin: 12,
    alignItems: "center",
  },
  settingContainer: {
    width: "80%",
    backgroundColor: Colors.primary.lightGray,
    borderRadius: 4,
    padding: 12,
    marginTop: 12,
  },
  setting: {
    marginRight: 30,
    width: 150,
    fontSize: 16,
  },
});
