import { useCompany } from "context/CompanyContext";
import React from "react";
import { StyleSheet, View } from "react-native";
import {Colors} from "~/constants/Colors";
import { useParts } from "~/context/PartsContext";
import { HistoryByUser } from "./HistoryByUser";
import { HistoryUsers } from "./HistoryUsers";

export function History() {

  const {selectedUser} = useCompany()

  return (
    <View style={styles.container}>
        <View style={styles.body}>
            <HistoryUsers/>
            {!!selectedUser &&
                <HistoryByUser />
            }
        </View>
        
    </View>
  );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        width: '100%',
        backgroundColor: Colors.primary.white,
    },
    body: {
        flex: 1,
        width: '100%',
        flexDirection: "row",
        backgroundColor: Colors.primary.white,
    }

});
