import { firestore } from "utils/firebase";
import { updateCompany } from "./company-service";

// const baseURL = 'http://localhost:8080'
const baseURL = 'https://api.tracknicity.com'

export const getUserHistory = (company, user) => {
    let query = firestore.collection('companies')
            .doc(company.id)
            .collection('history')
            .where('user', '==', user.id)
            .orderBy('date', 'desc')

    if (user.id==='all') {
        query = firestore.collection('companies')
        .doc(company.id)
        .collection('history')
        .orderBy('date', 'desc')
    }

    return query

}

export const getUserRequests = (company, user, setPending, setCompleted) => {
    let docRef = firestore.collection('companies')
    .doc(company.id)
    .collection('requests')
    .where('user', '==', user.id)
    .orderBy('date', 'desc')

    if (user.requests) {
        docRef = firestore.collection('companies')
        .doc(company.id)
        .collection('requests')
        .orderBy('date', 'desc')
    }
    return docRef.onSnapshot(querySnapshot => {
                const pending = []
                const completed = []
                querySnapshot.forEach(doc => {
                    if (doc.data().completed) {
                        completed.push({
                            id: doc.id,
                            ...doc.data()
                        })
                    } else {
                        pending.push({
                            id: doc.id,
                            ...doc.data()
                        })
                    }
                    
                })
                setPending(pending)
                setCompleted(completed)
            }, error => {
                console.log('error getting requests', error)
            })
}

export const getUserEmail = (uid, setEmail) => {
  const url = baseURL+'/getUserEmail/'+uid

  const requestOptions = {
      method: 'GET'
  };

  //Part is going TO a client so the client needs billed

  fetch(url, requestOptions)
  .then(response => response.json())
  .then(data => {
      // console.log('result getUserEmail',data);
      setEmail(data?.email)
  })
  .catch(e => {
      console.log('error getUserEmail', e)
  })
}

export const updateUserEmail = (company, user, oldEmail, onSuccess, onError) => {
  const url = baseURL+'/updateEmail'

  const body =  {
    uid: user.id, 
    email: user.email, 
  }

  const data = new FormData()
  for ( var key in body ) {
      data.append(key, body[key]);
  }

  const requestOptions = {
      method: 'POST',
      body: data
  };

  fetch(url, requestOptions)
  .then(response => response.json())
  .then(data => {
    if (data.success) {
      const userEmails = company.userEmails.filter(x => x!==oldEmail)
      userEmails.push(user.email)
      const companyData = {
        userEmails
      }
      updateCompany(company, companyData)
      onSuccess()
    } else {
      onError(data.message)
      console.log('friggin error', data)
    }
    
  })
  .catch(e => {
      console.log('error updateUserEmail', e)
  })
}

export const createUser = (company, user, password, userCount, onSuccess, onError) => {

  const url = baseURL+'/createUser'

  const body =  {
    email: user.email, 
    password
  }

  const data = new FormData()
  for ( var key in body ) {
      data.append(key, body[key]);
  }

  const requestOptions = {
      method: 'POST',
      body: data
  };

  fetch(url, requestOptions)
  .then(response => response.json())
  .then(data => {
    if (data.success) {
      const userEmails = company.userEmails
      userEmails.push(user.email)
      const companyData = {
        userEmails
      }
      updateCompany(company, companyData)

      const newUser = {
        active: true,
        ...user
      }
      firestore
        .collection('companies')
        .doc(company.id)
        .collection('users')
        .doc(data.uid)
        .set(newUser)
        .then(() => {
          onSuccess(userCount+1)
        })
        .catch(e => {
          console.log('error adding user', e)
        })
    } else {
      onError(data.message)
    }
    
  })
  .catch(e => {
      console.log('error adding user', e)
  })
}
  
export const updateUserInfo = (company, user, userCount, onSuccess) => {
  firestore
    .collection('companies')
    .doc(company.id)
    .collection('users')
    .doc(user.id)
    .update(user)
    .then(() => {
      onSuccess(userCount)
    })
    .catch(e => {
      console.log('error updating user', e)
    })
}