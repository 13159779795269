import React, { useState } from "react"
import styled from "styled-components"
import * as FaIcons from "react-icons/fa"
import { SidebarData } from "./SidebarData"
import { SubMenu } from "./SubMenu"
import { Image, StyleSheet, TouchableOpacity, View } from "react-native-web"
import { Colors } from "constants/Colors"
import { PrimaryText } from "components/Common/PrimaryText"
import { useCompany } from "context/CompanyContext"
import { useAuth } from "context/AuthContext"
import { logout } from "services/auth-service"
import { useDisplay } from "context/DisplayContext"
import { useParts } from "context/PartsContext"

const SidebarNav = styled.nav`
  background: #15171c;
  width: ${({ sidebar }) => (sidebar ? "280px" : "60px")};
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  transition: 350ms;
  z-index: 10;
  font-family: Galano, Arial, serif;
  overflow: scroll;
`

const Sidebar = () => {
  const { company } = useCompany()
  const { currentUser, setCurrentUser } = useAuth()
  const { showSidebar, setShowSidebar } = useDisplay()
  const { clearState } = useParts()

  const [subnav, setSubnav] = useState(false)
  const [showProfileMenu, setShowProfileMenu] = useState(false)

  function logoutPressed() {
    logout(setCurrentUser, clearState)
    window.location.reload(false)
  }

  return (
    <>
      {/** TOP NAV */}
      <View style={[styles.topBar, { marginLeft: showSidebar ? 280 : 60 }]}>
        {/* <TouchableOpacity style={styles.notificationContainer}>
          <FaIcons.FaBell size={20} color={Colors.primary.white} />
        </TouchableOpacity> */}

        <TouchableOpacity
          style={styles.userContainer}
          onPress={() => setShowProfileMenu(!showProfileMenu)}
        >
          <View style={styles.userIcon}>
            <FaIcons.FaUser size={20} color={Colors.primary.white} />
          </View>
          <View style={{ flex: 1, marginHorizontal: 24 }}>
            <PrimaryText
              fontSize={14}
              fontWeight={300}
              style={styles.userName}
              numberOfLines={1}
            >
              {currentUser?.firstName + " " + currentUser?.lastName}
            </PrimaryText>
            {!!currentUser.admin && (
              <PrimaryText
                fontSize={12}
                fontWeight={300}
                style={styles.userName}
                numberOfLines={1}
              >
                Admin
              </PrimaryText>
            )}
          </View>
          <FaIcons.FaChevronDown size={20} color={Colors.primary.darkGray} />
        </TouchableOpacity>
      </View>

      <SidebarNav sidebar={showSidebar}>
        <View
          style={{
            width: "100%",
            justifyContent: "space-between",
            paddingBottom: 12,
          }}
        >
          <View>
            <View style={styles.companyInfoContainer}>
              <View
                style={[
                  styles.tracknicityContainer,
                  !showSidebar && { justifyContent: "center" },
                ]}
              >
                <Image
                  source={require("assets/redLogo.png")}
                  style={[
                    styles.tracknicityLogo,
                    !showSidebar && { marginBottom: 12 },
                  ]}
                />
                {showSidebar && (
                  <PrimaryText
                    fontSize={20}
                    fontWeight={400}
                    style={styles.tracknicity}
                  >
                    Tracknicity
                  </PrimaryText>
                )}
              </View>
              {showSidebar && (
                <PrimaryText
                  fontSize={16}
                  fontWeight={300}
                  style={styles.companyName}
                  numberOfLines={1}
                >
                  {company?.name}
                </PrimaryText>
              )}
            </View>
            {SidebarData.map((item, index) => {
              if (
                !currentUser.admin &&
                (item.title === "Approvals" ||
                  item.title === "Company" ||
                  item.title === "Audits")
              )
                return
              return (
                <SubMenu
                  subnav={subnav}
                  setSubnav={setSubnav}
                  item={item}
                  key={index}
                  company={company}
                  currentUser={currentUser}
                  showSidebar={showSidebar}
                  setShowSidebar={setShowSidebar}
                />
              )
            })}
          </View>

          {/** SHOW SIDEBAR BUTTON */}
          <TouchableOpacity
            style={{
              alignSelf: showSidebar ? "flex-end" : "center",
              marginRight: showSidebar ? 12 : 0,
            }}
            onPress={() => setShowSidebar(!showSidebar)}
          >
            {showSidebar ? (
              <FaIcons.FaChevronLeft size={20} color={Colors.primary.white} />
            ) : (
              <FaIcons.FaChevronRight size={20} color={Colors.primary.white} />
            )}
          </TouchableOpacity>
        </View>
      </SidebarNav>
      {showProfileMenu && (
        <View style={styles.profileMenu}>
          <TouchableOpacity style={styles.menuButton} onPress={logoutPressed}>
            <PrimaryText
              fontSize={12}
              fontWeight={300}
              style={styles.userName}
              numberOfLines={1}
            >
              Logout
            </PrimaryText>
            <FaIcons.FaSignOutAlt size={20} color={Colors.primary.darkGray} />
          </TouchableOpacity>
        </View>
      )}
    </>
  )
}

const styles = StyleSheet.create({
  companyInfoContainer: {
    marginTop: 12,
  },
  tracknicityContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  tracknicity: {
    fontSize: 24,
    lineHeight: 24,
    color: Colors.primary.white,
  },
  tracknicityLogo: {
    height: 24,
    width: 24,
    borderRadius: 4,
    marginHorizontal: 12,
  },
  companyName: {
    flex: 1,
    fontSize: 16,
    color: Colors.primary.lightGray,
    marginLeft: 48,
    marginVertical: 12,
  },
  showSidebarButton: {
    alignSelf: "center",
  },

  //top bar
  topBar: {
    flexDirection: "row",
    paddingHorizontal: 12,
    backgroundColor: Colors.primary.white,
    height: 80,
    justifyContent: "flex-end",
    alignItems: "center",

    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
  },
  notificationContainer: {
    backgroundColor: Colors.primary.green,
    height: 36,
    width: 36,
    borderRadius: 18,
    justifyContent: "center",
    alignItems: "center",
  },
  userContainer: {
    flexDirection: "row",
    // justifyContent:'space-around',
    alignItems: "center",
  },
  userIcon: {
    backgroundColor: Colors.primary.darkGray,
    height: 36,
    width: 36,
    borderRadius: 18,
    justifyContent: "center",
    alignItems: "center",
  },
  userName: {
    fontSize: 14,
    lineHeight: 24,
    color: Colors.primary.darkGray,
  },
  profileMenu: {
    position: "absolute",
    zIndex: 1,
    backgroundColor: Colors.primary.white,
    right: 0,
    top: 80,
    height: 40,
    width: 200,
    paddingHorizontal: 12,

    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 3.5,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
  },
  menuButton: {
    flexDirection: "row",
    width: "100%",
    height: 40,
    justifyContent: "space-between",
    alignItems: "center",
  },
})

export default Sidebar
