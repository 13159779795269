import { firestore } from "utils/firebase";


export const getTotalValuationHistory = (company, setValuations, setInventoryTotal, setStagingAreaTotal, setTotal) => {
    return firestore
        .collection('companies')
        .doc(company.id)
        .collection('valuations')
        .orderBy('date')
        .onSnapshot(function(querySnapshot) {
            const temp = []
            querySnapshot.forEach(doc => {
                const valuation = {
                    id: doc.id,
                    ...doc.data(),
                    name: doc.data().date.toDate().toDateString(),
                    month: doc.data().date.toDate().toLocaleString('default', {month: 'short'}),
                    time: doc.data().date.toDate().getTime(),
                    total: doc.data().totalValuation+doc.data().totalStagedItemsValue
                }
                
                temp.push(valuation)
            })
            setValuations(temp)

            if (temp.length>0) {
                const end = temp[temp.length-1]
                setInventoryTotal(end.totalValuation)
                setStagingAreaTotal(end.totalStagedItemsValue)
                setTotal(end.total)
            }
        }, error => {
            console.log("Error getTotalValuationHistory: ", error);
        })
}

export const saveNewValuation = (company, inventoryValue, stagingValue) => {
    const today = new Date()
    let dateString = date2str(today, 'yyyy-MM-dd')
    return firestore
        .collection('companies')
        .doc(company.id)
        .collection('valuations')
        .doc(dateString)
        .set({
            date: today,
            totalValuation: inventoryValue,
            totalStagedItemsValue: stagingValue
        });
        
}

export function getStagedItemsValue(stagedItems) {
  
    let totalValue = 0
  
    stagedItems.forEach(item => {
  
      const deliveredQuantity = item?.deliveredQuantity ? item.deliveredQuantity : 0
      const quantityOnHand = item.receivedQuantity - deliveredQuantity > 0 ? item.receivedQuantity - deliveredQuantity : 0;
      let uomUnits = item?.uomUnits || 1
      const cost = item.cost/uomUnits

      totalValue += cost*quantityOnHand;
    });

    return totalValue
  
}

export function getInventoryValue(parts, company) {
    var totalValue = 0.0;
      parts.forEach(part => {
        const qty = part.qty ? part.qty : 0
        const value = (qty !== 0 && qty !== undefined) ? part.cost*qty : 0
        totalValue += value;

        const bStockQty = part.bStockQty ? part.bStockQty : 0
        if (bStockQty===0) return
        const bStockValuePercentage = part.bStockValuePercentage ? part?.bStockValuePercentage : company?.bStockValuePercentage || 90
        // console.log('test', part?.model, bStockValuePercentage, bStockQty)
        const bStockValue = bStockQty !== 0 ? (part.cost*bStockQty)*(bStockValuePercentage/100) : 0
        totalValue += bStockValue;
      })

    return totalValue
}

function date2str(x, y) {
    var z = {
        M: x.getMonth() + 1,
        d: x.getDate(),
        h: x.getHours(),
        m: x.getMinutes(),
        s: x.getSeconds()
    };
    y = y.replace(/(M+|d+|h+|m+|s+)/g, function(v) {
        return ((v.length > 1 ? "0" : "") + eval('z.' + v.slice(-1))).slice(-2)
    });
  
    return y.replace(/(y+)/g, function(v) {
        return x.getFullYear().toString().slice(-v.length)
    });
}

let shortFormatter = Intl.NumberFormat('en', {notation: 'compact', maximumSignificantDigits: 3})

export function formatShortNumber(number) {
  return number ? shortFormatter.format(number) : 0
}