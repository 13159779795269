import React, { memo } from 'react';
import {StyleSheet, View, Dimensions, TouchableOpacity} from 'react-native';
import {Colors} from 'constants/Colors';
import { PrimaryText } from 'components/Common/PrimaryText';
import * as FaIcons from 'react-icons/fa'



const {height, width} = Dimensions.get('window')


const Card = ({
  item,
  onPress
}) => {

  let style = styles(false)

  // if (!item.price) {
  // console.log(item)
  // }

  return (
    <TouchableOpacity 
    onPress={onPress}
    style={style.card}
    >
      <View style={{flexDirection: 'row', alignItems:'center'}}>
        <FaIcons.FaSquare name="square-medium" color={item.fromStock ? Colors.primary.red : Colors.primary.blue} size={12} style={{marginRight: 12}} />
        <View style={style.leftContainer}>
          <PrimaryText fontSize={13} fontWeight={500} style={{flex: 1}} >{item.itemFullName}</PrimaryText>
          <PrimaryText fontSize={12} fontWeight={400} style={{flex: 1}} >{item.customerFullName}</PrimaryText>
        </View>
      </View>
      <View style={{flexDirection: 'row'}}>
        <PrimaryText fontSize={13} fontWeight={400} style={[style.text, {width: width*0.12}]}>{item?.deliveredDate.toDate().toDateString()}</PrimaryText>
        <PrimaryText fontSize={13} fontWeight={400} style={[style.text, {width: width*0.12}]}>{item?.userName}</PrimaryText>
        <PrimaryText fontSize={13} fontWeight={400} style={style.text}>{item?.deliveredQuantity}</PrimaryText>
        {/* <PrimaryText fontSize={13} fontWeight={400} style={style.text}>{item?.price}</PrimaryText> */}
        {/* <View style={style.deliverButtonContainer}>
          <TouchableOpacity 
          onPress={onPress}
          style={style.deliverButton}
          >
            <FaIcons.FaUndo color={Colors.primary.white} size={12} />
          </TouchableOpacity>
        </View> */}
      </View>
    </TouchableOpacity>
  )
}

export const DeliveredItemCard = memo(Card)


const styles = darkMode => StyleSheet.create({
  card: {
    paddingHorizontal: 12,
    paddingVertical: 8,
    flexDirection: 'row',
    alignItems:'center',
    justifyContent:'space-between',
    borderColor: Colors.primary.lightGray,
    borderBottomWidth: 1
  },
  leftContainer: {
    flex: 1,
  },
  text: {
    width: width*0.06,
    textAlign:'center'
  },
  deliverButtonContainer: {
    width: width*0.06, 
    alignItems:'center',
    justifyContent:'center'
  },
  deliverButton: {
    backgroundColor: Colors.primary.lightBlue, 
    padding: 6,
    borderRadius: 4,
    alignItems: 'center',
    justifyContent:'center'
  }

});
