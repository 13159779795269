import React, { useState, useEffect } from "react"
// import browserStorage from 'store';

// var engine = require('store/src/store-engine')
// var storages = [
// 	require('store/storages/sessionStorage'),
// 	require('store/storages/cookieStorage')
// ]
// var plugins = [
// 	require('store/plugins/defaults'),
// 	require('store/plugins/expire')
// ]
// var browserStorage = engine.createStore(storages, plugins)

// This hook receives two parameters:
// storageKey: This is the name of our storage that gets used when we retrieve/save our persistent data.
// initialState: This is our default value, but only if the store doesn't exist, otherwise it gets overwritten by the store.
export const usePersistState = (storageKey, initialState) => {
  // Initiate the internal state.
  const [state, setInternalState] = useState(initialState)
  const [fetched, setFetched] = useState(false)
  const [loaded, setLoaded] = useState(false)

  // Only on our initial load, retrieve the data from the store and set the state to that data.
  useEffect(() => {
    getItem()
  }, [])

  useEffect(() => {
    // console.log('----', !state, loaded)
    if (!state && loaded) {
      setFetched(false)
    }
  }, [state])

  async function getItem() {
    // Retrieve the data from the store.
    // const storedItem = await browserStorage.get(storageKey)
    const storedItem = localStorage.getItem(storageKey)

    // If the store exists, overwrite the state with the store's data.
    // Otherwise if the store doesn't exist then "initialState" remains our default value.
    if (storedItem) {
      // if (storageKey==='COMPANY_company') console.log('stored item!', storageKey, storedItem)
      setInternalState(JSON.parse(storedItem))
    }
    setFetched(true)
    setLoaded(true)
  }

  // Create a replacement method that will set the state like normal, but that also saves the new state into the store.
  const setState = (newState) => {
    try {
      // console.log(storageKey, newState.length)
      localStorage.setItem(storageKey, JSON.stringify(newState))
      // browserStorage.set(storageKey, JSON.stringify(newState))
      setInternalState(newState)
    } catch (e) {
      console.log('failed storing', storageKey, 'catalog too big', newState.length)
      setInternalState(newState)
    }
    
  }

  return [state, setState, fetched, setFetched]
}
