import { firestore } from "utils/firebase";

export const getCustomers = (company) => {

    return firestore.collection('companies')
            .doc(company.id)
            .collection('clients')
            // .where('active', '==', true)
  
}

export const updateCustomer = (company, customer, data) => {

    return firestore.collection('companies')
            .doc(company.id)
            .collection('clients')
            .doc(customer.id)
            .update(data)
            // .where('active', '==', true)
  
}

export const getTimeData = (companyId, customerId, setTimeData) => {
    // console.log(companyId, userId)
    return firestore
    .collection('companies')
    .doc(companyId)
    // .collection('clients')
    // .doc(customerId)
    .collection('time_tracking')
    .where('customerID', '==', customerId)
    .onSnapshot(querySnapshot => {
        const temp = []
        
        querySnapshot.forEach(doc => {
            const data = doc.data()

            //get hours from PT0H0M0S format
            var reptms = /^PT(?:(\d+)H)?(?:(\d+)M)?(?:(\d+)S)?$/;
            var hours = 0, minutes = 0, seconds = 0, totalseconds;

            if (reptms.test(data.duration)) {
                var matches = reptms.exec(data.duration);
                if (matches[1]) hours = Number(matches[1]);
                if (matches[2]) minutes = Number(matches[2]);
                if (matches[3]) seconds = Number(matches[3]);
                const totalSeconds = hours * 3600  + minutes * 60 + seconds;
                const totalInHours = totalSeconds/3600;
                // timeTrackingItems.push({ serviceItemId: data.itemServiceID, timeTrackingHours: totalInHours})
                const timeTracking = {
                    id: doc.id,
                    ...data,
                    serviceItemId: data.itemServiceID, timeTrackingHours: totalInHours
                }
                temp.push(timeTracking)
                // totalUsedHours += totalInHours;
            }


            
        })
        setTimeData(temp)
    }, error => {
        console.log('error listening for time data', error)
    })
}

export const getBudgetData = (companyId, customerId, setBudgetData) => {
   return firestore
    .collection('companies')
    .doc(companyId)
    // .collection("clients")
    // .doc(customerId)
    .collection("estimateItems")
    .where('customerID', '==', customerId)
    .onSnapshot(querySnapshot => {
        const budgetHours = [];
        querySnapshot.forEach(function(doc) {
            budgetHours.push({
                id: doc.id,
                ...doc.data()
            });
        });
        setBudgetData(budgetHours);

    }, error => {
        console.log('error listening for estimate data', error)
    });
}

export const getOldTimeData = async (companyId, customerId, setTimeData) => {
    // console.log(companyId, userId)
    return firestore
    .collection('companies')
    .doc(companyId)
    .collection('clients')
    .doc(customerId)
    .collection('time_tracking')
    .get().then(querySnapshot => {
        const temp = []
        
        querySnapshot.forEach(doc => {
            const data = doc.data()

            //get hours from PT0H0M0S format
            var reptms = /^PT(?:(\d+)H)?(?:(\d+)M)?(?:(\d+)S)?$/;
            var hours = 0, minutes = 0, seconds = 0, totalseconds;

            if (reptms.test(data.duration)) {
                var matches = reptms.exec(data.duration);
                if (matches[1]) hours = Number(matches[1]);
                if (matches[2]) minutes = Number(matches[2]);
                if (matches[3]) seconds = Number(matches[3]);
                const totalSeconds = hours * 3600  + minutes * 60 + seconds;
                const totalInHours = totalSeconds/3600;
                // timeTrackingItems.push({ serviceItemId: data.itemServiceID, timeTrackingHours: totalInHours})
                const timeTracking = {
                    id: doc.id,
                    ...data,
                    serviceItemId: data.itemServiceID, timeTrackingHours: totalInHours
                }
                temp.push(timeTracking)
                // totalUsedHours += totalInHours;
            }


            
        })
        setTimeData(temp)
    })
}

export const getOldBudgetData = async (companyId, customerId, setBudgetData) => {
   return firestore
    .collection('companies')
    .doc(companyId)
    .collection("clients")
    .doc(customerId)
    .collection("estimateItems")
    .get().then(function(querySnapshot) {
        const budgetHours = [];
        querySnapshot.forEach(function(doc) {
            budgetHours.push({
                id: doc.id,
                ...doc.data()
            });
        });
        setBudgetData(budgetHours);

    });
}

export const addBudgetData = async (companyId, data) => {
    const id = data.TxnID ? data.TxnID+'-'+data.id : data.id
    return firestore
     .collection('companies')
     .doc(companyId)
     .collection("estimateItems")
     .doc(id)
     .set(data)
}

export const addTimeData = async (companyId, data) => {
    return firestore
        .collection('companies')
        .doc(companyId)
        .collection("time_tracking")
        .doc(data.id)
        .set(data)
}

export const getFromStockItems = (companyId, customerId, setFromStockItems) => {
    return firestore
    .collection('companies')
    .doc(companyId)
    .collection("history")
    .where('toClientId', '==', customerId)
    .orderBy('manufacturer')
    .orderBy('model')
    .onSnapshot(function(querySnapshot) {
        const stockItems = []
        querySnapshot.forEach(function(doc) {
            const data = doc.data()
            if (!data.rejected && data.fromLocationId!=='staging') {
                stockItems.push({
                    id: doc.id,
                    ...data
                })
            }
        });
        setFromStockItems(stockItems)
    });
}

export const fetchFromStockItems = (companyId, customerId, setFromStockItems) => {
    return firestore
    .collection('companies')
    .doc(companyId)
    .collection("history")
    .where('toClientId', '==', customerId)
    .orderBy('manufacturer')
    .orderBy('model')
    .get().then((querySnapshot) => {
        const stockItems = []
        querySnapshot.forEach((doc) => {
            const data = doc.data()
            if (!data.rejected && data.fromLocationId!=='staging' && !data.returned) {
                // console.log('data', data)
                stockItems.push({
                    id: doc.id,
                    ...data
                })
            }
        });
        setFromStockItems(stockItems)
    });
}

export const getReturnToStockItems = (companyId, customerId, setReturnToStockItems) => {
    return firestore
    .collection('companies')
    .doc(companyId)
    .collection("history")
    .where('fromClientId', '==', customerId)
    .orderBy('manufacturer')
    .orderBy('model')
    .onSnapshot(function(querySnapshot) {
        const stockItems = []
        querySnapshot.forEach(function(doc) {
            const data = doc.data()
            if (!data.rejected && data.fromLocationId!=='staging' && data.toLocationId!=='staging') {
                stockItems.push({
                    id: doc.id,
                    ...data
                })
            }
        });
        setReturnToStockItems(stockItems)
    });
}

export const getAllReturnToStockItems = (companyId, startDate, endDate) => {
    return firestore
    .collection('companies')
    .doc(companyId)
    .collection("history")
    .where('fromClientId', '>', "")
}