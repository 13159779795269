import React from "react"
import { StyleSheet, View, TouchableOpacity, Switch } from "react-native"
import Select from "react-select"
import { Colors } from "constants/Colors"
import { PrimaryText } from "components/Common/PrimaryText"
import { CustomInput } from "components/Inputs/CustomInput"
import { updateCompany } from "services/company-service"
import { Fonts } from "constants/Fonts"
import { FormControl, FormControlLabel, Radio, RadioGroup } from "@mui/material"
import { useCompany } from "context/CompanyContext"

export function Financial({
  bStockValuePercentage,
  setBStockValuePercentage,
  company,
  isDTools,
}) {
  const { accounts } = useCompany()

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: "1px solid lightgray",
      color: state.isSelected ? Colors.primary.white : Colors.primary.darkGray,
      paddingHorizontal: 12,
      fontFamily: Fonts.primary.regular,
      fontSize: 14,
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      width: 115,
    }),
    singleValue: (styles) => ({
      ...styles,
      color: Colors.primary.darkGray,
      fontFamily: Fonts.primary.regular,
      fontSize: 14,
    }),
  }

  function handleInventoryChange(e) {
    switch (e.target.value) {
      case "nonInventoryDoNothing":
        updateCompany(company, {
          nonInventoryDoNothing: true,
          nonInventoryCreateCheck: false,
        })
        break
      case "nonInventoryCreateCheck":
        updateCompany(company, {
          nonInventoryDoNothing: false,
          nonInventoryCreateCheck: true,
        })
        break
      case "inventoryCreateInvoice":
        updateCompany(company, {
          inventoryCreateInvoice: true,
          inventoryCreateFullInvoice: false,
          inventoryDoNothing: false,
        })
        break
      case "inventoryCreateFullInvoice":
        updateCompany(company, {
          inventoryCreateInvoice: false,
          inventoryCreateFullInvoice: true,
          inventoryDoNothing: false,
        })
        break
      case "inventoryDoNothing":
        updateCompany(company, {
          inventoryCreateInvoice: false,
          inventoryCreateFullInvoice: false,
          inventoryDoNothing: true,
        })
        break
      default:
        return
    }
  }

  function bStockChanged(text) {
    let trimmed = text.replace(/\D/g, "").slice(0, 3)
    if (Number(trimmed) > 100) {
      trimmed = 100
    }
    setBStockValuePercentage(Number(trimmed))
  }

  return (
    <View style={styles.container}>
      <View style={styles.settingContainer}>
        <PrimaryText fontSize={20} fontWeight={400} style={styles.title}>
          Inventory Valuation
        </PrimaryText>
        <View style={[styles.settingsRow, { marginBottom: 24 }]}>
          <PrimaryText
            fontSize={14}
            fontWeight={400}
            style={[styles.setting, { marginLeft: 12 }]}
          >
            Include Staged Items in Valuation:
          </PrimaryText>
          <Switch
            value={company?.includeStagedItemsInValue}
            onValueChange={(value) =>
              updateCompany(company, { includeStagedItemsInValue: value })
            }
          />
        </View>
        <PrimaryText fontSize={20} fontWeight={400} style={styles.title}>
          Stock Purchase Orders
        </PrimaryText>
        <View style={[styles.settingsRow, { marginBottom: 24 }]}>
          <PrimaryText
            fontSize={14}
            fontWeight={400}
            style={[styles.setting, { marginLeft: 12 }]}
          >
            Receive Stock Purchase Orders To Default Location:
          </PrimaryText>
          <Switch
            value={company?.receivePurchaseOrderToStock}
            onValueChange={(value) =>
              updateCompany(company, { receivePurchaseOrderToStock: value })
            }
          />
        </View>
        <PrimaryText
          fontSize={14}
          fontWeight={300}
          style={[styles.info, { marginBottom: isDTools ? 6 : 24 }]}
        >
          *Stock Purchase Orders are defined as Purchase Orders created to no
          Customer/Job.
        </PrimaryText>
        {isDTools && (
          <PrimaryText
            fontSize={14}
            fontWeight={300}
            style={[styles.info, { marginBottom: 24 }]}
          >
            **D-Tools does not tell us when Purchase Orders or item
            receipts are deleted. If the stock adjustment has already been made,
            you will need to manually adjust the quantity back to it's original
            value.
          </PrimaryText>
        )}

        {!isDTools && (
          <>
            <PrimaryText fontSize={20} fontWeight={400} style={styles.title}>
              Inventory Adjustment Approval Options
            </PrimaryText>
            <PrimaryText fontSize={14} fontWeight={300} style={styles.info}>
              When you approve an inventory adjustment, how should we handle the
              items in QuickBooks?
            </PrimaryText>

            <RadioGroup onChange={handleInventoryChange} style={{ margin: 24 }}>
              <PrimaryText fontSize={16} fontWeight={600}>
                For QuickBooks Inventory Items
              </PrimaryText>
              <FormControlLabel
                value="inventoryDoNothing"
                checked={company?.inventoryDoNothing}
                control={<Radio />}
                label={
                  <PrimaryText
                    fontSize={14}
                    fontWeight={400}
                    style={styles.setting}
                  >
                    Do Nothing
                  </PrimaryText>
                }
              />
              <FormControlLabel
                value="inventoryCreateInvoice"
                checked={company?.inventoryCreateInvoice}
                control={<Radio />}
                label={
                  <PrimaryText
                    fontSize={14}
                    fontWeight={400}
                    style={styles.setting}
                  >
                    Create Zero-Dollar Invoice
                  </PrimaryText>
                }
              />

              <FormControlLabel
                value="inventoryCreateFullInvoice"
                checked={company?.inventoryCreateFullInvoice}
                control={<Radio />}
                label={
                  <PrimaryText
                    fontSize={14}
                    fontWeight={400}
                    style={styles.setting}
                  >
                    Create Full-Price Invoice
                  </PrimaryText>
                }
              />
            </RadioGroup>

            <RadioGroup onChange={handleInventoryChange} style={{ margin: 24 }}>
              <PrimaryText fontSize={16} fontWeight={600}>
                For QuickBooks Non-Inventory Items
              </PrimaryText>
              <FormControlLabel
                value="nonInventoryDoNothing"
                checked={company?.nonInventoryDoNothing}
                control={<Radio />}
                label={
                  <PrimaryText
                    fontSize={14}
                    fontWeight={400}
                    style={styles.setting}
                  >
                    Do Nothing
                  </PrimaryText>
                }
              />
              <FormControlLabel
                value="nonInventoryCreateCheck"
                checked={company?.nonInventoryCreateCheck}
                control={<Radio />}
                label={
                  <PrimaryText
                    fontSize={14}
                    fontWeight={400}
                    style={styles.setting}
                  >
                    Create Check
                  </PrimaryText>
                }
              />

              {/** CHECKING ACCOUNT */}
              {company?.nonInventoryCreateCheck && (
                <>
                  <View
                    style={[styles.settingsRow, { marginTop: 0, zIndex: 999 }]}
                  >
                    <PrimaryText
                      fontSize={14}
                      fontWeight={400}
                      style={styles.setting}
                    >
                      Account for checks:
                    </PrimaryText>
                    <Select
                      styles={customStyles}
                      value={
                        company?.checkAccount
                          ? accounts.find(
                              (x) => x.value === company.checkAccount
                            )
                          : null
                      }
                      onChange={(value) =>
                        updateCompany(company, { checkAccount: value.value })
                      }
                      options={accounts}
                    />
                  </View>
                  <PrimaryText
                    fontSize={12}
                    fontWeight={300}
                    style={[styles.info, { marginLeft: 12 }]}
                  >
                    *Usually an adjustment register.
                  </PrimaryText>
                </>
              )}
            </RadioGroup>
          </>
        )}

        <PrimaryText fontSize={20} fontWeight={400} style={styles.title}>
          B-Stock Value
        </PrimaryText>
        <PrimaryText fontSize={14} fontWeight={300} style={styles.info}>
          Set your global B-Stock value percentage here. E.g. If an item's cost
          is $100, but you only want to value it at $80, your B-Stock value
          percentage will be 80%. You can set individual item percentages in the
          mobile app.
        </PrimaryText>
        <View style={styles.settingsRow}>
          <PrimaryText
            fontSize={20}
            fontWeight={400}
            style={[styles.setting, { marginLeft: 12 }]}
          >
            Global Value %:
          </PrimaryText>
          <CustomInput
            value={bStockValuePercentage}
            onChange={(text) => bStockChanged(text)}
            style={{ minWidth: 300, margin: 0 }}
            number
          />
          {!!(
            String(company.bStockValuePercentage) !== bStockValuePercentage
          ) && (
            <TouchableOpacity
              style={styles.button}
              onPress={() =>
                updateCompany(company, {
                  bStockValuePercentage: Number(bStockValuePercentage),
                })
              }
            >
              <PrimaryText
                fontSize={16}
                fontWeight={400}
                style={{ color: Colors.primary.white }}
              >
                Save
              </PrimaryText>
            </TouchableOpacity>
          )}
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    width: "100%",
    padding: 24,
    // backgroundColor:'red'
  },
  infoContainer: {
    marginTop: "5%",
  },
  info: {
    // marginRight: 24,
    marginBottom: 12,
  },
  settingContainer: {
    marginBottom: "5%",
  },
  settingsRow: {
    flexDirection: "row",
    margin: 12,
    alignItems: "center",
  },
  title: {
    color: Colors.primary.gray,
    marginVertical: 12,
  },
  setting: {
    marginRight: 30,
    // width: 204,
    fontSize: 14,
  },
  button: {
    backgroundColor: Colors.primary.green,
    // width: 100,
    justifyContent: "center",
    alignItems: "center",
    padding: 8,
    marginHorizontal: 4,
    borderRadius: 6,
  },
})
