import React, { useEffect, useState } from "react";
import { StyleSheet, View, TouchableOpacity } from "react-native";
import { Colors } from "~/constants/Colors";
import { useParts } from "~/context/PartsContext";
import { ManufacturerCard } from "~/components/Cards/ManufacturerCard";
import { CustomInput } from "~/components/Inputs/CustomInput";
import { isParentItem } from "utils/helpers";
import cloneDeep from "lodash/cloneDeep";
import { Header } from "components/Header";
import Select from "react-select";
import { Fonts } from "constants/Fonts";
import { useAuth } from "context/AuthContext";
import { ModelCard } from "components/Cards/ModelCard";
import { ListContainer } from "components/ListContainer";
import * as BiIcons from "react-icons/bi";

const options = [
  { value: "active", label: "Active" },
  { value: "archived", label: "Archived" },
];

export function Items(props) {
  const { setShowScanner, showScanner, onArchivePress, showEditItem } = props;
  const { manufacturers, parts, setSelectedItem, selectedItem } = useParts();
  const { currentUser } = useAuth();

  const [selectedFilter, setSelectedFilter] = useState(options[0]);
  const [items, setItems] = useState([]);
  const [search, setSearch] = useState(null);
  const [selectedParent, setSelectedParent] = useState(null);

  // console.log('test', selectedItem)

  useEffect(() => {
    if (search) {
      searchParts(search);
    } else {
      if (selectedParent) {
        if (selectedFilter.value === "active") {
          const temp = parts.filter(
            (x) =>
              x.active && !x.archived && x.parentRefID === selectedParent.id
          );
          setItems(temp);
        } else {
          const temp = parts.filter(
            (x) => x.active && x.archived && x.parentRefID === selectedParent.id
          );
          setItems(temp);
        }
      } else {
        if (selectedFilter.value === "active") {
          manufacturers &&
            setItems(manufacturers.filter((x) => x.active && !x.archived));
        } else {
          parts && setItems(parts.filter((x) => x.active && x.archived));
        }
      }
    }

    if (selectedItem) {
      const item = parts.find((x) => x.id === selectedItem.id);
      setSelectedItem(item);
    }
  }, [search, selectedParent, selectedFilter, parts, manufacturers, selectedItem]);

  useEffect(() => {
    if (!selectedParent) {
      if (selectedFilter.value === "active") {
        manufacturers &&
          setItems(manufacturers.filter((x) => x.active && !x.archived));
      } else {
        parts && setItems(parts.filter((x) => x.active && x.archived));
      }
      // setSearch(null)
      searchParts(search);
    } else {
      // setSearch(null)
      searchParts(search);
    }
  }, [selectedParent, parts, manufacturers]);

  function searchParts(search) {
    if (!search) return;
    if (selectedParent) {
      if (selectedFilter.value === "active") {
        const temp = items.filter(
          (x) =>
            x.active &&
            !x.archived &&
            x.parentRefID === selectedParent.id &&
            (
              x.lowercaseModel.includes(search.toLowerCase())
              || (x.partNumber && x.partNumber.toLowerCase().includes(search.toLowerCase()))
              || (x.upc && x.upc.toLowerCase().includes(search.toLowerCase()))
              || (x.description && x.description.toLowerCase().includes(search.toLowerCase()))
            )
        );
        setItems(temp);
      } else {
        const temp = items.filter(
          (x) =>
            x.active &&
            x.archived &&
            x.parentRefID === selectedParent.id &&
            (
              x.lowercaseModel.includes(search.toLowerCase())
              || (x.partNumber && x.partNumber.toLowerCase().includes(search.toLowerCase()))
              || (x.upc && x.upc.toLowerCase().includes(search.toLowerCase()))
              || (x.description && x.description.toLowerCase().includes(search.toLowerCase()))
            )



        );
        setItems(temp);
      }
    } else {
      if (selectedFilter.value === "active") {
        const temp = parts.filter(
          (x) =>
            x.active &&
            !x.archived &&
            (
              x.lowercaseModel.includes(search.toLowerCase())
              || (x.partNumber && x.partNumber.toLowerCase().includes(search.toLowerCase()))
              || (x.upc && x.upc.toLowerCase().includes(search.toLowerCase()))
              || (x.description && x.description.toLowerCase().includes(search.toLowerCase()))
            )

        );
        setItems(temp);
      } else {
        const temp = parts.filter(
          (x) =>
            x.active &&
            x.archived &&
            (
              x.lowercaseModel.includes(search.toLowerCase())
              || (x.partNumber && x.partNumber.toLowerCase().includes(search.toLowerCase()))
              || (x.upc && x.upc.toLowerCase().includes(search.toLowerCase()))
              || (x.description && x.description.toLowerCase().includes(search.toLowerCase()))
            )
        );
        setItems(temp);
      }
    }
  }

  function itemSelected(item) {
    const clonedItem = cloneDeep(item);
    if (isParentItem(parts, clonedItem)) {
      clonedItem.timeStamp = new Date().getTime().toString();

      const temp = parts.filter((x) => x.parentRefID === clonedItem.id);
      setSelectedParent(temp ? clonedItem : null);
      setItems(temp ? temp : clonedItem);
    } else {
      // console.log("here");
      setSelectedItem(clonedItem);
      showEditItem();
    }
  }

  function RenderItem({ index, style }) {
    const item = items[index];
    if (isParentItem(parts, item)) {
      return (
        <View style={style} key={item.id}>
          <ManufacturerCard
            onPress={() => {
              // console.log('pressed', item)
              setSearch(null)
              itemSelected(item)
            }}
            item={item}
            highlighted={item.id === selectedItem?.id}
            onArchive={currentUser.admin ? () => onArchivePress(item) : null}
          />
        </View>
      );
    } else {
      return (
        <View style={style} key={item.id}>
          <ModelCard
            onPress={() => {
              // console.log('pressed', item)
              itemSelected(item)
            }}
            item={item}
            highlighted={item.id === selectedItem?.id}
            onArchive={currentUser.admin ? () => onArchivePress(item) : null}
          />
        </View>
      );
    }
  }

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: "1px solid lightgray",
      color: state.isSelected ? Colors.primary.white : Colors.primary.darkGray,
      paddingHorizontal: 12,
      fontFamily: Fonts.primary.regular,
      fontSize: 14,
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      width: 85,
    }),
    singleValue: (styles) => ({
      ...styles,
      color: Colors.primary.darkGray,
      fontFamily: Fonts.primary.regular,
      fontSize: 14,
    }),
  };

  return (
    <View style={styles.listContainer}>
      <Header
        title={
          selectedParent
            ? `${selectedParent?.model} (${items?.length})`
            : `Items (${parts?.length - manufacturers?.length})`
        }
        // title={'Items'}
        onBack={selectedParent ? () => setSelectedParent(null) : null}
      // onBack={() => navigate(-1)}
      // onRight={currentUser?.admin ? () => navigation.navigate(Routes.EditItem) : null}
      />
      <View
        style={{
          zIndex: 999,
          flexDirection: "row",
          width: "100%",
          alignItems: "center",
        }}
      >
        <CustomInput
          placeholder={"Search Inventory Items"}
          style={{ marginRight: 12, flex: 1 }}
          onChange={setSearch}
          value={search}
        />
        {!showScanner && (
          <TouchableOpacity
            style={{
              marginRight: 12,
              backgroundColor: "gray",
              padding: 4,
              borderRadius: 4,
              shadowColor: "#000",
              shadowOffset: {
                width: 0,
                height: 1,
              },
              shadowOpacity: 0.25,
              shadowRadius: 3.84,
            }}
            onPress={() => setShowScanner(true)}
            disabled={showScanner}
          >
            <BiIcons.BiBarcodeReader color={Colors.primary.white} size={24} />
          </TouchableOpacity>
        )}

        {currentUser.admin && (
          <View style={styles.optionContainer}>
            {/* <PrimaryText fontSize={14} fontWeight={400} style={{color: Colors.primary.darkGray, marginHorizontal: 4}} >View:</PrimaryText> */}
            <Select
              styles={customStyles}
              value={selectedFilter}
              onChange={(value) => setSelectedFilter(value)}
              options={options}
            />
          </View>
        )}
      </View>
      {/* {!!selectedParent &&
        <TouchableOpacity 
        style={styles.backContainer}
        onPress={() => setSelectedParent(null)}
        >
          <FaIcons.FaChevronLeft color={Colors.primary.darkGray} size={16} style={{marginRight: 12}} />
          <PrimaryText fontSize={16} fontWeight={500} >{selectedParent?.model}</PrimaryText>
        </TouchableOpacity>
      } */}

      <ListContainer
        items={items}
        size={40}
        renderItem={RenderItem}
        listWidth={"nothing"}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  listContainer: {
    // flex: 1,
    width: "40%",
    flexDirection: "column",
    backgroundColor: Colors.primary.white,
  },
  backContainer: {
    marginVertical: 8,
    paddingHorizontal: 12,
    flexDirection: "row",
    alignItems: "center",
  },
  optionContainer: {
    // paddingHorizontal: 12,
    // width: 300,
    flexDirection: "row",
    alignItems: "center",
    // justifyContent:'space-between',
    marginVertical: 12,
    marginRight: 4,
  },
});
