import React from "react";
import { Dimensions, TouchableOpacity, View, Text, StyleSheet } from "react-native";
import {Colors} from "constants/Colors";
import * as MaterialCommunityIcons from "react-icons/md";
import * as GoIcons from "react-icons/go";
import { PrimaryText } from "components/Common/PrimaryText";
import * as FaIcons from "react-icons/fa";


const {width, height} = Dimensions.get("window");

export function ConfirmModal({ title, message, onConfirm, onConfirmText, onCancel }) {



  return (
    <View style={styles.container}>
        <View style={styles.body}>
            <PrimaryText fontSize={16} fontWeight={500} style={{color: Colors.primary.darkGray}} >{title}</PrimaryText>
            <PrimaryText fontSize={14} fontWeight={400} style={{color: Colors.primary.darkGray, marginVertical: 24, textAlign: 'center'}} >{message}</PrimaryText>

            <View style={styles.buttonContainer}>
                {onCancel &&
                <TouchableOpacity style={styles.cancelButton} onPress={onCancel}>
                    <PrimaryText fontSize={14} fontWeight={400} style={{color: Colors.primary.darkGray, textAlign: 'center'}} >{'No'}</PrimaryText>
                </TouchableOpacity>
                }
                
                <TouchableOpacity style={styles.deleteButton} onPress={onConfirm}>
                    <PrimaryText fontSize={14} fontWeight={400} style={{color: Colors.primary.white, textAlign: 'center'}} >{onConfirmText ? onConfirmText : 'Yes'}</PrimaryText>
                </TouchableOpacity>
            </View>
        </View>
    </View>
  );
}

const styles = StyleSheet.create({
    container: {
        position:'absolute',
        zIndex: 999,
        height: '100%', width: '100%',
        backgroundColor: 'rgba(255,255,255,0.7)',
        alignItems:'center',
        justifyContent: 'center'
    },
    body: {
            backgroundColor: Colors.primary.white,
            // height: 300, width: 300,
            alignItems: 'center',
            padding: 24,

            shadowColor: "#000",
            shadowOffset: {
                width: 0,
                height: 2,
            },
            shadowOpacity: 0.25,
            shadowRadius: 3.84,
    },
    buttonContainer: {
        flexDirection: 'row',
        justifyContent: 'space-around'
    },
    cancelButton: {
        paddingVertical: 12,
        marginHorizontal: 12,
        width: 100,
        backgroundColor: Colors.primary.lightGray,
        borderRadius: 6
    },
    deleteButton: {
        paddingVertical: 12,
        marginHorizontal: 12,
        width: 100,
        backgroundColor: Colors.primary.red,
        borderRadius: 6
    }
});