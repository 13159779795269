import React, { memo, useState } from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';
import { Colors } from '~/constants/Colors';
import { PrimaryText } from '~/components/Common/PrimaryText';
import * as IoIcons from 'react-icons/io';


const Card = ({
  title,
  onPress,
  expandable,
  subItems,
  highlighted,
}) => {

  const [expanded, setExpanded] = useState(false)

  return (
    <>
      <TouchableOpacity style={styles.card} onPress={onPress} >
        {expandable ? (
          highlighted ? (
            <IoIcons.IoMdArrowDropdown />
          ) : (
            <IoIcons.IoMdArrowDropright />
          )
        ) : (
          <IoIcons.IoMdRadioButtonOn size={10} style={{marginRight: 4}} />
        )}
        <PrimaryText fontSize={16} fontWeight={400} >{title}</PrimaryText>
      </TouchableOpacity>

      {highlighted && subItems && subItems.length > 0 &&
        <>
          {subItems.map((subItem, index) => {
            return (
              <TouchableOpacity
                key={index}
                style={[styles.card, { marginLeft: 20 }]}
                onPress={() => subItem.onPress()}
              >
                {/** bullet */}
                <IoIcons.IoMdRadioButtonOff size={10} style={{ marginRight: 4 }} />
                <PrimaryText fontSize={16} fontWeight={400} >{subItem.title}</PrimaryText>
              </TouchableOpacity>
            )
          })}
        </>
      }
    </>
  )
}

export const QuickReportCard = memo(Card)


const styles = StyleSheet.create({
  card: {
    width: '98%',
    flexDirection: 'row',
    padding: 4,
    marginBottom: 4,
    alignItems: 'center',
    borderWidth: 1,
    borderColor: Colors.primary.gray,
    borderRadius: 4,
  }

});
