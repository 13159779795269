import React from "react";
import { StyleSheet, View, TouchableOpacity } from "react-native";
import * as AiIcons from 'react-icons/ai';

import {Colors} from "~/constants/Colors";
import { PrimaryText } from "~/components/Common/PrimaryText";

export function ModalHeader({title, onClose}) {

  return (

        <View style={styles.modalHeader}>
          <PrimaryText fontSize={20} fontWeight={400} style={{width: 40}}></PrimaryText>
          <PrimaryText fontSize={18} fontWeight={400}>
            {title}
          </PrimaryText>
          <TouchableOpacity onPress={onClose}>
              <AiIcons.AiOutlineClose color={Colors.primary.darkGray} size={24} style={{width: 40}} />
          </TouchableOpacity>
        </View>
  );
}

const styles = StyleSheet.create({
  modalHeader: {
      backgroundColor: Colors.primary.white,
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: 20,
      borderColor: Colors.primary.gray,
      borderBottomWidth: 0.5,
      paddingBottom: 12
  }

});
