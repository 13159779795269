import React from 'react';
import {StyleSheet, View, Dimensions, TouchableOpacity} from 'react-native';
import {Colors} from 'constants/Colors';
import { PrimaryText } from 'components/Common/PrimaryText';
import * as FaIcons from 'react-icons/fa'



const {height, width} = Dimensions.get('window')


export const EditUserCard = ({
  item,
  location,
  onPress,
  onArchive,
  onEdit
}) => {

  let style = styles(false)

  return (
    <TouchableOpacity 
    onPress={onPress}
    style={[style.card, item.fromStock && {backgroundColor: Colors.primary.lightGray, borderColor: Colors.primary.white}]}
    >
      <PrimaryText fontSize={13} fontWeight={400} style={{flex: 1, marginRight: 12}} >{item.firstName+' '+item.lastName}</PrimaryText>
      <PrimaryText fontSize={13} fontWeight={400} style={{flex: 1, marginRight: 12}} >{location ? location.name : ''}</PrimaryText>
      <View style={{flexDirection: 'row'}}>
        <View style={style.buttonContainer}>
          {item.admin &&
            <FaIcons.FaCheck size={20} color={Colors.primary.blue} />
          }
        </View>
        <View style={style.buttonContainer}>
          {item.requests &&
            <FaIcons.FaCheck size={20} color={Colors.primary.blue} />
          }
        </View>
        <TouchableOpacity onPress={onEdit} style={style.buttonContainer}>
          <FaIcons.FaEdit size={20} color={Colors.primary.blue} />
        </TouchableOpacity>
        <TouchableOpacity onPress={onArchive} style={style.buttonContainer}>
          <FaIcons.FaArchive size={20} color={Colors.primary.blue} />
        </TouchableOpacity>
      </View>
    </TouchableOpacity>
  )
}


export const styles = darkMode => StyleSheet.create({
  card: {
    paddingHorizontal: 12,
    paddingVertical: 8,
    flexDirection: 'row',
    alignItems:'center',
    justifyContent:'space-between',
    borderColor: Colors.primary.lightGray,
    borderBottomWidth: 1
  },
  leftContainer: {
    flex: 1,
  },
  buttonContainer: {
    width: 70,
    alignItems: 'center'
  },

});
