import React, { memo } from 'react';
import {StyleSheet, TouchableOpacity} from 'react-native';
import {Colors} from '~/constants/Colors';
import { PrimaryText } from '~/components/Common/PrimaryText';
import * as MaterialCommunityIcons from "react-icons/md";
import { View } from 'react-native-web';


const Card = ({
  item,
  onPress,
  onArchive,
  highlighted
}) => {
  
  return (
    <TouchableOpacity style={[styles.card, highlighted && {backgroundColor: Colors.primary.lightGray}]} onPress={onPress}>
      <View style={{flexDirection:'row', alignItems: 'center', paddingVertical: 8, flex: 1}}>
        <MaterialCommunityIcons.MdFolderOpen color={Colors.primary.gray} size={24} />
        <PrimaryText fontSize={16} fontWeight={400} style={styles.text} numberOfLines={1}>{item.model}</PrimaryText>
      </View>
      {!!onArchive &&
        <TouchableOpacity style={styles.archiveButton} onPress={onArchive}>
          {item.archived ?
          <MaterialCommunityIcons.MdUnarchive color={Colors.primary.gray} size={24} />
          :
          <MaterialCommunityIcons.MdArchive color={Colors.primary.gray} size={24} />
          }
          
        </TouchableOpacity>
      }
      
    </TouchableOpacity>
  )
}

export const ManufacturerCard = memo(Card)


const styles = StyleSheet.create({
  card: {
    marginHorizontal: 12,
    
    flexDirection: 'row',
    alignItems:'center',
    justifyContent:'space-between',
    borderColor: Colors.primary.lightGray,
    borderBottomWidth: 1
  },
  text: {
    flex: 1,
    marginLeft: 12
  },
  archiveButton: {
    padding: 8,
  }

});
