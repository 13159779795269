import React from "react";
import { StyleSheet, View, Dimensions, TouchableOpacity } from "react-native";
import { Colors } from "~/constants/Colors";
import { PrimaryText } from "~/components/Common/PrimaryText";
import * as FaIcons from "react-icons/fa";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import { Text } from "react-native-web";
import QRCode from "react-qr-code";

const { height, width } = Dimensions.get("window");

export const StockItemCard = ({
  item,
  onPress,
  onPrint,
  selectedCustomer,
  admin,
  onEdit,
}) => {
  const printRef = React.useRef();

  const print = useReactToPrint({
    content: () => printRef.current,
  });

  const ComponentToPrint = React.forwardRef((props, ref) => {
    let printingPages = [];
    let qty = item.qty > 20 ? 20 : item.qty;
    // const {item, selectedCustomer} = props;s
    for (let i = 0; i < qty; i++) {
      printingPages.push(
        <View ref={ref} style={{ display: "flex" }}>
          <View style={{ flex: 1 }}>
            <style type="text/css" media="print">
              {`@page { size: landscape; margin: 20px!important; }`}
            </style>
            <View
              style={{
                width: 360,
                aspectRatio: 360 / 148,
                justifyContent: "center",
                // backgroundColor: "red",
              }}
            >
              <Text
                fontSize={18}
                fontWeight={600}
                style={{
                  textAlign: "center",
                  fontSize: 14,
                  fontWeight: 600,
                  marginBottom: 12,
                }}
              >
                {selectedCustomer?.fullName || selectedCustomer?.name}
              </Text>
              <View
                style={{
                  // flex: 1,
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                {item?.poId && <QRCode value={item.poId} size={100} />}
                <View
                  style={{
                    flex: 1,
                    flexGrow: 1,
                    height: "100%",
                    marginLeft: 12,
                    // backgroundColor: 'red',
                    justifyContent: "space-between",
                  }}
                >


                  <View style={{ marginTop: 2 }}>
                    <Text
                      fontSize={16}
                      fontWeight={500}
                      // style={{ textAlign: "center" }}
                      style={{ fontSize: 12, fontWeight: 500 }}
                    >
                      {item.itemFullName}
                    </Text>
                    <Text
                      fontSize={16}
                      fontWeight={500}
                      // style={{ textAlign: "center" }}
                      style={{ fontSize: 12, fontWeight: 500 }}
                    >
                      {`Location: ${"________________"}`}
                    </Text>
                    <Text
                      fontSize={14}
                      fontWeight={400}
                      // style={{ textAlign: "center" }}
                      style={{ fontSize: 12, fontWeight: 600, marginTop: 2 }}
                    >
                      (From Stock)
                    </Text>

                    {/* <Text
                    fontSize={14}
                    fontWeight={400}
                    // style={{ textAlign: "center" }}
                    style={{ fontSize: 12, fontWeight: 600 }}
                  >
                    {new Date().toDateString()}
                  </Text> */}
                  </View>

                  {item?.date && (
                    <View style={{}}>
                      <Text
                        fontSize={14}
                        fontWeight={400}
                        // style={{ textAlign: "center" }}
                        style={{ fontSize: 12, fontWeight: 400, marginTop: 2, }}
                      >
                        Staged: {item?.date.toDate().toDateString()}
                      </Text>
                    </View>
                  )}
                </View>
              </View>
            </View>
          </View>
        </View>
      );
    }
    return (
      <div style={{ display: "none" }}>
        <View ref={ref} style={{ display: "flex" }}>
          {printingPages}
        </View>
      </div>
    );
  });

  let style = styles(false);

  let {
    returnedQuantity = 0,

  } = item;
  if (item.return) returnedQuantity = 0

  if (!item.return && (item.qty - returnedQuantity <= 0)) {
    return null
  }

  return (
    <>
      <ComponentToPrint ref={printRef} />

      <TouchableOpacity style={style.card} onPress={onPress}>
        <PrimaryText fontSize={13} fontWeight={400} style={{ flex: 1 }}>
          {item.itemFullName}
        </PrimaryText>
        <View style={{ flexDirection: "row", alignItems: "center", justifyContent: 'flex-end' }}>
          <View style={style.text}>
            {!item.return && (
              <PrimaryText fontSize={13} fontWeight={400} >
                {item.qty - returnedQuantity}
              </PrimaryText>
            )}
          </View>
          <View style={style.text}>
            <PrimaryText fontSize={13} fontWeight={400} >
              {item.deliveredQuantity - returnedQuantity}
            </PrimaryText>
          </View>
          {onPrint ? (
            <View style={style.printButtonContainer}>
              <ReactToPrint
                trigger={() => (
                  <TouchableOpacity
                    onPress={() => {
                      print();
                    }}
                    style={style.printButton}
                  >
                    <FaIcons.FaPrint size={14} color={Colors.primary.white} />
                  </TouchableOpacity>
                )}
                content={() => printRef.current}
              />
            </View>
          ) : (
            <View style={style.printButtonContainer}>
              <PrimaryText
                fontSize={12}
                fontWeight={400}
                style={style.printButtonContainer}
              ></PrimaryText>
            </View>
          )}
          {admin &&
            (!item?.deliveredQuantity ||
              (item?.deliveredQuantity &&
                item.qty !== item?.deliveredQuantity)) ? (
            <View style={style.printButtonContainer}>
              <TouchableOpacity onPress={onEdit} style={style.editButton}>
                <FaIcons.FaExchangeAlt size={14} color={Colors.primary.white} />
              </TouchableOpacity>
            </View>
          ) : (
            <View style={style.printButtonContainer}></View>
          )}
        </View>
      </TouchableOpacity>
    </>
  );
};

export const styles = (darkMode) =>
  StyleSheet.create({
    card: {
      marginHorizontal: 12,
      paddingVertical: 8,
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      borderColor: Colors.primary.lightGray,
      borderBottomWidth: 1,
    },
    text: {
      // marginHorizontal: 6,
      width: width * 0.04,
      // textAlign: "right",
      alignItems: "center",
    },
    printButtonContainer: {
      width: width * 0.03,
      alignItems: "flex-end",
      // backgroundColor:'red'
    },
    printButton: {
      backgroundColor: Colors.primary.green,
      padding: 8,
      borderRadius: 4,
    },
    editButton: {
      backgroundColor: Colors.primary.gray,
      padding: 8,
      borderRadius: 4,
    },
  });
