import React, { useState, useEffect } from "react";
import { StyleSheet, View, TouchableOpacity } from "react-native";
import { Colors } from "constants/Colors";
import { useCompany } from "context/CompanyContext";
import { PrimaryText } from "components/Common/PrimaryText";
import * as MdIcons from "react-icons/md";
import { ScrollView, Text } from "react-native-web";
import { updateCompany } from "services/company-service";
import Spinner from "react-activity/dist/Spinner";
import "react-activity/dist/Spinner.css";
import { syncAccounts, syncCustomers, syncEstimates, syncItems, syncPurchaseOrders, syncTimeActivities } from "services/quickbooks-service";
import { timezoneOptions } from "constants/Constants";
import Select from 'react-select';
import { Fonts } from "constants/Fonts";
import { getQboURL } from "utils/helpers";

export function QuickBooks() {

    const { company, qboSyncData, setGetQboSyncData } = useCompany()

    const [alreadySynced, setAlreadySynced] = useState(false)
    const [startedSync, setStartedSync] = useState(false)
    const [items, setItems] = useState(false)
    const [customers, setCustomers] = useState(false)
    const [accounts, setAccounts] = useState(false)
    const [estimates, setEstimates] = useState(false)
    const [purchaseOrders, setPurchaseOrders] = useState(false)
    const [timeActivities, setTimeActivities] = useState(false)

    useEffect(() => {
        if (company.qbo) {
            setGetQboSyncData(true)
        }
    }, [company])

    useEffect(() => {
        if (qboSyncData) {
            setItems(qboSyncData?.items ? true : false)
            setCustomers(qboSyncData?.customers ? true : false)
            setAccounts(qboSyncData?.accounts ? true : false)
            setEstimates(qboSyncData?.estimates ? true : false)
            setPurchaseOrders(qboSyncData?.purchaseOrders ? true : false)
            setTimeActivities(qboSyncData?.timeActivities ? true : false)

            if (qboSyncData?.items
                && qboSyncData?.customers
                && qboSyncData?.accounts
                && qboSyncData?.estimates
                && qboSyncData?.purchaseOrders
                && qboSyncData?.timeActivities
            ) {
                setAlreadySynced(true)
            } else {
                setAlreadySynced(false)
            }

            if (qboSyncData?.items
                || qboSyncData?.customers
                || qboSyncData?.accounts
                || qboSyncData?.estimates
                || qboSyncData?.purchaseOrders
                || qboSyncData?.timeActivities
            ) {
                setStartedSync(true)
            } else {
                setStartedSync(false)
            }



        }

        if (company?.quickbooksSync) setAlreadySynced(true) // old sync style from v1


    }, [qboSyncData, company])

    function startSync() {
        setStartedSync(true)
        syncItems(company)
        syncCustomers(company)
        syncAccounts(company)
        syncEstimates(company)
        syncPurchaseOrders(company)
        syncTimeActivities(company)
    }

    function downloadQwcFile() {
        var companyName = company.name
        var shortenedCompanyName = companyName.replace(/[^\w]/gi, '')
        window.location = "https://tracknicity.com/admin/app/web_connector_qwc.php?id=" + company.id + "&companyName=" + shortenedCompanyName;
    }

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            borderBottom: '1px solid lightgray',
            color: state.isSelected ? Colors.primary.white : Colors.primary.darkGray,
            paddingHorizontal: 12,
            fontFamily: Fonts.primary.regular,
            fontSize: 14,
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            width: 240,
        }),
        singleValue: (styles) => ({
            ...styles,
            color: Colors.primary.darkGray,
            fontFamily: Fonts.primary.regular,
            fontSize: 14,
        }),
    }

    const qbURL = getQboURL(company)

    return (
        <ScrollView style={styles.container}>

            <View style={styles.body}>
                {/** TABS */}
                {company?.type === 'quickbooksOnline' || company?.qbo === true ?
                    <View style={styles.tabContainer}>
                        <PrimaryText fontSize={20} fontWeight={400} >QuickBooks Setup Instructions</PrimaryText>
                        <View style={styles.row}>
                            <PrimaryText fontSize={20} fontWeight={400} >1.</PrimaryText>
                            <TouchableOpacity
                                style={styles.button}
                                onPress={() => window.open(`${qbURL}connect?companyid=${company.id}`)}
                                disabled={!!company?.qboRealmId}
                            >
                                <PrimaryText fontSize={16} fontWeight={300} style={styles.buttonText}>Connect To QuickBooks</PrimaryText>
                                {company?.qboRealmId ?
                                    <MdIcons.MdOutlineCheckBox size={20} />
                                    :
                                    <MdIcons.MdCheckBoxOutlineBlank size={20} />
                                }

                            </TouchableOpacity>

                        </View>
                        <View style={styles.row}>
                            <PrimaryText fontSize={20} fontWeight={400} >2.</PrimaryText>
                            <TouchableOpacity
                                style={styles.button}
                                onPress={() => startSync()}
                                disabled={alreadySynced}
                            >
                                <PrimaryText fontSize={16} fontWeight={300} style={styles.buttonText}>Start Initial Sync To Tracknicity</PrimaryText>
                                {alreadySynced &&
                                    <MdIcons.MdOutlineCheckBox size={20} />
                                }
                            </TouchableOpacity>
                        </View>
                        {startedSync &&
                            <>
                                <View style={styles.row2}>
                                    <TouchableOpacity
                                        style={styles.button}
                                        onPress={() => syncItems(company)}
                                        disabled={!!items}
                                    >
                                        <PrimaryText fontSize={16} fontWeight={300} style={styles.buttonText}>Syncing Items</PrimaryText>
                                        {items ?
                                            <MdIcons.MdOutlineCheckBox size={20} />
                                            :
                                            <Spinner size={12} />
                                        }

                                    </TouchableOpacity>

                                </View>
                                <View style={styles.row2}>
                                    <TouchableOpacity
                                        style={styles.button}
                                        onPress={() => syncCustomers(company)}
                                        disabled={!!customers}
                                    >
                                        <PrimaryText fontSize={16} fontWeight={300} style={styles.buttonText}>Syncing Customers</PrimaryText>
                                        {customers ?
                                            <MdIcons.MdOutlineCheckBox size={20} />
                                            :
                                            <Spinner size={12} />
                                        }

                                    </TouchableOpacity>

                                </View>
                                <View style={styles.row2}>
                                    <TouchableOpacity
                                        style={styles.button}
                                        onPress={() => syncAccounts(company)}
                                        disabled={!!accounts}
                                    >
                                        <PrimaryText fontSize={16} fontWeight={300} style={styles.buttonText}>Syncing Accounts</PrimaryText>
                                        {accounts ?
                                            <MdIcons.MdOutlineCheckBox size={20} />
                                            :
                                            <Spinner size={12} />
                                        }

                                    </TouchableOpacity>

                                </View>
                                <View style={styles.row2}>
                                    <TouchableOpacity
                                        style={styles.button}
                                        onPress={() => syncEstimates(company)}
                                        disabled={!!estimates}
                                    >
                                        <PrimaryText fontSize={16} fontWeight={300} style={styles.buttonText}>Syncing Estimates</PrimaryText>
                                        {estimates ?
                                            <MdIcons.MdOutlineCheckBox size={20} />
                                            :
                                            <Spinner size={12} />
                                        }

                                    </TouchableOpacity>

                                </View>
                                <View style={styles.row2}>
                                    <TouchableOpacity
                                        style={styles.button}
                                        onPress={() => syncPurchaseOrders(company)}
                                        disabled={!!purchaseOrders}
                                    >
                                        <PrimaryText fontSize={16} fontWeight={300} style={styles.buttonText}>Syncing Purchase Orders</PrimaryText>
                                        {purchaseOrders ?
                                            <MdIcons.MdOutlineCheckBox size={20} />
                                            :
                                            <Spinner size={12} />
                                        }

                                    </TouchableOpacity>

                                </View>
                                <View style={styles.row2}>
                                    <TouchableOpacity
                                        style={styles.button}
                                        onPress={() => syncTimeActivities(company)}
                                        disabled={!!timeActivities}
                                    >
                                        <PrimaryText fontSize={16} fontWeight={300} style={styles.buttonText}>Syncing Time Activities</PrimaryText>
                                        {timeActivities ?
                                            <MdIcons.MdOutlineCheckBox size={20} />
                                            :
                                            <Spinner size={12} />
                                        }

                                    </TouchableOpacity>

                                </View>
                            </>
                        }


                        <PrimaryText fontSize={14} fontWeight={400} >*QuickBooks will not be altered in any way. This is a read-only process.</PrimaryText>

                    </View>
                    :
                    <View style={styles.tabContainer}>
                        <PrimaryText fontSize={20} fontWeight={400} >QuickBooks Desktop Integration</PrimaryText>
                        <View style={{ backgroundColor: Colors.primary.white, padding: 12, margin: 12, borderRadius: 4 }}>
                            <PrimaryText fontSize={16} fontWeight={400} style={styles.number} >Web Connect Password: <b>8752</b></PrimaryText>
                        </View>
                        <View style={[styles.row, { zIndex: 999 }]}>
                            <PrimaryText fontSize={16} fontWeight={400} style={styles.number} >1.</PrimaryText>
                            <PrimaryText fontSize={16} fontWeight={300} style={[styles.bold, { marginRight: 12 }]}>Select Timezone*</PrimaryText>
                            <Select
                                styles={customStyles}
                                value={company?.timezone ? timezoneOptions.find(x => x.value === company.timezone) : timezoneOptions[0]}
                                onChange={(value) => updateCompany(company, { timezone: value.value })}
                                options={timezoneOptions}
                            />
                        </View>
                        <PrimaryText fontSize={14} fontWeight={400} style={{ alignSelf: 'flex-start', marginLeft: 20, marginBottom: 4 }} >*Location of QuickBooks Company File, not the location of the business branch.</PrimaryText>
                        <View style={styles.descriptionRow}>
                            <PrimaryText fontSize={16} fontWeight={400} style={styles.number} >2.</PrimaryText>
                            <PrimaryText fontSize={16} fontWeight={300} style={styles.description}>
                                If you already have the <b>QuickBooks Web Connector</b> installed, proceed to step 3.
                                If not, please{'\n'} <a href="https://intuit.box.com/shared/static/l2tztaei6qozxv8znbb0vpas01johpjj.zip" target="_blank"><b>click here</b></a>
                                {' '} to download and install it before moving on.
                            </PrimaryText>
                        </View>
                        <View style={[styles.descriptionRow, { alignItems: 'center' }]}>
                            <PrimaryText fontSize={16} fontWeight={400} style={styles.number} >3.</PrimaryText>
                            <TouchableOpacity
                                style={styles.button}
                                onPress={() => downloadQwcFile()}
                            >
                                <PrimaryText fontSize={16} fontWeight={300} style={styles.buttonText}>Download QuickBooks Web Connector File</PrimaryText>
                            </TouchableOpacity>
                        </View>
                        <View style={styles.descriptionRow}>
                            <PrimaryText fontSize={16} fontWeight={400} style={styles.number} >4.</PrimaryText>
                            <PrimaryText fontSize={16} fontWeight={300} style={styles.description}>Launch <b>QuickBooks Desktop</b></PrimaryText>
                        </View>
                        <View style={styles.descriptionRow}>
                            <PrimaryText fontSize={16} fontWeight={400} style={styles.number} >5.</PrimaryText>
                            <PrimaryText fontSize={16} fontWeight={300} style={styles.description}>Open the QuickBooksDesktop <b>company file</b>  that you want to integrate with Tracknicity, and sign in as the main administrator and in single user mode.</PrimaryText>
                        </View>
                        <View style={styles.descriptionRow}>
                            <PrimaryText fontSize={16} fontWeight={400} style={styles.number} >6.</PrimaryText>
                            <PrimaryText fontSize={16} fontWeight={300} style={styles.description}>Open the .qwc file you downloaded in <b>step 3</b>.</PrimaryText>
                        </View>
                        <View style={styles.descriptionRow}>
                            <PrimaryText fontSize={16} fontWeight={400} style={styles.number} >7.</PrimaryText>
                            <PrimaryText fontSize={16} fontWeight={300} style={styles.description}>In the "Authorize New Web Service" window, click <b>OK</b>.</PrimaryText>
                        </View>
                        <View style={styles.descriptionRow}>
                            <PrimaryText fontSize={16} fontWeight={400} style={styles.number} >8.</PrimaryText>
                            <PrimaryText fontSize={16} fontWeight={300} style={styles.description}>In the "QuickBooks - Application Certificate" window, select <b>{'Yes, whenever this QuickBooks file is open > Continue'}</b>.</PrimaryText>
                        </View>
                        <View style={styles.descriptionRow}>
                            <PrimaryText fontSize={16} fontWeight={400} style={styles.number} >9.</PrimaryText>
                            <PrimaryText fontSize={16} fontWeight={300} style={styles.description}>In the "Access Confirmation" window, click <b>DONE</b>.</PrimaryText>
                        </View>
                        <View style={styles.descriptionRow}>
                            <PrimaryText fontSize={16} fontWeight={400} style={styles.number} >10.</PrimaryText>
                            <PrimaryText fontSize={16} fontWeight={300} style={styles.description}>In the "QuickBooks Web Connector" window, enter your password <b>8752</b> and check the box to the left of the Tracknicity application, and click Update Selected. The system performs a sync.</PrimaryText>
                        </View>

                        <PrimaryText fontSize={14} fontWeight={400} >*QuickBooks will not be altered in any way. This is a read-only process.</PrimaryText>
                        <PrimaryText fontSize={14} fontWeight={400} ><b>Additional Note</b>: The initial sync can take a while. Subsequent syncs will be much faster.</PrimaryText>

                        <View style={styles.descriptionRow}>
                            <PrimaryText fontSize={16} fontWeight={400} style={styles.number} >Troubleshooting:</PrimaryText>
                            <PrimaryText fontSize={16} fontWeight={300} style={styles.description}>On August 22nd, 2023, QuickBooks alerted customers of an issue with their older versions of QuickBooks Web Connector. If you have an issue stating "The certificate was revoked by it's certification authority", please follow <a href="https://quickbooks.intuit.com/learn-support/en-us/help-article/web-integration/fix-error-quickbooks-application-revoked/L6Y849bKg_US_en_US">this link</a> and the suggested steps to rectify the issue.</PrimaryText>
                        </View>

                    </View>
                }

            </View>

        </ScrollView>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        height: '100%',
        width: '100%',
        backgroundColor: Colors.primary.white,
        paddingTop: 12,
        marginLeft: 4,
        marginTop: 4,
        paddingBottom: 100
    },
    body: {
        flex: 1,
        backgroundColor: Colors.primary.white,
        alignItems: 'center'
    },
    tabContainer: {
        alignItems: 'center',
        justifyContent: 'space-around',
        backgroundColor: Colors.primary.lightGray,
        borderRadius: 4,
        padding: 12,
        marginTop: 12,
        width: '80%'
    },
    row: {
        flexDirection: 'row',
        alignItems: 'center',
        marginVertical: 12,
        width: '100%',
    },
    row2: {
        alignSelf: 'flex-start',
        flexDirection: 'row',
        alignItems: 'center',
        marginVertical: 12,
        marginLeft: 48,
    },
    button: {
        flexDirection: 'row',
        backgroundColor: Colors.primary.green,
        // width: 250,
        // flex: 1,
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: 8,
        marginHorizontal: 12,
        borderRadius: 6,
    },
    buttonText: {
        color: Colors.primary.white,
        marginRight: 12
    },


    //qb desktop
    descriptionRow: {
        flexDirection: 'row',
        marginVertical: 12,
        width: '100%',
    },
    number: {
        fontWeight: 300,
        marginRight: 12
    },
    description: {
        flex: 1
    },
    bold: {
        fontWeight: 500
    }


});
