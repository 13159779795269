import React, { forwardRef, useEffect, useState } from "react";
import { StyleSheet, View, Dimensions, ActivityIndicator } from "react-native";
import DatePicker from "react-datepicker";
import { Colors } from "constants/Colors";
import { Header } from "components/Header";
import { CustomInput } from "components/Inputs/CustomInput";
import { useCompany } from "context/CompanyContext";
import { PrimaryText } from "components/Common/PrimaryText";
import { Fonts } from "constants/Fonts";
import "react-datepicker/dist/react-datepicker.css";
import { useCustomers } from "context/CustomersContext";
import { useNavigate } from "react-router-dom";
import { formatOpenPurchaseOrderReport } from "utils/exports-helper";
import { useParts } from "context/PartsContext";
import { deletePO, getOpenPurchaseOrderItems } from "services/purchaseOrders-service";
import { OpenPurchaseOrderCard } from "components/Cards/OpenPurchaseOrderCard";
import { useAuth } from "context/AuthContext";
import Select from 'react-select';
import { ConfirmModal } from "components/Modals/Confirmation";
import { cloneDeep } from "lodash";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { ScrollView } from "react-native-web";

const { width, height } = Dimensions.get('window');
const options = [
  { value: 'open', label: 'Open' },
  { value: 'closed', label: 'Closed' },
  { value: 'all', label: 'All' },
];

export function PurchaseOrders() {

  const navigate = useNavigate()

  const { currentUser } = useAuth();
  const { users, company, isDTools } = useCompany()
  const { customers } = useCustomers()
  const { parts, manufacturers } = useParts()

  const [search, setSearch] = useState(null)

  const [purchaseOrderItems, setPurchaseOrderItems] = useState([])
  const [selectedStatus, setSelectedStatus] = useState(options[0])
  const [filtered, setFiltered] = useState(null)
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())

  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    let start = new Date()
    start.setDate(start.getDate() - 365)
    setStartDate(start)
  }, [])

  useEffect(() => {
    setLoading(true);

    startDate.setHours(0)
    startDate.setMinutes(0)
    startDate.setSeconds(0)
    endDate.setHours(23)
    endDate.setMinutes(59)
    endDate.setSeconds(59)

    getOpenPurchaseOrderItems(
      company.id,
      setPurchaseOrderItems,
      parts,
      manufacturers,
      startDate,
      endDate,
      selectedStatus?.value
    );

  }, [startDate, endDate, selectedStatus])

  useEffect(() => {
    if (!purchaseOrderItems) return;
    purchaseOrderItems.forEach((item) => {
      const customer = customers.find(x => x.id === item.customerId)
      if (customer) item.customerFullName = customer.fullName
      else if (!item.customerFullName) item.customerFullName = ''
    })
    // console.log("test", purchaseOrderItems?.length, sortBy);
    setFiltered(
      purchaseOrderItems
    );
    // temp.forEach((item) => {
    //   if (item.parentRefID && !item.manufacturer) {
    //     const parent = manufacturers.find((x) => x.id === item.parentRefID);
    //     item.manufacturer = parent ? parent.model : "";
    //     item.lowercaseMfg = parent ? parent.model.toLowerCase() : "";
    //   }
    // });
    setLoading(false);
  }, [purchaseOrderItems, customers]);

  // useEffect(() => {
  //   const temp = historyItems.concat(purchaseOrderItems)
  //   temp.sort((a, b) => a.deliveredDate < b.deliveredDate ? 1 : -1)
  //   setAllItems(temp)
  // }, [historyItems, purchaseOrderItems])

  //on search change
  useEffect(() => {
    if (!purchaseOrderItems) return;
    const temp = purchaseOrderItems.filter(x => !x?.fromStock)
    if (search) {
      temp.filter((x) =>
        x.itemFullName.toLowerCase().includes(search.toLowerCase())
      || (x.customerFullName && x.customerFullName.toLowerCase().includes(search.toLowerCase()))
      || (x.poNumber && x.poNumber.toLowerCase().includes(search.toLowerCase()))
      );
      setFiltered(temp);
    } else {
      setFiltered(temp);
    }
  }, [search, purchaseOrderItems]);

  function RenderItem({ index, style }) {
    const item = filtered[index]
    return (
      <View style={style}>
        <OpenPurchaseOrderCard
          item={item}
          open
          admin={currentUser.admin && selectedStatus?.value === 'open'}
          key={item.id}
          onDelete={() => setShowConfirmModal(item)}
        />
      </View>
    )
  }

  function deleteItem(item) {
    deletePO(company, item);
    const cloned = cloneDeep(purchaseOrderItems);
    const filtered = cloned.filter((x) => x.id !== item.id);
    setPurchaseOrderItems(filtered);
    setShowConfirmModal(false);
  }


  const DateInput = forwardRef(({ value, onClick }, ref) => (
    <button
      className="example-custom-input"
      style={{
        backgroundColor: Colors.primary.white,
        borderRadius: 4,
        borderWidth: 0.5,
        borderColor: Colors.primary.lightGray,
        fontFamily: Fonts.primary.regular,
        fontSize: 14
      }}
      onClick={onClick} ref={ref}>
      {value}
    </button>
  ))

  const getItemSize = index => {
    // const item = sorted[index]
    return 48
    // if (item?.stagingArea) {
    //   return 65
    // } else {
    //   return 65
    // }
  }

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: '1px solid lightgray',
      color: state.isSelected ? Colors.primary.white : Colors.primary.darkGray,
      paddingHorizontal: 12,
      fontFamily: Fonts.primary.regular,
      fontSize: 14
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      width: 115,
    }),
    singleValue: (styles) => ({
      ...styles,
      color: Colors.primary.darkGray,
      fontFamily: Fonts.primary.regular,
      fontSize: 14
    }),
  }

  return (
    <View style={{flex: 1, height: height, width: '100%'}}>
    <ScrollView style={styles.container}>
      <Header
        title={`${selectedStatus.value==='open' ? "Open" : selectedStatus.value==='closed' ? "Closed" : "All"} Purchase Orders`}
        onBack={() => navigate(-1)}
        exportData={formatOpenPurchaseOrderReport(filtered, users)}
      />
      <View style={{ zIndex: 999, flexDirection: 'row', width: '100%', alignItems: 'center', marginBottom: 24 }}>
        <CustomInput
          placeholder={'Filter By Customer or Item'}
          style={{ flex: 1, marginHorizontal: 12, height: 40 }}
          onChange={setSearch}
          value={search}
        />

        <View style={{ flexDirection: 'row', paddingLeft: 40, alignItems: 'center' }}>
          <PrimaryText fontSize={14} fontWeight={400} style={{ color: Colors.primary.darkGray, marginHorizontal: 4 }} >Status:</PrimaryText>
          <Select
            styles={customStyles}
            value={selectedStatus}
            onChange={(value) => setSelectedStatus(value)}
            options={options}
            name={'test'}
          />
        </View>

        <View style={styles.optionContainer} >
          <PrimaryText
            fontSize={14}
            fontWeight={400}
            style={{ color: Colors.primary.darkGray, marginHorizontal: 4 }}
            numberOfLines={1} >
            Date Range:
          </PrimaryText>

          <View >
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              customInput={<DateInput />}
            />
          </View>

          <PrimaryText fontSize={14} fontWeight={400} style={{ color: Colors.primary.darkGray, marginHorizontal: 4 }} >-</PrimaryText>

          <View >
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              customInput={<DateInput />}
            />
          </View>

        </View>
      </View>



      {filtered && filtered?.length > 0 ?
        // <View style={styles.body}>
        //   <View style={styles.detailLabelContainer}>
        <Table
          className="table"
          style={{ marginHorizontal: 6 }}
          stickyHeader={true}
        >
          <TableHead
            style={{
              padding: 0,
              backgroundColor: "#fff",
              // position: "sticky",
              top: 0,
            }}
            
          >
            <TableRow style={{ padding: 0}}>
              <TableCell align="left" style={{paddingLeft: 4}}>Item/Customer</TableCell>
              <TableCell align="center">Ordered</TableCell>
              <TableCell align="center">Received</TableCell>
              <TableCell align="center">Delivered</TableCell>
              <TableCell align="center">PO#</TableCell>
              {currentUser?.admin && selectedStatus?.value === 'open' && (
                <TableCell></TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {filtered.map((item) => (
              <OpenPurchaseOrderCard
                item={item}
                open
                admin={currentUser.admin && selectedStatus?.value === 'open'}
                key={item.id}
                onDelete={() => setShowConfirmModal(item)}
                onPress={() => console.log(item)}
              />
            ))}
          </TableBody>
        </Table>

        :
        <View style={styles.noCustomerContainer}>
          <PrimaryText fontSize={16} fontWeight={400} >
            {`No delivered items in selected time range`}
          </PrimaryText>
        </View>
      }

      

      {loading &&
        <ActivityIndicator
          animating={loading}
          size={'large'}
          style={StyleSheet.absoluteFill} color={Colors.primary.blue}
        />
      }
    </ScrollView>
    {!!showConfirmModal && (
        <ConfirmModal
          title={"Delete PO Line Item"}
          message={`Are you sure? This item can be recovered by making a change to the PO in ${isDTools ? "D-Tools" : "QuickBooks"
            }.`}
          onConfirm={() => deleteItem(showConfirmModal)}
          onCancel={() => setShowConfirmModal(false)}
        />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    // flex: 1,
    width: '100%',
    maxHeight: height,
    flexDirection: "column",
    backgroundColor: Colors.primary.white,
  },
  button: {
    backgroundColor: Colors.primary.green,
    padding: 12,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4
  },
  noCustomerContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  body: {
    flex: 1,
    width: '100%',
    backgroundColor: Colors.primary.white,
    marginTop: 12,
    alignItems: 'center'
  },
  optionContainer: {
    paddingHorizontal: 12,
    // width: 300,
    flexDirection: 'row',
    alignItems: 'center',
    // justifyContent:'space-between',
    marginVertical: 12
  },
  legend: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginVertical: 8
  },

  //details
  detailLabelContainer: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 12,
    marginTop: 12,
    backgroundColor: Colors.primary.gray
  },
  detailLabel: {
    width: width * 0.06,
    textAlign: 'center',
    color: Colors.primary.white
  },


  //modal
  modal: {
    // justifyContent: 'flex-end',
    margin: 0
  },
  modalContent: {
    // height: height*0.6,
    width: width,
    backgroundColor: Colors.primary.white,
    borderRadius: 12,
    padding: 12,
    // alignItems: 'center'
  },
  modalSwipeButton: {
    width: 32,
    height: 3,
    backgroundColor: Colors.primary.darkGray,
    borderRadius: 3,
    marginBottom: 12,
    alignSelf: 'center'
  },
  description: {
    marginHorizontal: 12,
    marginVertical: 4
  },
  lineBreak: {
    height: 1,
    backgroundColor: Colors.primary.lightGray,
    margin: 12,
  }


});
