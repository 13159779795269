import React, { useEffect, useState } from "react";
import { StyleSheet, View, FlatList } from "react-native";
import {Colors} from "~/constants/Colors";
import { Header } from "components/Header";
import { useCompany } from "context/CompanyContext";
import { AuditCard } from "components/Cards/AuditCard";
import { completeAudit, deleteAudit, getAuditByLocation } from "~/services/audits-service";
import { useNavigate } from "react-router-dom";
import { Links } from "constants/Links";

export function AuditsList() {

    const navigate = useNavigate()

  const {company, setSelectedAudit, selectedLocation} = useCompany()

  const [audits, setAudits] = useState([])

  useEffect(() => {
    const subscriber = getAuditByLocation(company, selectedLocation, setAudits)
    return () => subscriber()
  }, [selectedLocation])


  function auditSelected(audit) {
      setSelectedAudit(audit)
      navigate(Links.AuditReport)
  }

  return (
    <View style={styles.listContainer}>
      <Header 
        title={'Audits'}
        // onBack={selectedParent ? () => setSelectedParent(null) : null} 
        // onRight={currentUser?.admin ? () => navigation.navigate(Routes.EditItem) : null}
        />
      <FlatList
        style={{flex: 1}}
        contentContainerStyle={{backgroundColor: Colors.primary.white}}
        data={audits}
        keyExtractor={item => item.id}
        renderItem={({ item, index }) => {
            return (
                <AuditCard item={item} onPress={() => auditSelected(item)} />
            )
        }}
        />
    </View>
  );
}

const styles = StyleSheet.create({
    listContainer: {
        // flex: 1,
        width: '60%',
        flexDirection: "column",
        backgroundColor: Colors.primary.white,
    }
});
