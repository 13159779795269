import React, { memo } from 'react';
import {StyleSheet, View} from 'react-native';
import {Colors} from '~/constants/Colors';
import { PrimaryText } from '~/components/Common/PrimaryText';
import { useCompany } from '~/context/CompanyContext';
import { TouchableOpacity } from 'react-native-web';
import { getItemName } from 'utils/helpers';


const Card = ({
  item,
  onReject,
  onApprove
}) => {

  const {users} = useCompany()

  let style = styles(false)
  const user = users.find(x => x.id===item.user)
  const color = 
  (!item?.fromClientId && !item.toClientId && !item.manualAdjustment) ? Colors.primary.purple //internal
  : item.manualAdjustment ? item.fromLocationId==='manual' ? Colors.primary.green : Colors.primary.red //manual adjustment
  : item.fromClientId ? Colors.primary.green //return
  : Colors.primary.red //to client

  const approvedColor = item.approved ? Colors.primary.lightGray : Colors.primary.blue
  const approvedTextColor = item.approved ? Colors.primary.darkGray : Colors.primary.white

  return (
    <View style={style.card}>
      <View style={{width: 20, height: '100%', backgroundColor: color, marginRight: 24, borderRadius: 10}} />
      <View style={style.buttonContainer}>
        <TouchableOpacity 
        style={[style.button, {backgroundColor: approvedColor, opacity: item.rejected ? 0.4 : 1}]}
        disabled={item.rejected}
        onPress={onApprove}
        >
          <PrimaryText fontSize={16} fontWeight={400} style={{color: approvedTextColor}}>{item.approved ? 'Unapprove' : 'Approve'}</PrimaryText>
        </TouchableOpacity>
        <TouchableOpacity 
        style={[style.button, {backgroundColor: Colors.primary.lightGray, opacity: item.approved ? 0.4 : 1}]}
        disabled={item.approved}
        onPress={onReject}
        >
          <PrimaryText fontSize={16} fontWeight={400} style={{color: Colors.primary.darkGray}}>{item.rejected ? 'Undo Reject' : 'Reject'}</PrimaryText>
        </TouchableOpacity>
      </View>
      <View style={{marginHorizontal: 24, flexGrow: 1}}>
        <PrimaryText fontSize={13} fontWeight={400}>{item?.date.toDate().toDateString()} - {user?.firstName} {user?.lastName}</PrimaryText>
        <PrimaryText fontSize={16} fontWeight={500}>Item: {getItemName(item)}</PrimaryText>
        <PrimaryText fontSize={14} fontWeight={400}>From: {item?.from}</PrimaryText>
        <PrimaryText fontSize={14} fontWeight={400}>To: {item?.to}</PrimaryText>
      </View>

      <View style={{flex: 1}}>
        {item.rejected &&
          <View style={style.rejectedContainer}>
            <PrimaryText fontSize={24} fontWeight={400} style={{color: Colors.primary.red}}>Rejected</PrimaryText>
          </View>
        }
        {item?.projectAddition &&
        <View style={style.rejectedContainer}>
          <PrimaryText fontSize={24} fontWeight={400} style={{color: Colors.primary.green}}>Project {color===Colors.primary.green ? 'Return' : `Addition`}</PrimaryText>
        </View>
        }
      </View>
      
      <View style={{flex: 1, alignItems:'flex-end'}}>
        <View style={[style.qtyContainer, {backgroundColor: color}]}>
          <PrimaryText fontSize={16} fontWeight={400} style={{color: Colors.primary.white}}>{item?.qty}</PrimaryText>
        </View>
      </View>
      
    </View>
  )
}

export const ApprovalsCard = memo(Card)


const styles = darkMode => StyleSheet.create({
  card: {
    flex: 1,
    marginHorizontal: 12,
    paddingVertical: 8,
    flexDirection:'row',
    alignItems:'center',
    justifyContent:'space-between',
    borderColor: Colors.primary.lightGray,
    borderBottomWidth: 1
  },
  buttonContainer: {
    marginHorizontal: 24,
  },
  button: {
    width: 200,
    justifyContent:'center',
    alignItems:'center',
    padding: 8,
    marginVertical: 4,
    borderRadius: 6,

    shadowColor: "#000",
      shadowOffset: {
        width: 0,
        height: 1,
      },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
  },
  qtyContainer: {
    height: 60, 
    width: 60,
    borderRadius: 30,
    justifyContent:'center',
    alignItems: 'center',
  },
  rejectedContainer: {
    flex: 1,
    justifyContent: 'center', 
    alignItems:'center', 
  }

});
