import React from "react";
import {
  StyleSheet,
  View,
  Dimensions,
  TouchableOpacity,
  Text,
} from "react-native";
import { Colors } from "~/constants/Colors";
import { PrimaryText } from "~/components/Common/PrimaryText";
import { TableCell, TableRow } from "@mui/material";

const { height, width } = Dimensions.get("window");

export const DToolsProjectItemCard = ({ item, onPress, dToolsCustomFields }) => {
  let {
    Manufacturer = "",
    Model = "",
    OrderStatus = "",
    Picked = false,
    Quantity,
    ReceivedDate = null,
    CustomField11 = null,
    CustomField14 = null,
  } = item;

  // console.log("item", item);
  if (dToolsCustomFields) {
    const {
      deliveredDate,
      returnInfo,
    } = dToolsCustomFields;

    if (deliveredDate) {
      CustomField11 = item[deliveredDate];
    }
    if (returnInfo) {
      CustomField14 = item[returnInfo];
    }
  }

  const name = Manufacturer ? `${Manufacturer}: ${Model}` : Model;



  let style = styles(false);

  return (
    <TableRow
    // onClick={onPress}
    // style={[
    //   style.card,
    //   item.quantity !== item.deliveredQuantity && {
    //     backgroundColor: Colors.primary.lightGray,
    //     borderColor: Colors.primary.white,
    //   },
    // ]}
    >
      <TableCell>
        <PrimaryText fontSize={13} fontWeight={400} style={{ flex: 1 }}>
          {name}
          {Quantity > 1 ? ` (${Quantity})` : ""}
        </PrimaryText>
      </TableCell>
      <TableCell align="center">
        {/* <View style={{ flexDirection: "row", flex: 1 }}> */}
        <PrimaryText fontSize={13} fontWeight={400} style={style.text}>
          {OrderStatus === "Ordered" ? "Y" : ""}
        </PrimaryText>
      </TableCell>
      <TableCell align="center">
        <PrimaryText fontSize={13} fontWeight={400} style={style.text}>
          {ReceivedDate ? "Y" : ""}
        </PrimaryText>
      </TableCell>
      <TableCell align="center">
        <PrimaryText fontSize={13} fontWeight={400} style={style.text}>
          {Picked ? "Y" : ""}
        </PrimaryText>
      </TableCell>
      <TableCell align="center">
        <PrimaryText fontSize={13} fontWeight={400} style={style.text}>
          {CustomField11 ? "Y" : ""}
        </PrimaryText>
      </TableCell>
      <TableCell align="center">
        <PrimaryText fontSize={13} fontWeight={400} style={style.text}>
          {CustomField14 ? "Y" : ""}
        </PrimaryText>
      </TableCell>
      {/* </View> */}
    </TableRow>
  );
};

const styles = (darkMode) =>
  StyleSheet.create({
    card: {
      paddingHorizontal: 12,
      paddingVertical: 8,
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      borderColor: Colors.primary.lightGray,
      borderBottomWidth: 1,
    },
    text: {
      // marginHorizontal: 6,
      // width: width * 0.08,
      textAlign: "right",
    },
  });
