import React, { useState } from "react"
import { StyleSheet, View, TouchableOpacity } from "react-native"
import { Colors } from "constants/Colors"
import { useCompany } from "context/CompanyContext"
import { PrimaryText } from "components/Common/PrimaryText"
import { Company } from "./Company"
import { Financial } from "./Inventory"
import { Header } from "components/Header"
import { Subscription } from "./Subscription"
import { ScrollView } from "react-native-web"
import { ConfirmModal } from "components/Modals/Confirmation"
import { cancelSubscription } from "services/subscription-service"
import { updateCompany } from "services/company-service"
import { logout } from "services/auth-service"
import { useAuth } from "context/AuthContext"
import { useParts } from "context/PartsContext"

export function Settings() {
  const { company, isDTools, stagingAreaSubLocations } = useCompany()
  const { setCurrentUser } = useAuth()
  const { clearState } = useParts()

  const [activeTab, setActiveTab] = useState(0)
  const [name, setName] = useState(company?.name)
  const [bStockValuePercentage, setBStockValuePercentage] = useState(
    company?.bStockValuePercentage ? company.bStockValuePercentage : "90"
  )

  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [showConfirmModal2, setShowConfirmModal2] = useState(false)

  function onSubscriptionCancelled() {
    const data = {
      active: false,
      paymentCustomerId: "",
      paymentSubscriptionId: "",
    }
    updateCompany(company, data, setShowConfirmModal2(true))
  }

  return (
    <View style={styles.container}>
      <Header title={"Settings"} />

      <View style={styles.body}>
        {/** TABS */}
        <View style={styles.tabContainer}>
          <TouchableOpacity
            onPress={() => setActiveTab(0)}
            style={[styles.tabButton, activeTab === 0 && styles.activeTab]}
          >
            <PrimaryText
              fontSize={14}
              fontWeight={400}
              styles={activeTab !== 0 && { color: Colors.primary.white }}
            >
              Company
            </PrimaryText>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => setActiveTab(1)}
            style={[styles.tabButton, activeTab === 1 && styles.activeTab]}
          >
            <PrimaryText fontSize={14} fontWeight={400}>
              Inventory
            </PrimaryText>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => setActiveTab(2)}
            style={[styles.tabButton, activeTab === 2 && styles.activeTab]}
          >
            <PrimaryText fontSize={14} fontWeight={400}>
              Subscription
            </PrimaryText>
          </TouchableOpacity>
        </View>

        <ScrollView style={{ width: "80%" }}>
          {activeTab === 0 && (
            <Company
              company={company}
              name={name}
              setName={setName}
              isDTools={isDTools}
              stagingAreaSubLocations={stagingAreaSubLocations}
            />
          )}
          {activeTab === 1 && (
            <Financial
              company={company}
              bStockValuePercentage={bStockValuePercentage}
              setBStockValuePercentage={setBStockValuePercentage}
              isDTools={isDTools}
            />
          )}
          {activeTab === 2 && (
            <Subscription
              setShowConfirmModal={setShowConfirmModal}
              isDTools={isDTools}
            />
          )}
        </ScrollView>
      </View>

      {showConfirmModal && (
        <ConfirmModal
          title={"Cancel Subscription"}
          message={"Are you sure?"}
          onConfirm={() => {
            setShowConfirmModal(false)
            cancelSubscription(company, onSubscriptionCancelled)
          }}
          onCancel={() => setShowConfirmModal(false)}
        />
      )}

      {showConfirmModal2 && (
        <ConfirmModal
          title={"Subscription Successfully Cancelled"}
          message={""}
          onConfirm={() => logout(setCurrentUser, clearState)}
          onConfirmText={"Okay"}
          onCancel={null}
        />
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    height: "100%",
    width: "100%",
    backgroundColor: Colors.primary.white,
    paddingTop: 12,
    marginLeft: 4,
    marginTop: 4,
  },
  body: {
    flex: 1,
    backgroundColor: Colors.primary.white,
    alignItems: "center",
  },
  tabContainer: {
    flexDirection: "row",
    justifyContent: "space-around",
    backgroundColor: Colors.primary.lightGray,
    borderRadius: 4,
    padding: 4,
    marginTop: 4,
    width: "80%",
  },
  tabButton: {
    alignItems: "center",
    justifyContent: "center",
    width: "32.5%",
    borderRadius: 4,
    marginHorizontal: 2,
    padding: 2,
    // width: width*0.15-12,
    backgroundColor: Colors.primary.lightGray,
  },
  activeTab: {
    backgroundColor: Colors.primary.white,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,

    elevation: 5,
  },
})
