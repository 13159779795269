import React, { forwardRef, useEffect, useRef, useState } from "react";
import {
  StyleSheet,
  View,
  Dimensions,
  FlatList,
  TouchableOpacity,
} from "react-native";
import { Colors } from "~/constants/Colors";
import { useParts } from "~/context/PartsContext";
import { useCompany } from "~/context/CompanyContext";
import { PrimaryText } from "~/components/Common/PrimaryText";
import { useCustomers } from "~/context/CustomersContext";
import {
  getFromStockItems,
  getReturnToStockItems,
} from "~/services/customers-service";
import { PurchaseOrderItemCard } from "~/components/Cards/PurchaseOrderItemCard";
import { StockItemCard } from "~/components/Cards/StockItemCard";
// import ReactNativeModal from "react-native-modal";
import * as MdIcons from "react-icons/md";
import { useAuth } from "~/context/AuthContext";
import { FullModal } from "components/Modals/FullModal";
import { ModalHeader } from "components/Modals/ModalHeader";
import { ConfirmModal } from "components/Modals/Confirmation";
import { EditItemQtyByLocationCard } from "~/components/Cards/EditItemQtyByLocationCard";
import * as MaterialCommunityIcons from "react-icons/md";
import {
  checkAlertStatus,
  deletePO,
  getPurchaseOrderItems,
  movePOToNewCustomer,
  movePOToStock,
  moveStockTransactionToNewCustomer,
  setAlert,
} from "services/purchaseOrders-service";
import { cloneDeep } from "lodash";
import * as FaIcons from "react-icons/fa";
import { ExportToCsv, download, generateCsv, mkConfig } from "export-to-csv";
import { formatDToolsReconciliationReport, formatProjectItemReport } from "utils/exports-helper";
import { getItemName, getItemNameFromManufacturer } from "utils/helpers";
import { CustomInput } from "components/Inputs/CustomInput";
import { CustomerCard } from "components/Cards/CustomerCard";
import {
  rejectTransaction,
  returnAndMoveStockTransaction,
} from "services/transactions-service";
import { LocationExpandableCard } from "components/Cards/LocationExpandableCard";
import { DToolsProjectItemCard } from "components/Cards/DToolsProjectItemCard";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { ScrollView } from "react-native-web";
import { markDToolsItemReturned, reduceDToolsProjectItems } from "services/dtools-service";

const { width, height } = Dimensions.get("window");

export function ProjectItems() {
  const { currentUser } = useAuth();
  const {
    selectedCustomer,
    customers,
    dToolsProjectItems,
    dToolsProjectLocations,
    getDToolsProjectItems,
  } = useCustomers();
  const { company, users, isDTools, locations } = useCompany();
  const { parts, manufacturers, locationParts } = useParts();

  const [activeTab, setActiveTab] = useState(0);

  const [poItems, setPoItems] = useState(null);
  const [poParts, setPoParts] = useState(null);
  const [poFromStock, setPoFromStock] = useState(null);

  const [stockItems, setStockItems] = useState(null);
  const [stockParts, setStockParts] = useState(null);

  const [returnItems, setReturnItems] = useState(null);
  const [returnParts, setReturnParts] = useState(null);

  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedItemAlert, setSelectedItemAlert] = useState(false);
  const [showEdit, setShowEdit] = useState(false);

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showReturnConfirmation, setShowReturnConfirmation] = useState(false);

  const [editCustomer, setEditCustomer] = useState(false);
  const [search, setSearch] = useState(null);
  const [filtered, setFiltered] = useState(null);
  const [newCustomer, setNewCustomer] = useState(null);

  const [returnToStock, setReturnToStock] = useState(false);
  const [quantityToReturn, setQuantityToReturn] = useState(null);
  const [newLocation, setNewLocation] = useState(null);

  //on selected customer change
  useEffect(() => {
    let poSubscriber;
    let stockSubscriber;
    let returnSubscriber;
    if (selectedCustomer) {
      //get items
      poSubscriber = getPurchaseOrderItems(
        company.id,
        selectedCustomer.id,
        setPoItems
      );
      stockSubscriber = getFromStockItems(
        company.id,
        selectedCustomer.id,
        setStockItems
      );
      returnSubscriber = getReturnToStockItems(
        company.id,
        selectedCustomer.id,
        setReturnItems
      );

      // const selectedItem = {
      //   customerId : selectedCustomer.id,
      //   itemListId: "96d18fbd-bab1-4f65-bdc7-b0d38f948242",
      //   quantityToDeliver: 1,
      //   // deliveredDate, manufacturer, model, cost, price
      // }
      // markDToolsItemReturned(company, selectedItem, currentUser)
    }

    return () => {
      poSubscriber && poSubscriber();
      stockSubscriber && stockSubscriber();
      returnSubscriber && returnSubscriber();
      setPoItems(null);
      setPoParts(null);
      setStockItems(null);
      setStockParts(null);
      setReturnItems(null);
      setReturnParts(null);
    };
  }, [selectedCustomer]);

  //get actual parts for po items
  useEffect(() => {
    if (poItems) {
      const poParts = [];
      const poFromStock = [];
      poItems.forEach((item) => {
        const part = parts.find((x) => x.id === item.itemListId);
        if (part) {
          item.itemFullName = getItemNameFromManufacturer(part, manufacturers);
        } else {
          item.itemFullName = item?.manufacturer
            ? getItemName(item)
            : item.itemFullName
              ? item.itemFullName
              : isDTools
                ? "Item deleted from D-Dtools or has not been synced"
                : `Item Deleted From QB` + item.itemListId;
        }
        // console.log("item", item?.itemFullName)

        if (!item.fromStock) {
          if (isDTools) {
            const projectItems = dToolsProjectItems
              ? dToolsProjectItems.filter(
                (x) =>
                  item.projectSIItemIds &&
                  item.projectSIItemIds.includes(x.SIItemId)
              )
              : [];
            item.projectItems = projectItems;
            item.projectItems.forEach((projectItem) => {
              const location =
                dToolsProjectLocations &&
                dToolsProjectLocations.find(
                  (x) => x.Id === projectItem.LocationId
                );
              projectItem.location = location;
              if (projectItem?.BulkWire || projectItem?.BulkItem) {
                item.bulk = true;
              }
            });
          }
          poParts.push(item);
        } else {
          poFromStock.push(item); // this never displays but is used to grab the delivered quantity for the associated history doc
        }
      });
      setPoFromStock(poFromStock.sort((a, b) => a.itemFullName > b.itemFullName ? 1 : -1));
      setPoParts(poParts.sort((a, b) => a.itemFullName > b.itemFullName ? 1 : -1));
    }
  }, [poItems, parts, dToolsProjectItems, dToolsProjectLocations]);

  // console.log(dToolsProjectItems.filter(x => x.LocationId))

  //get actual parts for stock items
  useEffect(() => {
    if (stockItems) {
      const stockParts = [];
      stockItems.forEach((item) => {
        const part = parts.find((x) => x.id === item.partID);
        if (part) {
          item.itemFullName = getItemNameFromManufacturer(part, manufacturers);
        } else {
          // console.log(' no part? ', item)
          item.itemFullName = getItemNameFromManufacturer(item, manufacturers);
        }
        item.deliveredQuantity = item.qty;
        item.deliveredBy = item.user;
        item.deliveredDate = item.date;
        item.timeCreated = item.date;
        item.stock = true;
        stockParts.push(item);
      });

      setStockParts(stockParts.sort((a, b) => a.itemFullName > b.itemFullName ? 1 : -1));
    }
  }, [stockItems]);

  //get actual parts for return items
  useEffect(() => {
    if (returnItems) {
      const returns = [];
      returnItems.forEach((item) => {
        const part = parts.find((x) => x.id === item.partID);
        if (part) {
          item.itemFullName = getItemNameFromManufacturer(part, manufacturers);
        } else {
          // console.log(' no part? ', item)
          item.itemFullName = item.manufacturer
            ? `${item.manufacturer}: ${item.model}`
            : item.model;
        }

        //this is a return from this client
        item.deliveredQuantity = item.qty;
        item.deliveredBy = item.user;
        item.deliveredDate = item.date;
        item.timeCreated = item.date;
        item.return = true;
        item.stock = true;
        item.qty = "";
        returns.push(item);
      });

      setReturnParts(returns.sort((a, b) => a.itemFullName > b.itemFullName ? 1 : -1));
    }
  }, [returnItems]);

  //for staged items (poFromStock), update associated stock item
  useEffect(() => {
    if (poFromStock && stockParts) {
      poFromStock.forEach((po) => {

        const stockItemIndex = stockParts.findIndex(
          (x) =>
            po?.historyDocRef === x.id
        );

        if (stockItemIndex > -1) {
          const stockItem = stockParts[stockItemIndex];
          stockItem.deliveredQuantity = po.deliveredQuantity
            ? po.deliveredQuantity
            : 0;
          stockItem.deliveredBy = po.deliveredBy;
          stockItem.deliveredDate = po.deliveredDate;
          stockItem.poId = po.id;
          stockItem.returnedQuantity = po.returnedQuantity

          // console.log('wtf', stockItem)
        }
      });

      stockParts.forEach((stock) => {
        if (stock.poId) {
          const poItem = poFromStock.find((x) => x.id === stock.poId);
          if (!poItem) {
            stock.deliveredQuantity = stock.qty;
            stock.deliveredBy = stock.user;
            stock.deliveredDate = stock.date;
            stock.timeCreated = stock.date;
          }
        }
      });
    }
  }, [poFromStock, stockParts]);

  useEffect(() => {
    if (selectedItem) {
      //check if we're setup to receive alert
      console.log("selected item", selectedItem);
      checkAlertStatus(
        company.id,
        selectedItem.id,
        currentUser.id,
        setSelectedItemAlert
      );
      // markDToolsItemInstalled(company, selectedItem)


      // setTimeout(() => {
      //   movePOToStock(
      //     company,
      //     currentUser,
      //     selectedItem,
      //     selectedCustomer,
      //     { name: 'test', id: 'dafdaf'},
      //     1,
      //     clearEdit,
      //     locationParts
      //   );
      // }, 250);
    }
  }, [selectedItem]);

  function deleteItem(item) {
    deletePO(company, item);
    const cloned = cloneDeep(poItems);
    const filtered = cloned.filter((x) => x.id !== item.id);
    setPoItems(filtered);
    setShowConfirmModal(false);
  }

  //on search change
  useEffect(() => {
    if (search) {
      const tempSearch = search.toLowerCase();
      const temp = customers.filter((x) =>
        x.lowercaseFullName.includes(tempSearch)
      );
      setFiltered(temp);
    } else {
      setFiltered(null);
    }
  }, [search]);

  const customerSelected = (item) => {
    //search if there are children
    const hasChildren = customers.find((x) => x.parent_id === item.id);

    if (!hasChildren) {
      setNewCustomer(item);
      setSearch(null);
    } else {
      //   setSearch(item.fullName)
      const temp = customers.filter((x) => x.parent_id === item.id);
      setFiltered(temp);
    }
  };

  function saveNewCustomer() {
    if (selectedItem.stock) {
      // console.log('stock item!')
      moveStockTransactionToNewCustomer(
        company,
        selectedItem,
        newCustomer,
        onSuccessMoveCustomer
      );
      return;
    } else {
      // console.log('here yay!', selectedItem)
      movePOToNewCustomer(
        company,
        selectedItem,
        newCustomer,
        onSuccessMoveCustomer
      );
      return;
    }
  }

  function onSuccessMoveCustomer() {
    clearEdit();
  }

  useEffect(() => {
    if (!showEdit) clearEdit();
  }, [showEdit]);

  function clearEdit() {
    setEditCustomer(false);
    setNewLocation(null);
    setNewCustomer(false);
    setReturnToStock(false);
    setSelectedItem(null);
    setShowEdit(false);
  }

  function returnToStockPressed() {
    setShowReturnConfirmation(true);
  }

  function returnToStockConfirmed() {
    setShowReturnConfirmation(false);

    if (selectedItem.stock) {
      console.log("stock item!", selectedItem);
      returnAndMoveStockTransaction(
        company,
        currentUser,
        parts,
        selectedItem,
        quantityToReturn,
        locationParts,
        newLocation,
        clearEdit
      );
      return;
    } else {
      console.log("quantity", quantityToReturn);
      movePOToStock(
        company,
        currentUser,
        selectedItem,
        selectedCustomer,
        newLocation,
        quantityToReturn,
        clearEdit,
        locationParts
      );
    }
  }

  const options = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    filename: `${selectedCustomer.fullName
      } Project Items ${new Date().toLocaleDateString()}`,
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
  };

  // const csvExporter = new ExportToCsv(options);

  // function exportCSV() {

  //   console.log("export data", exportData);
  //   csvExporter.generateCsv(exportData);
  // }

  const csvConfig = mkConfig(options);


  function exportCSV() {
    const exportData = formatProjectItemReport(
      poParts,
      stockParts,
      returnParts,
      users,
      selectedCustomer
    );
    if (exportData && exportData?.length > 0) {
      let csv = generateCsv(csvConfig)(exportData);
      download(csvConfig)(csv);
    }
  }

  function exportReconciliation(items) {
    const exportData = formatDToolsReconciliationReport(items);
    if (exportData && exportData?.length > 0) {
      let csv = generateCsv(csvConfig)(exportData);
      download(csvConfig)(csv);
    }
  }

  const selectedItemUser =
    selectedItem && users.find((x) => x.id === selectedItem?.deliveredBy);
  let selectedItemUserName = null;
  selectedItemUserName =
    selectedItemUser &&
    selectedItemUser?.firstName + " " + selectedItemUser?.lastName;

  // console.log(dToolsProjectLocations)

  return (
    <View style={styles.container}>
      <View style={{ flexDirection: "row", justifyContent: "center" }}>
        <PrimaryText
          fontSize={16}
          fontWeight={600}
        // style={{ textAlign: "center" }}
        >
          Items
        </PrimaryText>
        <View style={{ flexDirection: "row", position: 'absolute', right: 0 }}>
          
          {isDTools && activeTab === 3 && (
            <TouchableOpacity
              // disabled={rightDisabled}
              onPress={getDToolsProjectItems}
              style={styles.refreshMenu}
            >
              <FaIcons.FaSyncAlt color={Colors.primary.white} size={20} />
            </TouchableOpacity>
          )}
          {isDTools && activeTab === 3 && (
            <TouchableOpacity
              // disabled={rightDisabled}
              onPress={() => reduceDToolsProjectItems(dToolsProjectItems, poParts, stockParts, returnParts, exportReconciliation)}
              style={styles.reconciliationButton}
            >
              <FaIcons.FaArrowAltCircleDown color={Colors.primary.white} size={20} />
              <PrimaryText
                fontSize={14}
                fontWeight={400}
                style={{ marginLeft: 6, color: Colors.primary.white }}
              >
                Reconciliation
              </PrimaryText>
            </TouchableOpacity>
          )}
          {activeTab !== 3 && (
            <TouchableOpacity
              // disabled={rightDisabled}
              onPress={() => exportCSV()}
              style={styles.exportMenu}
            >
              <FaIcons.FaFileDownload color={Colors.primary.white} size={20} />
            </TouchableOpacity>
          )}
        </View>
      </View>

      {selectedCustomer && true ? (
        <View style={styles.body}>
          {/** TABS */}
          <View style={styles.tabContainer}>
            <TouchableOpacity
              onPress={() => setActiveTab(0)}
              style={[styles.tabButton, activeTab === 0 && styles.activeTab]}
            >
              <PrimaryText
                fontSize={14}
                fontWeight={400}
                styles={activeTab !== 0 && { color: Colors.primary.white }}
              >
                Ordered
              </PrimaryText>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => setActiveTab(1)}
              style={[styles.tabButton, activeTab === 1 && styles.activeTab]}
            >
              <PrimaryText fontSize={14} fontWeight={400}>
                From Stock
              </PrimaryText>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => setActiveTab(2)}
              style={[styles.tabButton, activeTab === 2 && styles.activeTab]}
            >
              <PrimaryText fontSize={14} fontWeight={400}>
                Returns
              </PrimaryText>
            </TouchableOpacity>
            {isDTools && (
              <TouchableOpacity
                onPress={() => setActiveTab(3)}
                style={[styles.tabButton, activeTab === 3 && styles.activeTab]}
              >
                <PrimaryText fontSize={14} fontWeight={400}>
                  D-Tools Project
                </PrimaryText>
              </TouchableOpacity>
            )}
          </View>

          {/** CHART */}
          {activeTab === 0 && (
            <View style={{ flex: 1, width: "100%", paddingHorizontal: 12 }}>
              <View style={styles.detailLabelContainer}>
                <PrimaryText fontSize={12} fontWeight={400} style={{ flex: 1 }}>
                  Item
                </PrimaryText>
                <View
                  style={{
                    flexDirection: "row",
                    // flex: 1,
                    justifyContent: "space-between",
                  }}
                >

                  <View style={styles.detailLabel2}>
                    <PrimaryText
                      fontSize={12}
                      fontWeight={400}
                    // style={styles.detailLabel2}
                    >
                      Ordered
                    </PrimaryText>
                  </View>
                  <View style={styles.detailLabel2}>
                    <PrimaryText
                      fontSize={12}
                      fontWeight={400}
                    // style={styles.detailLabel2}
                    >
                      Received
                    </PrimaryText>
                  </View>
                  <View style={styles.detailLabel2}>
                    <PrimaryText
                      fontSize={12}
                      fontWeight={400}
                    // style={styles.detailLabel2}
                    >
                      Delivered
                    </PrimaryText>
                  </View>
                  <View style={styles.detailLabel2}>
                    <PrimaryText
                      fontSize={12}
                      fontWeight={400}
                    // style={styles.detailLabel2}
                    >
                      PO#
                    </PrimaryText>
                  </View>
                  <View style={{ width: width * 0.025 }}>
                    <PrimaryText
                      fontSize={12}
                      fontWeight={400}
                      style={[{ textAlign: "center" }]}
                    ></PrimaryText>
                  </View>
                  {currentUser.admin && (
                    <>
                      <View style={{ width: width * 0.025 }}>
                        <PrimaryText
                          fontSize={12}
                          fontWeight={400}
                          style={[{ textAlign: "center" }]}
                        ></PrimaryText>
                      </View>
                      <View style={{ width: width * 0.025 }}>
                        <PrimaryText
                          fontSize={12}
                          fontWeight={400}
                          style={[{ textAlign: "center" }]}
                        ></PrimaryText>
                      </View>
                    </>
                  )}
                </View>
              </View>
              <FlatList
                data={poParts}
                keyExtractor={(item) => item.id}
                renderItem={({ item, index }) => {
                  // if (!item.id) console.log('wtf', item)
                  return (
                    <PurchaseOrderItemCard
                      item={item}
                      onPress={() => setSelectedItem(item)}
                      admin={currentUser.admin}
                      onDelete={() => setShowConfirmModal(item)}
                      selectedCustomer={selectedCustomer}
                      onPrint={true}
                      onEdit={() => {
                        setShowEdit(true);
                        setSelectedItem(item);
                      }}
                      key={item.id}
                      open={true}
                    />
                  );
                }}
              />
            </View>
          )}
          {(activeTab === 1 || activeTab === 2) && (
            <View style={{ flex: 1, width: "100%", paddingHorizontal: 12 }}>
              <View style={styles.detailLabelContainer}>
                <PrimaryText fontSize={12} fontWeight={400} style={{ flex: 1 }}>
                  Item
                </PrimaryText>
                <View
                  style={{
                    flexDirection: "row",
                    flex: 1,
                    justifyContent: "flex-end",
                  }}
                >
                  <View style={styles.detailLabel2}>
                    <PrimaryText
                      fontSize={12}
                      fontWeight={400}
                    // style={styles.detailLabel2}
                    >
                      {activeTab === 1 && "Staged"}
                    </PrimaryText>
                  </View>
                  <View style={styles.detailLabel2}>
                    <PrimaryText
                      fontSize={12}
                      fontWeight={400}
                    // style={styles.detailLabel2}
                    >
                      {activeTab === 1 ? `Delivered` : "Returned"}
                    </PrimaryText>
                  </View>
                  <View style={[styles.detailLabel3, { width: width * 0.03 }]}>
                    <PrimaryText
                      fontSize={12}
                      fontWeight={400}
                      style={styles.detailLabel3}
                    ></PrimaryText>
                  </View>
                  {currentUser.admin && (
                    <View
                      style={[styles.detailLabel3, { width: width * 0.03 }]}
                    >
                      <PrimaryText fontSize={12} fontWeight={400}></PrimaryText>
                    </View>
                  )}
                </View>
              </View>
              <FlatList
                data={activeTab === 1 ? stockParts : returnParts}
                keyExtractor={(item) => item.id}
                renderItem={({ item, index }) => {
                  return (
                    <StockItemCard
                      key={item.id}
                      item={item}
                      onPress={() => setSelectedItem(item)}
                      onPrint={
                        activeTab === 1 && item?.deliveredQuantity < item?.qty
                      }
                      selectedCustomer={selectedCustomer}
                      admin={currentUser.admin && activeTab === 1}
                      onEdit={() => {
                        setShowEdit(true);
                        setSelectedItem(item);
                      }}
                    />
                  );
                }}
              />
            </View>
          )}
          {activeTab === 3 && (
            <>
              <PrimaryText
                fontSize={12}
                fontWeight={400}
                style={{ marginBottom: 4, marginTop: 12 }}
              >
                *To ensure the most up to date information, please ensure your
                D-Tools project is checked In and all updates from API have been
                completed.
              </PrimaryText>
              <ScrollView
                style={{
                  flex: 1,
                  width: "100%",
                  paddingHorizontal: 18,
                  marginTop: 12,
                }}
              >
                {/* <TableContainer> */}
                <Table
                  className="table"
                  style={{ marginHorizontal: 6 }}
                  stickyHeader={true}
                >
                  <TableHead
                    style={{
                      padding: 0,
                      backgroundColor: "#fff",
                      position: "sticky",
                      top: 0,
                    }}
                  >
                    <TableRow style={{ padding: 0 }}>
                      <TableCell
                        style={{
                          textAlign: "left",
                          position: "sticky",
                          top: 0,
                        }}
                      >
                        Item
                      </TableCell>
                      {/* <TableCell>CAUSES</TableCell> */}
                      <TableCell>Ordered</TableCell>
                      <TableCell>Received</TableCell>
                      <TableCell>Picked</TableCell>
                      <TableCell>Delivered</TableCell>
                      <TableCell>Returned</TableCell>
                      {/* <TableCell></TableCell> */}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {dToolsProjectItems &&
                      dToolsProjectItems.map((item, index) => {
                        return (
                          <DToolsProjectItemCard
                            item={item}
                            onPress={() => setSelectedItem(item)}
                            dToolsCustomFields={company?.dToolsCustomFields}
                          />
                        );
                      })}
                  </TableBody>
                </Table>
                {/* </TableContainer> */}
              </ScrollView>
            </>
          )}
        </View>
      ) : (
        <View style={styles.noCustomerContainer}>
          <PrimaryText fontSize={16} fontWeight={400}>
            {selectedCustomer
              ? "No time data available"
              : `Select a customer or project`}
          </PrimaryText>
        </View>
      )}

      {/** INFO MODAL */}
      {!!selectedItem && !showEdit && (
        <FullModal>
          <View style={styles.modalContent}>
            <ModalHeader
              title={selectedItem?.itemFullName}
              onClose={() => setSelectedItem(null)}
            />

            <View style={{ marginVertical: 12 }}>
              <PrimaryText
                fontSize={14}
                fontWeight={300}
                style={styles.description}
              >
                {!selectedItem.deliveredQuantity
                  ? "Item has not been checked out"
                  : `${(selectedItem.return || (selectedItem.quantity - selectedItem?.returnedQuantity <= 0)) ? "Returned On: " : "Delivered On: "
                  } ${selectedItem.deliveredDate.toDate().toDateString()}`}
              </PrimaryText>

              {selectedItem.stock && (
                <PrimaryText
                  fontSize={14}
                  fontWeight={300}
                  style={styles.description}
                >
                  {selectedItem.return
                    ? `Returned To:  ${selectedItem.to}`
                    : `From:  ${selectedItem.from}`}
                </PrimaryText>
              )}

              {!!selectedItem.deliveredQuantity && (
                <PrimaryText
                  fontSize={14}
                  fontWeight={300}
                  style={styles.description}
                >
                  By: {selectedItemUserName || ""}
                </PrimaryText>
              )}

              {!selectedItem.stock && (
                <PrimaryText
                  fontSize={14}
                  fontWeight={300}
                  style={[styles.description, { marginTop: 12 }]}
                >
                  {`Ordered On: ${selectedItem.timeCreated
                    .toDate()
                    .toDateString()}`}
                </PrimaryText>
              )}

              {isDTools && selectedItem?.requestedDeliveryDate && (
                <PrimaryText
                  fontSize={14}
                  fontWeight={300}
                  style={[styles.description, { marginTop: 12 }]}
                >
                  {`Requested Delivery Date: ${selectedItem?.requestedDeliveryDate
                    .toDate()
                    .toDateString()}`}
                </PrimaryText>
              )}

              {isDTools && selectedItem?.estimatedDeliveryDate && (
                <PrimaryText
                  fontSize={14}
                  fontWeight={300}
                  style={[styles.description, { marginTop: 12 }]}
                >
                  {`Estimated Delivery Date: ${selectedItem?.estimatedDeliveryDate
                    .toDate()
                    .toDateString()}`}
                </PrimaryText>
              )}

              {!selectedItem.stock &&
                selectedItem.receivedQuantity !== selectedItem.quantity && (
                  <TouchableOpacity
                    onPress={() =>
                      setAlert(
                        company.id,
                        selectedItem.id,
                        currentUser.id,
                        !selectedItemAlert,
                        setSelectedItemAlert
                      )
                    }
                    style={{ flexDirection: "row", alignItems: "center" }}
                  >
                    <PrimaryText
                      fontSize={14}
                      fontWeight={300}
                      style={[styles.description, { marginTop: 12 }]}
                    >
                      {`Get notified when this item is received`}
                    </PrimaryText>
                    {selectedItemAlert ? (
                      <MdIcons.MdOutlineCheckBox
                        name={
                          selectedItemAlert
                            ? "checkbox-marked-outline"
                            : "checkbox-blank-outline"
                        }
                        color={Colors.primary.darkGray}
                        size={24}
                      />
                    ) : (
                      <MdIcons.MdCheckBoxOutlineBlank
                        name={
                          selectedItemAlert
                            ? "checkbox-marked-outline"
                            : "checkbox-blank-outline"
                        }
                        color={Colors.primary.darkGray}
                        size={24}
                      />
                    )}
                  </TouchableOpacity>
                )}
            </View>
          </View>
        </FullModal>
      )}

      {/** EDIT MODAL */}
      {!!selectedItem && showEdit && (
        <FullModal>
          <View style={styles.modalContent}>
            <ModalHeader
              title={selectedItem?.itemFullName}
              onClose={() => {
                setShowEdit(false);
                setSelectedItem(null);
                setNewCustomer(null);
                setNewLocation(null);
                setReturnToStock(false);
              }}
            />

            <View style={{ marginVertical: 12 }}>
              <PrimaryText
                fontSize={14}
                fontWeight={300}
                style={styles.description}
              >
                {!selectedItem.deliveredQuantity
                  ? "Item has not been delivered"
                  : `${selectedItem.return ? "Returned On: " : "Delivered On: "
                  } ${selectedItem.deliveredDate.toDate().toDateString()}`}
              </PrimaryText>

              {!selectedItem.stock && (
                <PrimaryText
                  fontSize={14}
                  fontWeight={300}
                  style={[styles.description, { marginTop: 12 }]}
                >
                  {`Ordered On: ${selectedItem.timeCreated
                    .toDate()
                    .toDateString()}`}
                </PrimaryText>
              )}

              <TouchableOpacity
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  borderBottomWidth: 1,
                  borderColor: Colors.primary.gray,
                }}
                onPress={() => setEditCustomer(!editCustomer)}
              >
                <PrimaryText
                  fontSize={14}
                  fontWeight={300}
                  style={[styles.description, { marginTop: 12 }]}
                >
                  {`Customer/Job: ${newCustomer
                    ? newCustomer?.fullName || newCustomer?.name
                    : selectedCustomer?.fullName || selectedCustomer?.name
                    }`}
                </PrimaryText>
                <FaIcons.FaCaretRight />
              </TouchableOpacity>

              {editCustomer ? (
                <>
                  <CustomInput
                    placeholder={"Search Customers & Jobs"}
                    style={{ marginHorizontal: 12, height: 30, marginTop: 12 }}
                    onChange={setSearch}
                    value={search}
                  />

                  {!!search && (
                    <View style={styles.searchedContainer}>
                      <FlatList
                        contentContainerStyle={{
                          flexGrow: 1,
                          backgroundColor: Colors.primary.white,
                          width: 400,
                          alignSelf: "center",
                        }}
                        data={filtered}
                        keyExtractor={(item) => item.id}
                        renderItem={({ item, index }) => {
                          return (
                            <CustomerCard
                              onPress={() => customerSelected(item)}
                              item={item}
                              small
                            />
                          );
                        }}
                      />
                    </View>
                  )}
                </>
              ) : /** RETURN TO STOCK BUTTON */
                selectedItem.stock ||
                  (!selectedItem.stock && selectedItem.receivedQuantity) ? (
                  <>
                    <TouchableOpacity
                      style={{
                        marginHorizontal: 12,
                        marginVertical: 12,
                        backgroundColor: Colors.primary.green,
                        padding: 5,
                        alignSelf: "center",
                        borderRadius: 5,
                      }}
                      onPress={() => {
                        setNewLocation(null);
                        setReturnToStock(!returnToStock);
                      }}
                    >
                      <PrimaryText
                        fontSize={14}
                        fontWeight={300}
                        style={{ color: Colors.primary.white }}
                      >
                        {`Return To ${newLocation ? newLocation.name : "Stock"}`}
                      </PrimaryText>
                    </TouchableOpacity>
                    {returnToStock &&
                      (!newLocation ? (
                        <View style={styles.searchedContainer}>
                          <FlatList
                            contentContainerStyle={{
                              flexGrow: 1,
                              backgroundColor: Colors.primary.white,
                              width: 400,
                              alignSelf: "center",
                            }}
                            data={locations.filter((x) => x.active)}
                            keyExtractor={(item) => item.id}
                            renderItem={({ item, index }) => {
                              return (
                                <LocationExpandableCard
                                  onPress={() => setNewLocation(item)}
                                  item={item}
                                  small
                                />
                              );
                            }}
                          />
                        </View>
                      ) : (
                        <View
                          style={{ flexDirection: "row", alignItems: "center" }}
                        >
                          <PrimaryText
                            fontSize={14}
                            fontWeight={300}
                            style={{ color: Colors.primary.darkGray, flex: 1 }}
                          >
                            Quantity To Return
                          </PrimaryText>
                          <EditItemQtyByLocationCard
                            item={selectedItem}
                            useQuantity={1}
                            onQuantityChanged={setQuantityToReturn}
                            maxQuantity={
                              selectedItem.receivedQuantity
                                ? selectedItem.receivedQuantity -
                                (selectedItem?.deliveredQuantity || 0)
                                : selectedItem.qty -
                                (selectedItem?.deliveredQuantity || 0)
                            }
                          />
                        </View>
                      ))}
                  </>
                ) : (
                  <PrimaryText
                    fontSize={12}
                    fontWeight={300}
                    style={{
                      color: Colors.primary.darkGray,
                      marginTop: 24,
                      textAlign: "center",
                    }}
                  >
                    *Only received items can be returned to stock. Please edit
                    your Purchase Order to change this to a stock item.
                  </PrimaryText>
                )}

              {(newCustomer || newLocation) && (
                <TouchableOpacity
                  style={{
                    marginHorizontal: 12,
                    marginTop: 12,
                    backgroundColor: Colors.primary.lightBlue,
                    padding: 5,
                    alignSelf: "center",
                    borderRadius: 5,
                  }}
                  onPress={newLocation ? returnToStockPressed : saveNewCustomer}
                >
                  <PrimaryText
                    fontSize={14}
                    fontWeight={300}
                    style={{ color: Colors.primary.white }}
                  >
                    {newLocation ? "Return" : "Save"}
                  </PrimaryText>
                </TouchableOpacity>
              )}
            </View>
          </View>
        </FullModal>
      )}

      {!!showConfirmModal && (
        <ConfirmModal
          title={"Delete PO Line Item"}
          message={`Are you sure? This item can be recovered by making a change to the PO in ${isDTools ? "D-Tools" : "QuickBooks"
            }.`}
          onConfirm={() => deleteItem(showConfirmModal)}
          onCancel={() => setShowConfirmModal(false)}
        />
      )}

      {/** CONFIRM APPROVAL CHANGE */}
      {showReturnConfirmation && (
        <ConfirmModal
          title={'Return Item?'}
          message={`Are you sure you want to return this item to ${newLocation?.name}?`}
          onConfirm={returnToStockConfirmed}
          onCancel={() => setShowReturnConfirmation(false)}
        />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
    backgroundColor: Colors.primary.extremelyLightGray,
    paddingTop: 12,
    marginLeft: 4,
    marginTop: 4,
    borderRadius: 12,
    borderColor: Colors.primary.lightGray,
    borderWidth: 1,
  },
  exportMenu: {
    // position: "absolute",
    // right: 24,
    backgroundColor: Colors.primary.blue,
    borderRadius: 4,
    justifyContent: "center",
    alignItems: "center",
    height: 30,
    width: 30,
    marginRight: 6,
  },
  refreshMenu: {
    backgroundColor: Colors.primary.gray,
    borderRadius: 4,
    justifyContent: "center",
    alignItems: "center",
    height: 30,
    width: 30,
    marginRight: 6,
  },
  reconciliationButton: {
    backgroundColor: Colors.primary.green,
    flexDirection: "row",
    borderRadius: 4,
    justifyContent: "center",
    alignItems: "center",
    height: 30,
    // width: 100,
    marginRight: 6,
    paddingHorizontal: 6,
  },

  noCustomerContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  body: {
    flex: 1,
    backgroundColor: Colors.primary.extremelyLightGray,
    marginTop: 12,
    alignItems: "center",
  },
  tabContainer: {
    flexDirection: "row",
    justifyContent: "space-around",
    backgroundColor: Colors.primary.lightGray,
    borderRadius: 4,
    padding: 4,
    marginTop: 4,
    width: "80%",
  },
  tabButton: {
    alignItems: "center",
    justifyContent: "center",
    // width: "32.5%",
    flex: 1,
    borderRadius: 4,
    marginHorizontal: 2,
    padding: 2,
    // width: width*0.15-12,
    backgroundColor: Colors.primary.lightGray,
  },
  activeTab: {
    backgroundColor: Colors.primary.white,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,

    elevation: 5,
  },

  //details
  detailLabelContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginHorizontal: 6,
    paddingHorizontal: 12,
    marginVertical: 12,
    backgroundColor: Colors.primary.lightGray,
  },
  detailLabel: {
    width: width * 0.08,
    textAlign: "right",
  },
  detailLabel2: {
    width: width * 0.04,
    // textAlign: "right",
    alignItems: "center",
    // backgroundColor:'red',
    // marginHorizontal: 1
  },
  detailLabel3: {
    width: width * 0.08,
    textAlign: "right",
  },

  //modal
  modalContent: {
    // height: height*0.6,
    width: 400,
    backgroundColor: Colors.primary.white,
    borderRadius: 12,
    padding: 12,
    // alignItems: 'center'
  },
  modalSwipeButton: {
    width: 32,
    height: 3,
    backgroundColor: Colors.primary.darkGray,
    borderRadius: 3,
    marginBottom: 12,
    alignSelf: "center",
  },
  description: {
    marginHorizontal: 12,
    marginVertical: 4,
  },
  searchedContainer: {
    width: 400,

    height: height * 0.2,
    zIndex: 999,
  },
  selectedCustomer: {
    flexDirection: "row",
    backgroundColor: Colors.primary.lightGray,
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: 16,
    paddingHorizontal: 12,
    marginHorizontal: 12,
    marginTop: 12,
    height: 30,
  },
});
