import React, { useEffect, useState } from 'react';
import {StyleSheet, View, Dimensions, TextInput, TouchableOpacity} from 'react-native';
import {Colors} from '~/constants/Colors';
import { PrimaryText } from '~/components/Common/PrimaryText';
import * as FaIcons from "react-icons/fa";
import * as BsIcons from "react-icons/bs";
import { useParts } from 'context/PartsContext';
import { getItemNameFromManufacturer } from 'utils/helpers';
// import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';


const {height, width} = Dimensions.get('window')


export const ItemCheckoutCard = ({
  item,
  index,
  onQuantityChanged,
  removeItem
}) => {

  const {manufacturers} = useParts()

  const [qty, setQty] = useState(item.qty)

  useEffect(() => {
    setQty(item.qty)
  }, [item])

  function subtract() {
    if (qty>0) {
      item.qty--
      setQty(qty-1)
    }
    onQuantityChanged(item, index)
  }

  function add() {
    item.qty++
    setQty(qty+1)
    onQuantityChanged(item, index)
  }

  function quantityChanged(text) {
    const trimmed = text.replace(/\D/g,'')
    item.qty = Number(trimmed)
    setQty(Number(trimmed))
    onQuantityChanged(item, index)
  }

  let style = styles(false)

  return (
    <View style={style.card}>
      <View style={{flexDirection: 'row', alignItems: 'center'}}>
      <TouchableOpacity onPress={removeItem} style={{marginRight: 12}}>
          <FaIcons.FaWindowClose color={Colors.primary.gray} size={20} style={{marginLeft: 20}} />
      </TouchableOpacity>
      <PrimaryText fontSize={14} fontWeight={400}>{getItemNameFromManufacturer(item, manufacturers)}</PrimaryText>
      </View>
      <View style={style.quantityContainer}>
        <TouchableOpacity onPress={subtract}>
          <FaIcons.FaMinus color={Colors.primary.red} size={20}  />
        </TouchableOpacity>
        <TextInput 
            value={qty.toString()}
            onChangeText={(text) => quantityChanged(text)}
            style={{height: 23, width: width*0.12, textAlign: 'center'}}
            keyboardType={'number-pad'}
        />
        <TouchableOpacity onPress={add}>
          <FaIcons.FaPlus color={Colors.primary.red} size={20} />
        </TouchableOpacity>
        
      </View>
    </View>
  )
}


export const styles = darkMode => StyleSheet.create({
  card: {
    marginHorizontal: 12,
    paddingVertical: 8,
    flexDirection: 'row',
    alignItems:'center',
    justifyContent:'space-between',
    borderColor: Colors.primary.lightGray,
    borderBottomWidth: 1,
    backgroundColor: Colors.primary.white
  },
  quantityContainer: {
    flexDirection: 'row',
    alignItems:'center'
  }

});
