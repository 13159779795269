import React, { useEffect, useState } from "react";
import {
  Dimensions,
  FlatList,
  StyleSheet,
  TouchableOpacity,
  View,
} from "react-native-web";
import * as MaterialCommunityIcons from "react-icons/md";

import { Header } from "components/Header";
import { Colors } from "~/constants/Colors";
import { ProjectItems } from "./ProjectItems";
import { ProjectTime } from "./ProjectTime";
import { useCustomers } from "context/CustomersContext";
import { CustomInput } from "components/Inputs/CustomInput";
import Select from "react-select";

import { CustomerCard } from "components/Cards/CustomerCard";
import { PrimaryText } from "components/Common/PrimaryText";
import { useCompany } from "context/CompanyContext";
import { Fonts } from "constants/Fonts";
import { updateCustomer } from "services/customers-service";
import { useAuth } from "context/AuthContext";

const { width, height } = Dimensions.get("window");

const defaultStaging = { value: null, label: "Default" };

export function Projects() {
  const { customers, selectedCustomer, setSelectedCustomer } = useCustomers();
  const { stagingAreaSubLocations, company } = useCompany();
  const { currentUser } = useAuth();

  const [stagingAreaOptions, setStagingAreaOptions] = useState([]);
  const [selectedStagingArea, setSelectedStagingArea] = useState(null);
  const [search, setSearch] = useState(null);
  const [filtered, setFiltered] = useState(null);

  useEffect(() => {
    if (stagingAreaSubLocations) {
      let options = stagingAreaSubLocations.map((x) => {
        return { value: x.id, label: x.name };
      });
      options.unshift(defaultStaging);
      setStagingAreaOptions(options);
    } else {
      setStagingAreaOptions([defaultStaging]);
    }
  }, [stagingAreaSubLocations]);

  useEffect(() => {
    if (
      selectedCustomer &&
      selectedCustomer?.stagingArea &&
      stagingAreaOptions
    ) {
      let find = stagingAreaOptions.find(
        (x) => x.value === selectedCustomer?.stagingArea
      );
      setSelectedStagingArea(find ? find : defaultStaging);
    } else {
      setSelectedStagingArea(defaultStaging);
    }
  }, [selectedCustomer, stagingAreaOptions]);

  useEffect(() => {
    if (selectedStagingArea && selectedCustomer) {
      // updateCustomer(company, selectedCustomer, {stagingArea: selectedStagingArea?.value})
    }
  }, [selectedStagingArea]);

  //on search change
  useEffect(() => {
    if (search) {
      const tempSearch = search.toLowerCase();
      const temp = customers.filter((x) =>
        x.lowercaseFullName.includes(tempSearch)
      );
      setFiltered(temp);
    } else {
      setFiltered(null);
    }
  }, [search]);

  const customerSelected = (item) => {
    //search if there are children
    const hasChildren = customers.find((x) => x.parent_id === item.id);

    if (!hasChildren) {
      setSelectedCustomer(item);
      setSearch(null);
    } else {
      //   setSearch(item.fullName)
      const temp = customers.filter((x) => x.parent_id === item.id);
      setFiltered(temp);
    }
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: "1px solid lightgray",
      color: state.isSelected ? Colors.primary.white : Colors.primary.darkGray,
      paddingHorizontal: 12,
      fontFamily: Fonts.primary.regular,
      fontSize: 14,
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      width: 115,
    }),
    singleValue: (styles) => ({
      ...styles,
      color: Colors.primary.darkGray,
      fontFamily: Fonts.primary.regular,
      fontSize: 14,
    }),
  };
  
  const { viewHours = true } = currentUser;

  return (
    <View style={styles.container}>
      <Header
        title={`Project Tracking`}
        // onBack={() => navigation.goBack()}
      />

      <View style={{ zIndex: 999 }}>
        {selectedCustomer ? (
          <View style={styles.selectedCustomer}>
            <PrimaryText fontSize={16} fontWeight={400}>
              {selectedCustomer.fullName || selectedCustomer.name}
            </PrimaryText>
            <TouchableOpacity
              onPress={() => setSelectedCustomer(null)}
              style={styles.closeContainer}
            >
              <MaterialCommunityIcons.MdOutlineClose
                size={24}
                color={Colors.primary.gray}
              />
            </TouchableOpacity>
          </View>
        ) : (
          <CustomInput
            placeholder={"Search Customers & Jobs"}
            style={{ marginHorizontal: 12, height: 40 }}
            onChange={setSearch}
            value={search}
          />
        )}

        {!!search && (
          <View style={styles.searchedContainer}>
            <FlatList
              contentContainerStyle={{
                flexGrow: 1,
                backgroundColor: Colors.primary.white,
                width: width - 12,
                alignSelf: "center",
              }}
              data={filtered}
              keyExtractor={(item) => item.id}
              renderItem={({ item, index }) => {
                return (
                  <CustomerCard
                    onPress={() => customerSelected(item)}
                    item={item}
                  />
                );
              }}
            />
          </View>
        )}
      </View>
      {selectedCustomer && (
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            marginTop: 4,
            justifyContent: "flex-end",
            zIndex: 999,
          }}
        >
          <PrimaryText fontSize={16} fontWeight={400}>
            {"Staging Area sub-location:   "}
          </PrimaryText>
          <Select
            styles={customStyles}
            value={selectedStagingArea}
            onChange={(value) => {
              selectedCustomer.stagingArea = value.value;
              setSelectedStagingArea(value);
              updateCustomer(company, selectedCustomer, {
                stagingArea: value?.value,
              });
            }}
            options={stagingAreaOptions}
          />
        </View>
      )}

      <View style={styles.body}>
        {selectedCustomer ? (
          <>
            {viewHours && <ProjectTime />}
            <ProjectItems />
          </>
        ) : (
          <View style={styles.noCustomerContainer}>
            <PrimaryText fontSize={16} fontWeight={400}>
              {`Select a customer or project`}
            </PrimaryText>
          </View>
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: "100%",
    backgroundColor: Colors.primary.white,
  },
  body: {
    flex: 1,
    width: "100%",
    flexDirection: "row",
    backgroundColor: Colors.primary.white,
  },
  searchedContainer: {
    width: width,
    height: height * 0.5,
    position: "absolute",
    left: 0,
    right: 0,
    top: 40,
    zIndex: 999,
  },
  noCustomerContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },

  selectedCustomer: {
    flexDirection: "row",
    backgroundColor: Colors.primary.lightGray,
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: 16,
    paddingHorizontal: 12,
    marginHorizontal: 12,
    height: 40,
  },
});
