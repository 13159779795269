import React, { useState, createContext, useEffect, useContext } from "react";
import { getUserHistory } from "~/services/user-service";
import { useParts } from "./PartsContext";
import { useAuth } from "./AuthContext";
import { useCustomers } from "./CustomersContext";
import { useCompany } from "./CompanyContext";

export const UserContext = createContext();

export const UserProvider = ({ children }) => {

  const {currentUser} = useAuth()
  const {company, locations } = useCompany()
  const {parts, manufacturers} = useParts()
  const {customers} = useCustomers()

  const [history, setHistory] = useState(null)
  const [getHistory, setGetHistory] = useState(false)

  useEffect(() => {
    if (currentUser && parts && locations && customers && manufacturers) {
      setTimeout(() => {
        setGetHistory(true)
      }, 250);
      
    } else {
      setGetHistory(false)
    }
  }, [currentUser, parts, locations, customers, manufacturers])

  useEffect(() => {
    if (!getHistory) return

    const subscriber = getUserHistory(company, {id: 'all'}).limit(30)
    .onSnapshot(querySnapshot => {
        const temp = []
        querySnapshot && querySnapshot.forEach(doc => {
            temp.push({
                id: doc.id,
                ...doc.data()
            })
        })
        gotHistory(temp)
    })
    return () => subscriber && subscriber()

  }, [getHistory])

  const gotHistory = (docs) => {
    docs.forEach(doc => {
      const part = parts.find(x => x.id===doc.partID)
      if (part) {
        doc.manufacturer = part.manufacturer
        doc.model = part.model
        if (part.parentRefID) {
          const parent = manufacturers.find(x => x.id ===part.parentRefID)
          doc.manufacturer = parent ? parent.model : ''
          doc.lowercaseMfg = parent ? parent.model.toLowerCase() : ''
          part.manufacturer = parent ? parent.model : ''
          part.lowercaseMfg = parent ? parent.model.toLowerCase() : ''
        }
      }



      if (doc.fromClientId) {
        const from = customers.find(x => x.id===doc.fromClientId)
        if (from) {
          doc.from = from.fullName
        }
      } else if (doc.fromLocationId) {
        const from = locations.find(x => x.id===doc.fromLocationId)
        if (from) {
          doc.from = from?.name
        }
      }

      if (doc.toClientId) {
        const from = customers.find(x => x.id===doc?.toClientId)
        if (from) {
          doc.to = from.fullName
        }
      } else if (doc.toLocationId) {
        const from = locations.find(x => x.id===doc?.toLocationId)
        if (from) {
          doc.to = from?.name
        }
      }

      
    })
    setHistory(docs)
    // setFiltered(docs)
    // setLoading(false)
  }

  return (
    <UserContext.Provider value={{
      history, setHistory,
      getHistory, setGetHistory
      }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext)
