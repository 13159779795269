import React, { memo } from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';
import { Colors } from '~/constants/Colors';
import { PrimaryText } from '~/components/Common/PrimaryText';


const Card = ({
  item,
  onPress,
  qty,
  name
}) => {

  let style = styles(false)
  
  return (
    <TouchableOpacity style={style.card} onPress={onPress}>
      <PrimaryText fontSize={16} fontWeight={400} style={style.text}>{name}</PrimaryText>
      <PrimaryText fontSize={16} fontWeight={400} style={style.text}>Qty: {qty}</PrimaryText>
    </TouchableOpacity>
  )
}

export const ItemByLocationCard = memo(Card)

const styles = darkMode => StyleSheet.create({
  card: {
    marginHorizontal: 12,
    paddingVertical: 8,
    flexDirection: 'row',
    alignItems:'center',
    justifyContent:'space-between',
    borderColor: Colors.primary.lightGray,
    borderBottomWidth: 1
  }

});
