import React, { useState, createContext, useEffect } from "react";
import { useAuth } from "./AuthContext";
import { useCompany } from "./CompanyContext";

export const NotificationsContext = createContext();

export const NotificationsProvider = ({ children, navigationRef }) => {

  const {currentUser} = useAuth()
  const {company} = useCompany()

  const [loaded, setLoaded] = useState(false)
  const [notifications, setNotifications] = useState(null)

  useEffect(() => {
    if (currentUser && company && !loaded) {
      setLoaded(true)
    }
  
  }, [currentUser, company])

  return (
    <NotificationsContext.Provider value={{ 
        notifications, setNotifications
    }}>
      {children}
    </NotificationsContext.Provider>
  );
};
