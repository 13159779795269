import { dollarFormatter } from "context/CompanyContext";
import { getItemName, getUserName } from "utils/helpers";

export function formatMinMaxReport(data) {
  const temp = [];
  data &&
    data.forEach((item) => {
      const obj = {
        Item: getItemName(item),
        Location: item.locationName,
        Quantity: item.locationQty,
        MinimumQuantity: item.minStockQty,
        MaximumQuantity: item.maxStockQty,
        OnOrder: item.onOrderQty,
      };
      if (item.onOrderQty === undefined) delete obj.OnOrder;
      temp.push(obj);
    });

  return temp;
}

export function formatApprovalsReport(data, users, selectedFilter) {
  const temp = [];
  data &&
    data.forEach((item) => {
      if (selectedFilter && selectedFilter.value !== "all") {
        if (!item.approved && selectedFilter.value === "approved") return;
        if (item.approved && selectedFilter.value === "unapproved") return;
      }
      if (selectedFilter && item.staging) return;
      if (selectedFilter && item.manualAdjustment) return;

      temp.push({
        Date: item.date.toDate().toLocaleDateString(),
        Item: getItemName(item),
        Quantity: item.qty,
        From: item.from,
        To: item.to,
        By: getUserName(item.user, users),
        Approved: item.approved,
        Rejected: item.rejected,
      });
    });

  return temp;
}

export function formatInventoryByLocationReport(location, data) {
  const temp = [];
  data &&
    data.forEach((item) => {
      const tempItem = {
        Location: location?.name,
        SubLocation:
          item?.subLocation && location.subLocations
            ? location.subLocations.find((x) => x.id === item?.subLocation)
                ?.name
            : "",
        Item: getItemName(item),
        Quantity: location?.name === "All" ? item.qty : item.locationQty,
        TotalValue: dollarFormatter.format(
          item.cost * (location?.name === "All" ? item.qty : item.locationQty)
        ),
        MinimumQuantity: item.minStockQty,
        MaximumQuantity: item.maxStockQty,
      };
      if (location?.name === "All") {
        delete tempItem.MaximumQuantity;
        delete tempItem.MinimumQuantity;
        tempItem.LastUsed = new Date(item.lastModified).toLocaleDateString();
      }
      temp.push(tempItem);
    });

  return temp;
}

export function formatAuditReport(location, data) {
  const temp = [];
  data &&
    data.forEach((item) => {
      const tempItem = {
        Approved: item.approved ? "Y" : "N",
        Item: getItemName(item),
        OriginalQuantity: item.locationQty,
        NewQuantity: item?.newQty !== undefined ? item.newQty : "",
        Adjustment:
          item?.newQty !== undefined ? item.newQty - item?.locationQty : "",
        Counted: item?.newQty !== undefined ? "Y" : "N",
        Notes: "",
      };
      temp.push(tempItem);
    });

  return temp;
}

export function formatStagingAreaReport(data, users, stagingAreaSubLocations) {
  const temp = [];
  data &&
    data.forEach((item) => {
      const DeliveredQuantity = item?.deliveredQuantity
        ? item.deliveredQuantity
        : 0;
      const quantityOnHand =
        item.receivedQuantity - DeliveredQuantity > 0
          ? item.receivedQuantity - DeliveredQuantity
          : 0;
      let uomUnits = item?.uomUnits || 1;
      const cost = item.cost / uomUnits;

      const exportData = {
        FromStock: item.fromStock ? "TRUE" : "FALSE",
        Item: item.itemFullName,
        Customer: item.customerFullName,
        Staging: item?.stagingArea
          ? stagingAreaSubLocations?.find((x) => x.id === item?.stagingArea)
              ?.name
          : "Default",
        Quantity: item.quantity,
        ReceivedQuantity: item.receivedQuantity,
        DeliveredQuantity,
        StagedQuantity: quantityOnHand,
        TotalCost: dollarFormatter.format(cost * quantityOnHand),
        LastDeliveredDate: item.deliveredDate
          ? item.deliveredDate.toDate
            ? item.deliveredDate.toDate().toLocaleDateString()
            : new Date(item.deliveredDate)
          : "",
        LastDeliveredBy: item.deliveredBy
          ? getUserName(item.deliveredBy, users)
          : "",
      };

      temp.push(exportData);
    });

  return temp;
}

export function formatDeliveryReport(data, users) {
  const temp = [];
  data &&
    data.forEach((item) => {
      temp.push({
        FromStock: item.fromStock ? "TRUE" : "FALSE",
        Item: item.itemFullName,
        Customer: item.customerFullName,
        DeliveredQuantity: item.deliveredQuantity,
        DeliveredDate: item.deliveredDate.toDate().toLocaleDateString(),
        By: item.userName,
        Cost: item?.cost || 0,
        TotalCost: item?.cost * item.deliveredQuantity || 0,
        Price: item?.price || 0,
        TotalPrice: item?.price * item.deliveredQuantity || 0,
      });
    });

  return temp;
}

export function formatOpenPurchaseOrderReport(data) {
  const temp = [];
  data &&
    data.forEach((item) => {
      const {
        returnedQuantity = 0,
        quantity = 0,
        receivedQuantity = 0,
        deliveredQuantity = 0,
        itemFullName,
        customerFullName = "",
        poNumber = "",
      } = item
      temp.push({
        Item: itemFullName,
        Customer: customerFullName,
        OrderedQuantity: quantity-returnedQuantity,
        ReceivedQuantity: receivedQuantity-returnedQuantity,
        DeliveredQuantity: deliveredQuantity-returnedQuantity,
        PO: poNumber,
      });
    });

  return temp;
}

export function formatReturnedReport(data, users) {
  const temp = [];
  data &&
    data.forEach((item) => {
      temp.push({
        Item: item.itemFullName,
        Customer: item.customerFullName,
        ReturnedTo: item.to,
        ReturnedQuantity: item.deliveredQuantity,
        ReturnDate: item.deliveredDate.toDate().toLocaleDateString(),
        By: item.userName,
        Cost: item?.cost || 0,
        TotalCost: item?.cost * item.deliveredQuantity || 0,
        Price: item?.price || 0,
        TotalPrice: item?.price * item.deliveredQuantity || 0,
      });
    });

  return temp;
}

export function formatAgingItemsReport(data) {
  const temp = [];
  data &&
    data.forEach((item) => {
      temp.push({
        Item: getItemName(item),
        Quantity: item.qty,
        LastUsed: new Date(item.lastModified).toLocaleDateString(),
      });
    });

  return temp;
}

export function formatInventoryValueByLocationReport(data) {
  const temp = [];
  data &&
    data.forEach((item) => {
      temp.push({
        Location: item?.name,
        TotalValue: dollarFormatter.format(item.totalValue),
      });
    });

  return temp;
}

export function formatMostValuableItemsReport(data) {
  const temp = [];
  data &&
    data.forEach((item) => {
      temp.push({
        Item: getItemName(item),
        Quantity: item.qty,
        Cost: item.cost,
        TotalValue: dollarFormatter.format(item.cost * item.qty),
        LastUsed: new Date(item.lastModified).toLocaleDateString(),
      });
    });

  return temp;
}

/** PROJECTS */
export function formatProjectItemReport(
  poParts,
  stockParts,
  returnParts,
  users,
  selectedCustomer
) {
  const temp = [];
  temp.push({
    Col1: "Ordered Items",
    Col2: "",
    Col3: "",
    Col4: "",
    Col5: "",
    Col6: "",
    Col7: "",
  });
  temp.push({
    Col1: "Customer",
    Col2: "Item",
    Col3: "Ordered",
    Col4: "Received",
    Col5: "Delivered",
    Col6: "LastDeliveredDate",
    Col7: "LastDeliveredBy",
  });
  poParts &&
    poParts.forEach((item) => {
      let returnedQuantity = item.returnedQuantity ? item.returnedQuantity : 0;
      temp.push({
        Col1: selectedCustomer.fullName,
        Col2: item.itemFullName,
        Col3: item.quantity - returnedQuantity,
        Col4: item.receivedQuantity - returnedQuantity,
        Col5: (item.deliveredQuantity ? item.deliveredQuantity : 0) - returnedQuantity,
        Col6:
          item.deliveredDate && item.deliveredQuantity
            ? item.deliveredDate.toDate().toLocaleDateString()
            : "",
        Col7:
          item.deliveredBy && item.deliveredQuantity
            ? getUserName(item.deliveredBy, users)
            : "",
      });
    });

  temp.push({
    Col1: "",
    Col2: "",
    Col3: "",
    Col4: "",
    Col5: "",
    Col6: "",
    Col7: "",
  });
  temp.push({
    Col1: "Stock Items",
    Col2: "",
    Col3: "",
    Col4: "",
    Col5: "",
    Col6: "",
    Col7: "",
  });
  temp.push({
    Col1: "Customer",
    Col2: "Item",
    Col3: "Quantity",
    Col4: "",
    Col5: "DeliveredQuantity",
    Col6: "Date",
    Col7: "By",
  });
  stockParts &&
    stockParts.forEach((item) => {
      temp.push({
        Col1: selectedCustomer.fullName,
        Col2: item.itemFullName,
        Col3: item.qty,
        Col4: "",
        Col5: item.deliveredQuantity ? item.deliveredQuantity : 0,
        Col6:
          item.deliveredDate && item.deliveredQuantity
            ? item.deliveredDate.toDate().toLocaleDateString()
            : "",
        Col7:
          item.deliveredBy && item.deliveredQuantity
            ? getUserName(item.deliveredBy, users)
            : "",
      });
    });

  temp.push({
    Col1: "",
    Col2: "",
    Col3: "",
    Col4: "",
    Col5: "",
    Col6: "",
    Col7: "",
  });
  temp.push({
    Col1: "Returned Items",
    Col2: "",
    Col3: "",
    Col4: "",
    Col5: "",
    Col6: "",
    Col7: "",
  });
  temp.push({
    Col1: "Customer",
    Col2: "Item",
    Col3: "",
    Col4: "",
    Col5: "ReturnedQuantity",
    Col6: "Date",
    Col7: "By",
  });
  returnParts &&
    returnParts.forEach((item) => {
      temp.push({
        Col1: selectedCustomer.fullName,
        Col2: item.itemFullName,
        Col3: "",
        Col4: "",
        Col5: item.deliveredQuantity,
        Col6:
          item.deliveredDate && item.deliveredQuantity
            ? item.deliveredDate.toDate().toLocaleDateString()
            : "",
        Col7:
          item.deliveredBy && item.deliveredQuantity
            ? getUserName(item.deliveredBy, users)
            : "",
      });
    });

  return temp;
}

export function formatDToolsReconciliationReport(
  dToolsProjectItems,
) {
  const temp = [];

  temp.push({
    Item: "",
    Quantity: "",
    Ordered: "",
    Stock: "",
    Delivered: "",
    Returned: "",
  });

  temp.push({
    Item: "Items in D-Tools Project",
    Quantity: "",
    Ordered: "",
    Stock: "",
    Delivered: "",
    Returned: "",
  });

  temp.push({
    Item: "",
    Quantity: "",
    Ordered: "",
    Stock: "",
    Delivered: "",
    Returned: "",
  });

  dToolsProjectItems &&
  dToolsProjectItems.filter(x => x.found).forEach((item) => {
      temp.push({
        Item: item.Manufacturer + ": " + item.Model,
        Quantity: item.Quantity,
        Ordered: item.ordered,
        Stock: item.stock,
        Delivered: item.delivered || "",
        Returned: item.returned,
      });
    });

    temp.push({
      Item: "",
      Quantity: "",
      Ordered: "",
      Stock: "",
      Delivered: "",
      Returned: "",
    });

    temp.push({
      Item: "",
      Quantity: "",
      Ordered: "",
      Stock: "",
      Delivered: "",
      Returned: "",
    });

    temp.push({
      Item: "Items not in D-Tools Project",
      Quantity: "",
      Ordered: "",
      Stock: "",
      Delivered: "",
      Returned: "",
    });

    temp.push({
      Item: "",
      Quantity: "",
      Ordered: "",
      Stock: "",
      Delivered: "",
      Returned: "",
    });

    dToolsProjectItems &&
    dToolsProjectItems.filter(x => !x.found).forEach((item) => {
        temp.push({
          Item: item.itemFullName || item.Manufacturer + ": " + item.Model,
          Quantity: item.Quantity || "",
          Ordered: item.ordered || "",
          Stock: item.stock || "",
          Delivered: item.delivered || "",
          Returned: item.returned || "",
        });
      });



  return temp;
}
