import { firestore } from "utils/firebase";
import { getQboURL, sandboxCompanies } from "utils/helpers";
const baseURL = 'https://tracknicity.com/admin/'
// const baseURL = 'http://localhost:8080/admin/'

export const approveQbAdjustment = (company, historyItem, parts) => {
    
    const {id, from, approved, custom, partID, synced} = historyItem
    const part = parts.find(x => x.id===partID)
    const receivedToLocation = from === "Received To Location"
    if (approved && !custom && !receivedToLocation) {
        if (!company.qbo) {
            removeFromQueue(company, historyItem);
        }
    }

    //if it's already been synced before, we want to set sync to false 
    firestore.collection('companies').doc(company.id).collection('history').doc(id).update({
        approved: !approved,
        approvalDate: new Date(),
        synced: false
    })
    .then(function() {
        // console.log("Document successfully updated!");
        if (!approved) {
            // we're approving so we need to add to queues
            if (!custom && !receivedToLocation) {
                if (part.type === "NonInventory" && company.nonInventoryCreateCheck) {
                    //NON INVENTORY PART AND CREATE CHECK SELECTED
                    addCheckQueue(company, historyItem, part);
                }
                // if ((part.type == "NonInventory") && (company.nonInventoryCreateInvoice)) {
                //     //NON INVENTORY PART AND CREATE INVOICE SELECTED
                //     addInvoiceQueue(company, historyItem, part);
                // }
                if (part.type === "Inventory" && (company.inventoryCreateInvoice || company.inventoryCreateFullInvoice)) {
                    //INVENTORY PART AND CREATE INVOICE SELECTED
                    addInvoiceQueue(company, historyItem, part);
                }
            }

        }
    });
    
}

function addCheckQueue(company, historyItem, part) {

    const cost = part.cost;
    const price = part.price || cost;

    // console.log(part.price)

    //IF IT'S AN INTERNAL INVENTORY MOVE, WE DON'T NEED TO DO ANYTHING IN QuickBooks
    if (historyItem.fromClientId || historyItem.toClientId) {
        let url = baseURL+"app/addCheck.php";
        const realmId = company.qboRealmId ? company.qboRealmId : ''
        const accessToken = company.accessToken ? company.accessToken : ''
        const refreshToken = company.qboRefreshToken ? company.qboRefreshToken : ''
        if (company.qbo) {
            url = baseURL+"app/qbo/qbo-addCheck.php"
            if (sandboxCompanies.includes(company.id)) {
                url = baseURL+"app/qbo/qbo-addCheckForSandbox.php"
            }
        }

        if (historyItem.toClientId) {

            const body =  {
                realmId: realmId,
                accessToken: accessToken,
                refreshToken: refreshToken,
                companyDocId: company.id, 
                account: company.checkAccount, 
                cost: cost, 
                price: price,
                customerName: historyItem.toClientId, 
                historyDocId: historyItem.id,
                itemName: historyItem.partID, 
                quantity: historyItem.qty
            }
    
            const data = new FormData()
            for ( var key in body ) {
                data.append(key, body[key]);
            }
    
            //add no cors to the fetch request to avoid cors issues
            const requestOptions = {
                method: 'POST',
                body: data,
                mode: 'no-cors'
            };

            //Part is going TO a client so the client needs billed

            fetch(url, requestOptions)
            .then(response => response.text())
            .then(data => {
                console.log('result toClientId add check queue',data);
            })
            .catch(e => {
                console.log('error toClientId add check queue', e)
            })
        }

        if (historyItem.fromClientId) {
            const body =  {
                realmId: realmId,
                accessToken: accessToken,
                refreshToken: refreshToken,
                companyDocId: company.id, 
                account: company.checkAccount, 
                cost: -cost, 
                price: -price,
                customerName: historyItem.fromClientId, 
                historyDocId: historyItem.id,
                itemName: historyItem.partID, 
                quantity: historyItem.qty
            }
    
            const data = new FormData()
            for ( var key in body ) {
                data.append(key, body[key]);
            }
    
            const requestOptions = {
                method: 'POST',
                body: data
            };

            //Part is going TO a client so the client needs billed

            fetch(url, requestOptions)
            // .then(response => response.json())
            .then(data => {
                // console.log('result fromClientId add check queue',data);
            })
            .catch(e => {
                console.log('error fromClientId add check queue', e)
            })
        }
    }

}

function removeFromQueue(company, historyItem) {
    //IF IT'S AN INTERNAL INVENTORY MOVE, WE DON'T NEED TO DO ANYTHING IN QuickBooks
    if ((historyItem.fromClientId != "") || (historyItem.toClientId !="")) {

        if (company.qbo) return

        const body =  {
            companyDocId: company.id, 
            historyDocId: historyItem.id
        }

        const data = new FormData()
        for ( var key in body ) {
            data.append(key, body[key]);
        }

        const requestOptions = {
            method: 'POST',
            body: data
        };

        //Part is going TO a client so the client needs billed

        fetch(baseURL+"app/removeFromQueue.php", requestOptions)
        // .then(response => response.json())
        .then(data => {
            // console.log('result removing from check queue',data);
        })
        .catch(e => {
            console.log('error removing from check queue', e)
        })
    }
}

function addInvoiceQueue(company, historyItem, part) {

    const {cost, price} = part

    var url = baseURL+"app/addInvoice.php"

    //IF IT'S AN INTERNAL INVENTORY MOVE, WE DON'T NEED TO DO STUFF IN QuickBooks
    if (historyItem.fromClientId || historyItem.toClientId) {

        if (historyItem.toClientId) {
            //Part is going TO a client so the client needs billed
            const body =  {
                companyDocId: company.id, 
                account: company.checkAccount, 
                cost: company?.inventoryCreateFullInvoice ? price : 0, 
                customerName: historyItem.toClientId, 
                historyDocId: historyItem.id,
                itemName: historyItem.partID, 
                quantity: historyItem.qty,
                class: ''
            }
    
            const data = new FormData()
            for ( var key in body ) {
                data.append(key, body[key]);
            }
    
            const requestOptions = {
                method: 'POST',
                body: data
            };

            //Part is going TO a client so the client needs billed

            fetch(url, requestOptions)
            // .then(response => response.json())
            .then(data => {
                // console.log('result toClientId add invoice queue',data);
            })
            .catch(e => {
                console.log('error toClientId add invoice queue', e)
            })
        }
        if (historyItem.fromClientId) {
            //Part is going TO company so the client needs credited
            const body =  {
                companyDocId: company.id, 
                account: company.checkAccount, 
                cost: company?.inventoryCreateFullInvoice ? price : 0, 
                customerName: historyItem.fromClientId, 
                historyDocId: historyItem.id,
                itemName: historyItem.partID, 
                quantity: -historyItem.qty,
                class: ''
            }
    
            const data = new FormData()
            for ( var key in body ) {
                data.append(key, body[key]);
            }
    
            const requestOptions = {
                method: 'POST',
                body: data
            };

            //Part is going TO a client so the client needs billed

            fetch(url, requestOptions)
            // .then(response => response.json())
            .then(data => {
                // console.log('result toClientId add invoice queue',data);
            })
            .catch(e => {
                console.log('error toClientId add invoice queue', e)
            })
            
        }
    }

}

export const fetchQboSyncData = (company, setQboSyncData) => {
    // console.log(companyId, userId)
    return firestore
    .collection('companies')
    .doc(company.id)
    .collection('qboSyncData')
    .doc('lastSync')
    .onSnapshot(doc => {
        if (doc.exists) {
            const data = doc.data()
            const lastSync = {
                id: doc.id,
                ...data
            }
            setQboSyncData(lastSync)
        }
  
    })
}

export function syncItems(company) {

    const URL = getQboURL(company)

    const body =  {
        companyDocId: company.id,
        realmId: company.qboRealmId,
        accessToken: company.qboAccessToken,
        refreshToken: company.qboRefreshToken,
        defaultLocationId: company.defaultLocationId,
        defaultLocationName: company.defaultLocationName
    }

    const data = new FormData()
    for ( var key in body ) {
        data.append(key, body[key]);
    }

    const requestOptions = {
        method: 'POST',
        body: data
    };

    fetch(URL+"syncItems", requestOptions)
    .then(response => response.json())
    .then(data => {
        if (data.success) {
            firestore
            .collection('companies')
            .doc(company.id)
            .collection('qboSyncData')
            .doc('lastSync')
            .set({
                items: new Date()
            }, {merge: true})
        } else {
            console.log('error', data)
        }
        // console.log('result removing from check queue',data);
    })
    .catch(e => {
        console.log('error in items sync fetch', e)
    })
}

export function syncCustomers(company) {

    const URL = getQboURL(company)

    const body =  {
        companyDocId: company.id,
        realmId: company.qboRealmId,
        accessToken: company.qboAccessToken,
        refreshToken: company.qboRefreshToken,
        defaultLocationId: company.defaultLocationId,
        defaultLocationName: company.defaultLocationName
    }

    const data = new FormData()
    for ( var key in body ) {
        data.append(key, body[key]);
    }

    const requestOptions = {
        method: 'POST',
        body: data
    };

    fetch(URL+"syncCustomers", requestOptions)
    .then(response => response.json())
    .then(data => {
        if (data.success) {
            firestore
            .collection('companies')
            .doc(company.id)
            .collection('qboSyncData')
            .doc('lastSync')
            .set({
                customers: new Date()
            }, {merge: true})
        } else {
            console.log('error', data)
        }
        // console.log('result removing from check queue',data);
    })
    .catch(e => {
        console.log('error in items sync fetch', e)
    })
}

export function syncAccounts(company) {

    const URL = getQboURL(company)

    const body =  {
        companyDocId: company.id,
        realmId: company.qboRealmId,
        accessToken: company.qboAccessToken,
        refreshToken: company.qboRefreshToken,
        defaultLocationId: company.defaultLocationId,
        defaultLocationName: company.defaultLocationName
    }

    const data = new FormData()
    for ( var key in body ) {
        data.append(key, body[key]);
    }

    const requestOptions = {
        method: 'POST',
        body: data
    };

    fetch(URL+"syncAccounts", requestOptions)
    .then(response => response.json())
    .then(data => {
        if (data.success) {
            firestore
            .collection('companies')
            .doc(company.id)
            .collection('qboSyncData')
            .doc('lastSync')
            .set({
                accounts: new Date()
            }, {merge: true})
        } else {
            console.log('error', data)
        }
        // console.log('result removing from check queue',data);
    })
    .catch(e => {
        console.log('error in items sync fetch', e)
    })
}

export function syncEstimates(company) {

    const URL = getQboURL(company)

    const body =  {
        companyDocId: company.id,
        realmId: company.qboRealmId,
        accessToken: company.qboAccessToken,
        refreshToken: company.qboRefreshToken,
        defaultLocationId: company.defaultLocationId,
        defaultLocationName: company.defaultLocationName
    }

    const data = new FormData()
    for ( var key in body ) {
        data.append(key, body[key]);
    }

    const requestOptions = {
        method: 'POST',
        body: data
    };

    fetch(URL+"syncEstimates", requestOptions)
    .then(response => response.json())
    .then(data => {
        return console.log('sync response',data);
        if (data.success) {
            firestore
            .collection('companies')
            .doc(company.id)
            .collection('qboSyncData')
            .doc('lastSync')
            .set({
                estimates: new Date()
            }, {merge: true})
        } else {
            console.log('error', data)
        }
        // console.log('result removing from check queue',data);
    })
    .catch(e => {
        console.log('error in items sync fetch', e)
    })
}

export function syncPurchaseOrders(company) {

    const URL = getQboURL(company)

    const body =  {
        companyDocId: company.id,
        realmId: company.qboRealmId,
        accessToken: company.qboAccessToken,
        refreshToken: company.qboRefreshToken,
        defaultLocationId: company.defaultLocationId,
        defaultLocationName: company.defaultLocationName
    }

    const data = new FormData()
    for ( var key in body ) {
        data.append(key, body[key]);
    }

    const requestOptions = {
        method: 'POST',
        body: data
    };

    fetch(URL+"syncPurchaseOrders", requestOptions)
    .then(response => response.json())
    .then(data => {
        if (data.success) {
            firestore
            .collection('companies')
            .doc(company.id)
            .collection('qboSyncData')
            .doc('lastSync')
            .set({
                purchaseOrders: new Date()
            }, {merge: true})
        } else {
            console.log('error', data)
        }
        // console.log('result removing from check queue',data);
    })
    .catch(e => {
        console.log('error in items sync fetch', e)
    })
}

export function syncTimeActivities(company) {

    const URL = getQboURL(company)

    const body =  {
        companyDocId: company.id,
        realmId: company.qboRealmId,
        accessToken: company.qboAccessToken,
        refreshToken: company.qboRefreshToken,
        defaultLocationId: company.defaultLocationId,
        defaultLocationName: company.defaultLocationName
    }

    const data = new FormData()
    for ( var key in body ) {
        data.append(key, body[key]);
    }

    const requestOptions = {
        method: 'POST',
        body: data
    };

    fetch(URL+"syncTimeActivities", requestOptions)
    .then(response => response.json())
    .then(data => {
        if (data.success) {
            firestore
            .collection('companies')
            .doc(company.id)
            .collection('qboSyncData')
            .doc('lastSync')
            .set({
                timeActivities: new Date()
            }, {merge: true})
        } else {
            console.log('error', data)
        }
        // console.log('result removing from check queue',data);
    })
    .catch(e => {
        console.log('error in items sync fetch', e)
    })
}

