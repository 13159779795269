import React from 'react';
import {StyleSheet, View, Dimensions, TouchableOpacity} from 'react-native';
import { Colors } from '~/constants/Colors';
import { PrimaryText } from '~/components/Common/PrimaryText';


const {height, width} = Dimensions.get('window')


export const AuditCard = ({
  item,
  onPress
}) => {

  let style = styles(false)

  return (
    <TouchableOpacity style={style.card} onPress={onPress} >
      <PrimaryText fontSize={14} fontWeight={400}>{item.createdAt.toDate().toDateString()}</PrimaryText>
      <View style={{flexDirection: 'row', alignItems:'center'}}>
        <PrimaryText fontSize={14} fontWeight={400}>{item.complete ? 'Complete' : 'Pending'}</PrimaryText>
      </View>
    </TouchableOpacity>
  )
}


export const styles = darkMode => StyleSheet.create({
  card: {
    paddingVertical: 8,
    flexDirection: 'row',
    alignItems:'center',
    justifyContent:'space-between',
    borderColor: Colors.primary.lightGray,
    borderBottomWidth: 1,
    backgroundColor: Colors.primary.white,
  },

});
