import React, { useEffect, useState } from "react";
import { StyleSheet, View, Dimensions, ActivityIndicator, FlatList } from "react-native";
import { Colors } from "constants/Colors";
import { Header } from "components/Header";
import { useCompany } from "context/CompanyContext";
import { PrimaryText } from "components/Common/PrimaryText";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import { useParts } from "context/PartsContext";
import { ValueByLocationCard } from "components/Cards/ValueByLocationCard";
import { formatInventoryValueByLocationReport } from "utils/exports-helper";

const {width} = Dimensions.get('window');

export function ValueByLocation() {

  const navigate = useNavigate()

  const {locations} = useCompany()
  const {parts, locationParts} = useParts()

  const [filtered, setFiltered] = useState(null)

  const [loading, setLoading] = useState(true)

  function sum(items) {
    const sum = items && items?.length>0 ? items.map(x => x.totalValue).reduce((a,b) => {return a+b}) : 0
    return(sum)
  }

  useEffect(() => {

    const temp = []
    locations.filter(x => x.active).forEach(location => {
      const filteredLocationParts = locationParts.filter(x => x.locationID===location.id)
      filteredLocationParts.forEach(locationPart => {
        const part = parts.find(x => x.id===locationPart.partID)
        part ? locationPart.totalValue = part.cost*locationPart.locationQty : locationPart.totalValue = 0
      })
      const totalValue = (sum(filteredLocationParts))
      const tempItem = {
        ...location,
        totalValue,
      }
      temp.push(tempItem)
    })
    
    setFiltered(temp.sort((a,b) => a.totalValue<b.totalValue ? 1 : -1))
    setLoading(false)
  }, [])

  return (
    <View style={styles.container}>
      <Header 
      title={'Value By Location'}
      onBack={() => navigate(-1)}
      exportData={formatInventoryValueByLocationReport(filtered)}
      />

        
      {filtered && filtered?.length>0 ?
      <View style={styles.body}>
        <View style={styles.detailLabelContainer}>
            <PrimaryText fontSize={14} fontWeight={400} style={{flex: 1, color: Colors.primary.white}} >Item</PrimaryText>
            <View style={{flexDirection:'row'}}>
                <PrimaryText fontSize={14} fontWeight={400} style={[styles.detailLabel, {width: width*0.12}]} >Total Value</PrimaryText>
            </View>
        </View>

        <FlatList
        style={{flex: 1, width: '100%', borderTopWidth: 1, borderColor: Colors.primary.lightGray}}
        data={filtered}
        keyExtractor={item => item.id}
        renderItem={({ item, index }) => {
            
            return (
                <ValueByLocationCard
                item={item}
                />
            )
        }}
        />

      </View>
      :
          <View style={styles.noCustomerContainer}>
              <PrimaryText fontSize={16} fontWeight={400} >
                  {`No values to report`}
              </PrimaryText>
          </View>
      }

      {loading &&
            <ActivityIndicator 
            animating={loading}
            size={'large'} 
            style={StyleSheet.absoluteFill} color={Colors.primary.blue} 
            />
        }
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
      flex: 1,
      width: '100%',
      flexDirection: "column",
      backgroundColor: Colors.primary.white,
  },
  button: {
    backgroundColor: Colors.primary.green,
    padding: 12,
    justifyContent:'center',
    alignItems:'center',
    borderRadius: 4
  },
  noCustomerContainer:{
      flex: 1,
      justifyContent:'center',
      alignItems:'center'
  },
  body: {
      flex: 1,
      width: '100%',
      backgroundColor: Colors.primary.white,
      marginTop: 12,
      alignItems:'center'
  },
  optionContainer: {
    paddingHorizontal: 12,
    // width: 300,
    flexDirection: 'row',
    alignItems:'center',
    // justifyContent:'space-between',
    marginVertical: 12
  },
  legend: {
    flexDirection:'row', 
    justifyContent:'center', 
    marginVertical: 8
  },

  //details
  detailLabelContainer: {
    width: '100%',
    flexDirection:'row',
    justifyContent:'space-between',
    paddingHorizontal: 12,
    marginTop: 12,
    backgroundColor: Colors.primary.gray
  },
  detailLabel: {
      width: width*0.06,
      textAlign:'right',
      color: Colors.primary.white
  },


  //modal
  modal: {
    // justifyContent: 'flex-end',
    margin: 0
  },
  modalContent: {
    // height: height*0.6,
    width: width,
    backgroundColor: Colors.primary.white,
    borderRadius: 12,
    padding: 12,
    // alignItems: 'center'
  },
  modalSwipeButton: {
    width: 32, 
    height: 3, 
    backgroundColor: Colors.primary.darkGray, 
    borderRadius: 3, 
    marginBottom: 12, 
    alignSelf:'center'
  },
  description: {
    marginHorizontal: 12,
    marginVertical: 4
  },
  lineBreak: {
    height: 1,
    backgroundColor: Colors.primary.lightGray,
    margin: 12,
  }


});
