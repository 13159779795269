import React, { useEffect, useState } from "react";
import {
  StyleSheet,
  View,
  ActivityIndicator,
  Alert,
  FlatList,
} from "react-native";
import { Colors } from "constants/Colors";
import { Header } from "components/Header";
import { useParts } from "context/PartsContext";
import { CustomInput } from "components/Inputs/CustomInput";
import * as FaIcons from "react-icons/fa";
import { ItemCheckoutStagingCard } from "components/Cards/ItemCheckoutStagingCard";
import { useCustomers } from "context/CustomersContext";
import { useCompany } from "context/CompanyContext";
import cloneDeep from "lodash/cloneDeep";
import { PrimaryText } from "components/Common/PrimaryText";
import { useAuth } from "context/AuthContext";
import { useLocation, useNavigate } from "react-router-dom";
import { getCustomerStagedItems } from "services/purchaseOrders-service";
import { submitStagingTransaction } from "services/transactions-service";
import { TouchableOpacity } from "react-native-web";
import { BiBarcode, BiQrScan } from "react-icons/bi";
import { Scanner } from "components/Scanner/Scanner";
import { POScanner } from "components/Scanner/POScanner";

export function StagingAreaTransaction(props) {
  const navigate = useNavigate();

  const params = useLocation();

  const { company, stagingAreaSubLocations } = useCompany();
  const { currentUser } = useAuth();
  const { selectedCustomer } = useCustomers();
  const { parts, manufacturers } = useParts();

  const [returning, setReturning] = useState(false);

  const [stagedParts, setStagedParts] = useState(null);
  const [filtered, setFiltered] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const [search, setSearch] = useState(null);

  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [loading, setLoading] = useState(false);

  const [showScanner, setShowScanner] = useState(false);
  const [scannedItem, setScannedItem] = useState("");

  useEffect(() => {
    const isReturning = params.state?.to;
    setReturning(isReturning);
    getCustomerStagedItems(
      company,
      selectedCustomer,
      parts,
      manufacturers,
      gotStagedParts,
      isReturning
    );

    if (params.state?.item) {
      // console.log("item", params.state.item);
      selectedStagedItem(params.state.item);
    }
  }, []);

  useEffect(() => {
    if (search && stagedParts) {
      const temp = stagedParts.filter(
        (x) =>
          x.itemFullName.toLowerCase().includes(search.toLowerCase()) ||
          x.itemFullName.toLowerCase().includes(search.toLowerCase())
      );
      setFiltered(temp);
    } else {
      setFiltered(stagedParts);
    }
  }, [search, stagedParts]);

  useEffect(() => {
    // console.log("selectedItems", selectedItems);
    setSubmitDisabled(selectedItems.length === 0);
  }, [selectedItems]);

  function gotStagedParts(items) {
    setStagedParts(items);
    setFiltered(items);
  }

  function stagedItemQtyChanged(index, qty) {
    filtered[index].quantityToDeliver = qty;
    // checkSubmitDisabled()
  }

  function selectedStagedItem(item) {
    // console.log(item)
    const cloned = [...selectedItems];
    const find = cloned.find((x) => x?.id === item?.id);
    if (find) {
      //remove
      setSelectedItems(cloned.filter((x) => x.id !== item.id));
    } else {
      cloned.push(item);
      setSelectedItems(cloned);
    }
  }

  function submitTransaction() {
    setSubmitDisabled(true);
    setLoading(true);

    submitStagingTransaction(
      company,
      currentUser,
      selectedItems,
      stagedParts,
      returning,
      selectedCustomer,
      navigate,
      setLoading,
      setSubmitDisabled
    );
  }



  return (
    <View style={styles.container}>
      <Header
        title={returning ? "Return Staged Items" : "Deliver Staged Items"}
        onBack={() => navigate(-1)}
        onRight={() => submitTransaction()}
        rightText={"Submit"}
        rightDisabled={submitDisabled}
      />
      <PrimaryText
        fontSize={16}
        fontWeight={500}
        style={{ marginVertical: 4, textAlign: "center" }}
        numberOfLines={1}
      >
        {selectedCustomer?.name}
      </PrimaryText>
      <PrimaryText
        fontSize={12}
        fontWeight={400}
        style={{ marginVertical: 4, textAlign: "center" }}
        numberOfLines={1}
      >
        Staging Area:{" "}
        {selectedCustomer?.stagingArea
          ? stagingAreaSubLocations?.find(
            (x) => x.id === selectedCustomer?.stagingArea
          )?.name
          : "Default"}
      </PrimaryText>
      <TouchableOpacity
        onPress={() => setShowScanner(true)}
        style={[styles.itemButtons, { marginBottom: 6 }]}
      >
        <PrimaryText
          fontSize={14}
          fontWeight={400}
          style={styles.itemButtonText}
        >
          {"Scan Items"}
        </PrimaryText>
        <BiQrScan name="magnify" color={Colors.primary.white} size={20} />
      </TouchableOpacity>
      <CustomInput
        placeholder={"Search Items"}
        style={{ marginHorizontal: 12 }}
        onChange={setSearch}
        value={search}
      />

      <View style={styles.body}>
        <View style={styles.legend}>
          {!returning &&
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                marginRight: 12,
              }}
            >
              <FaIcons.FaSquare
                name="square-medium"
                color={Colors.primary.red}
                size={8}
                style={{ marginRight: 4 }}
              />
              <PrimaryText fontSize={16} fontWeight={400}>
                {"Ordered"}
              </PrimaryText>
            </View>
          }
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <FaIcons.FaSquare
              name="square-medium"
              color={Colors.primary.blue}
              size={8}
              style={{ marginRight: 4 }}
            />
            <PrimaryText fontSize={16} fontWeight={400}>
              {returning ? "Delivered" : "Received/From Stock"}
            </PrimaryText>
          </View>
        </View>

        <FlatList
          style={{ flex: 1 }}
          contentContainerStyle={{ backgroundColor: Colors.primary.white }}
          data={filtered}
          keyExtractor={(item) => item.id}
          renderItem={({ item, index }) => {
            return (
              <ItemCheckoutStagingCard
                onQuantityChanged={(qty) => stagedItemQtyChanged(index, qty)}
                item={item}
                selectedStagedItem={() => selectedStagedItem(item)}
                selectedItems={selectedItems}
                returning={returning}
              />
            );
          }}
        />
      </View>

      <POScanner
        // editing={true}
        showScanner={showScanner}
        setShowScanner={setShowScanner}
        keepScannerOpen={true}
        title={"Scan To Search"}
        stagedParts={stagedParts}
        selectedStagedItem={selectedStagedItem}
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
      // setUpc={setUpc}
      />

      {loading && (
        <ActivityIndicator
          size={"large"}
          animating
          color={Colors.primary.blue}
          style={StyleSheet.absoluteFill}
        />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: "100%",
    flexDirection: "column",
    backgroundColor: Colors.primary.white,
  },
  body: {
    flex: 1,
    paddingHorizontal: 12,
  },
  legend: {
    flexDirection: "row",
    justifyContent: "center",
    marginVertical: 8,
  },
  itemButtons: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
    backgroundColor: Colors.primary.red,
    borderRadius: 4,
    paddingVertical: 6,
    marginHorizontal: 4,
    width: "30%",
  },
  itemButtonText: {
    color: Colors.primary.white,
    marginRight: 6,
  },
});
