import React from 'react';
import {StyleSheet, View, Dimensions, TouchableOpacity} from 'react-native';
import {Colors} from 'constants/Colors';
import { PrimaryText } from 'components/Common/PrimaryText';
import * as FaIcons from 'react-icons/fa'
import * as MdIcons from 'react-icons/md'



const {height, width} = Dimensions.get('window')


export const EditInventoryLocationCard = ({
  item,
  user,
  isDefault,
  onArchive,
  onEdit,
  onMakeDefault,
  subLocation,
  onAssignItems
}) => {

  return (
    <View 
    style={[styles.card, item.fromStock && {backgroundColor: Colors.primary.lightGray, borderColor: Colors.primary.white}]}
    >
      <PrimaryText fontSize={13} fontWeight={400} style={{flex: 1, marginRight: 12}} >{item.name}</PrimaryText>
      <PrimaryText fontSize={13} fontWeight={500} style={{flex: 1, marginBottom: 4}} >{user ? user.firstName+' '+user.lastName : ''}</PrimaryText>

      <View style={{flexDirection: 'row'}}>

        <TouchableOpacity onPress={onEdit} style={[styles.buttonContainer, subLocation && {width: 20}]}>
          <FaIcons.FaEdit size={20} color={Colors.primary.blue} />
        </TouchableOpacity>

        <TouchableOpacity onPress={onArchive} style={styles.buttonContainer}>
          {subLocation ?
          <FaIcons.FaTrash size={20} color={Colors.primary.blue} />
          :
          <FaIcons.FaArchive size={20} color={Colors.primary.blue} />
          }
        </TouchableOpacity>

        {onMakeDefault &&
          <View style={styles.buttonContainer}>
            {isDefault ?
            <FaIcons.FaCheck size={20} color={Colors.primary.green} />
            :
            <TouchableOpacity onPress={onMakeDefault} style={styles.buttonContainer}>
              <MdIcons.MdCheckBoxOutlineBlank size={20} color={Colors.primary.gray} />
            </TouchableOpacity>
            }
          </View>
        }

        {subLocation && onAssignItems &&
          <TouchableOpacity onPress={onAssignItems} style={styles.buttonContainer2}>
            <PrimaryText fontSize={13} fontWeight={400} style={{color: Colors.primary.white}} >Assign Items</PrimaryText>
          </TouchableOpacity>
        }
        
      </View>
    </View>
  )
}


export const styles = StyleSheet.create({
  card: {
    paddingHorizontal: 12,
    paddingVertical: 8,
    flexDirection: 'row',
    alignItems:'center',
    justifyContent:'space-between',
    borderColor: Colors.primary.lightGray,
    borderBottomWidth: 1
  },
  leftContainer: {
    flex: 1,
  },
  buttonContainer: {
    width: 100,
    alignItems: 'center'
  },
  buttonContainer2: {
    width: 100,
    backgroundColor: Colors.primary.blue,
    alignItems:'center',
    justifyContent:'center',
    padding: 4,
    borderRadius: 4,
    // marginLeft: 24
  },

});
