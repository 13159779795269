import React, { memo } from "react"
import { StyleSheet, View, Dimensions, TouchableOpacity } from "react-native"
import { Colors } from "constants/Colors"
import { PrimaryText } from "components/Common/PrimaryText"

const { height, width } = Dimensions.get("window")

const Card = ({ item, onPress, onDeliver, admin, stagingArea }) => {
  let style = styles(false)

  return (
    <TouchableOpacity
      onPress={() => {
        onPress()
        console.log("pressed", item)
      }}
      style={[
        style.card,
        item.fromStock && {
          backgroundColor: Colors.primary.lightGray,
          borderColor: Colors.primary.white,
        },
      ]}
    >
      {/* <TouchableOpacity 
      onPress={onPress}
      style={{width: 40}}
      >
        <MaterialCommunityIcons.MdCheckBoxOutlineBlank size={12} color={Colors.primary.darkGray} />
      </TouchableOpacity> */}
      <View style={style.leftContainer}>
        <PrimaryText
          fontSize={13}
          fontWeight={500}
          style={{ flex: 1, marginBottom: 4 }}
        >
          {item.itemFullName}
        </PrimaryText>
        <PrimaryText fontSize={12} fontWeight={400} style={{ flex: 1, marginBottom: 4 }}>
          {item.customerFullName}
        </PrimaryText>
          <PrimaryText fontSize={13} fontWeight={400} style={{ flex: 1 }}>
            Staging Area: {stagingArea ? stagingArea?.name : 'Default'}
          </PrimaryText>
      </View>
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        <PrimaryText fontSize={13} fontWeight={400} style={style.text}>
          {item.quantity}
        </PrimaryText>
        <PrimaryText fontSize={13} fontWeight={400} style={style.text}>
          {item.fromStock ? "Stock" : item.receivedQuantity}
        </PrimaryText>
        <PrimaryText fontSize={13} fontWeight={400} style={style.text}>
          {item.deliveredQuantity ? item.deliveredQuantity : 0}
        </PrimaryText>
        {admin && (
          <View style={style.deliverButtonContainer}>
            <TouchableOpacity onPress={onDeliver} style={style.deliverButton}>
              <PrimaryText
                fontSize={13}
                fontWeight={400}
                style={{ color: Colors.primary.white }}
              >
                Deliver
              </PrimaryText>
            </TouchableOpacity>
          </View>
        )}
      </View>
    </TouchableOpacity>
  )
}

export const StagedItemCard = memo(Card)

export const styles = (darkMode) =>
  StyleSheet.create({
    card: {
      paddingHorizontal: 12,
      marginVertical: 4,
      paddingVertical: 4,
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      borderColor: Colors.primary.lightGray,
      borderBottomWidth: 1,
    },
    leftContainer: {
      flex: 1,
      justifyContent:'center'
    },
    text: {
      // marginHorizontal: 6,
      width: 60,
      textAlign: "right",
    },
    deliverButtonContainer: {
      width: width * 0.06,
      alignItems: "flex-end",
    },
    deliverButton: {
      backgroundColor: Colors.primary.lightBlue,
      padding: 6,
      borderRadius: 4,
    },
  })
