import { auth, firestore } from "utils/firebase";
import { createCompany } from "./company-service";
// import { useAlert } from 'react-alert'



export const login = (email, password, setEmailError, setPasswordError) => {
  auth.signInWithEmailAndPassword(email, password).then(user => {

  }).catch(err => {

    console.log('error signing in', err);
    //return errors

    if (err.code === 'auth/invalid-email') {
      setEmailError('Invalid email address')
    } else {
      setPasswordError('Invalid password')
    }
  })
}

export const logout = (setCurrentUser, clearState) => {
  auth.signOut()
    .then(() => {
      setCurrentUser(null)
      clearState()
      // browserStorage.clearAll()
      localStorage.clear()
    })


}

export const getUser = (companyId, userId, func) => {
  // console.log(companyId, userId)
  firestore.collection('companies').doc(companyId).collection('users').doc(userId)
    .get().then(userDoc => {
      if (userDoc.exists) {
        const user = {
          ...userDoc.data(),
          id: userDoc.id
        }
        func(user)
      } else {
        func(null)
      }
    })
}

export const sendPasswordReset = (email) => {
  // const alert = useAlert()
  auth.sendPasswordResetEmail(email)
    .then(() => {
      window.alert('Password reset email successfully sent')
    })
    .catch(e => {
      window.alert(e.message)
      // console.log('error sending password reset', e)
    })
}

export const signup = (email, password, firstName, lastName, company, onSuccess) => {
  auth.createUserWithEmailAndPassword(email, password)
    .then(user => {
      const data = {
        email,
        firstName,
        lastName,
        userId: user.user.uid,
        company
      }
      createCompany(data, onSuccess)
    })
    .catch(e => {
      console.log('error signing up', e)
    })
}
