import React from 'react';
import {StyleSheet, View, Dimensions, TouchableOpacity} from 'react-native';
import {Colors} from 'constants/Colors';
import { PrimaryText } from 'components/Common/PrimaryText';
import * as FaIcons from 'react-icons/fa'



const {height, width} = Dimensions.get('window')


export const SerialCard = ({
  item,
  onPress,
  onDelete,
  admin
}) => {

  let style = styles(false)

  const part = item.part
  const itemName = part.manufacturer ? part.manufacturer+': '+part.model : part.model

  return (
    <TouchableOpacity 
    onPress={onPress}
    style={[style.card, item.fromStock && {backgroundColor: Colors.primary.lightGray, borderColor: Colors.primary.white}]}
    >
      <PrimaryText fontSize={13} fontWeight={400} style={{flex: 0.5, marginRight: 12}} >{item.serialNum}</PrimaryText>
      <View style={style.leftContainer}>
        <PrimaryText fontSize={13} fontWeight={500} style={{flex: 1, marginBottom: 4}} numberOfLines={1} >{itemName}</PrimaryText>
        <PrimaryText fontSize={12} fontWeight={400} style={{flex: 1}} >{item.customer.fullName}</PrimaryText>
      </View>
      <View style={{flexDirection: 'row', alignItems:'center'}}>
        <PrimaryText fontSize={13} fontWeight={400} style={style.text}>{item.location}</PrimaryText>
        <PrimaryText fontSize={13} fontWeight={400} style={style.text}>{item.mac}</PrimaryText>
        <PrimaryText fontSize={13} fontWeight={400} style={style.text}>{item.additional}</PrimaryText>
        {admin &&
        <View style={style.deleteButtonContainer}>
          <TouchableOpacity 
          onPress={onDelete}
          style={style.deleteButton}
          >
            <FaIcons.FaTrashAlt size={14} color={Colors.primary.white}/>
          </TouchableOpacity>
        </View>
        }
        
      </View>
    </TouchableOpacity>
  )
}


export const styles = darkMode => StyleSheet.create({
  card: {
    paddingHorizontal: 12,
    paddingVertical: 8,
    flexDirection: 'row',
    alignItems:'center',
    justifyContent:'space-between',
    borderColor: Colors.primary.lightGray,
    borderBottomWidth: 1
  },
  leftContainer: {
    flex: 1,
  },
  text: {
    // marginHorizontal: 6,
    width: 200,
    textAlign:'right'
  },
  deleteButtonContainer: {
    width: width*0.06, 
    alignItems:'flex-end'
  },
  deleteButton: {
    backgroundColor: Colors.primary.red, 
    padding: 8,
    borderRadius: 4,
  }

});
