import React from "react";
import {
  StyleSheet,
  View,
  Dimensions,
  TouchableOpacity,
} from "react-native";
import { Colors } from "~/constants/Colors";
import { PrimaryText } from "~/components/Common/PrimaryText";
import * as FaIcons from "react-icons/fa";
import { TableCell, TableRow } from "@mui/material";

const { height, width } = Dimensions.get("window");

export const OpenPurchaseOrderCard = ({
  item,
  onPress,
  admin,
  onDelete,
  open = true,
  onPrint = false,
  selectedCustomer,
  onEdit,
}) => {
  let style = styles(false);


  const {
    returnedQuantity = 0,
    quantity = 0,
    receivedQuantity = 0,
    deliveredQuantity = 0,
    itemFullName,
    customerFullName = ""
  } = item

  return (


    <TableRow
      onClick={onPress}
    >
      <TableCell>
        <View style={style.leftContainer}>

          <PrimaryText fontSize={13} fontWeight={500} style={{ flex: 1 }} >{itemFullName}</PrimaryText>

          <PrimaryText fontSize={12} fontWeight={400} style={{ flex: 1 }} >{customerFullName}</PrimaryText>

        </View>
      </TableCell>

      <TableCell align="center">
        <PrimaryText
          fontSize={13}
          fontWeight={400}
          style={style.text}
        >
          {quantity - returnedQuantity}
        </PrimaryText>
      </TableCell>
      <TableCell align="center">
        <PrimaryText
          fontSize={13}
          fontWeight={400}
          style={style.text}
        >
          {receivedQuantity - returnedQuantity}
        </PrimaryText>
      </TableCell>
      <TableCell align="center">
        <PrimaryText
          fontSize={13}
          fontWeight={400}
          style={style.text}
        >
          {deliveredQuantity - returnedQuantity}
        </PrimaryText>
      </TableCell>
      <TableCell align="center">
        <PrimaryText
          fontSize={13}
          fontWeight={400}
          style={style.text}
        >
          {item?.poNumber}
        </PrimaryText>
      </TableCell>

      {admin && (
        <TableCell align="center">
          <View style={style.deleteButtonContainer}>
            <TouchableOpacity onPress={onDelete} style={style.deleteButton}>
              <FaIcons.FaTrashAlt size={14} color={Colors.primary.white} />
            </TouchableOpacity>
          </View>
        </TableCell>
      )}


    </TableRow>

  );
};

const styles = (darkMode) =>
  StyleSheet.create({

    text: {
      // width: width * 0.06,
      textAlign: 'center',
    },
    deleteButtonContainer: {
      // width: width * 0.03,
      alignItems: "center",
    },
    deleteButton: {
      backgroundColor: Colors.primary.red,
      padding: 8,
      borderRadius: 4,
    },
    editButton: {
      backgroundColor: Colors.primary.gray,
      padding: 8,
      borderRadius: 4,
    },
    printButtonContainer: {
      // width: width * 0.03,
      alignItems: "center",
    },
    printButton: {
      backgroundColor: Colors.primary.green,
      padding: 8,
      borderRadius: 4,
    },
    leftContainer: {
      flex: 1,
    },
    deleteButtonContainer: {
      // width: width * 0.03,
      alignItems: "center",
    },
    deleteButton: {
      backgroundColor: Colors.primary.red,
      padding: 8,
      borderRadius: 4,
    },
  });
