import React from "react"
import {
  StyleSheet,
  View,
  Dimensions,
} from "react-native"
import { Colors } from "constants/Colors"
import { Header } from "components/Header"
import { PrimaryText } from "components/Common/PrimaryText"
import "react-datepicker/dist/react-datepicker.css"
import { useNavigate } from "react-router-dom"

const { width } = Dimensions.get("window")

export function DToolsOverview() {
  const navigate = useNavigate()


  return (
    <View style={styles.container}>
      <Header
        title={"Overview"}
        onBack={() => navigate(-1)}
      // exportData={formatAgingItemsReport(filtered)}
      />

      <View style={styles.body}>
        <View style={{ width: "100%", paddingHorizontal: 12 }}>
          <PrimaryText
            fontSize={16}
            fontWeight={300}
            style={{ marginBottom: 12 }}
          >
            Tracknicity pulls all Items, Projects and Purchase Orders from D-Tools.  Read about these individual aspects below.
          </PrimaryText>

          <ol>
            <li>
              <PrimaryText
                fontSize={16}
                fontWeight={500}
                style={{ marginBottom: 12 }}
              >
                Items
              </PrimaryText>
              <ol type="a">
                <li>
                  <PrimaryText
                    fontSize={14}
                    fontWeight={400}
                    style={{ marginBottom: 12 }}
                  >
                    All Items in your D-Tools catalog will be imported into Tracknicity to be used as inventory items
                  </PrimaryText>
                  <ol type="i">
                    <li>
                      <PrimaryText
                        fontSize={14}
                        fontWeight={400}
                        style={{ marginBottom: 12 }}
                      >
                        When an item in D-Tools is marked inactive, it will show up as “Archived” in Tracknicity
                      </PrimaryText>
                    </li>
                    <li>
                      <PrimaryText
                        fontSize={14}
                        fontWeight={400}
                        style={{ marginBottom: 12 }}
                      >
                        You can keep items active in D-Tools, but hide them in Tracknicity by having an administrator archive them on the Tracknicity web dashboard.
                      </PrimaryText>
                    </li>
                  </ol>
                  <li>
                    <PrimaryText
                      fontSize={14}
                      fontWeight={400}
                      style={{ marginBottom: 12 }}
                    >
                      If you need to add a new item to Tracknicity, you need to add it to your D-Tools catalog first.  This will get it to populate in Tracknicity.
                    </PrimaryText>
                  </li>
                </li>
              </ol>
            </li>

            <li>
              <PrimaryText
                fontSize={16}
                fontWeight={500}
                style={{ marginBottom: 12 }}
              >
                Projects
              </PrimaryText>
              <ol type="a">
                <li>
                  <PrimaryText
                    fontSize={14}
                    fontWeight={400}
                    style={{ marginBottom: 12 }}
                  >
                    All “Approved” Projects are imported to Tracknicity
                  </PrimaryText>
                  <ol type="i">
                    <li>
                      <PrimaryText
                        fontSize={14}
                        fontWeight={400}
                        style={{ marginBottom: 12 }}
                      >
                        Depending on how you have your D-Tools automation settings set up, when a Project status is changed to “Approved” in D-Tools, it will show up in Tracknicity.  These settings can be changed.  Click here to learn more (<a href="https://komododecks.com/recordings/QRRXvGDmwVNuEhj321gG">here</a>)
                      </PrimaryText>
                    </li>

                  </ol>
                  <li>
                    <PrimaryText
                      fontSize={14}
                      fontWeight={400}
                      style={{ marginBottom: 12 }}
                    >
                      You can have multiple projects per customer in Tracknicity, just like in D-Tools
                    </PrimaryText>
                  </li>
                  <ol type="i">
                    <li>
                      <PrimaryText
                        fontSize={14}
                        fontWeight={400}
                        style={{ marginBottom: 12 }}
                      >
                        If a selected Customer does not have any active Jobs under it, the main Customer will be selected for the given transaction or report.
                      </PrimaryText>
                    </li>
                  </ol>
                </li>
              </ol>
            </li>

            <li>
              <PrimaryText
                fontSize={16}
                fontWeight={500}
                style={{ marginBottom: 12 }}
              >
                Projects
              </PrimaryText>
              <ol type="a">
                <li>
                  <PrimaryText
                    fontSize={14}
                    fontWeight={400}
                    style={{ marginBottom: 12 }}
                  >
                    All Purchase Orders in D-Tools will be imported to Tracknicity. This allows your team to track the status of an item from ordering all the way to delivery of the item to the customer. Your team can also set notifications on an item so they are alerted when it has been received to the warehouse.
                  </PrimaryText>

                  <li>
                    <PrimaryText
                      fontSize={14}
                      fontWeight={400}
                      style={{ marginBottom: 12 }}
                    >
                      Purchase Orders for items that are for a specific job get linked to that Customer's Job and get automatically placed in the staging area. This populates a Pick List of items to be checked out from a staging area. Once the items are checked out, you have historical data of who took the items and when.
                    </PrimaryText>
                  </li>

                  <li>
                    <PrimaryText
                      fontSize={14}
                      fontWeight={400}
                      style={{ marginBottom: 12 }}
                    >
                      Purchase Orders for items that are NOT for a specific job automatically go into your inventory in your default location.  This allows you to purchase items for stock and have your inventory quantity automatically increased by the quantity on the purchase order.  This can be turned off in company settings if you do not use this feature of D-Tools Purchase Orders
                    </PrimaryText>
                  </li>
                </li>
              </ol>
            </li>


          </ol>
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: "100%",
    flexDirection: "column",
    backgroundColor: Colors.primary.white,
  },
  body: {
    flex: 1,
    width: "100%",
    backgroundColor: Colors.primary.white,
    marginTop: 12,
    alignItems: "center",
  },
})
