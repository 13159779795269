import React from 'react';
import {StyleSheet, View, Dimensions} from 'react-native';
import {Colors} from 'constants/Colors';
import { PrimaryText } from 'components/Common/PrimaryText';
import * as MdIcons from 'react-icons/md';

const { width } = Dimensions.get('window')


export const AuditDetailCard = ({
  item,
  name
}) => {

  return (
    <View style={styles.card} >
      {item.approved ? <MdIcons.MdCheck name="check" color={Colors.primary.green} size={16} style={{width: width*0.06}} />
      :
      <PrimaryText fontSize={12} fontWeight={400} style={[styles.detailLabel, {width: width*0.06}]}></PrimaryText>
      }
      <View style={{flex: 1, opacity: item.approved ? 0.6 : 1}}>
        <PrimaryText fontSize={14} fontWeight={400}>{name}</PrimaryText>
        
      </View>

      <PrimaryText fontSize={12} fontWeight={400} style={styles.detailLabel}>{`${item.locationQty}`}</PrimaryText>

      <PrimaryText fontSize={12} fontWeight={600} style={styles.detailLabel}>{item?.newQty}</PrimaryText>

      <PrimaryText fontSize={12} fontWeight={400} style={styles.detailLabel}>{`${item?.newQty!==undefined ? item.newQty-item?.locationQty : ''}`}</PrimaryText>

      {item?.newQty!==undefined ?
        <MdIcons.MdCheck name="check" color={Colors.primary.green} size={16} style={{width: width*0.09}} />
        :
        <PrimaryText fontSize={12} fontWeight={600} style={styles.detailLabel}></PrimaryText>
      }
      
    </View>
  )
}


export const styles = StyleSheet.create({
  card: {
    marginHorizontal: 12,
    paddingVertical: 8,
    flexDirection: 'row',
    alignItems:'center',
    justifyContent:'space-between',
    borderColor: Colors.primary.lightGray,
    borderBottomWidth: 1,
    backgroundColor: Colors.primary.white
  },
  detailLabel: {
    width: width*0.09,
    textAlign:'center',
  },

});
