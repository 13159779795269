import React, { createContext, useContext, useState } from "react";

export const DisplayContext = createContext();

export const DisplayProvider = ({ children }) => {

  const [showSidebar, setShowSidebar] = useState(true)

  return (
    <DisplayContext.Provider value={{
      showSidebar, setShowSidebar
      }}>
      {children}
    </DisplayContext.Provider>
  );
};

export const useDisplay = () => useContext(DisplayContext)
