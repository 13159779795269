import React from "react";
import { StyleSheet, View } from "react-native";
import { Colors } from "constants/Colors";
import { useCompany } from "context/CompanyContext";
import { LocationsSelector } from "components/Locations/LocationsSelector";
import { ReceiveTransaction } from "./ReceiveTransaction";

export function Receive() {

    const {selectedLocation} = useCompany()

    return (
        <View style={styles.container}>
            <View style={styles.body}>
                <LocationsSelector all={false} />
                {!!selectedLocation &&
                    <ReceiveTransaction />
                }
            </View>
            
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        width: '100%',
        backgroundColor: Colors.primary.white,
    },
    body: {
        flex: 1,
        width: '100%',
        flexDirection: "row",
        backgroundColor: Colors.primary.white,
    }

});
